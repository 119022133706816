import { makeAutoObservable } from "mobx";
import {ProcessStatus} from "./AudioPlayer.types";

/**
 * Храним всё состояние (статусы, fileLink, recognizedText, audioId и т.п.)
 * Это "ViewModel" или "Store" для нашего плеера.
 */
export class AudioPlayerStore {
    // Статусы
    uploadStatus: ProcessStatus = "idle";
    recognitionStatus: ProcessStatus = "idle";
    storageStatus: ProcessStatus = "idle";

    // Результаты
    audioId: string = "";
    fileLink: string = "";
    recognizedText: string = "";

    // Колбэки (необязательно держать здесь, можно передавать в Interactor)
    onError?: (msg: string) => void;
    onTextLoaded?: (text: string) => void;
    onLoadComplete?: (audioId: string) => void;

    constructor() {
        makeAutoObservable(this); // MobX будет "слеживать" за всеми полями
    }

    /** Утилиты для изменения статусов */
    setUploadStatus(status: ProcessStatus) {
        this.uploadStatus = status;
    }
    setRecognitionStatus(status: ProcessStatus) {
        this.recognitionStatus = status;
    }
    setStorageStatus(status: ProcessStatus) {
        this.storageStatus = status;
    }
    setAllError(msg: string) {
        this.uploadStatus = "error";
        this.recognitionStatus = "error";
        this.storageStatus = "error";
        this.onError?.(msg);
    }

    /** Храним/читаем итог */
    setAudioId(id: string) {
        this.audioId = id;
    }
    setFileLink(link: string) {
        this.fileLink = link;
    }
    setRecognizedText(text: string) {
        this.recognizedText = text;
        this.onTextLoaded?.(text);
    }

    /** Когда всё успешно */
    notifyComplete() {
        this.onLoadComplete?.(this.audioId);
    }
}
