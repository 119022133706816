// src/components/Onboarding/OnboardingFlowManager.ts
import { makeAutoObservable } from 'mobx';
import { Subscription } from 'rxjs';
import {AppUserManagerProtocol} from "../../../domain/interfaces/AppUserManagerProtocol";
import {AppUser} from "../../../domain/models";

export type OnboardingStep =
    | 'chooseRole'
    | 'chooseLanguages'
    | 'auth'
    | 'profile'
    | 'done';

export class OnboardingFlowManager {
    currentStep: OnboardingStep = 'chooseRole';

    private subscription?: Subscription;

    constructor(private userManager: AppUserManagerProtocol) {
        makeAutoObservable(this);

        // Подписываемся на стрим currentUser и при изменении
        // вычисляем, какой сейчас шаг
        this.subscription = userManager.currentUserPublisher().subscribe(user => {
            // console.log("OnboardingFlowManager, currentUserPublisher, user: ", user)
            this.defineStep(user);
        });
    }

    private defineStep(user: AppUser | null) {
        // console.log("OnboardingFlowManager, defineStep, user: ", user)
        if (!user) {
            // console.log("OnboardingFlowManager, defineStep, !user")

            this.currentStep = 'chooseRole';
            return;
        }
        if (!user.role) {
            // console.log("OnboardingFlowManager, defineStep, !user.role")
            this.currentStep = 'chooseRole';
            return;
        }
        if (!user.languages) {
            // console.log("OnboardingFlowManager, defineStep, !user.languages")
            this.currentStep = 'chooseLanguages';
            return;
        }
        // if (!user.email) {
        //     this.currentStep = 'auth';
        //     return;
        // }
        // Если нужно проверить поля профиля:
        if (!user.profileName) {
            this.currentStep = 'profile';
            return;
        }
        // Иначе всё заполнено
        this.currentStep = 'done';
    }

    dispose() {
        this.subscription?.unsubscribe();
    }
}