import React from "react";
import { LessonWord } from "../LessonWord";
import { LessonWordCard } from "./LessonWordCard";

interface LessonWordsGridViewProps {
    words: LessonWord[];
}

export class LessonWordsGridView extends React.Component<LessonWordsGridViewProps> {
    render() {
        const { words } = this.props;

        return (
            <div style={styles.container}>
                {words.map((word) => (
                    <LessonWordCard key={word.id} word={word} />
                ))}
            </div>
        );
    }
}

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridAutoFlow: "row",
        gridAutoRows: "60px",
        overflowY: "auto",
        gap: "8px",
        padding: "8px",
        boxSizing: "border-box",
        width: "100%",
        height: "100%",
        minHeight: "100px"
    }
};