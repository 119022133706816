import { makeAutoObservable, reaction, runInAction } from "mobx";
import { LessonWordsState } from "./LessonWordsState";
import { LessonWordsRepository } from "./LessonWordsRepository";
import { LessonWord } from "../LessonWord";
import { WordItem } from "../../../../domain/models";
import FirestoreWordsService from "../../../../data/implementations/FirestoreWordsService";

export class LessonWordsInteractor {
    private repository: LessonWordsRepository;
    private sessionId: string;
    private studentId: string;
    private unsubscribeFn: (() => void) | null = null;

    public version = 0;
    public items: LessonWord[] = [];
    public isAdding = false;
    public newWordDraft = {
        text: "",
        translation: "",
        example: ""
    };

    private isUpdatingFromServer = false;

    constructor(
        sessionId: string,
        initialState: LessonWordsState,
        repository: LessonWordsRepository,
        studentId: string,
    ) {
        this.sessionId = sessionId;
        this.repository = repository;
        this.studentId = studentId;

        // Начальное состояние
        this.version = initialState.version;
        this.items = [...initialState.items];
        this.isAdding = initialState.isAdding;
        this.newWordDraft = { ...initialState.newWordDraft };

        makeAutoObservable(this);

        // Подписка на обновления из Firestore
        this.subscribeToServerUpdates();

        reaction(
            () => JSON.stringify({
                items: this.items,
                isAdding: this.isAdding,
                newWordDraft: this.newWordDraft
            }),
            (newVal, oldVal) => {
                if (this.isUpdatingFromServer) {
                    return;
                }
                if (newVal !== oldVal) {
                    this.saveUpdatedState();
                }
            }
        );
    }

    private subscribeToServerUpdates() {
        this.unsubscribeFn = this.repository.subscribeToUpdates(
            this.sessionId,
            (newState) => {
                if (!newState) return;
                if (newState.version > this.version) {
                    this.updateFromServer(newState);
                }
            }
        );
    }

    public unsubscribe() {
        if (this.unsubscribeFn) {
            this.unsubscribeFn();
            this.unsubscribeFn = null;
        }
    }

    // ------ Обновление локального стейта при приходе данных с сервера ------
    private updateFromServer(newState: LessonWordsState) {
        if (newState.version <= this.version) return;

        runInAction(() => {
            this.isUpdatingFromServer = true;

            this.version = newState.version;
            this.items = [...newState.items];
            this.isAdding = newState.isAdding;
            this.newWordDraft = { ...newState.newWordDraft };

            // Сбрасываем флаг сразу или через microtask
            setTimeout(() => {
                runInAction(() => {
                    this.isUpdatingFromServer = false;
                });
            }, 0);
        });
    }

    // ------ Сохранение в Firestore при локальных изменениях ------
    private saveUpdatedState() {
        this.version++;
        const newState: LessonWordsState = {
            version: this.version,
            items: [...this.items],
            isAdding: this.isAdding,
            newWordDraft: { ...this.newWordDraft },
        };

        this.repository.saveState(this.sessionId, newState).catch(err => {
            console.error("Error saving words:", err);
        });
    }

    // ------ Методы для редактирования и CRUD ------
    public addWord(text: string, translation: string, example?: string) {
        const newId = crypto.randomUUID().toUpperCase();
        const newWord: LessonWord = {
            id: newId,
            text,
            translation,
            example
        };
        this.items.push(newWord);

        const wordItem: WordItem = {
            id: newId,
            word: text,
            translation,
            example: example ?? "",
            repetitions: [],
            imageURL: ""
        };

        // Берём сервис
        const wordsService = FirestoreWordsService
            .getInstance()           // Singleton
            .wordsService(this.studentId); // указываем studentId как owner

        // Асинхронно добавляем
        wordsService.addWords([wordItem])
            .catch(err => {
                console.error("Failed to add new global word:", err);
            });
    }

    public setIsAdding(value: boolean) {
        this.isAdding = value;
    }
    public updateDraftText(value: string) {
        this.newWordDraft.text = value;
    }
    public updateDraftTranslation(value: string) {
        this.newWordDraft.translation = value;
    }
    public updateDraftExample(value: string) {
        this.newWordDraft.example = value;
    }
}