import {action, computed, makeAutoObservable, makeObservable, observable, reaction, runInAction} from "mobx";
import React from "react";
import {CanvasItem} from "./CanvasItem";
import {CanvasInteractor} from "./CanvasInteractor";

export class CanvasViewModel {
    protected interactor: CanvasInteractor;

    public focusedItemId: string | null = null;
    public editingId: string | null = null;
    public isUploadingImage = false;
    public fileInputRef = React.createRef<HTMLInputElement>();

    public get items(): CanvasItem[] {
        return this.interactor.items;
    }
    public isCanvasFocused = false;

    public setCanvasFocus(focused: boolean) {
        this.isCanvasFocused = focused;

        // If the canvas loses focus, also clear item focus and editing state
        if (!focused) {
            this.focusedItemId = null;
            this.editingId = null;
        }
    }

    constructor(interactor: CanvasInteractor) {
        this.interactor = interactor;
        makeAutoObservable(this)

        this.setupDrawingReaction();
        window.addEventListener("keydown", this.handleKeyDown);
    }

    protected setupDrawingReaction() {
        reaction(
            () => ({
                focusedId: this.focusedItemId,
                items: this.items,
            }),
            (current, prev) => {
                const { focusedId: currentId } = current;
                const { focusedId: prevId, items: prevItems } = prev;

                if (prevId && prevId !== currentId) {
                    const oldItem = prevItems.find(it => it.id === prevId);
                    if (oldItem?.type === "drawing" && oldItem.canDraw) {
                        const newItem = this.interactor.finalizeDrawing(prevId);
                        if (newItem) {
                            runInAction(() => {
                                this.focusedItemId = newItem.id;
                            });
                        }
                    }
                }
            }
        );
    }

    public destroy() {
        window.removeEventListener("keydown", this.handleKeyDown);
    }

    protected handleKeyDown = (e: KeyboardEvent) => {
        if (!this.isCanvasFocused) return;

        if (e.key === 'Escape') {
            // Если элемент редактируется – прекращаем редактирование
            if (this.editingId) {
                this.setEditingId(null);
            } else if (this.focusedItemId) {
                // Если выделен рисунок, который ещё можно рисовать – завершаем его
                const item = this.items.find(i => i.id === this.focusedItemId);
                if (item && item.type === 'drawing' && item.canDraw) {
                    // Завершаем режим рисования: финализируем рисунок
                    this.finalizeDrawing(item.id);
                }
            }
            e.preventDefault();
            return;
        }

        const isCtrlOrCmd = e.ctrlKey || e.metaKey;

        if (isCtrlOrCmd && e.key.toLowerCase() === 'z') {
            e.preventDefault();

            if (e.shiftKey) {
                // Ctrl+Shift+Z: Redo - передаем команду в интерактор
                this.interactor.redoAction();
                // Сбрасываем фокус после отмены действия
                this.focusedItemId = null;
                this.editingId = null;
            } else {
                // Ctrl+Z: Undo - передаем команду в интерактор
                this.interactor.undoAction();
                // Сбрасываем фокус после отмены действия
                this.focusedItemId = null;
                this.editingId = null;
            }
            return;
        }

        if (this.editingId) return;

        // Duplicate item: Ctrl/Cmd + D
        if (isCtrlOrCmd && e.key.toLowerCase() === "d" && this.focusedItemId) {
            e.preventDefault();
            this.interactor.duplicateItem(this.focusedItemId);
            return;
        }

        // Delete / Backspace => remove item
        if (this.focusedItemId && (e.key === "Delete" || e.key === "Backspace")) {
            e.preventDefault();
            this.handleRemoveItem(this.focusedItemId);
        }
    };


    public handleAddTextItem() {
        const newItem = this.interactor.addTextItem();
        this.focusedItemId = newItem.id;
    }

    public handleAddTextAtPosition(x: number, y: number) {
        const newItem = this.interactor.addTextItem({ x, y });
        this.focusedItemId = newItem.id;
    }

    public handleAddDrawing() {
        const newItem = this.interactor.addDrawing();
        this.focusedItemId = newItem.id;
    }

    public finalizeDrawing(itemId: string) {
        return this.interactor.finalizeDrawing(itemId);
    }

    public handleRemoveItem(itemId: string) {
        this.interactor.removeItem(itemId);
        if (this.focusedItemId === itemId) this.focusedItemId = null;
        if (this.editingId === itemId) this.editingId = null;
    }

    public handleUpdateItem(itemId: string, patch: Partial<CanvasItem>) {
        this.interactor.updateItem(itemId, patch);
    }

    public handleAddImage() {
        if (this.fileInputRef.current) {
            this.fileInputRef.current.value = "";
            this.fileInputRef.current.click();
        }
    }

    public async handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        const file = e.target.files?.[0];
        if (!file) return;

        try {
            this.isUploadingImage = true;
            await this.interactor.handleImageFileSelected(file);
        } finally {
            this.isUploadingImage = false;
            if (this.fileInputRef.current) {
                this.fileInputRef.current.value = "";
            }
        }
    }

    public setFocusedItemId(id: string | null) {
        this.focusedItemId = id;
    }

    public setEditingId(id: string | null) {
        this.editingId = id;
    }

    public setAllItems(newItems: CanvasItem[]) {
        this.interactor.setAllItems(newItems);
    }
}