import { WordItem } from "../../../domain/models";

export function serializeWordItem(wordItem: WordItem): any {
    return {
        id: wordItem.id,
        word: wordItem.word,
        translation: wordItem.translation,
        example: wordItem.example ?? null,
        imageURL: wordItem.imageURL ?? null,
        repetitions: wordItem.repetitions.map(rep => ({
            date: rep.date.toISOString(),
            type: rep.type,
            success: rep.success
        }))
    };
}

export function deserializeWordItem(data: any): WordItem | null {
    if (typeof data !== 'object' || !data.id || !data.word || !data.translation) {
        return null;
    }
    return {
        id: data.id,
        word: data.word,
        translation: data.translation,
        example: data.example || undefined,
        imageURL: data.imageURL || undefined,
        repetitions: Array.isArray(data.repetitions)
            ? data.repetitions.map((rep: any) => ({
                date: rep.date ? new Date(rep.date) : new Date(),
                type: rep.type || 'learn',
                success: !!rep.success
            }))
            : []
    };
}