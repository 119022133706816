import { MissingWordCreateViewModel } from "./MissingWordCreateViewModel";
import { observer } from "mobx-react-lite";
import { EditExerciseItemView } from "../../BaseComponents/GrammarExercisesCreation/EditExerciseItemView";
import BaseButton from "../../../BaseButton";

interface MissingWordEditViewProps {
    vm: MissingWordCreateViewModel;
}
export const MissingWordEditView: React.FC<MissingWordEditViewProps> = observer(({ vm }) => {
    const handleDelete = (id: string) => {
        vm.setExercises(vm.exercises.filter((ex) => ex.id !== id));
    };

    return (
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <div style={{ overflowY: "auto" }}>
                {vm.exercises.map((exercise) => (
                    <EditExerciseItemView
                        key={exercise.id}
                        exercise={exercise}
                        setExercise={(updated) => {
                            vm.setExercises(
                                vm.exercises.map((ex) => (ex.id === exercise.id ? updated : ex))
                            );
                        }}
                        onDelete={() => handleDelete(exercise.id)}
                    />
                ))}
            </div>
            <div style={{ position: "absolute", right: "0", top: "0" }}>
                <BaseButton onClick={() => vm.save()}>Save</BaseButton>
            </div>
        </div>
    );
});