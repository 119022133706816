import React from "react";
import { observer } from "mobx-react-lite";
import { PracticeAnagramView } from "../exercises_types/Anagram/Practice/PracticeAnagramView";
import { MatchingPairsExerciseView } from "../exercises_types/MatchingPairs/Practice/MatchingPairsExerciseView";
import { JustContentPracticeView } from "../CanvasRefactor/JustContentPractice/JustContentPracticeView";
import { PracticeTableView } from "../exercises_types/Table/Practice/PracticeTableView";
import { PracticeHangmanView } from "../exercises_types/Hangman/Practice/PracticeHangmanView";
import { BaseText } from "../exercises_types/BaseComponents/BaseText";
import {FreePracticeView} from "../exercises_types/Free/Practice/FreePracticeView";
import {CrosswordLearnView} from "../exercises_types/Crossword/Base/CrosswordLearnView";
import {PracticeWordSearchView} from "../exercises_types/WordSearch/Practice/PracticeWordSearchView";
import {MissingWordView} from "../exercises_types/MissingWord/Practice/MissingWordView";
import {FillBlanksView} from "../exercises_types/FillBlanks/Practice/FillBlanksView";
import {PracticeStateUI} from "./PracticeStateUI";
import {SelectOptionView} from "../exercises_types/SelectOption/Practice/SelectOptionView";
import {Resizer} from "../Resizer";

interface PracticeExerciseContentProps {
    practiceState: PracticeStateUI;
}

export const PracticeExerciseContent: React.FC<PracticeExerciseContentProps> = observer(
    ({ practiceState }) => {
        const containerStyle: React.CSSProperties = {
            width: "100%",
            height: "550px",
            border: "1px solid #ccc",
            padding: "0px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        };

        const renderExercise = () => {
            switch (practiceState.type) {
                case "selectOption":
                    return <SelectOptionView viewModel={practiceState.vm} />;
                case "missingWord":
                    return <MissingWordView viewModel={practiceState.vm} />
                case "anagram":
                    return <PracticeAnagramView vm={practiceState.vm} />;
                case "matchingPairs":
                    return <MatchingPairsExerciseView viewModel={practiceState.vm} />;
                case "wordSearch":
                    //TODO: refactor
                    return <Resizer targetSize={{ width: 800, height: 550 }}>
                        <div style={{ width: 500, height: 600 }}>
                            <PracticeWordSearchView vm={practiceState.vm} />;
                        </div>
                    </Resizer>
                case "crossword":
                    return <CrosswordLearnView viewModel={practiceState.vm} />;
                case "fillBlanks":
                    return <FillBlanksView viewModel={practiceState.vm} />;
                case "justContent":
                    return <JustContentPracticeView viewModel={practiceState.vm} width="100%" height="490px"/>;
                case "table":
                    return <PracticeTableView viewModel={practiceState.vm} />;
                case "hangman":
                    return <PracticeHangmanView viewModel={practiceState.vm} />;
                case "free":
                    return <FreePracticeView viewModel={practiceState.vm} />
                case "done":
                    return <BaseText font="headline">Practice Done</BaseText>;
                default:
                    return <BaseText font="headline">Practice Default</BaseText>;
            }
        };

        return <div style={containerStyle}>{renderExercise()}</div>;
    }
);