import { MissingWordCreateState, MissingWordCreateViewModel } from "./MissingWordCreateViewModel";
import { observer } from "mobx-react-lite";
import { EditSentenceView } from "../../BaseComponents/GrammarExercisesCreation/EditSentenceView";

interface MissingWordSentencesCheckViewProps {
    vm: MissingWordCreateViewModel;
}
export const MissingWordSentencesCheckView: React.FC<MissingWordSentencesCheckViewProps> = observer(
    ({ vm }) => {
        const isConverting = vm.state === MissingWordCreateState.Converting;

        const handleDelete = (id: string) => {
            vm.setSentences(vm.sentences.filter((s) => s.id !== id));
        };

        return (
            <div style={{ padding: "16px" }}>
                {vm.sentences.length === 0 ? (
                    <div>Loading...</div>
                ) : (
                    <div style={{ overflowY: "auto" }}>
                        {vm.sentences.map((sentence) => (
                            <EditSentenceView
                                key={sentence.id}
                                sentence={sentence}
                                setSentence={(updated) => {
                                    vm.setSentences(
                                        vm.sentences.map((s) =>
                                            s.id === sentence.id ? updated : s
                                        )
                                    );
                                }}
                                onDelete={() => handleDelete(sentence.id)}
                            />
                        ))}
                    </div>
                )}
                {isConverting ? (
                    <div>Converting...</div>
                ) : (
                    <button onClick={() => vm.convert()}>Convert</button>
                )}
            </div>
        );
    }
);