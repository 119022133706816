// renderHtmlWithFields.ts
import parse, { DOMNode, Element } from "html-react-parser";
import React from "react";
import {CustomAudioElement} from "../Base/CustomAudioElement";

/**
 * Renders an HTML string, replacing <input> / <textarea> with
 * React-controlled elements, driven by a `fields` object
 * and `onFieldChange` callback.
 *
 * @param htmlString The raw HTML to parse
 * @param fields A dictionary of field values, keyed by fieldId
 * @param onFieldChange (fieldId, newValue) => void
 * @returns A ReactNode that you can render
 */
export function renderHtmlWithFields(
    htmlString: string,
    fields: Record<string, string>,
    onFieldChange: (fieldId: string, newValue: string) => void
): React.ReactNode {

    function transformDom(node: DOMNode) {
        // We only care about DOM nodes of type 'tag'
        if (node.type === "tag") {
            const elem = node as Element;

            if (elem.name === "audio-player") {
                const oldAudioId = elem.attribs?.audioid || "";
                return (
                    <CustomAudioElement
                        audioId={oldAudioId}
                        onAudioIdChange={(newAudioId) => onFieldChange(oldAudioId, newAudioId)}
                    />
                );
            }

            // figure out fieldId from either data-field or name
            if (elem.name === "input" || elem.name === "textarea") {
                const fieldId = elem.attribs?.["data-field"]
                    || elem.attribs?.name
                    || elem.attribs?.id;
                if (!fieldId) {
                    // not one of our fields, so leave it as-is
                    return undefined;
                }

                // parse style if you want to keep inline styles
                const style = parseInlineStyle(elem.attribs?.style);

                // Distinguish input type:
                const inputType = elem.attribs?.type ?? "text";

                // ---------------------------------------
                // 1) RADIO
                // ---------------------------------------
                if (inputType === "radio") {
                    const radioValue = elem.attribs?.value ?? "";
                    // This radio button is 'checked' if fields[fieldId] === radioValue
                    const checked = fields[fieldId] === radioValue;

                    return (
                        <input
                            type="radio"
                            name={fieldId}        // keep the same name if you want group behavior
                            value={radioValue}
                            checked={checked}
                            onChange={() => onFieldChange(fieldId, radioValue)}
                            style={style}
                        />
                    );
                }

                // ---------------------------------------
                // 2) SUBMIT
                // ---------------------------------------
                if (inputType === "submit") {
                    // Usually we let <input type="submit"> remain as-is
                    // or transform into a <button>. Here, do nothing:
                    return undefined;
                }

                // ---------------------------------------
                // 3) TEXT, etc.
                // ---------------------------------------
                if (elem.name === "input") {
                    // e.g. <input type="text" name="xxx" />
                    const val = fields[fieldId] ?? "";
                    return (
                        <input
                            type={inputType}
                            value={val}
                            placeholder={elem.attribs?.placeholder ?? ""}
                            onChange={(e) => onFieldChange(fieldId, e.target.value)}
                            style={style}
                        />
                    );
                }

                // ---------------------------------------
                // 4) TEXTAREA
                // ---------------------------------------
                if (elem.name === "textarea") {
                    const val = fields[fieldId] ?? "";
                    return (
                        <textarea
                            placeholder={elem.attribs?.placeholder ?? ""}
                            value={val}
                            onChange={(e) => onFieldChange(fieldId, e.target.value)}
                            style={style}
                        />
                    );
                }
            }

            // If it's some other element (e.g. <select> or <section>), or
            // if no fieldId was found, we just return undefined → "no transform"
            // so it keeps the default rendering.
            return undefined;
        }

        // For text nodes, etc., just keep them as-is
        return undefined;
    }

    // Parse + transform
    return parse(htmlString, { replace: transformDom });
}

/**
 * Simple helper that parses inline style="color: red; width:100px"
 * into a React.CSSProperties object.
 * If you don't need inline styles from HTML, you can skip this step.
 */
function parseInlineStyle(inlineStyle?: string): React.CSSProperties {
    if (!inlineStyle) return {};
    const styleObj: React.CSSProperties = {};
    const rules = inlineStyle.split(";");
    for (const rule of rules) {
        const [prop, val] = rule.split(":");
        if (!prop || !val) continue;
        const propKey = prop.trim().replace(/-([a-z])/g, (_, c) => c.toUpperCase());
        // @ts-ignore
        styleObj[propKey as keyof React.CSSProperties] = val.trim();
    }
    return styleObj;
}