import { Exercise, ExerciseAnagram } from "../../../domain/models";
import { serializeWordItem, deserializeWordItem } from "./WordItemSerializer";

export function serializeAnagram(exercise: Exercise): any {
    if (exercise.type.kind !== 'anagram') {
        throw new Error("serializeAnagram called with non-anagram exercise");
    }

    const an: ExerciseAnagram = exercise.type.data;
    return {
        type: 'anagram',
        authorId: exercise.authorId,
        description: exercise.description,
        word: serializeWordItem(an.word)
    };
}

export function deserializeAnagram(data: any, id: string): Exercise | null {
    const { word } = data;
    if (!word) return null;

    const wordItem = deserializeWordItem(word);
    if (!wordItem) return null;

    return {
        id,
        authorId: data.authorId,
        description: data.description,
        type: {
            kind: 'anagram',
            data: {
                word: wordItem
            }
        }
    };
}