import React, { useState } from 'react';

export enum ButtonVariant {
    Classic = 'classic',
    Big = 'big',
    Small = 'small',
}

interface ButtonProps {
    color?: string;
    onClick: () => void;
    children: React.ReactNode;
    variant?: ButtonVariant;
    disabled?: boolean; // Добавляем флаг disabled
}

const BaseButton: React.FC<ButtonProps> = ({
                                               color = '#007bff',
                                               onClick,
                                               children,
                                               variant = ButtonVariant.Classic,
                                               disabled = false, // Значение по умолчанию
                                           }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isPressed, setIsPressed] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => {
        setIsHovered(false);
        setIsPressed(false);
    };
    const handleMouseDown = () => setIsPressed(true);
    const handleMouseUp = () => setIsPressed(false);

    // Если кнопка disabled, то не реагируем на hover и pressed
    const currentStyle =
        disabled
            ? { ...getBaseStyle(color, variant), opacity: 0.5, cursor: 'not-allowed' }
            : isPressed
                ? getActiveStyle(color, variant)
                : isHovered
                    ? getHoverStyle(color, variant)
                    : getBaseStyle(color, variant);

    return (
        <button
            onClick={!disabled ? onClick : undefined}
            onMouseEnter={!disabled ? handleMouseEnter : undefined}
            onMouseLeave={!disabled ? handleMouseLeave : undefined}
            onMouseDown={!disabled ? handleMouseDown : undefined}
            onMouseUp={!disabled ? handleMouseUp : undefined}
            style={currentStyle}
            disabled={disabled} // HTML-атрибут disabled
        >
            {children}
        </button>
    );
};

function getBaseStyle(color: string, variant: ButtonVariant): React.CSSProperties {
    const baseStyle: React.CSSProperties = {
        color: '#ffffff',
        backgroundColor: color,
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        fontWeight: 'bold',
        textAlign: 'center',
    };

    switch (variant) {
        case ButtonVariant.Big:
            return {
                ...baseStyle,
                fontSize: '24px',
                padding: '15px 25px',
            };
        case ButtonVariant.Small:
            return {
                ...baseStyle,
                fontSize: '14px',
                padding: '5px 10px',
            };
        case ButtonVariant.Classic:
        default:
            return {
                ...baseStyle,
                fontSize: '18px',
                padding: '10px 20px',
            };
    }
}

/** Стиль при наведении */
function getHoverStyle(color: string, variant: ButtonVariant): React.CSSProperties {
    return {
        ...getBaseStyle(color, variant),
        backgroundColor: darkenColor(color, 0.1),
        boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)',
    };
}

/** Стиль при нажатии */
function getActiveStyle(color: string, variant: ButtonVariant): React.CSSProperties {
    return {
        ...getBaseStyle(color, variant),
        transform: 'scale(0.95)',
        backgroundColor: darkenColor(color, 0.15),
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    };
}

/** Функция для затемнения цвета */
function darkenColor(color: string, amount: number): string {
    const colorValue = parseInt(color.slice(1), 16);
    const r = Math.max(0, (colorValue >> 16) - Math.round(255 * amount));
    const g = Math.max(0, ((colorValue >> 8) & 0x00ff) - Math.round(255 * amount));
    const b = Math.max(0, (colorValue & 0x0000ff) - Math.round(255 * amount));
    return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`;
}

export default BaseButton;