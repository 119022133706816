import React, {useEffect} from "react";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {$getSelection, $isRangeSelection, FORMAT_ELEMENT_COMMAND, FORMAT_TEXT_COMMAND} from "lexical";
import { $patchStyleText } from "@lexical/selection"; // For color & font-size
import { $isListNode, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND, ListNode } from "@lexical/list";
import { $findMatchingParent } from "@lexical/utils";

/**
 * This plugin connects the external "toolbar commands" to Lexical.
 */
export const LexicalToolbarPlugin: React.FC<{
    getToolbarCommand?: (cb: (cmd: string, val?: string) => void) => void;
}> = ({getToolbarCommand}) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (!getToolbarCommand) return;

        const handleCommand = (command: string, value?: string) => {
            editor.update(() => {
                const selection = $getSelection();
                if (!$isRangeSelection(selection)) return; // no selection => skip

                switch (command) {
                    case "b":
                        // Toggle bold
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
                        break;

                    case "i":
                        // Toggle italic
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
                        break;

                    case "s":
                        // Применение зачеркивания текста
                        // Проверяем, есть ли уже зачеркивание у текста
                        const anchor = selection.anchor.getNode();
                        const styles = anchor.getStyle() || "";
                        const hasStrikethrough = styles.includes("text-decoration: line-through");

                        // Переключаем стиль зачеркивания
                        $patchStyleText(selection, {
                            "text-decoration": hasStrikethrough ? null : "line-through"
                        });
                        break;

                    case "c":
                        // Apply color via $patchStyleText
                        if (value) {
                            $patchStyleText(selection, {color: value});
                        }
                        break;

                    case "a+":
                        // Increase font-size by 2px, for example
                        applyFontSizeDelta(selection, 2);
                        break;

                    case "a-":
                        // Decrease font-size by 2px
                        applyFontSizeDelta(selection, -2);
                        break;

                    case "al":
                        // Выравнивание по левому краю
                        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
                        break;

                    case "ac":
                        // Выравнивание по центру
                        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
                        break;

                    case "ar":
                        // Выравнивание по правому краю
                        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
                        break;

                    case "ul":
                        // Переключение маркированного списка
                        toggleBulletList();
                        break;

                    case "r":
                        // "reset" (remove color, etc.)
                        $patchStyleText(selection, {
                            color: null,
                            "font-size": null,
                            "text-decoration": null
                        });

                        // Сброс выравнивания на левое (по умолчанию)
                        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
                        break;

                    default:
                        console.warn("Unknown command:", command);
                }
            });
        };

        // Toggle bulleted list
        const toggleBulletList = () => {
            const selection = $getSelection();
            if (!$isRangeSelection(selection)) return;

            const anchorNode = selection.anchor.getNode();
            const parentListNode = $findMatchingParent(anchorNode, $isListNode);

            if (parentListNode) {
                // If already in a list, get the current alignment before removing it
                const listNodeAlignment = parentListNode.getFormat();

                // Remove the list
                editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);

                // After removing, restore the alignment to the resulting paragraph
                // Need to wait for the next update cycle for the new paragraphs to be created
                setTimeout(() => {
                    editor.update(() => {
                        // We need a fresh selection after the list transformation
                        const newSelection = $getSelection();
                        if (!$isRangeSelection(newSelection)) return;

                        // Apply the previous alignment format based on format number
                        // In Lexical: 0 = left, 1 = center, 2 = right, 3 = justify
                        if (listNodeAlignment === 1) {
                            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
                        } else if (listNodeAlignment === 2) {
                            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
                        } else if (listNodeAlignment === 0) {
                            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
                        }
                        // Default is already left, so no need to apply if format was 0
                    });
                }, 0);
            } else {
                // If not in a list, create a new unordered list
                editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
            }
        };

        // Expose handleCommand to the parent
        getToolbarCommand((cmd, val) => handleCommand(cmd, val));
    }, [editor, getToolbarCommand]);

    /** Helper: parse current font-size style, apply delta */
    const applyFontSizeDelta = (selection: any, delta: number) => {
        // 1) read the current style on selection
        // 2) parse "font-size" if available
        // 3) add delta, clamp, patch
        const anchor = selection.anchor.getNode();
        const styles = anchor.getStyle() || ""; // e.g. "color: red; font-size:14px;"
        // We'll do a naive parse:
        const match = styles.match(/font-size:\s*(\d+)px/);
        let currentSize = match ? parseInt(match[1], 10) : 16;
        let newSize = Math.max(10, currentSize + delta); // clamp so it won't go <10
        // apply new style
        $patchStyleText(selection, {
            "font-size": newSize + "px",
        });
    };

    return null;
};