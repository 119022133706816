import React from "react";
import { Position, WordLine, GridType } from "./types";
import {SelectionStateColors} from "./SelectionStateContext";
import {makeAutoObservable, runInAction} from "mobx";

// Equivalent to Swift's Game class.
export class Game {
    wordLines: WordLine[] = [];
    onGameCompleted?: () => void;
    foundWords: Set<string> = new Set();
    fieldSize: { rowsCount: number; columnsCount: number };
    letters: GridType;
    words: string[];

    constructor(
        words: string[],
        fieldSize: { rowsCount: number; columnsCount: number },
        grid: GridType
    ) {
        this.fieldSize = fieldSize;
        this.words = words.map((w) => w.toUpperCase());
        this.letters = grid;
        makeAutoObservable(this);
    }

    lettersForPositions(positions: Position[]): string[] {
        return positions.map((pos) => this.letter(pos));
    }

    letter(pos: Position): string {
        return this.letters[pos.row][pos.col];
    }

    isCellInBounds(row: number, column: number): boolean {
        return (
            row >= 0 &&
            row < this.letters.length &&
            column >= 0 &&
            column < this.letters[0].length
        );
    }

    addFoundedWord(word: string) {
        this.foundWords.add(word);
        if (this.checkGameFinished) {
            this.onGameCompleted?.();
        }
    }

    isNewWordFound(selection: string): boolean {
        return this.words.includes(selection) && !this.foundWords.has(selection);
    }

    get checkGameFinished(): boolean {
        return this.foundWords.size === this.words.length;
    }

    setWordFound(word: string) {
        if (this.foundWords.has(word)) return;
        const positionsDict = this.findWordsPositions();
        const position = positionsDict[word];
        if (!position) return;
        const start = position[0];
        const end = position[position.length - 1];
        const color = SelectionStateColors[
            Math.floor(Math.random() * SelectionStateColors.length)
            ];
        const newLine: WordLine = {
            startIndex: start,
            endIndex: end,
            color: color,
        };
        this.foundWords.add(word);
        this.wordLines.push(newLine);
    }

    markAllFound() {
        const positionsDict = this.findWordsPositions();
        let colorIndex = 0;
        for (const word of this.words) {
            const positions = positionsDict[word];
            if (positions) {
                const start = positions[0];
                const end = positions[positions.length - 1];
                const color = SelectionStateColors[colorIndex % SelectionStateColors.length];
                this.wordLines.push({ startIndex: start, endIndex: end, color });
                colorIndex++;
            }
        }
    }

    findWordsPositions(): Record<string, Position[]> {
        const rows = this.letters.length;
        const cols = this.letters[0]?.length ?? 0;
        const positionsDict: Record<string, Position[]> = {};

        // Directions from Swift's Direction.allCases
        // We'll define them inline.
        const directions = [
            { rowDelta: -1, colDelta: 0 },
            { rowDelta: 1, colDelta: 0 },
            { rowDelta: 0, colDelta: -1 },
            { rowDelta: 0, colDelta: 1 },
            // Diagonals if needed
            { rowDelta: -1, colDelta: -1 },
            { rowDelta: -1, colDelta: 1 },
            { rowDelta: 1, colDelta: -1 },
            { rowDelta: 1, colDelta: 1 },
        ];

        for (let row = 0; row < rows; row++) {
            for (let col = 0; col < cols; col++) {
                for (const direction of directions) {
                    const wordFound = this.searchWord({ row, col }, direction);
                    if (wordFound) {
                        positionsDict[wordFound.word] = wordFound.positions;
                    }
                }
            }
        }
        return positionsDict;
    }

    private searchWord(
        startPosition: Position,
        direction: { rowDelta: number; colDelta: number }
    ): { word: string; positions: Position[] } | null {
        const { rowDelta, colDelta } = direction;
        const positions: Position[] = [];
        let currentRow = startPosition.row;
        let currentCol = startPosition.col;
        let currentWord = "";

        while (
            currentRow >= 0 &&
            currentRow < this.fieldSize.rowsCount &&
            currentCol >= 0 &&
            currentCol < this.fieldSize.columnsCount
            ) {
            const letter = this.letters[currentRow][currentCol];
            currentWord += letter;
            positions.push({ row: currentRow, col: currentCol });

            if (this.words.includes(currentWord) && !this.foundWords.has(currentWord)) {
                return { word: currentWord, positions };
            }

            currentRow += rowDelta;
            currentCol += colDelta;
        }

        return null;
    }
}