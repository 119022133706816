// CrosswordUIHandler.ts
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { CrosswordUIState } from "./CrosswordUIState";
import { ExerciseUIHandler } from "../../../../../domain/Multiplayer/ExerciseUIHandler";
import { SessionStateInteractor } from "../../../../../domain/Multiplayer/SessionStateInteractor";
import { ExerciseUIState as UIState } from "../../../../../domain/Multiplayer/ExerciseUIState";

export class CrosswordUIHandler implements ExerciseUIHandler {
    // Observable свойства
    public foundWords: Set<string>;
    public hintedWords: Set<string>;
    public flattenedGrid: string[];

    private rowsCount: number;
    private columnsCount: number;
    private exerciseId: string;
    private interactor?: SessionStateInteractor;
    private isUpdatingFromServer: boolean = false;
    private currentUIState: CrosswordUIState;

    constructor(
        exerciseId: string,
        interactor?: SessionStateInteractor,
        initialState?: CrosswordUIState
    ) {
        this.exerciseId = exerciseId;
        this.interactor = interactor;
        this.currentUIState =
            initialState || new CrosswordUIState(0, 0, [], new Set<string>(), new Set<string>());

        // Инициализируем observable свойства из currentUIState
        this.foundWords = this.currentUIState.foundWords;
        this.hintedWords = this.currentUIState.hintedWords;
        this.rowsCount = this.currentUIState.rowsCount;
        this.columnsCount = this.currentUIState.columnsCount;
        this.flattenedGrid = this.currentUIState.flattenedGrid;

        makeAutoObservable(this);

        // Используем reaction для отслеживания изменений нужных полей
        reaction(
            () => ({
                found: Array.from(this.foundWords).sort(), // сортировка для стабильного сравнения
                hinted: Array.from(this.hintedWords).sort(),
                grid: this.flattenedGrid.slice(), // копия массива
                rows: this.rowsCount,
                cols: this.columnsCount,
            }),
            (data) => {
                if (this.isUpdatingFromServer) return; // не обрабатывать изменения, инициированные извне
                const newState = new CrosswordUIState(
                    data.rows,
                    data.cols,
                    data.grid,
                    new Set(data.found),
                    new Set(data.hinted)
                );
                if (!newState.isEqual(this.currentUIState)) {
                    this.currentUIState = newState;
                    // Сообщаем об обновлении внешнему interactor'у
                    if (this.interactor) {
                        this.interactor.updateCrosswordState(this.exerciseId, newState);
                    }
                }
            }
        );
    }

    // Геттер для вычисляемого свойства grid (двумеричный массив)
    get grid(): string[][] {
        if (this.rowsCount <= 0 || this.columnsCount <= 0) return [];
        const result: string[][] = [];
        for (let i = 0; i < this.rowsCount; i++) {
            const start = i * this.columnsCount;
            const row = this.flattenedGrid.slice(start, start + this.columnsCount);
            result.push(row);
        }
        return result;
    }

    // Сеттер для grid, обновляющий размеры и flattenedGrid
    set grid(newGrid: string[][]) {
        this.rowsCount = newGrid.length;
        this.columnsCount = newGrid.length > 0 ? newGrid[0].length : 0;
        this.flattenedGrid = newGrid.flat();
        // Изменения попадут в reaction, поэтому дополнительный вызов не требуется.
    }

    // Метод для обновления состояния, полученного извне (например, от сервера)
    updateUIState(uiState: UIState | undefined): void {
        if (!uiState || uiState.type !== "crossword") return;
        const s = uiState.data;
        runInAction(() => {
            this.isUpdatingFromServer = true;
            this.foundWords = s.foundWords;
            this.hintedWords = s.hintedWords;
            this.rowsCount = s.rowsCount;
            this.columnsCount = s.columnsCount;
            this.flattenedGrid = s.flattenedGrid;
            this.currentUIState = s;
            this.isUpdatingFromServer = false;
        });
    }
}