// FillBlanksViewModel.ts
import { makeAutoObservable } from "mobx";
import { FillBlanksInteractor } from "./FillBlanksInteractor";
import { FillBlanksState, FillBlanksPlaceholder } from "./FillBlanksState";
import { Exercise } from "../../../../../domain/models";
import { PracticeSessionDelegate } from "../../../../../domain/Exercises/PracticeSessionDelegate";
import { FillBlanksUIHandler } from "./FillBlanksUIHandler";
import { FillBlanksUseCase } from "./FillBlanksUseCase";

export class FillBlanksViewModel {
    private interactor: FillBlanksInteractor;
    public state: FillBlanksState;

    constructor(
        exercise: Exercise,
        delegate?: PracticeSessionDelegate,
        uiHandler?: FillBlanksUIHandler,
        useCase?: FillBlanksUseCase
    ) {
        this.interactor = new FillBlanksInteractor(
            exercise,
            delegate,
            uiHandler,
            useCase
        );
        this.state = this.interactor.state;

        // When Interactor state changes, update local state
        this.interactor.onStateChange = (newState) => {
            this.state = newState;
        };

        makeAutoObservable(this);
    }

    // Accessors
    get rawText(): string {
        return this.state.rawText;
    }

    get placeholders(): FillBlanksPlaceholder[] {
        return this.state.placeholders;
    }

    get audioURL(): string | undefined {
        return this.state.audioURL;
    }

    get creationType(): string | undefined {
        return this.state.creationType;
    }

    // Methods your UI might call
    updatePlaceholder(placeholderId: number, newValue: string) {
        this.interactor.updatePlaceholder(placeholderId, newValue);
    }

    checkPlaceholder(placeholderId: number) {
        this.interactor.checkPlaceholder(placeholderId);
    }

    checkAll() {
        this.interactor.checkAll();
    }
}