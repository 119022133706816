// ./ui/components/exercises_types/FillBlanks/Previews/FillBlanksViewable.tsx

import React from "react";
import { Exercise, ExerciseFillBlanks } from "../../../../../domain/models";
import { ExerciseViewable } from "../../ExerciseViewable";
import { Resizer } from "../../../Resizer";
import {FillBlanksViewModel} from "../Practice/FillBlanksViewModel";
import {FillBlanksView} from "../Practice/FillBlanksView";

/**
 * Класс, реализующий общий интерфейс "ExerciseViewable" для FillBlanks.
 * Позволяет показывать FillBlanks в общем механизме viewables.
 */
export class FillBlanksViewable implements ExerciseViewable {
    private data: ExerciseFillBlanks;

    constructor(data: ExerciseFillBlanks) {
        this.data = data;
    }

    renderView(exercise: Exercise, width?: number, height?: number): JSX.Element {
        if (exercise.type.kind !== "fillBlanks") {
            throw new Error("Unsupported exercise type for FillBlanksViewable");
        }

        const targetWidth = width ?? 300;
        const targetHeight = height ?? 150;

        const vm = new FillBlanksViewModel(exercise, undefined, undefined, undefined)

        return (
            <Resizer targetSize={{ width: targetWidth, height: targetHeight }}>
                <div style={{ width: "400px", height: "250px", alignContent: "center" }}>
                    <FillBlanksView viewModel={vm} />
                </div>
            </Resizer>
        );
    }
}