// LessonsList.tsx
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import LessonCard from './LessonCard';
import { LessonsListViewModel } from './LessonListViewModel';
import CompositionRoot from "../../../../../compositionRoot";

const LessonsList: React.FC = observer(() => {
    const navigate = useNavigate();
    const location = useLocation();

    // mode=all или mode=my
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get('mode') === 'all' ? 'all' : 'my';

    // Получаем (или создаём) ViewModel
    const viewModel: LessonsListViewModel = CompositionRoot.getLessonsListViewModel(navigate);

    // Грузим нужные данные
    useEffect(() => {
        void viewModel.loadLessons(mode);
    }, [mode, viewModel]);

    return (
        <div style={styles.container}>
            <h2 style={styles.header}>
                {mode === 'all' ? 'All Lessons' : 'My Lessons'}
            </h2>
            {viewModel.lessons.map((lesson) => (
                <LessonCard
                    key={lesson.id}
                    lesson={lesson}
                    onOpenLesson={(id) => viewModel.onLessonCardClick(id)}
                />
            ))}
        </div>
    );
});

export default LessonsList;

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        width: '100%',
        maxWidth: '800px',
        margin: '0 auto',
        padding: '16px',
    },
    header: {
        marginBottom: '24px',
        textAlign: 'center',
        color: '#fff',
    },
};