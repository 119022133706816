import {SelectOptionCreateState, SelectOptionCreateViewModel} from "./SelectOptionCreateViewModel";
import {observer} from "mobx-react-lite";
import {EditSentenceView} from "../../BaseComponents/GrammarExercisesCreation/EditSentenceView";

interface SelectOptionSentencesCheckViewProps {
    vm: SelectOptionCreateViewModel;
}
export const SelectOptionSentencesCheckView: React.FC<SelectOptionSentencesCheckViewProps> = observer(
    ({ vm }) => {
        const isConverting = vm.state === SelectOptionCreateState.Converting;

        const handleDelete = (id: string) => {
            vm.setSentences(vm.sentences.filter((s) => s.id !== id));
        };

        return (
            <div style={{ padding: "16px" }}>
                {vm.sentences.length === 0 ? (
                    <div>Loading...</div>
                ) : (
                    <div style={{ overflowY: "auto" }}>
                        {vm.sentences.map((sentence) => (
                            <EditSentenceView
                                key={sentence.id}
                                sentence={sentence}
                                setSentence={(updated) => {
                                    vm.setSentences(
                                        vm.sentences.map((s) =>
                                            s.id === sentence.id ? updated : s
                                        )
                                    );
                                }}
                                onDelete={() => handleDelete(sentence.id)}
                            />
                        ))}
                    </div>
                )}
                {isConverting ? (
                    <div>Converting...</div>
                ) : (
                    <button onClick={() => vm.convert()}>Convert</button>
                )}
            </div>
        );
    }
);
