// src/ui/screens/learningPlan/LearningPlanEditScreen.tsx

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import LearningPlanEditViewModel from './LearningPlanEditViewModel';
import { LearningPlanEditorViewModel } from '../../PracticeManagement/UI/StudentLearningPlan/LearningPlanEditorViewModel';
import { LearningPlanEditor } from '../../PracticeManagement/UI/StudentLearningPlan/LearningPlanEditor';
import BaseButton, { ButtonVariant } from '../../BaseButton';
import StudentCard from '../StudentCard';

const LearningPlanEditScreen: React.FC = observer(() => {
    const { studentId } = useParams<{ studentId: string }>();
    const navigate = useNavigate();

    // ViewModel для общего экрана: грузит userId, studentData и т.п.
    const [viewModel] = useState(() => new LearningPlanEditViewModel());

    // ViewModel для редактора плана
    const [planEditorVM, setPlanEditorVM] = useState<LearningPlanEditorViewModel | null>(null);

    useEffect(() => {
        if (studentId) {
            viewModel.init(studentId);
        }
        return () => {
            viewModel.dispose();
        };
    }, [studentId, viewModel]);

    // Когда у нас появился userId и studentId, создаём редактор
    useEffect(() => {
        if (viewModel.userId && viewModel.studentId) {
            setPlanEditorVM(
                new LearningPlanEditorViewModel(viewModel.userId, viewModel.studentId)
            );
        }
    }, [viewModel.userId, viewModel.studentId]);

    // Проверяем загрузку/ошибки и т.д.
    if (viewModel.loadingUser) {
        return <div>Loading user...</div>;
    }
    if (!viewModel.userId) {
        return <div>No user found. Please log in first.</div>;
    }
    if (viewModel.error) {
        return <div style={{ color: "red" }}>{viewModel.error}</div>;
    }
    if (viewModel.loadingStudent) {
        return <div>Loading student...</div>;
    }
    if (!viewModel.studentData) {
        return <div>Student not found</div>;
    }

    // Рендерим UI
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 12, margin: '40px auto' }}>
            <StudentCard data={viewModel.studentData} />

            {/* Компонент редактора плана (если уже есть VM) */}
            {planEditorVM ? (
                <LearningPlanEditor viewModel={planEditorVM} />
            ) : (
                <div>Initializing plan editor...</div>
            )}

            <div>
                <BaseButton variant={ButtonVariant.Classic} onClick={() => navigate(-1)}>
                    Back
                </BaseButton>
            </div>

        </div>
    );
});

export default LearningPlanEditScreen;