import React from "react";
import { createPortal } from "react-dom";

interface ITooltipPortalProps {
    x: number;
    y: number;
    content: React.ReactNode;
    visible: boolean;
}

const tooltipContainer = document.createElement("div");
document.body.appendChild(tooltipContainer);

// Компонент для рендера тултипа в портале
export class TooltipPortal extends React.Component<ITooltipPortalProps> {
    render() {
        const { x, y, content, visible } = this.props;

        if (!visible) return null;

        const style: React.CSSProperties = {
            position: "fixed",
            top: y,
            left: x,
            zIndex: 9999,
            backgroundColor: "var(--background-color)",
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "8px",
            boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
            color: 'var(--text-color)'
        };

        return createPortal(
            <div style={style}>
                {content}
            </div>,
            tooltipContainer
        );
    }
}