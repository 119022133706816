// ExerciseTextPreview.tsx
import React from "react";
import { Exercise } from "../../../domain/models";

/**
 * Пропы для основного компонента превью.
 * - exercise: данные упражнения
 * - width, height: размеры контейнера
 * - onClick: обработчик клика левой кнопкой
 * - onContextMenu: обработчик клика правой кнопкой (контекстное меню)
 * - style: возможность переопределять/добавлять стили к контейнеру
 */
interface Props {
    exercise: Exercise;
    width?: number;
    height?: number;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    onContextMenu?: React.MouseEventHandler<HTMLDivElement>;
    style?: React.CSSProperties;
}

/**
 * Основной компонент. По полю "kind" в exercise.type выбирает,
 * какую конкретную TextPreview-компоненту отобразить.
 * Все превьюшки имеют один и тот же контейнер (одинаковый размер и стили).
 */
export function ExerciseTextPreview({
                                        exercise,
                                        width = 250,
                                        height = 80,
                                        onClick,
                                        onContextMenu,
                                        style,
                                    }: Props) {
    // Базовый стиль контейнера
    const containerStyle: React.CSSProperties = {
        width,
        height,
        padding: "4px 8px",
        backgroundColor: "rgba(128,128,128,0.1)",
        borderRadius: 6,
        overflow: "hidden",
    };

    // Если нужно смешать стили, объединяем их:
    const finalStyle: React.CSSProperties = {
        ...containerStyle,
        ...style,
    };

    switch (exercise.type.kind) {
        case "missingWord":
            return (
                <div style={finalStyle} onClick={onClick} onContextMenu={onContextMenu}>
                    <MissingWordTextPreview exercise={exercise} />
                </div>
            );

        case "selectOption":
            return (
                <div style={finalStyle} onClick={onClick} onContextMenu={onContextMenu}>
                    <SelectOptionTextPreview exercise={exercise} />
                </div>
            );

        case "wordSearch":
            return (
                <div style={finalStyle} onClick={onClick} onContextMenu={onContextMenu}>
                    <WordSearchTextPreview exercise={exercise} />
                </div>
            );

        case "anagram":
            return (
                <div style={finalStyle} onClick={onClick} onContextMenu={onContextMenu}>
                    <AnagramTextPreview exercise={exercise} />
                </div>
            );

        case "crossword":
            return (
                <div style={finalStyle} onClick={onClick} onContextMenu={onContextMenu}>
                    <CrosswordTextPreview exercise={exercise} />
                </div>
            );

        case "fillBlanks":
            return (
                <div style={finalStyle} onClick={onClick} onContextMenu={onContextMenu}>
                    <FillBlanksTextPreview exercise={exercise} />
                </div>
            );

        case "matchingPairs":
            return (
                <div style={finalStyle} onClick={onClick} onContextMenu={onContextMenu}>
                    <MatchingPairsTextPreview exercise={exercise} />
                </div>
            );

        case "justContent":
            return (
                <div style={finalStyle} onClick={onClick} onContextMenu={onContextMenu}>
                    <JustContentTextPreview exercise={exercise} />
                </div>
            );

        case "table":
            return (
                <div style={finalStyle} onClick={onClick} onContextMenu={onContextMenu}>
                    <TableTextPreview exercise={exercise} />
                </div>
            );

        case "hangman":
            return (
                <div style={finalStyle} onClick={onClick} onContextMenu={onContextMenu}>
                    <HangmanTextPreview exercise={exercise} />
                </div>
            );

        case "free":
            return (
                <div style={finalStyle} onClick={onClick} onContextMenu={onContextMenu}>
                    <FreeTextPreview exercise={exercise} />
                </div>
            );

        default:
            return (
                <div style={finalStyle} onClick={onClick} onContextMenu={onContextMenu}>
                    <div style={{ fontWeight: "bold" }}>Unknown</div>
                </div>
            );
    }
}

/* ===============================
 *  Превью для каждого подтипа
 * =============================== */

function MissingWordTextPreview({ exercise }: { exercise: Exercise }) {
    if (exercise.type.kind !== "missingWord") return null;

    const desc = exercise.description ?? "No description";
    const { sentence, correctForm } = exercise.type.data;

    return (
        <>
            <div style={{ fontWeight: "bold" }}>Missing Word</div>
            <div style={previewLineStyle}>Description: {desc}</div>
            <div style={previewLineStyle}>{sentence ?? "-"}</div>
            <div style={previewLineStyle}>{correctForm ?? "-"}</div>
        </>
    );
}

function SelectOptionTextPreview({ exercise }: { exercise: Exercise }) {
    if (exercise.type.kind !== "selectOption") return null;

    const desc = exercise.description ?? "No description";
    const { sentence, correctOption } = exercise.type.data;

    return (
        <>
            <div style={{ fontWeight: "bold" }}>Select Option</div>
            <div style={previewLineStyle}>Description: {desc}</div>
            <div style={previewLineStyle}>{sentence ?? "-"}</div>
            <div style={previewLineStyle}>{correctOption ?? "-"}</div>
        </>
    );
}

function WordSearchTextPreview({ exercise }: { exercise: Exercise }) {
    if (exercise.type.kind !== "wordSearch") return null;

    const desc = exercise.description ?? "No description";
    const { words } = exercise.type.data;
    const wordsList = words.map((w) => w.word).join(", ");

    return (
        <>
            <div style={{ fontWeight: "bold" }}>Word Search</div>
            <div style={previewLineStyle}>Description: {desc}</div>
            <div style={previewLineStyle}>Words:</div>
            <div style={previewLineStyle}>{wordsList || "-"}</div>
        </>
    );
}

function AnagramTextPreview({ exercise }: { exercise: Exercise }) {
    if (exercise.type.kind !== "anagram") return null;

    const desc = exercise.description ?? "No description";
    const { word } = exercise.type.data;

    return (
        <>
            <div style={{ fontWeight: "bold" }}>Anagram</div>
            <div style={previewLineStyle}>Description: {desc}</div>
            <div style={previewLineStyle}>Word:</div>
            <div style={previewLineStyle}>{word.word || "-"}</div>
        </>
    );
}

function CrosswordTextPreview({ exercise }: { exercise: Exercise }) {
    if (exercise.type.kind !== "crossword") return null;

    const desc = exercise.description ?? "No description";
    const { words } = exercise.type.data;
    const wordsList = words.map((w) => w.word).join(", ");

    return (
        <>
            <div style={{ fontWeight: "bold" }}>Crossword</div>
            <div style={previewLineStyle}>Description: {desc}</div>
            <div style={previewLineStyle}>Words:</div>
            <div style={previewLineStyle}>{wordsList || "-"}</div>
        </>
    );
}

function FillBlanksTextPreview({ exercise }: { exercise: Exercise }) {
    if (exercise.type.kind !== "fillBlanks") return null;

    const desc = exercise.description ?? "No description";
    const { text, words } = exercise.type.data;
    const wordsList = words.map((w) => w.word).join(", ");

    return (
        <>
            <div style={{ fontWeight: "bold" }}>Fill Blanks</div>
            <div style={previewLineStyle}>Description: {desc}</div>
            <div style={previewLineStyle}>{text || "-"}</div>
            <div style={previewLineStyle}>{wordsList || "-"}</div>
        </>
    );
}

function MatchingPairsTextPreview({ exercise }: { exercise: Exercise }) {
    if (exercise.type.kind !== "matchingPairs") return null;

    const desc = exercise.description ?? "No description";
    const { pairs } = exercise.type.data;

    const pairsOutput = pairs
        .map((p) => {
            const leftText = p.left?.text ?? "(no text)";
            const rightText = p.right?.text ?? "(no text)";
            return `${leftText} -> ${rightText}`;
        })
        .join(", ");

    return (
        <>
            <div style={{ fontWeight: "bold" }}>Matching Pairs</div>
            <div style={previewLineStyle}>Description: {desc}</div>
            <div style={previewLineStyle}>Pairs:</div>
            <div style={previewLineStyle}>{pairsOutput || "-"}</div>
        </>
    );
}

function JustContentTextPreview({ exercise }: { exercise: Exercise }) {
    if (exercise.type.kind !== "justContent") return null;

    const desc = exercise.description ?? "No description";
    const { contents } = exercise.type.data;

    return (
        <>
            <div style={{ fontWeight: "bold" }}>Canvas</div>
            <div style={previewLineStyle}>Description: {desc}</div>
            <div style={previewLineStyle}>
                Contents count: {contents.length}
            </div>
        </>
    );
}

function TableTextPreview({ exercise }: { exercise: Exercise }) {
    if (exercise.type.kind !== "table") return null;

    const desc = exercise.description ?? "No description";
    const { columns } = exercise.type.data;
    const headers = columns.map((col) => col.header).join(", ");
    const items = columns[0]?.items?.join(", ") ?? "";

    return (
        <>
            <div style={{ fontWeight: "bold" }}>Table</div>
            <div style={previewLineStyle}>Description: {desc}</div>
            <div style={previewLineStyle}>Headers: {headers || "-"}</div>
            <div style={previewLineStyle}>Items (col 1): {items || "-"}</div>
        </>
    );
}

function HangmanTextPreview({ exercise }: { exercise: Exercise }) {
    if (exercise.type.kind !== "hangman") return null;

    const { word, hint } = exercise.type.data;
    const desc = exercise.description ?? "No description";

    return (
        <>
            <div style={{ fontWeight: "bold" }}>Hangman</div>
            <div style={previewLineStyle}>Description: {desc}</div>
            <div style={previewLineStyle}>
                Word: {word}
            </div>
            <div style={previewLineStyle}>
                Hint: {hint}
            </div>
        </>
    );
}

function FreeTextPreview({ exercise }: { exercise: Exercise }) {
    if (exercise.type.kind !== "free") return null;

    const { htmlString } = exercise.type.data;
    const desc = exercise.description ?? "No description";

    return (
        <>
            <div style={{ fontWeight: "bold" }}>Free</div>
            <div style={previewLineStyle}>Description: {desc}</div>
            <div style={previewLineStyle}>
                {htmlString}
            </div>
        </>
    );
}


/**
 * Общий стиль для одной текстовой строки в превью.
 */
const previewLineStyle: React.CSSProperties = {
    fontSize: 12,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
};