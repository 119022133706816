// ----------------- src/ui/screens/students/StudentCard.tsx -----------------
import React from 'react';
import { CombinedStudent } from "./CombinedStudent";

type Props = {
    data: CombinedStudent;
    onClick?: () => void;
};

/**
 * Первый вариант карточки, адаптированный под тёмный фон приложения (#1f1f1f).
 * - Увеличен контраст для текста.
 * - Фон карточки слегка светлее, чтобы выделяться на тёмном фоне.
 * - Текстовая информация стала белой или почти белой.
 * - ID студента в нижнем правом углу мелким шрифтом серым цветом.
 */
const StudentCard: React.FC<Props> = ({ data, onClick }) => {
    const { student, userData } = data;

    return (
        <div
            style={{
                ...styles.cardContainer,
                cursor: onClick ? 'pointer' : 'default',
            }}
            onClick={onClick}
        >
            <div style={styles.header}>
                <div style={styles.avatarContainer}>
                    {userData?.profileAvatar ? (
                        <img
                            src={userData.profileAvatar}
                            alt="Avatar"
                            style={styles.avatar}
                        />
                    ) : (
                        <div style={styles.noAvatar}>No Avatar</div>
                    )}
                </div>
                <div style={styles.nameInfo}>
                    <h3 style={styles.title}>
                        {userData?.profileName ?? 'Unnamed Student'}
                    </h3>
                    <div style={styles.email}>{student.email}</div>
                </div>
            </div>

            <div style={styles.aboutBlock}>
                <b>About: </b>
                {userData?.profileAbout ?? 'No info'}
            </div>

            <div style={styles.addDate}>
                <b>Add Date:</b> {student.addDate.toLocaleString()}
            </div>

            <div style={styles.studentId}>
                {student.id}
            </div>
        </div>
    );
};

const styles: Record<string, React.CSSProperties> = {
    cardContainer: {
        backgroundColor: "var(--modal-bg)",
        color: 'var(--text-color)', // текст светлый для контраста
        borderRadius: 8,
        padding: '12px',
        marginBottom: '12px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.5)',
        position: 'relative',
        maxWidth: '400px',
        border: '1px solid #3f3f3f', // тёмно-серый бордер
        textAlign: 'left'
    },
    header: {
        display: 'flex',
        gap: '12px',
    },
    avatarContainer: {
        width: 60,
        height: 60,
        overflow: 'hidden',
        borderRadius: '50%',
        backgroundColor: '#3f3f3f', // фон под аватаром
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatar: {
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
    },
    noAvatar: {
        fontSize: '12px',
        color: '#aaa', // слегка более приглушённый цвет
    },
    nameInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    title: {
        margin: '0 0 4px 0',
        fontSize: '16px',
        lineHeight: '1.2',
        color: 'var(--text-color)',
    },
    email: {
        fontSize: '14px',
        color: 'var(--text-color)',
    },
    aboutBlock: {
        marginTop: '8px',
        marginBottom: '8px',
        fontSize: '14px',
        lineHeight: '1.3',
    },
    addDate: {
        fontSize: '13px',
        color: 'var(--text-color)',
    },
    studentId: {
        position: 'absolute',
        bottom: '8px',
        right: '12px',
        fontSize: '12px',
        color: '#999', // серый цвет для ID
    },
};

export default StudentCard;