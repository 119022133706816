// CrosswordLearnViewModel.ts
import { makeAutoObservable, runInAction } from "mobx";
import { Exercise, Position, WordItem } from "../../../../../domain/models";
import { PracticeSessionDelegate } from "../../../../../domain/Exercises/PracticeSessionDelegate";
import { CrosswordLearnInteractor } from "../Domain/CrosswordLearnInteractor";
import { CrosswordState } from "../Domain/CrosswordState";
import { CrosswordBuilderProtocol, DefaultCrosswordBuilder } from "../Domain/CrosswordBuilderProtocol";
import { CrosswordUIHandler } from "../Domain/CrosswordUIHandler";

export class CrosswordLearnViewModel {
    interactor: CrosswordLearnInteractor;
    state: CrosswordState;

    constructor(
        exercise: Exercise,
        delegate?: PracticeSessionDelegate,
        builder?: CrosswordBuilderProtocol,
        uiHandler?: CrosswordUIHandler
    ) {
        // Проверяем, что упражнение имеет тип "crossword"
        if (exercise.type.kind !== "crossword") {
            throw new Error("Передано упражнение не типа 'crossword'");
        }
        // Если builder не передан, используем DefaultCrosswordBuilder
        this.interactor = new CrosswordLearnInteractor(
            exercise,
            delegate,
            builder || new DefaultCrosswordBuilder(),
            uiHandler
        );
        // Начальное состояние интерактора
        this.state = this.interactor.state;

        // Подписываемся на обновления состояния от интерактора
        this.interactor.onStateChange = (newState: CrosswordState) => {
            runInAction(() => {
                this.state = newState;
            });
        };

        makeAutoObservable(this);
    }

    // --- Actions, делегирующие вызовы интерактору ---
    finishCrossword() {
        this.interactor.finishCrossword();
    }

    clearCellContent(pos: Position) {
        this.interactor.clearCellContent(pos);
    }

    handleLetterChange(pos: Position): Position | undefined {
        return this.interactor.handleLetterChange(pos);
    }

    hintForWord(w: WordItem) {
        this.interactor.hintForWord(w);
    }

    isPositionEditable(pos: Position): boolean {
        return this.interactor.isPositionEditable(pos);
    }

    handleGridUpdate(pos: Position, newVal: string) {
        this.interactor.handleGridUpdate(pos, newVal);
    }

    // --- Computed getters для удобства доступа к данным ---
    get hintedWords(): Set<WordItem> {
        return this.state.hintedWords;
    }

    get foundWords(): Set<WordItem> {
        return this.state.foundWords;
    }

    get isCrosswordSolved(): boolean {
        return this.state.isCrosswordSolved;
    }

    get words(): WordItem[] {
        return this.state.words;
    }

    get placedWords(): any {
        return this.state.placedWords;
    }

    get occupiedPositions(): Set<Position> {
        return this.state.occupiedPositions;
    }

    get startPositions(): { [key: string]: number } {
        return this.state.startPositions;
    }

    get cellSize(): number {
        return this.state.cellSize;
    }

    get gridData(): string[][] {
        return this.state.grid;
    }

    get minRow(): number {
        return this.occupiedPositions.size
            ? Math.min(...Array.from(this.occupiedPositions).map((p) => p.row))
            : 0;
    }

    get maxRow(): number {
        return this.occupiedPositions.size
            ? Math.max(...Array.from(this.occupiedPositions).map((p) => p.row))
            : 0;
    }

    get minCol(): number {
        return this.occupiedPositions.size
            ? Math.min(...Array.from(this.occupiedPositions).map((p) => p.col))
            : 0;
    }

    get maxCol(): number {
        return this.occupiedPositions.size
            ? Math.max(...Array.from(this.occupiedPositions).map((p) => p.col))
            : 0;
    }
}