import { makeAutoObservable } from "mobx";
import { LessonWordsInteractor } from "./LessonWordsInteractor";
import { LessonWord } from "../LessonWord";

export class LessonWordsWindowViewModel {
    private interactor: LessonWordsInteractor;

    constructor(interactor: LessonWordsInteractor) {
        this.interactor = interactor;
        makeAutoObservable(this);
    }

    get version(): number {
        return this.interactor.version;
    }

    get items(): LessonWord[] {
        return this.interactor.items;
    }

    // Связка с флагом
    get isAdding(): boolean {
        return this.interactor.isAdding;
    }
    setIsAdding(value: boolean) {
        this.interactor.setIsAdding(value);
    }
    // Доступ к draft
    get draftText(): string {
        return this.interactor.newWordDraft.text;
    }
    get draftTranslation(): string {
        return this.interactor.newWordDraft.translation;
    }
    get draftExample(): string {
        return this.interactor.newWordDraft.example;
    }
    setDraftText(value: string) {
        this.interactor.updateDraftText(value);
    }
    setDraftTranslation(value: string) {
        this.interactor.updateDraftTranslation(value);
    }
    setDraftExample(value: string) {
        this.interactor.updateDraftExample(value);
    }

    public destroy() {
        this.interactor.unsubscribe();
    }

    public addWord(text: string, translation: string, example?: string) {
        this.interactor.addWord(text, translation, example);
    }
}