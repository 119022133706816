// ExerciseMissingWordEditView.tsx
import React from 'react';
import {ExerciseMissingWord} from "../../../../../domain/models";

interface ExerciseMissingWordEditViewProps {
    exercise: ExerciseMissingWord;
    setExercise: (exercise: ExerciseMissingWord) => void;
    onDelete: () => void;
}

export const ExerciseMissingWordEditView: React.FC<ExerciseMissingWordEditViewProps> = ({
                                                                                            exercise,
                                                                                            setExercise,
                                                                                            onDelete,
                                                                                        }) => {
    const handleChange = (field: keyof ExerciseMissingWord, value: string) => {
        setExercise({ ...exercise, [field]: value });
    };

    return (
        <div style={{ maxWidth: '600px', border: '1px solid #ccc', padding: '8px' }}>
            <div>
                <label>Sentence</label>
                <input
                    type="text"
                    value={exercise.sentence}
                    onChange={(e) => handleChange('sentence', e.target.value)}
                />
            </div>
            <div>
                <label>Correct Form</label>
                <input
                    type="text"
                    value={exercise.correctForm}
                    onChange={(e) => handleChange('correctForm', e.target.value)}
                />
            </div>
            <button onClick={onDelete} style={{ color: 'red' }}>
                Delete
            </button>
        </div>
    );
};
