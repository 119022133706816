import { Exercise, ExerciseMissingWord } from "../../../domain/models";

export function serializeMissingWord(exercise: Exercise): any {
    if (exercise.type.kind !== 'missingWord') {
        throw new Error("serializeAnagram called with non-anagram exercise");
    }

    const mw: ExerciseMissingWord = exercise.type.data;
    return {
        type: 'missingWord',
        authorId: exercise.authorId,
        description: exercise.description,
        sentence: mw.sentence,
        correctForm: mw.correctForm
    };
}

export function deserializeMissingWord(data: any, id: string): Exercise | null {
    const { sentence, correctForm } = data;
    if (typeof sentence !== 'string' || typeof correctForm !== 'string') {
        return null;
    }
    return {
        id,
        authorId: data.authorId,
        description: data.description,
        type: {
            kind: 'missingWord',
            data: {
                sentence,
                correctForm
            }
        }
    };
}