import { Exercise, ExerciseWordSearch, WordItem } from "../../../domain/models";
import { serializeWordItem, deserializeWordItem } from "./WordItemSerializer";

export function serializeWordSearch(exercise: Exercise): any {
    if (exercise.type.kind !== 'wordSearch') {
        throw new Error("serializeAnagram called with non-anagram exercise");
    }

    const ws: ExerciseWordSearch = exercise.type.data;
    const rowsCount = ws.grid.length;
    const columnsCount = rowsCount > 0 ? ws.grid[0].length : 0;
    const flattenedGrid = ws.grid.flat();

    return {
        type: 'wordSearch',
        authorId: exercise.authorId,
        description: exercise.description,
        rowsCount,
        columnsCount,
        gridData: flattenedGrid,
        words: ws.words.map(w => serializeWordItem(w))
    };
}

export function deserializeWordSearch(data: any, id: string): Exercise | null {
    const { rowsCount, columnsCount, gridData, words } = data;
    if (
        typeof rowsCount !== 'number' ||
        typeof columnsCount !== 'number' ||
        !Array.isArray(gridData) ||
        !Array.isArray(words)
    ) {
        return null;
    }

    // Rebuild the 2D grid
    const grid: string[][] = [];
    let index = 0;
    for (let r = 0; r < rowsCount; r++) {
        const row = gridData.slice(index, index + columnsCount);
        index += columnsCount;
        if (!Array.isArray(row) || row.length !== columnsCount) {
            return null;
        }
        grid.push(row);
    }

    const wordsDeser: WordItem[] = words
        .map((w: any) => deserializeWordItem(w))
        .filter((w): w is WordItem => w !== null);

    return {
        id,
        authorId: data.authorId,
        description: data.description,
        type: {
            kind: 'wordSearch',
            data: {
                words: wordsDeser,
                grid
            }
        }
    };
}