import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { NotesWindowViewModel } from './NotesWindowViewModel';
import { CanvasContainer } from "../Base/CanvasContainer";
import { NotesInteractor } from './NotesInteractor';
import CompositionRoot from '../../../../compositionRoot'; // Assuming this path based on usage

interface NotesWindowViewProps {
    noteId: string;
}

export const NotesWindowView: React.FC<NotesWindowViewProps> = observer(
    ({ noteId }) => {
        const [activeIndex, setActiveIndex] = useState(0);
        const [viewModels, setViewModels] = useState<NotesWindowViewModel[]>([]);

        useEffect(() => {
            // Create 5 interactors and viewmodels with different IDs
            const initialState = { version: 0, items: [] };
            const newViewModels = Array(5).fill(null).map((_, index) => {
                const id = index === 0 ? noteId : `${noteId}_${index}`;
                const interactor = new NotesInteractor(
                    id,
                    initialState,
                    CompositionRoot.noteRepository
                );
                return new NotesWindowViewModel(interactor);
            });

            setViewModels(newViewModels);

            // Cleanup function
            return () => {
                newViewModels.forEach(vm => vm.destroy());
            };
        }, [noteId]);

        if (viewModels.length === 0) {
            return <div>Loading...</div>;
        }

        return (
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                {/* Switcher component */}
                <div
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        zIndex: 10,
                        display: 'flex',
                        gap: '5px',
                        background: 'rgba(255, 255, 255, 0.7)',
                        padding: '5px',
                        borderRadius: '5px',
                        boxShadow: '0 1px 3px rgba(0,0,0,0.12)'
                    }}
                >
                    {Array(5).fill(null).map((_, index) => (
                        <button
                            key={index}
                            onClick={() => setActiveIndex(index)}
                            style={{
                                width: '24px',
                                height: '24px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: activeIndex === index ? '#4a90e2' : '#e0e0e0',
                                color: activeIndex === index ? 'white' : 'black',
                                border: 'none',
                                borderRadius: '50%',
                                cursor: 'pointer',
                                fontWeight: 'bold',
                                fontSize: '12px'
                            }}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>

                {/* Canvas containers */}
                {viewModels.map((viewModel, index) => (
                    <div
                        key={index}
                        style={{
                            display: index === activeIndex ? 'block' : 'none',
                            width: '100%',
                            height: '100%'
                        }}
                    >
                        <CanvasContainer viewModel={viewModel.canvasViewModel} />
                    </div>
                ))}
            </div>
        );
    }
);