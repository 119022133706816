// ===================== ./PracticeSelectOption/SelectOptionInteractor.ts =====================
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { SelectOptionUseCase } from "./SelectOptionUseCase";
import { SelectOptionUIHandler } from "./SelectOptionUIHandler";
import { SelectOptionState } from "./SelectOptionState";
import { Exercise } from "../../../../../domain/models";
import { PracticeSessionDelegate } from "../../../../../domain/Exercises/PracticeSessionDelegate";

export class SelectOptionInteractor {
    private delegate?: PracticeSessionDelegate;
    private useCase: SelectOptionUseCase;
    private uiHandler: SelectOptionUIHandler;

    public onStateChange?: (newState: SelectOptionState) => void;
    private _state: SelectOptionState;

    constructor(
        exercise: Exercise,
        delegate?: PracticeSessionDelegate,
        uiHandler?: SelectOptionUIHandler,
        useCase?: SelectOptionUseCase
    ) {
        if (exercise.type.kind !== "selectOption") {
            throw new Error("Not a SelectOption exercise");
        }
        this.delegate = delegate;
        this.useCase = useCase || new SelectOptionUseCase();
        this.uiHandler = uiHandler || new SelectOptionUIHandler(exercise.id);

        // Изначальное состояние
        const data = exercise.type.data;
        this._state = {
            sentence: data.sentence,
            options: [data.option1, data.option2, data.option3, data.option4],
            selectedOption: this.uiHandler.selectedOption,
            isCorrect: this.uiHandler.isCorrect,
            correctOption: data.correctOption,
        };

        makeAutoObservable(this);

        this.setupBindings();
        this.syncFromUIHandler();
    }

    get state(): SelectOptionState {
        return this._state;
    }

    private setState(newState: SelectOptionState) {
        this._state = newState;
        this.onStateChange?.(newState);
    }

    private setupBindings(): void {
        reaction(
            () => [this.uiHandler.selectedOption, this.uiHandler.isCorrect],
            () => {
                this.syncFromUIHandler();
            }
        );
    }

    private syncFromUIHandler() {
        const { selectedOption, isCorrect } = this.uiHandler;
        const { sentence, options, correctOption } = this._state;
        const incoming: SelectOptionState = {
            sentence,
            options,
            correctOption,
            selectedOption,
            isCorrect,
        };

        if (JSON.stringify(incoming) !== JSON.stringify(this._state)) {
            runInAction(() => {
                this.setState(incoming);
            });
        }
    }

    private syncToUIHandler(newState: SelectOptionState) {
        // Копируем в UIHandler оба поля:
        this.uiHandler.selectedOption = newState.selectedOption;
        this.uiHandler.isCorrect = newState.isCorrect;
        this.uiHandler.sendUpdateIfNeeded();
    }

    // -----------------
    // Методы обновления
    // -----------------
    public selectAndCheckOption(chosenOption: string) {
        // Pull in any fresh remote changes
        this.syncFromUIHandler();

        // Let useCase set both .selectedOption + .isCorrect at once
        const updated = this.useCase.selectAndCheckOption(this._state, chosenOption);
        if (updated !== this._state) {
            this.setState(updated);

            // Push the combined update (selection + correctness) to the DB
            this.syncToUIHandler(updated);
        }
    }

    public goNext() {
        this.syncFromUIHandler();
        const updated = this.useCase.goNext(this._state);
        if (updated !== this._state) {
            this.setState(updated);
            this.syncToUIHandler(updated);
        }
    }
}