import React, { useEffect, useRef, useState } from "react";
import { useSizeContext } from "./SizeContext";
import {useSelectionState} from "./SelectionStateContext";
import {observer} from "mobx-react-lite";

interface WordsWrapViewProps {
    width: number;
}

// Simple text component with diagonal strike for found words
// We replicate 'DiagonallyStrikethroughText' logic using CSS.
const DiagonallyStrikethroughText: React.FC<{
    text: string;
    isFound: boolean;
    padding: number;
}> = ({ text, isFound, padding }) => {
    return (
        <div
            style={{
                padding: `0 ${padding}px`,
                position: "relative",
                textDecoration: "none",
                color: isFound ? "gray" : "black",
                whiteSpace: "nowrap",
            }}
        >
            {text}
            {isFound && (
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: 0,
                        width: "100%",
                        height: 3,
                        backgroundColor: "gray",
                        transform: "translateY(-50%) rotate(-10deg)",
                    }}
                />
            )}
        </div>
    );
};

export const WordsWrapView: React.FC<WordsWrapViewProps> = observer(({ width }) => {
    const { game } = useSelectionState(); // game получаем из SelectionStateContext
    const { wordsListViewHeight, setWordsListViewHeight } = useSizeContext();

    // The found words are in game.foundWords
    const foundWords = game.foundWords;
    const words = game.words;

    const [lines, setLines] = useState<string[][]>([[]]);
    const containerRef = useRef<HTMLDivElement>(null);
    const wordsPadding = 4;

    useEffect(() => {
        setLines(arrangeWordsIntoLines(words, width, wordsPadding));
    }, [words, width]);

    useEffect(() => {
        if (containerRef.current) {
            const rect = containerRef.current.getBoundingClientRect();
            setWordsListViewHeight(rect.height);
        }
    }, [lines, setWordsListViewHeight]);


    return (
        <div
            ref={containerRef}
            style={{
                backgroundColor: "white",
                borderRadius: 12,
                padding: 6,
                width,
            }}
        >
            {lines.map((lineWords, lineIndex) => (
                <div key={lineIndex} style={{ display: "flex", justifyContent: "center", flexWrap: "nowrap" }}>
                    {lineWords.map((word, idx) => (
                        <DiagonallyStrikethroughText
                            key={idx}
                            text={word}
                            isFound={foundWords.has(word)}
                            padding={wordsPadding}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
});

// Replicates the arrangement logic from Swift
function arrangeWordsIntoLines(
    words: string[],
    maxWidth: number,
    wordsPadding: number
): string[][] {
    const lines: string[][] = [[]];
    let currentLineWidth = 0;

    const measureText = (text: string) => {
        // A rough measure. In practice, use canvas or some approximate approach
        // or measure after render. For simpler approximation:
        const avgCharWidth = 8; // Adjust if needed or measure accurately
        return text.length * avgCharWidth + wordsPadding * 2;
    };

    for (const word of words) {
        const wordWidth = measureText(word);
        if (currentLineWidth + wordWidth > maxWidth && lines[0].length !== 0) {
            lines.push([word]);
            currentLineWidth = wordWidth;
        } else {
            lines[lines.length - 1].push(word);
            currentLineWidth += wordWidth;
        }
    }
    return lines.filter((line) => line.length > 0);
}