// --------------------- HorizontalDraggableInfiniteList.tsx ---------------------
import React, { useRef, useCallback, CSSProperties } from "react";
import {
    DndContext,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent,
    closestCenter
} from "@dnd-kit/core";
import {
    SortableContext,
    horizontalListSortingStrategy,
    useSortable,
    arrayMove
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

// Универсальный тип для компонента
export interface HorizontalDraggableInfiniteListProps<T> {
    items: T[];
    // Функция для получения уникального ключа
    getItemKey: (item: T) => string;
    // Как рендерить сам элемент
    renderItem: (item: T, index: number) => React.ReactNode;
    // Callback, в котором сообщаем о новом порядке
    onReorder?: (newItems: T[]) => void;

    // Ниже опциональные пропсы для стилей и пр. (можно расширять)
    containerStyle?: CSSProperties;
    itemContainerStyle?: CSSProperties;
    infiniteScroll?: boolean;
    loadMoreItems?: () => void; // Если хотим подгружать элементы
}

function DraggableItem<T>({
                              item,
                              index,
                              getItemKey,
                              renderItem,
                              itemContainerStyle
                          }: {
    item: T;
    index: number;
    getItemKey: (item: T) => string;
    renderItem: (item: T, index: number) => React.ReactNode;
    itemContainerStyle?: CSSProperties;
}) {
    const id = getItemKey(item);

    // useSortable - хук из @dnd-kit/sortable, даёт нам методы и состояния для одного элемента
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id
    });

    const style: React.CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: "grab",
        opacity: isDragging ? 0.8 : 1,
        ...itemContainerStyle
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {renderItem(item, index)}
        </div>
    );
}

// Сам универсальный компонент
export function HorizontalDraggableInfiniteList<T>({
                                                       items,
                                                       getItemKey,
                                                       renderItem,
                                                       onReorder,
                                                       containerStyle,
                                                       itemContainerStyle,
                                                       infiniteScroll = false,
                                                       loadMoreItems
                                                   }: HorizontalDraggableInfiniteListProps<T>) {
    const listRef = useRef<HTMLDivElement | null>(null);

    // Настраиваем сенсоры (PointerSensor можно заменить на MouseSensor/TouchSensor и т.д.)
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: { distance: 5 }
        })
    );

    // Хендлер окончания перетаскивания
    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        if (!over || active.id === over.id) return;

        const oldIndex = items.findIndex((item) => getItemKey(item) === active.id);
        const newIndex = items.findIndex((item) => getItemKey(item) === over.id);
        if (oldIndex === -1 || newIndex === -1) return;

        // Поменяем элементы местами
        const newArr = arrayMove(items, oldIndex, newIndex);
        // Сообщаем наружу
        onReorder?.(newArr);
    };

    // Хендлер прокрутки, если хотим "бесконечно" догружать элементы
    const handleScroll = useCallback(() => {
        if (!listRef.current || !infiniteScroll || !loadMoreItems) return;
        const { scrollLeft, scrollWidth, clientWidth } = listRef.current;
        if (scrollLeft + clientWidth >= scrollWidth - 50) {
            loadMoreItems();
        }
    }, [infiniteScroll, loadMoreItems]);

    return (
        <div
            ref={listRef}
            onScroll={handleScroll}
            style={{
                display: "flex",
                overflowX: "auto",
                whiteSpace: "nowrap",
                flexWrap: "nowrap",
                padding: "8px",
                ...containerStyle
            }}
        >
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext items={items.map(getItemKey)} strategy={horizontalListSortingStrategy}>
                    {items.map((item, index) => (
                        <DraggableItem
                            key={getItemKey(item)}
                            item={item}
                            index={index}
                            getItemKey={getItemKey}
                            renderItem={renderItem}
                            itemContainerStyle={{
                                marginRight: 8,
                                ...itemContainerStyle
                            }}
                        />
                    ))}
                </SortableContext>
            </DndContext>
        </div>
    );
}