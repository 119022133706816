// src/ui/screens/student/StudentScreenInteractor.ts
import { makeAutoObservable, runInAction } from 'mobx';
import CompositionRoot from '../../../../compositionRoot';
import { AppUser } from '../../../../domain/models/AppUser';
import { Student } from '../../../../domain/models/Student';
import {CombinedStudent} from "../CombinedStudent";
import {CompletedClass} from "./CompletedClass";
import {LoadCompletedClassesUseCase} from "./LoadCompletedClassesUseCase";

export default class StudentScreenInteractor {
    teacherId: string | null = null;
    studentId: string | null = null;

    loadingStudent = false;
    error: string | null = null;
    studentData: CombinedStudent | null = null;

    completedClasses: CompletedClass[] = [];

    private loadCompletedClassesUseCase = new LoadCompletedClassesUseCase();


    constructor() {
        makeAutoObservable(this);
    }

    async startClass(teacherId: string, studentId: string, classId: string) {
        const userStorage = CompositionRoot.getUserStorage();
        const classStorage = CompositionRoot.getClassStorage();

        await classStorage.createClassSession(classId, teacherId, studentId, new Date());

        await Promise.all([
            userStorage.setCurrentClass(teacherId, classId),
            userStorage.setCurrentClass(studentId, classId),
        ]);
    }

    /**
     * Аналогично StudentsScreenInteractor: предоставляем метод подписки.
     * Возвращаем функцию отписки.
     */
    subscribeToCurrentUser(callback: (user: AppUser | null) => void): () => void {
        const appUserManager = CompositionRoot.getAppUserManager();
        const publisher = appUserManager.currentUserPublisher();

        const subscription = publisher.subscribe((user) => {
            callback(user);
        });

        return () => subscription.unsubscribe();
    }

    /**
     * Вызывается, когда известен teacherId (или userId) + studentId
     */
    async loadStudent(teacherId: string, studentId: string) {
        try {
            runInAction(() => {
                this.loadingStudent = true;
                this.error = null;
                this.studentData = null;
            });

            const userStorage = CompositionRoot.getUserStorage();
            const students = await userStorage.getStudents(teacherId);
            const foundStudent = students.find((s) => s.id === studentId);

            if (!foundStudent) {
                runInAction(() => {
                    this.error = 'Student not found for this teacher';
                });
                return;
            }

            const userData = await userStorage.getUserData(studentId);

            runInAction(() => {
                this.studentData = { student: foundStudent, userData };
            });
        } catch (e: any) {
            runInAction(() => {
                this.error = e.message || 'Failed to load student';
            });
        } finally {
            runInAction(() => {
                this.loadingStudent = false;
            });
        }
    }

    async loadCompletedClasses(studentUserId: string) {
        try {
            this.loadingStudent = true;
            this.error = null;

            const completed = await this.loadCompletedClassesUseCase.execute(studentUserId);

            runInAction(() => {
                this.completedClasses = completed;
                this.loadingStudent = false;
            });
        } catch (e: any) {
            runInAction(() => {
                this.error = e.message || 'Failed to load completed classes';
                this.loadingStudent = false;
            });
        }
    }


    dispose() {
        // если нужно отписываться ещё от чего-то
    }
}