import { HtmlRecognizer } from "./HtmlRecognizer";
import { OpenAIRequester } from "../../../../../data/implementations/OpenAIRequester";
import { WordItem } from "../../../../../domain/models/WordItem";

interface OptimizeHtmlResponse {
    optimizedHtml: string;
}

const EXERCISE_PROMPT = `
Generate clean semantic HTML5 markup describing the image content. Use appropriate tags like <figure>, <img>, <figcaption>, <section>. 
Include CSS styles inline if needed. Don't add background colors.
Если логика содержимого подразумевает ввод от пользователя, добавь поля для ввода текста в HTML.
Make visual structure as close as possible to original.
`;

const OPTIMIZATION_PROMPT = `
Оптимизируй следующий HTML код, улучшив визуальное расположение элементов:
{HTML}
Особенности:
1. Если в предложении присутствуют input-поля, встрои их непосредственно в текст, а не размещай отдельно.
2. Не добавляй дополнительных инструкций, заголовков или описаний.
3. В каждый элемент, с которым пользователь может взаимодействовать, добавь уникальный id.
4. Не удаляй br теги.
Верни только оптимизированный HTML код.
`;

export class OpenAIImageHtmlRecognizer implements HtmlRecognizer {
    constructor(private readonly requester: OpenAIRequester) {}

    async recognize(
        image: File | null,
        comment: string,
        words?: WordItem[]
    ): Promise<string> {
        console.log("Начало обработки изображения");

        if (!image) {
            console.log("Изображение отсутствует, возвращаем fallback HTML");
            return `
                <div>
                    <p>Нет картинки с правилом.</p>
                    <p>Комментарий: ${comment}</p>
                </div>
            `;
        }

        try {
            const base64Image = await this.fileToBase64(image);
            console.log("Изображение конвертировано в Base64");

            const rawHtml = await this.requester.requestHtmlWithImage(
                base64Image,
                EXERCISE_PROMPT
            );
            console.log("Сгенерирован исходный HTML:", rawHtml);

            const optimizedHtml = await this.optimizeHtml(rawHtml);
            console.log("Оптимизированный HTML:", optimizedHtml);

            return optimizedHtml;
        } catch (error) {
            console.error("Ошибка при обработке изображения:", error);
            return `<div>Произошла ошибка при обработке изображения: ${error}</div>`;
        }
    }

    private async optimizeHtml(html: string): Promise<string> {
        try {
            const response = await this.requester.requestObjectWithCustomFormat<OptimizeHtmlResponse>(
                OPTIMIZATION_PROMPT.replace("{HTML}", `\`\`\`\n${html}\n\`\`\``),
                { optimizedHtml: html }
            );
            return response.optimizedHtml;
        } catch (error) {
            console.error("Ошибка оптимизации HTML:", error);
            return html;
        }
    }

    private async fileToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const dataUrl = reader.result as string;
                resolve(dataUrl.split(",")[1]);
            };
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    }
}