import React, {useEffect, useRef} from "react";
import { CanvasViewModel } from "./CanvasViewModel";
import {CanvasLayout} from "./ui/CanvasLayout";
import {observer} from "mobx-react-lite";

interface CanvasContainerProps {
    viewModel: CanvasViewModel;
    width?: string;
    height?: string;
}

export const CanvasContainer: React.FC<CanvasContainerProps> = observer(({
                                                                        viewModel,
                                                                        width,
                                                                        height = "490px",
                                                                    }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    // Handle clicks outside the canvas to clear focus
    useEffect(() => {
        const handleDocumentClick = (e: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
                viewModel.setCanvasFocus(false);
            }
        };

        document.addEventListener('mousedown', handleDocumentClick);
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [viewModel]);

    // Handle focus on the container
    const handleContainerMouseDown = () => {
        viewModel.setCanvasFocus(true);
    };

    return (
        <div
            ref={containerRef}
            id={"1234"}
            onMouseDown={handleContainerMouseDown}
            style={{
                width: "100%",
                height: "100%",
                position: 'relative',
                outline: viewModel.isCanvasFocused ? '2px solid rgba(0,123,255,0.3)' : 'none',
                borderRadius: '4px'
            }}
        >
            <CanvasLayout
                items={viewModel.items}
                onAddTextItem={() => viewModel.handleAddTextItem()}
                onAddDrawingItem={() => viewModel.handleAddDrawing()}
                onAddTextAtPosition={(x, y) => viewModel.handleAddTextAtPosition(x, y)}
                onAddImage={() => viewModel.handleAddImage()}
                onUpdateItem={(itemId, data) => viewModel.handleUpdateItem(itemId, data)}
                onRemoveItem={(itemId) => viewModel.handleRemoveItem(itemId)}
                focusedItemId={viewModel.focusedItemId}
                editingId={viewModel.editingId}
                onSetFocusedItem={(id) => viewModel.setFocusedItemId(id)}
                onSetEditingId={(id) => viewModel.setEditingId(id)}
                fileInputRef={viewModel.fileInputRef}
                onFileChange={(e) => viewModel.handleFileChange(e)}
                onItemsChange={(newItems) => viewModel.setAllItems(newItems)}
                isUploadingImage={viewModel.isUploadingImage}
                width={width}
                height={height}
            />
        </div>
    );
});