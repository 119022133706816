import React from "react";
import { useSelectionState } from "./SelectionStateContext";
import {observer} from "mobx-react-lite";

interface SelectionLineViewProps {
    width: number;
    height: number;
}

export const SelectionLineView: React.FC<SelectionLineViewProps> = observer(({ width, height }) => {
    const { selectedPositions, lineColor, game } = useSelectionState();
    const rows = game.fieldSize.rowsCount;
    const cols = game.fieldSize.columnsCount;

    // If only one letter is selected, we draw a small circle
    const positionsToDraw =
        selectedPositions.length === 1
            ? [selectedPositions[0], selectedPositions[0]]
            : selectedPositions;

    if (positionsToDraw.length < 2) {
        return null;
    }

    const pathD = buildPathD(positionsToDraw, width, height, rows, cols);
    const squareSize = Math.min(width / cols, height / rows);
    const strokeWidth = squareSize * 0.75;

    return (
        <svg
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                pointerEvents: "none",
                width,
                height,
            }}
        >
            <path
                d={pathD}
                stroke={lineColor}
                strokeWidth={strokeWidth}
                fill="none"
                strokeLinecap="round"
            />
        </svg>
    );
});

// Builds the path string for the selected positions
function buildPathD(
    positions: { row: number; col: number }[],
    width: number,
    height: number,
    rowsCount: number,
    columnsCount: number
): string {
    if (!positions.length) return "";
    const stepX = width / columnsCount;
    const stepY = height / rowsCount;

    const pathPoints = positions.map((pos) => {
        const x = pos.col * stepX + stepX / 2;
        const y = pos.row * stepY + stepY / 2;
        return { x, y };
    });

    return pathPoints.reduce((acc, curr, index) => {
        if (index === 0) {
            return `M${curr.x},${curr.y}`;
        }
        return acc + ` L${curr.x},${curr.y}`;
    }, "");
}