// LessonsListViewModel.ts
import { makeAutoObservable, runInAction } from 'mobx';
import { NavigateFunction } from 'react-router-dom';
import CompositionRoot from "../../../../../compositionRoot";
import {Exercise, Lesson} from "../../../../../domain/models";

interface ExerciseSummary {
    id: string;
    info: string;
}

export interface LessonCardViewData {
    id: string;
    name: string;
    authorId?: string;
    exercises: ExerciseSummary[];
}

export class LessonsListViewModel {
    public lessons: LessonCardViewData[] = [];

    private lastMode: 'all' | 'my' | null = null;
    private isLoaded = false;

    private navigate: NavigateFunction;
    private userManager = CompositionRoot.getAppUserManager();
    private storage = CompositionRoot.exercisesStorage;

    constructor(navigate: NavigateFunction) {
        makeAutoObservable(this, {}, { autoBind: true });
        this.navigate = navigate;
    }

    public setNavigate(navigate: NavigateFunction) {
        this.navigate = navigate;
    }

    /**
     * Главный метод загрузки:
     * При каждом маунте LessonsList вызываем loadLessons(mode).
     * Если ранее уже грузили этот же режим — не загружаем заново.
     */
    public async loadLessons(mode: 'all' | 'my') {
        console.log("loadLessons")
        if (mode === 'all') {
            await this.loadAllLessons();
        } else {
            await this.loadMyLessons();
        }
        this.isLoaded = true;
        this.lastMode = mode;
    }

    private async loadMyLessons() {
        console.log("loadMyLessons")
        const currentUser = this.userManager.currentUser;
        if (!currentUser?.exerciseSets) return;
        console.log("loadMyLessons !currentUser?.exerciseSets passed")
        try {
            const lessonsData = await Promise.all(
                currentUser.exerciseSets.map(async (id: string) => {
                    try {
                        const lesson = (await this.storage.getExerciseSet(id)) as Lesson;
                        return lesson;
                    } catch (error) {
                        console.error('Error loading lesson with id:', id, error);
                        return null;
                    }
                })
            );

            const filteredData = lessonsData.filter(
                (lesson): lesson is Lesson => lesson !== null
            );
            const lessonViewData = filteredData.map((lesson) => ({
                id: lesson.id,
                name: lesson.name || lesson.id,
                authorId: lesson.authorId,
                exercises: lesson.exercises.map((ex) => ({
                    id: ex.id,
                    info: this.getExerciseShortInfo(ex),
                })),
            }));

            runInAction(() => {
                this.lessons = lessonViewData;
            });
        } catch (error) {
            console.error('Error loading my lessons:', error);
        }
    }

    private async loadAllLessons() {
        try {
            const allLessons = await this.storage.getAllExerciseSets();
            const lessonViewData = allLessons.map((lesson) => ({
                id: lesson.id,
                name: lesson.name || lesson.id,
                authorId: lesson.authorId,
                exercises: lesson.exercises.map((ex) => ({
                    id: ex.id,
                    info: this.getExerciseShortInfo(ex),
                })),
            }));

            runInAction(() => {
                this.lessons = lessonViewData;
            });
        } catch (error) {
            console.error('Error loading all lessons:', error);
        }
    }

    private getExerciseShortInfo(exercise: Exercise): string {
        return `Exercise type: ${exercise.type.kind}`;
    }

    public onLessonCardClick(lessonId: string) {
        this.navigate(`/lessonPractice/${lessonId}`);
    }
}