import React, { useState, useRef, useEffect } from "react";
import CompositionRoot from "../../../../../compositionRoot";
import { ImageUploading } from "../../../../../data/implementations/Files/ImageUploader";

interface CustomImageElementProps {
    src: string;          // current image URL (could be invalid)
    alt?: string;
    style?: React.CSSProperties;
    onImageChanged: (newSrc: string) => void;
}

/**
 * Renders:
 *  - If `src` is non-empty and valid => actual <img />.
 *  - If `src` is empty, or onError => placeholder block with "Upload" logic.
 *  Uses `aspect-ratio: 1 / 1` to keep the placeholder a square by default.
 */
export function CustomImageElement({ src, alt, style, onImageChanged }: CustomImageElementProps) {
    const [isBroken, setIsBroken] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [isHovering, setIsHovering] = useState(false);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const imageUploader: ImageUploading = CompositionRoot.imageUploading; // your firebase storage

    // If src changes externally, reset isBroken if we have a new non-empty src.
    useEffect(() => {
        if (!src) setIsBroken(true);
        else setIsBroken(false);
    }, [src]);

    function handlePickFile() {
        fileInputRef.current?.click();
    }

    async function handleFileSelect(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.files || e.target.files.length === 0) return;
        const file = e.target.files[0];
        e.target.value = ""; // clear so user can pick the same file again
        await uploadAndSetImage(file);
    }

    async function handleDrop(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        setIsHovering(false);
        const file = e.dataTransfer.files?.[0];
        if (file) {
            await uploadAndSetImage(file);
        }
    }

    function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        setIsHovering(true);
    }

    function handleDragLeave() {
        setIsHovering(false);
    }

    async function uploadAndSetImage(file: File) {
        try {
            setUploading(true);
            const downloadUrl = await imageUploader.uploadImage(file, crypto.randomUUID());
            onImageChanged(downloadUrl);
        } catch (err) {
            console.error("Upload error:", err);
            alert("Failed to upload image.");
        } finally {
            setUploading(false);
        }
    }

    // If we have a non-empty src and it's not broken => show <img>
    if (src && !isBroken) {
        return (
            <div style={{ display: "inline-block", position: "relative", ...style }}>
                <img
                    src={src}
                    alt={alt}
                    style={{ cursor: "pointer", ...style }}
                    onClick={handlePickFile}
                    onError={() => setIsBroken(true)}
                />
                <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileSelect}
                />
                {uploading && (
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: "rgba(255,255,255,0.7)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        Uploading...
                    </div>
                )}
            </div>
        );
    }

    // Else, no src or broken => placeholder
    // Use aspect-ratio to keep it square, so it adapts to parent's width or a max-width
    return (
        <div
            style={{
                // aspect-ratio ensures we keep a square. For older browsers, you might need a fallback.
                aspectRatio: "1 / 1",
                width: "100%", // Let parent container control width, or set a maxWidth
                border: "2px dashed #888",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: isHovering ? "#ccc" : "#f0f0f0", // more contrast
                cursor: "pointer",
                ...style,
            }}
            onClick={handlePickFile}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            {uploading ? "Uploading..." : "+ img"}
            <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileSelect}
            />
        </div>
    );
}