import {AudioPlayerStore} from "./AudioPlayerStore";
import compositionRoot from "../../../../compositionRoot";

export class AudioPlayerInteractor {
    private store: AudioPlayerStore;

    constructor(store: AudioPlayerStore) {
        this.store = store;
    }

    /** Генерируем ID (uppercase) */
    generateAudioId(): string {
        return crypto.randomUUID().toUpperCase()
    }

    /**
     * Если уже есть audioId, грузим (fileLink, recognizedText) из стораджа.
     */
    async loadExistingAudio(audioId: string) {
        this.store.setAudioId(audioId);
        // Считаем, что uploadStatus=loading, т.к. "получаем данные"
        this.store.setUploadStatus("loading");
        this.store.setRecognitionStatus("idle");
        this.store.setStorageStatus("idle");

        try {
            const { fileLink, recognizedText } = await compositionRoot.audioStorageService.fetchAudioData(audioId);
            this.store.setFileLink(fileLink);
            this.store.setRecognizedText(recognizedText);

            // Всё готово
            this.store.setUploadStatus("success");
            this.store.setRecognitionStatus("success");
            this.store.setStorageStatus("success");
            this.store.notifyComplete();
        } catch (err: any) {
            this.store.setAllError(err.message || "Failed to load existing audio data");
        }
    }

    /**
     * Основной флоу:
     * 1) Генерим audioId
     * 2) Загружаем файл + audioId => получаем fileLink
     * 3) Параллельно распознаём
     * 4) По завершении сохраняем всё в storage
     */
    async startUploadFlow(file: File) {
        // Генерим id
        const audioId = this.generateAudioId();
        this.store.setAudioId(audioId);

        // Обновим статусы
        this.store.setUploadStatus("loading");
        this.store.setRecognitionStatus("loading");
        this.store.setStorageStatus("idle");

        try {
            const uploadPromise = compositionRoot.audioUploadService.uploadAudio(file, audioId);
            const recognitionPromise = compositionRoot.textRecognitionService.recognizeTextFromAudio(file);

            const [fileLink, recognizedText] = await Promise.all([uploadPromise, recognitionPromise]);

            // Ставим статусы
            this.store.setUploadStatus("success");
            this.store.setRecognitionStatus("success");

            // Сохраняем в store
            this.store.setFileLink(fileLink);
            this.store.setRecognizedText(recognizedText);

            // Запишем всё в storage
            this.store.setStorageStatus("loading");
            await compositionRoot.audioStorageService.storeAudioData({
                audioId,
                fileLink,
                recognizedText
            });

            this.store.setStorageStatus("success");
            this.store.notifyComplete();
        } catch (err: any) {
            this.store.setAllError(err.message || "Error during upload+recognition flow");
        }
    }
}
