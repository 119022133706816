/** Сервис распознавания */
export interface ITextRecognitionService {
    recognizeTextFromAudio(file: File): Promise<string>;
}

/** Мок распознавания текста */
export class MockTextRecognitionService implements ITextRecognitionService {
    async recognizeTextFromAudio(file: File): Promise<string> {
        await this.simulateDelay(1000);
        return "Recognized text from audio";
    }

    private async simulateDelay(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
}

export class OpenAIWhisperRecognitionService implements ITextRecognitionService {
    private apiKey: string;
    private apiUrl = "https://api.openai.com/v1/audio/transcriptions";
    private model = "whisper-1";

    constructor(apiKey: string) {
        this.apiKey = apiKey;
    }

    /**
     * Распознаём текст из аудиофайла file с помощью OpenAI Whisper.
     * Возвращаем расшифрованный текст (string).
     */
    public async recognizeTextFromAudio(file: File): Promise<string> {
        // 1) Формируем FormData
        const formData = new FormData();
        // Поле "file" — это ключ, который ожидает OpenAI для загрузки аудио.
        // Третьим параметром можно передать file.name, чтобы API корректно
        // определил MIME-тип и имя файла (полезно для Whisper).
        formData.append("file", file, file.name);
        // Указываем модель. В Swift-коде: "model=whisper-1"
        formData.append("model", this.model);

        // 2) Делаем POST-запрос
        const response = await fetch(this.apiUrl, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${this.apiKey}`
                // Не ставьте "Content-Type": "multipart/form-data" вручную.
                // fetch сам выставит границы form-data при использовании FormData.
            },
            body: formData
        });

        // 3) Проверяем статус
        if (!response.ok) {
            // Если ошибка, пытаемся распарсить тело:
            let errorBody: any = "";
            try {
                errorBody = await response.json();
            } catch {
                /* ignore JSON parse error */
            }
            console.error("OpenAI Whisper API error:", response.status, errorBody);
            throw new Error(`OpenAI Whisper API Error: ${response.statusText}`);
        }

        // 4) Парсим JSON-ответ. Ожидаем, что там есть ключ "text".
        const jsonData = await response.json();
        if (!jsonData || typeof jsonData.text !== "string") {
            throw new Error("OpenAI Whisper response has no valid 'text' field.");
        }

        // 5) Возвращаем распознанный текст
        return jsonData.text;
    }
}