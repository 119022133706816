import {Exercise} from "../models";
import {ExercisesStorage} from "../interfaces/ExercisesStorage";

export interface LoadExercisesInteractor {
    getAllExercises(): Promise<Exercise[]>;
}

export class LoadExercisesInteractorStub implements LoadExercisesInteractor {
    private mockExercises: Exercise[] = [];

    constructor(mockExercises?: Exercise[]) {
        if (mockExercises) {
            this.mockExercises = mockExercises;
        }
    }

    async getAllExercises(): Promise<Exercise[]> {
        // Возвращаем заглушку
        return this.mockExercises;
    }
}

export class LoadExercisesInteractorImpl implements LoadExercisesInteractor {
    private storage: ExercisesStorage;

    constructor(storage: ExercisesStorage) {
        this.storage = storage;
    }

    async getAllExercises(): Promise<Exercise[]> {
        return await this.storage.getAllExercises();
    }
}