import {
    Exercise,
    ExerciseJustContent,
} from "../../../../../domain/models";
import {CanvasItem} from "../CanvasItem";
import {decodeCanvasItem, encodeCanvasItem} from "./FirestoreNoteRepository";

export function serializeJustContent(exercise: Exercise): any {
    if (exercise.type.kind !== 'justContent') {
        throw new Error("serializeAnagram called with non-anagram exercise");
    }

    const jc: ExerciseJustContent = exercise.type.data;
    return {
        type: 'justContent',
        authorId: exercise.authorId,
        description: exercise.description,
        contents: jc.contents.map(item => encodeCanvasItem(item))
    };
}

export function deserializeJustContent(data: any, id: string): Exercise | null {
    const { contents } = data;
    if (!Array.isArray(contents)) {
        return null;
    }

    const decodedContents: CanvasItem[] = contents
        .map((ci: any) => decodeCanvasItem(ci))
        .filter((c: CanvasItem | null): c is CanvasItem => c !== null);

    return {
        id,
        authorId: data.authorId,
        description: data.description,
        type: {
            kind: 'justContent',
            data: {
                contents: decodedContents
            }
        }
    };
}