// src/data/implementations/FirestoreUserService.ts
import { UserStorage } from '../../domain/interfaces/UserStorage';
import {AppUser, fromDictionary, Student, Tutor} from '../../domain/models';
import { db } from '../../data/config/firebase';
import {
    doc,
    setDoc,
    onSnapshot,
    collection,
    getDocs,
    updateDoc,
    query,
    where,
    arrayUnion,
    Timestamp,
    getDoc,
    runTransaction,
} from 'firebase/firestore';

class FirestoreUserService implements UserStorage {
    async saveUser(user: AppUser): Promise<void> {
        const userData = {
            uid: user.uid,
            email: user.email ?? "",
            role: user.role ?? null,
            languages: user.languages ?? null,
            status: user.status ?? "free",
            trialStartDate: user.trialStartDate ? Timestamp.fromDate(user.trialStartDate) : null,
            delayedSets: user.delayedSets ?? [],
            exerciseSets: user.exerciseSets ?? [],
            profileName: user.profileName ?? null,
            profileAvatar: user.profileAvatar ?? null,
            profileAbout: user.profileAbout ?? null,
            currentClass: user.currentClass ?? null,
            completedClasses: user.completedClasses ?? [],
        };
        await setDoc(doc(db, "usersData", user.uid), userData, { merge: true });
    }

    observeUserData(userId: string, callback: (data: Record<string, any>) => void): () => void {
        const unsubscribe = onSnapshot(doc(db, "usersData", userId), (snapshot) => {
            callback(snapshot.data() || {});
        });
        return unsubscribe; // Вернем функцию для отписки
    }

    async addStudent(userId: string, student: Student): Promise<void> {
        const studentData = {
            email: student.email,
            addDate: student.addDate,
            isConfirmed: student.isConfirmed
        };
        await setDoc(doc(db, "usersData", userId, "students", student.id), studentData, { merge: true });
    }

    async getStudents(userId: string): Promise<Student[]> {
        const snapshot = await getDocs(collection(db, "usersData", userId, "students"));
        return snapshot.docs.map(doc => ({
            id: doc.id,
            email: doc.data().email,
            addDate: (doc.data().addDate as Timestamp).toDate(),
            isConfirmed: doc.data().isConfirmed
        }));
    }

    observeStudents(
        userId: string,
        callback: (students: Student[]) => void,
        errorCallback?: (error: Error) => void
    ): () => void {
        const ref = collection(db, "usersData", userId, "students");

        // onSnapshot returns an unsubscribe function
        const unsubscribe = onSnapshot(
            ref,
            (querySnapshot) => {
                const students: Student[] = querySnapshot.docs.map((doc) => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        email: data.email,
                        addDate: (data.addDate as Timestamp).toDate(),
                        isConfirmed: data.isConfirmed,
                    };
                });

                callback(students);
            },
            (error) => {
                // Optional: handle snapshot errors
                if (errorCallback) {
                    errorCallback(error);
                } else {
                    console.error("observeStudents error:", error);
                }
            }
        );

        return unsubscribe;
    }


    async getTutors(userId: string): Promise<Tutor[]> {
        const snapshot = await getDocs(collection(db, "usersData", userId, "tutors"));
        return snapshot.docs.map(doc => ({
            id: doc.id,
            email: doc.data().email,
            addDate: (doc.data().addDate as Timestamp).toDate(),
            isConfirmed: doc.data().isConfirmed
        }));
    }

    async addTutorForStudent(studentId: string): Promise<void> {
        // const currentUser = AppUserManager.getCurrentUser();
        // if (!currentUser) {
        //     throw new Error("No current user");
        // }
        // const tutorData = {
        //     email: currentUser.email || "",
        //     addDate: Timestamp.now(),
        //     isConfirmed: false
        // };
        // await setDoc(doc(db, "usersData", studentId, "tutors", currentUser.uid), tutorData);
    }

    async confirmTutor(tutor: Tutor, student: Student): Promise<void> {
        const studentRef = doc(db, "usersData", tutor.id, "students", student.id);
        const tutorRef = doc(db, "usersData", student.id, "tutors", tutor.id);

        await runTransaction(db, async (transaction) => {
            transaction.set(
                studentRef,
                { ...student, isConfirmed: true },
                { merge: true }
            );
            transaction.set(
                tutorRef,
                { ...tutor, isConfirmed: true },
                { merge: true }
            );
        });
    }

    async getUserData(userId: string): Promise<AppUser | null> {
        const docRef = doc(db, "usersData", userId);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
            return null;
        }
        const data = docSnap.data();
        // Преобразуем данные в объект AppUser
        return fromDictionary({
            ...data,
            uid: docSnap.id,
        });
    }


    async findUserByEmail(email: string): Promise<AppUser | null> {
        const userQuery = query(collection(db, "usersData"), where("email", "==", email));
        const querySnapshot = await getDocs(userQuery);
        const doc = querySnapshot.docs[0];

        if (!doc) return null;

        const data = doc.data();
        return fromDictionary({ ...data, uid: doc.id });
    }

    async sendSetToStudent(studentId: string, setId: string): Promise<void> {
        const userRef = doc(db, "usersData", studentId);
        await updateDoc(userRef, {
            delayedSets: arrayUnion(setId)
        });
    }

    async addExerciseSet(id: string, userId: string): Promise<void> {
        const userRef = doc(db, "usersData", userId);
        await updateDoc(userRef, {
            exerciseSets: arrayUnion(id) // Добавляем ID набора упражнений в массив
        });
    }

    async setCurrentClass(userId: string, classId: string): Promise<void> {
        const userRef = doc(db, "usersData", userId);
        await updateDoc(userRef, {
            currentClass: classId
        });
    }

    async completeClass(userId: string, classId: string): Promise<void> {
        const userRef = doc(db, "usersData", userId);
        await updateDoc(userRef, {
            currentClass: null,
            completedClasses: arrayUnion(classId)
        });
    }
}

export default FirestoreUserService;
