import React, { MouseEvent, TouchEvent } from "react";
import { useSelectionState } from "./SelectionStateContext";
import {observer} from "mobx-react-lite";

interface FieldViewProps {
    width: number;
    height: number;
}

export const FieldView: React.FC<FieldViewProps> = observer(({ width, height }) => {
    const { game, handleDragChange, handleDragEnd } = useSelectionState();
    const rows = game.fieldSize.rowsCount;
    const cols = game.fieldSize.columnsCount;

    // For pointer tracking
    const onPointerDown = (e: React.PointerEvent<HTMLDivElement>) => {
        e.currentTarget.setPointerCapture(e.pointerId);
        handleMove(e);
    };

    const onPointerMove = (e: React.PointerEvent<HTMLDivElement>) => {
        if (e.buttons === 1) {
            handleMove(e);
        }
    };

    const onPointerUp = (e: React.PointerEvent<HTMLDivElement>) => {
        e.currentTarget.releasePointerCapture(e.pointerId);
        handleDragEnd();
    };

    function handleMove(e: React.PointerEvent<HTMLDivElement>) {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        handleDragChange({ x, y }, { width: rect.width, height: rect.height });
    }

    return (
        <div
            onPointerDown={onPointerDown}
            onPointerMove={onPointerMove}
            onPointerUp={onPointerUp}
            style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}
        >
            {Array.from({ length: rows }, (_, rowIndex) => (
                <div key={rowIndex} style={{ display: "flex", flex: 1 }}>
                    {Array.from({ length: cols }, (_, colIndex) => {
                        const letter = game.letter({ row: rowIndex, col: colIndex });
                        return (
                            <div
                                key={colIndex}
                                style={{
                                    flex: 1,
                                    border: "1px solid #ccc",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <span style={{ fontWeight: "bold", fontSize: "1.5rem", userSelect: "none", color: "black" }}>{letter}</span>
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );
});
