// LessonWordsContainer.tsx
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FirestoreLessonWordsRepository } from "./FirestoreLessonWordsRepository";
import { LessonWordsInteractor } from "./LessonWordsInteractor";
import { LessonWordsWindowViewModel } from "./LessonWordsWindowViewModel";
import { LessonWordsWindowView } from "./LessonWordsWindowView";
import { LessonWordsState } from "./LessonWordsState";

interface LessonWordsContainerProps {
    sessionId: string;
    studentId: string;
    width?: number;
    height?: number;
}

// Начальное состояние (если документа нет в Firestore)
const initialWordsState: LessonWordsState = {
    version: 0,
    items: [],
    // Новые поля (см. ниже в LessonWordsState)
    isAdding: false,
    newWordDraft: {
        text: "",
        translation: "",
        example: ""
    }
};

export const LessonWordsContainer: React.FC<LessonWordsContainerProps> = observer(
    ({ sessionId, studentId, width, height }) => {

        // Создаём вью-модель один раз
    const [viewModel] = useState(() => {
        const repo = new FirestoreLessonWordsRepository();
        const interactor = new LessonWordsInteractor(sessionId, initialWordsState, repo, studentId);
        return new LessonWordsWindowViewModel(interactor);
    });

    // При размонтировании — отписываемся
    useEffect(() => {
        return () => {
            viewModel.destroy();
        };
    }, [viewModel]);

    return (
        <LessonWordsWindowView
            viewModel={viewModel}
            width={width}
            height={height}
        />
    );
});