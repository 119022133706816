import { makeAutoObservable, reaction, runInAction } from "mobx";
import { MissingWordState } from "./MissingWordState";
import { MissingWordUseCase } from "./MissingWordUseCase";
import { MissingWordUIHandler } from "./MissingWordUIHandler";
import { Exercise } from "../../../../../domain/models";
import { PracticeSessionDelegate } from "../../../../../domain/Exercises/PracticeSessionDelegate";

/**
 * Interactor — связующее звено между UIHandler (который отвечает за онлайн-синхронизацию),
 * UseCase (бизнес-логикой) и локальным состоянием приложения.
 */
export class MissingWordInteractor {
    private delegate?: PracticeSessionDelegate;
    private useCase: MissingWordUseCase;
    private uiHandler: MissingWordUIHandler;

    public onStateChange?: (newState: MissingWordState) => void;
    private _state: MissingWordState;

    constructor(
        exercise: Exercise,
        delegate?: PracticeSessionDelegate,
        uiHandler?: MissingWordUIHandler,
        useCase?: MissingWordUseCase
    ) {
        if (exercise.type.kind !== "missingWord") {
            throw new Error("Not a missingword exercise");
        }
        this.delegate = delegate;
        this.useCase = useCase || new MissingWordUseCase();

        // Если UIHandler не передан, создаём дефолтный.
        this.uiHandler =
            uiHandler ||
            new MissingWordUIHandler(exercise.id);

        // Изначально state берём из exercise + UIHandler
        this._state = {
            sentence: exercise.type.data.sentence,
            currentInput: this.uiHandler.currentInput,
            isCorrect: this.uiHandler.isCorrect,
            correctForm: exercise.type.data.correctForm,
        };

        makeAutoObservable(this);

        this.setupBindings();
        this.syncFromUIHandler();
    }

    get state(): MissingWordState {
        return this._state;
    }

    private setState(newState: MissingWordState) {
        this._state = newState;
        this.onStateChange?.(newState);
    }

    /**
     * Настраиваем MobX-reaction, чтобы отлавливать любые изменения полей в UIHandler
     * и при необходимости подтягивать их в локальное состояние.
     */
    private setupBindings(): void {
        reaction(
            () => [
                this.uiHandler.currentInput,
                this.uiHandler.isCorrect,
            ],
            () => {
                this.syncFromUIHandler();
            }
        );
    }

    /**
     * Подтянуть изменения из UIHandler (если они пришли, например, по сети).
     */
    private syncFromUIHandler() {
        const { currentInput, isCorrect } = this.uiHandler;
        const sentence = this._state.sentence
        const correctForm = this._state.correctForm
        const incoming: MissingWordState = {
            sentence,
            currentInput,
            isCorrect,
            correctForm
        };

        if (JSON.stringify(incoming) !== JSON.stringify(this._state)) {
            runInAction(() => {
                this.setState(incoming);
            });
        }
    }

    /**
     * Запушить локальные изменения обратно в UIHandler,
     * чтобы они транслировались через сеть.
     */
    private syncToUIHandler(newState: MissingWordState) {
        this.uiHandler.currentInput = newState.currentInput;
        this.uiHandler.isCorrect = newState.isCorrect;

        this.uiHandler.sendUpdateIfNeeded();
    }

    // -----------------
    // Методы обновления
    // -----------------

    public updateInputValue(newValue: string) {
        this.syncFromUIHandler();  // на всякий случай подтянем последние изменения
        const updated = this.useCase.updateInput(this._state, newValue);
        if (updated !== this._state) {
            this.setState(updated);
            this.syncToUIHandler(updated);
        }
    }

    public checkAnswer() {
        this.syncFromUIHandler();
        const updated = this.useCase.checkAnswer(this._state);
        if (updated !== this._state) {
            this.setState(updated);
            this.syncToUIHandler(updated);
        }
    }

    public goNext() {
        this.syncFromUIHandler();
        const updated = this.useCase.goNext(this._state);
        if (updated !== this._state) {
            this.setState(updated);
            this.syncToUIHandler(updated);
        }
    }
}