import React from "react";
import { Resizer } from "../../../Resizer";
import { ExerciseViewable } from "../../ExerciseViewable";
import {Exercise} from "../../../../../domain/models";
import {CrosswordLearnViewModel} from "../Base/CrosswordLearnViewModel";
import {CrosswordLearnView} from "../Base/CrosswordLearnView";
import {ExerciseCrossword} from "../Domain/ExerciseCrossword"; // Интерфейс для viewable-объектов

export class ExerciseCrosswordViewable implements ExerciseViewable {
    constructor(private exerciseCrossword: ExerciseCrossword) {}

    renderView(exercise: Exercise, width?: number, height?: number): JSX.Element {
        if (exercise.type.kind !== "crossword") {
            throw new Error("Unsupported exercise type for ExerciseCrosswordViewable");
        }
        const targetWidth = width ?? 300;
        const targetHeight = height ?? 150;
        // Создаем вьюмодель. Если интеракторы/хендлеры не нужны – можно передать undefined.
        const viewModel = new CrosswordLearnViewModel(exercise, undefined, undefined, undefined);
        return (
            <Resizer targetSize={{ width: targetWidth, height: targetHeight }}>
                <CrosswordLearnView viewModel={viewModel} />
            </Resizer>
        );
    }
}