// ----------------- src/ui/components/ShareButton.tsx -----------------
import React, { useState } from 'react';
import BaseButton, { ButtonVariant } from '../../BaseButton';

type ShareButtonProps = {
    link: string;
    variant?: ButtonVariant;
};

const ShareButton: React.FC<ShareButtonProps> = ({ link, variant = ButtonVariant.Small }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleShare = async () => {
        try {
            await navigator.clipboard.writeText(link);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 3000);
        } catch (error) {
            console.error('Failed to copy link', error);
        }
    };

    return (
        <BaseButton variant={variant} onClick={handleShare}>
            {isCopied ? 'Link Copied' : 'Copy link'}
        </BaseButton>
    );
};

export default ShareButton;