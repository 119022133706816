// CrosswordLearnView.tsx
import React from "react";
import { observer } from "mobx-react-lite";
import { CrosswordGridView } from "./CrosswordGridView";
import { WordsListView } from "./WordsListView";
import {CrosswordLearnViewModel} from "./CrosswordLearnViewModel";

interface CrosswordLearnViewProps {
    viewModel: CrosswordLearnViewModel;
}

export const CrosswordLearnView: React.FC<CrosswordLearnViewProps> = observer(({ viewModel }) => {
    const handleFinishClick = () => {
        viewModel.finishCrossword();
    };

    const containerStyle: React.CSSProperties = {
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
    };

    const innerContainerStyle: React.CSSProperties = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "1rem",
    };

    const puzzleContainerStyle: React.CSSProperties = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginRight: "2rem",
    };

    return (
        <div style={containerStyle}>
            <div style={innerContainerStyle}>
                <div style={puzzleContainerStyle}>
                    <CrosswordGridView
                        model={{
                            occupiedPositions: Array.from(viewModel.occupiedPositions),
                            startPositions: viewModel.startPositions,
                            gridData: viewModel.gridData,
                            minRow: viewModel.minRow,
                            maxRow: viewModel.maxRow,
                            minCol: viewModel.minCol,
                            maxCol: viewModel.maxCol,
                            cellSize: viewModel.cellSize,
                        }}
                        isPositionEditable={viewModel.isPositionEditable.bind(viewModel)}
                        handleGridUpdate={viewModel.handleGridUpdate.bind(viewModel)}
                        handleLetterChange={viewModel.handleLetterChange.bind(viewModel)}
                        clearCellContent={viewModel.clearCellContent.bind(viewModel)}
                    />
                    {viewModel.isCrosswordSolved && (
                        <button onClick={handleFinishClick} style={{ marginTop: "1rem", padding: "0.5rem 1rem" }}>
                            Далее
                        </button>
                    )}
                </div>

                <WordsListView
                    words={viewModel.words}
                    foundWords={viewModel.foundWords}
                    hintedWords={viewModel.hintedWords}
                    onHint={viewModel.hintForWord.bind(viewModel)}
                />
            </div>
        </div>
    );
});