// ========== MissingWord/Base/MissingWordViewBase.tsx ==========

import React, { useEffect, useState } from 'react';
import { BaseVStack } from '../../BaseComponents/BaseVStack';
import { BaseText } from '../../BaseComponents/BaseText';
import { BaseTextField } from '../../BaseComponents/BaseTextField';
import BaseButton from "../../../BaseButton";

interface MissingWordViewBaseProps {
    sentence: string;
    inputText: string;
    isCorrect: boolean | null;
    correctAnswer: string;
    onInputChange: (value: string) => void;
    onEnterPressed: () => void;
    onCheckPressed: () => void;
}

/**
 * Компонент-«база», который отрисовывает MissingWord по заданным пропсам.
 */
export function MissingWordViewBase({
                                        sentence,
                                        inputText,
                                        isCorrect,
                                        correctAnswer,
                                        onInputChange,
                                        onEnterPressed,
                                        onCheckPressed,
                                    }: MissingWordViewBaseProps) {

    const [autoFocus, setAutoFocus] = useState(false);

    useEffect(() => {
        // При первом рендере включаем автофокус
        setAutoFocus(true);
    }, []);

    return (
        <BaseVStack>
            <BaseText font="largeTitle">
                {sentence}
            </BaseText>

            <div style={{ height: 16 }} />

            <div style={{  }}>
                <BaseTextField
                    placeholder="Enter the missing word"
                    value={inputText}
                    onChange={onInputChange}
                    onEnter={onEnterPressed}
                    fontSize={20}
                    controlSize="large"
                    frameMaxWidth={400}
                    autoFocus={autoFocus}
                />
            </div>

            <div style={{ height: 16 }} />

            {isCorrect === null && (
                <div>
                    <BaseButton onClick={onCheckPressed}>
                        Check
                    </BaseButton>
                </div>
            )}
            {isCorrect === false && (
                <>
                    <BaseText>Wrong</BaseText>
                    <BaseText>Correct answer: {correctAnswer}</BaseText>
                </>
            )}

            {isCorrect === true && (
                <>
                    <BaseText>Correct!</BaseText>
                </>
            )}
        </BaseVStack>
    );
}