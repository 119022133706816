// OpenAIRequester.ts

import { JsonSchemaGenerator } from "./JsonSchemaGenerator";

/**
 * Аналог Swift:
 * public class OpenAIRequester: OpenAIRequesting {
 *   ...
 * }
 */
export class OpenAIRequester {
    private apiKey: string;
    private apiUrl: string = "https://api.openai.com/v1/chat/completions";
    private model: string = "gpt-4o-2024-08-06";

    private schemaGenerator = new JsonSchemaGenerator();

    constructor(apiKey: string) {
        this.apiKey = apiKey;
    }

    /**
     * Аналог Swift:
     * public func requestWithCustomFormat<T: Codable>(prompt: String, example: T) async throws -> [T]
     */
    public async requestWithCustomFormat<T>(prompt: string, example: T): Promise<T[]> {
        const url = this.apiUrl;
        const headers = {
            "Authorization": `Bearer ${this.apiKey}`,
            "Content-Type": "application/json",
        };

        // messages
        const messages = [
            { role: "system", content: "You are a helpful assistant." },
            { role: "user", content: prompt },
        ];

        // Generate JSON schema for an array of T
        const jsonSchema = this.schemaGenerator.generateArrayOf(example);

        const responseFormat = {
            type: "json_schema",
            json_schema: {
                name: "custom_response",
                strict: true,
                schema: jsonSchema,
            },
        };

        // Параметры, аналог Swift:
        const parameters = {
            model: this.model,
            messages: messages,
            max_tokens: 1500,
            temperature: 0.2,
            response_format: responseFormat,
        };

        // Отправляем запрос
        const response = await fetch(url, {
            method: "POST",
            headers,
            body: JSON.stringify(parameters),
        });

        if (!response.ok) {
            throw new Error(`OpenAI API request failed with status ${response.status}: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("Received JSON:", data);

        // Ищем data.choices[0].message.content
        const choices = data.choices;
        if (!choices || choices.length === 0) {
            throw new Error("No choices returned from OpenAI API");
        }
        const message = choices[0].message;
        const content = message?.content;
        if (!content) {
            throw new Error("No content in the assistant response");
        }

        // Swift: let contentData = Data(content.utf8)
        //        let responseJson = try decoder.decode(Root<T>.self, from: contentData)
        //        return responseJson.items
        // => В TS делаем так:
        let parsed: any;
        try {
            parsed = JSON.parse(content);
        } catch (error) {
            throw new Error("Failed to parse assistant response as JSON");
        }

        // В Swift: struct Root<T>: Codable { let items: [T] }
        // => ждём parsed.items как массив T
        const items: T[] = parsed.items;
        if (!items) {
            throw new Error("No 'items' array in JSON response");
        }

        return items;
    }

    /**
     * Аналог Swift:
     * public func requestObjectWithCustomFormat<T: Codable>(prompt: String, example: T) async throws -> T
     *
     * Возвращает один объект, а не массив.
     */
    public async requestObjectWithCustomFormat<T>(prompt: string, example: T): Promise<T> {
        const url = this.apiUrl;
        const headers = {
            "Authorization": `Bearer ${this.apiKey}`,
            "Content-Type": "application/json",
        };

        const messages = [
            { role: "system", content: "You are a helpful assistant." },
            { role: "user", content: prompt },
        ];

        // Генерируем схему для одного объекта:
        const jsonSchema = this.schemaGenerator.generateObject(example);

        const responseFormat = {
            type: "json_schema",
            json_schema: {
                name: "custom_response",
                strict: true,
                schema: jsonSchema,
            },
        };

        const parameters = {
            model: this.model,
            messages,
            max_tokens: 1500,
            temperature: 0.2,
            response_format: responseFormat,
        };

        const response = await fetch(url, {
            method: "POST",
            headers,
            body: JSON.stringify(parameters),
        });
        if (!response.ok) {
            throw new Error(`OpenAI API request failed with status ${response.status}: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("Received JSON:", data);

        const choices = data.choices;
        if (!choices || choices.length === 0) {
            throw new Error("No choices returned from OpenAI API");
        }
        const message = choices[0].message;
        const content = message?.content;
        if (!content) {
            throw new Error("No content in the assistant response");
        }

        let parsed: any;
        try {
            parsed = JSON.parse(content);
        } catch (error) {
            throw new Error("Failed to parse assistant response as JSON");
        }

        // Возвращаем parsed как T
        // (предполагаем, что top-level JSON === T)
        return parsed as T;
    }

    /**
     * Аналог Swift requestStrict(prompt: String) async throws -> [WordTranslation]
     * Просто пример, если у вас есть WordTranslation
     */
    public async requestStrict(prompt: string): Promise<any[]> {
        // Swift code uses: WordTranslation(word: "example", translation: "пример")
        // but let's just define a dummy
        const example = { word: "example", translation: "пример" };
        return this.requestWithCustomFormat(prompt, example);
    }

    public async requestHtmlWithImage(base64Image: string, prompt: string): Promise<string> {
        const url = this.apiUrl;
        const headers = {
            Authorization: `Bearer ${this.apiKey}`,
            "Content-Type": "application/json",
        };

        // Собираем messages для Chat Completions
        const messages = [
            {
                role: "system",
                content: "You are a helpful assistant.",
            },
            {
                role: "user",
                // content может быть массивом объектов (с текстом и картинкой).
                content: [
                    { type: "text", text: prompt },
                    {
                        type: "image_url",
                        image_url: {
                            // Передаем base64, оформленное как data URL
                            url: `data:image/jpeg;base64,${base64Image}`,
                            // detail можно оставить "auto", "low" или "high"
                            // в зависимости от того, насколько детальный анализ нужен
                            detail: "auto",
                        },
                    },
                ],
            },
        ];

        // Создаем параметры запроса
        const parameters = {
            model: this.model,
            messages,
            max_tokens: 1500,
            temperature: 0.2,
            // Здесь можно не использовать response_format,
            // так как мы хотим обычный текст (HTML), а не JSON по схеме
            // Если бы хотели жестко в JSON, можно было бы оставить.
        };

        // Отправляем запрос в OpenAI
        const response = await fetch(url, {
            method: "POST",
            headers,
            body: JSON.stringify(parameters),
        });

        if (!response.ok) {
            throw new Error(
                `OpenAI API request failed with status ${response.status}: ${response.statusText}`
            );
        }

        const data = await response.json();
        console.log("Received JSON:", data);

        const choices = data.choices;
        if (!choices || choices.length === 0) {
            throw new Error("No choices returned from OpenAI API");
        }
        const message = choices[0].message;
        const content = message?.content;
        if (!content) {
            throw new Error("No content in the assistant response");
        }

        // Здесь мы предполагаем, что модель вернет готовый HTML,
        // который можно напрямую отобразить.
        return content;
    }

}