// FillBlanksUseCase.ts

import { FillBlanksState, FillBlanksPlaceholder } from "./FillBlanksState";

export class FillBlanksUseCase {
    /**
     * Parse the raw text to identify placeholders.
     * (You can store them separately in the state, so we can manage each placeholder’s userInput.)
     */
    public parseText(state: FillBlanksState): FillBlanksState {
        // Example logic: parse the `rawText` for `[ ... ]` sequences
        // to fill an array of placeholders with isCorrect = null initially.
        const placeholders: FillBlanksPlaceholder[] = [];

        let placeholderId = 0;
        let isInPlaceholder = false;
        let currentWord = "";

        for (let i = 0; i < state.rawText.length; i++) {
            const char = state.rawText[i];
            if (char === "[") {
                isInPlaceholder = true;
                currentWord = "";
            } else if (char === "]") {
                // we have a placeholder
                placeholders.push({
                    id: placeholderId++,
                    correctWord: currentWord,
                    userInput: "",
                    isCorrect: null,
                });
                isInPlaceholder = false;
            } else if (isInPlaceholder) {
                currentWord += char;
            }
        }

        return {
            ...state,
            placeholders,
        };
    }

    /**
     * Update user input for a specific placeholder
     */
    public updatePlaceholder(
        state: FillBlanksState,
        placeholderId: number,
        newInput: string
    ): FillBlanksState {
        const newPlaceholders = state.placeholders.map((p) => {
            if (p.id === placeholderId) {
                return { ...p, userInput: newInput };
            }
            return p;
        });
        return {
            ...state,
            placeholders: newPlaceholders,
        };
    }

    /**
     * Check if the user’s input matches the correct word,
     * set isCorrect = true/false. Possibly auto-jump to next placeholder, etc.
     */
    public checkPlaceholder(
        state: FillBlanksState,
        placeholderId: number
    ): FillBlanksState {
        const newPlaceholders = state.placeholders.map((p) => {
            if (p.id === placeholderId) {
                const userTrim = p.userInput.trim().toLowerCase();
                const correctTrim = p.correctWord.trim().toLowerCase();
                return { ...p, isCorrect: userTrim === correctTrim };
            }
            return p;
        });
        return {
            ...state,
            placeholders: newPlaceholders,
        };
    }

    /**
     * Optional: check ALL placeholders at once
     */
    public checkAll(state: FillBlanksState): FillBlanksState {
        const newPlaceholders = state.placeholders.map((p) => {
            const userTrim = p.userInput.trim().toLowerCase();
            const correctTrim = p.correctWord.trim().toLowerCase();
            return { ...p, isCorrect: userTrim === correctTrim };
        });
        return {
            ...state,
            placeholders: newPlaceholders,
        };
    }
}