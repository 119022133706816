// AnagramInteractor.ts
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { AnagramUseCase } from "./AnagramUseCase";
import { AnagramUIHandler } from "./AnagramUIHandler";
import { AnagramState } from "./AnagramState";
import {PracticeSessionDelegate} from "../../../../../domain/Exercises/PracticeSessionDelegate";
import {Exercise, WordItem} from "../../../../../domain/models";

export class AnagramInteractor {
    private delegate?: PracticeSessionDelegate;
    private useCase: AnagramUseCase;
    private uiHandler: AnagramUIHandler;
    private _state: AnagramState;
    public onStateChange?: (newState: AnagramState) => void;

    constructor(
        exercise: Exercise,
        delegate?: PracticeSessionDelegate,
        uiHandler?: AnagramUIHandler,
        useCase?: AnagramUseCase
    ) {
        if (exercise.type.kind !== "anagram") {
            throw new Error("Not a Anagram exercise!");
        }

        const word = exercise.type.data.word;

        this.delegate = delegate;
        this.useCase = useCase || new AnagramUseCase();

        // UIHandler – для онлайн-синхронизации (или локально, если interactor не задан).
        this.uiHandler = uiHandler || new AnagramUIHandler(word.id);

        if (!this.uiHandler.scrambledLetters) {
            // Ни у кого ещё нет scramble. Генерируем один раз
            const randomScramble = word.word.split("").sort(() => Math.random() - 0.5).join("");
            this.uiHandler.scrambledLetters = randomScramble;
            // Сохраняем в Firestore через Interactor
            this.uiHandler.sendUpdateIfNeeded();
        }

        // Локальное состояние (или восстанавливаем из UIHandler)
        this._state = {
            originalWord: word.word,
            displayedWord: word.translation,
            scrambledLetters: this.uiHandler.scrambledLetters,
            currentAnswer: "_".repeat(word.word.length),
            isCompleted: false,
        };

        makeAutoObservable(this);

        // Подтягиваем данные из UIHandler (если есть)
        this.syncFromUIHandler();

        // Настраиваем реакцию на изменение UIHandler
        this.setupBindings();
    }

    get state(): AnagramState {
        return this._state;
    }

    private setState(newState: AnagramState) {
        this._state = newState;
        this.onStateChange?.(newState);
    }

    private setupBindings() {
        reaction(
            () => [this.uiHandler.scrambledLetters, this.uiHandler.currentAnswer, this.uiHandler.isCompleted],
            () => {
                this.syncFromUIHandler();
            }
        );
    }

    private syncFromUIHandler() {
        // Сравниваем uiHandler-данные с локальным state
        const newState: AnagramState = {
            originalWord: this._state.originalWord,
            displayedWord: this._state.displayedWord,
            scrambledLetters: this.uiHandler.scrambledLetters || this._state.scrambledLetters,
            currentAnswer: this.uiHandler.currentAnswer || this._state.currentAnswer,
            isCompleted: this.uiHandler.isCompleted || false
        };
        if (JSON.stringify(newState) !== JSON.stringify(this._state)) {
            runInAction(() => this.setState(newState));
        }
    }

    private syncToUIHandler(newState: AnagramState) {
        this.uiHandler.scrambledLetters = newState.scrambledLetters;
        this.uiHandler.currentAnswer = newState.currentAnswer;
        this.uiHandler.isCompleted = newState.isCompleted;
        this.uiHandler.sendUpdateIfNeeded();
    }

    // Методы для UI

    selectLetter(letterIndex: number) {
        // Логика - useCase.selectLetter
        const updated = this.useCase.selectLetter(this._state, letterIndex);
        // Можно дополнительно проверять, не собрали ли слово полностью
        // const final = this.useCase.checkComplete(updated);
        if (JSON.stringify(updated) !== JSON.stringify(this._state)) {
            this.setState(updated);
            this.syncToUIHandler(updated);
        }
    }

    removeLetterFromAnswer(answerIndex: number) {
        const updated = this.useCase.removeLetter(this._state, answerIndex);
        // checkComplete(...) если нужно
        if (JSON.stringify(updated) !== JSON.stringify(this._state)) {
            this.setState(updated);
            this.syncToUIHandler(updated);
        }
    }

    goNext() {
        // delegate?.nextStep() или логика next?
    }
}