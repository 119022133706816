// TeacherLessonSessionsList.tsx
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {SessionInfo} from "../ARefactor/Models/SessionInfo";

interface Props {
    lessonId: string;
    sessionInfos: SessionInfo[];
    onSelectSession: (sessionId: string) => void;
}

const TeacherLessonSessionsList: React.FC<Props> = observer(({
                                                                 lessonId,
                                                                 sessionInfos,
                                                                 onSelectSession
                                                             }) => {

    // Просто выводим список сессий
    return (
        <div>
            <h3>Сессии по уроку {lessonId}</h3>
            {sessionInfos.length === 0 && <p>Нет сессий</p>}
            <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                {sessionInfos.map(info => (
                    <li key={info.sessionId} style={{ marginBottom: '8px' }}>
                        <b>Session ID:</b> {info.sessionId} | <b>Student:</b> {info.studentId}
                        {" "}
                        <button onClick={() => onSelectSession(info.sessionId)}>
                            Открыть
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
});

export default TeacherLessonSessionsList;