import { AudioPlayerStore } from "./AudioPlayerStore";
import { AudioPlayerInteractor } from "./AudioPlayerInteractor";
import { makeAutoObservable } from "mobx";
import React from "react";
import { ProcessStatus, TextDisplayMode } from "./AudioPlayer.types";

export class AudioPlayerViewModel {
    store: AudioPlayerStore;
    interactor: AudioPlayerInteractor;

    isTextExpanded = false;

    textDisplayMode: TextDisplayMode = "tooltip";

    constructor(store: AudioPlayerStore, interactor: AudioPlayerInteractor) {
        this.store = store;
        this.interactor = interactor;
        makeAutoObservable(this, {}, { autoBind: true });
    }

    initTextDisplayMode(mode?: TextDisplayMode) {
        if (mode === "tooltip" || mode === "expand") {
            this.textDisplayMode = mode;
        } else {
            this.textDisplayMode = "tooltip";
        }
    }

    toggleTextExpanded() {
        this.isTextExpanded = !this.isTextExpanded;
    }

    /** Проверить тип файла */
    private isValidAudioFile(file: File) {
        return file.type.startsWith("audio/");
    }

    /** DnD drop */
    handleDrop(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (!files || !files.length) return;
        const file = files[0];
        if (this.isValidAudioFile(file)) {
            this.startUploadFlow(file);
        }
    }

    handleDragOver(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
    }

    /** Paste */
    handlePaste(e: React.ClipboardEvent<HTMLDivElement>) {
        const items = e.clipboardData?.items;
        if (!items) return;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.kind === "file") {
                const file = item.getAsFile();
                if (file && this.isValidAudioFile(file)) {
                    this.startUploadFlow(file);
                    break;
                }
            }
        }
    }

    /** Обработка выбора файла через input */
    handleFileSelected(file: File) {
        // Если уже идёт загрузка, игнорируем
        if (this.store.uploadStatus === "loading" || this.store.uploadStatus === "success") return;
        this.startUploadFlow(file);
    }

    /** Запуск флоу загрузки */
    startUploadFlow(file: File) {
        this.interactor.startUploadFlow(file);
    }

    /** Загрузка уже существующего аудио */
    loadExistingAudio(audioId: string) {
        this.interactor.loadExistingAudio(audioId);
    }

    /* Геттеры для React */
    get uploadStatus(): ProcessStatus {
        return this.store.uploadStatus;
    }
    get recognitionStatus(): ProcessStatus {
        return this.store.recognitionStatus;
    }
    get storageStatus(): ProcessStatus {
        return this.store.storageStatus;
    }
    get fileLink(): string {
        return this.store.fileLink;
    }
    get recognizedText(): string {
        return this.store.recognizedText;
    }
    get showAddAudioButton(): boolean {
        return this.uploadStatus === "idle" && !this.store.audioId;
    }
}
