// CrosswordLearnInteractor.ts
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { CrosswordUIHandler } from "./CrosswordUIHandler";
import { CrosswordState } from "./CrosswordState";
import { CrosswordUseCase, CrosswordUseCaseProtocol } from "./CrosswordUseCaseProtocol";
import { Exercise, Position, WordItem } from "../../../../../domain/models";
import { CrosswordBuilderProtocol, DefaultCrosswordBuilder } from "./CrosswordBuilderProtocol";
import { PracticeSessionDelegate } from "../../../../../domain/Exercises/PracticeSessionDelegate";

export class CrosswordLearnInteractor {
    private _state: CrosswordState;
    private delegate?: PracticeSessionDelegate;
    private uiHandler: CrosswordUIHandler;
    private useCase: CrosswordUseCaseProtocol;

    public onStateChange?: (s: CrosswordState) => void;

    constructor(
        exercise: Exercise,
        delegate?: PracticeSessionDelegate,
        builder: CrosswordBuilderProtocol = new DefaultCrosswordBuilder(),
        uiHandler?: CrosswordUIHandler,
        useCase: CrosswordUseCaseProtocol = new CrosswordUseCase()
    ) {
        if (exercise.type.kind !== "crossword") {
            throw new Error("Not a crossword exercise");
        }
        this.delegate = delegate;
        this.useCase = useCase;

        // Инициализируем состояние из данных упражнения (words)
        const initial = this.useCase.initializeState(exercise.type.data.words, builder);
        // Если uiHandler не передан, создаем новый
        this.uiHandler =
            uiHandler ||
            new CrosswordUIHandler(
                "some-random-exercise-id-" + Math.random().toString(36).substring(2),
                undefined
            );
        this._state = initial;

        makeAutoObservable(this);
        this.setupBindings();

        setTimeout(() => {
            this.syncFromUIHandler();
        }, 0);
    }

    get state(): CrosswordState {
        return this._state;
    }
    set state(newState: CrosswordState) {
        this._state = newState;
        if (this.onStateChange) {
            this.onStateChange(newState);
        }
    }

    private setupBindings(): void {
        reaction(
            () => ({
                hinted: Array.from(this.uiHandler.hintedWords).map(s => s.toLowerCase()),
                found: Array.from(this.uiHandler.foundWords).map(s => s.toLowerCase()),
                grid: this.uiHandler.grid // двумерный массив
            }),
            (data) => {
                const newHinted = new Set(
                    this.state.words.filter(w => data.hinted.includes(w.word.toLowerCase()))
                );
                const newFound = new Set(
                    this.state.words.filter(w => data.found.includes(w.word.toLowerCase()))
                );
                const newGrid = data.grid;
                if (
                    !this.setEquals(this.state.hintedWords, newHinted) ||
                    !this.setEquals(this.state.foundWords, newFound) ||
                    !this.gridsAreEqual(this.state.grid, newGrid)
                ) {
                    const st = this.cloneState(this.state);
                    st.hintedWords = newHinted;
                    st.foundWords = newFound;
                    st.grid = newGrid;
                    runInAction(() => {
                        this.state = st;
                    });
                }
            }
        );
    }

    // Синхронизация изменений из uiHandler в локальное состояние
    private syncFromUIHandler(): void {
        const lowerHinted = new Set(Array.from(this.uiHandler.hintedWords).map(s => s.toLowerCase()));
        const lowerFound = new Set(Array.from(this.uiHandler.foundWords).map(s => s.toLowerCase()));
        const newHinted = new Set(
            this.state.words.filter(w => lowerHinted.has(w.word.toLowerCase()))
        );
        const newFound = new Set(
            this.state.words.filter(w => lowerFound.has(w.word.toLowerCase()))
        );
        const newGrid = this.uiHandler.grid;
        if (
            !this.setEquals(this.state.hintedWords, newHinted) ||
            !this.setEquals(this.state.foundWords, newFound) ||
            !this.gridsAreEqual(this.state.grid, newGrid)
        ) {
            let newGrid = this.uiHandler.grid;
            if (newGrid.length === 0) {
                newGrid = this.state.grid;
            }

            const st = this.cloneState(this.state);
            st.hintedWords = newHinted;
            st.foundWords = newFound;
            st.grid = newGrid;
            runInAction(() => {
                this.state = st;
            });
        }
    }

    // Передает локальное состояние в uiHandler
    private syncToUIHandler(newState: CrosswordState): void {
        runInAction(() => {
            this.uiHandler.hintedWords = new Set(
                Array.from(newState.hintedWords).map(w => w.word.toLowerCase())
            );
            this.uiHandler.foundWords = new Set(
                Array.from(newState.foundWords).map(w => w.word.toLowerCase())
            );
            this.uiHandler.grid = newState.grid;
        });
    }

    public finishCrossword(): void {
        this.syncFromUIHandler();
        const newState = this.useCase.finishCrossword(this.state, this.delegate);
        if (!this.state.isEqual(newState)) {
            runInAction(() => {
                this.state = newState;
            });
            this.syncToUIHandler(newState);
        }
    }

    public clearCellContent(pos: Position): void {
        this.syncFromUIHandler();
        const newState = this.useCase.clearCellContent(this.state, pos);
        if (!this.state.isEqual(newState)) {
            runInAction(() => {
                this.state = newState;
            });
            this.syncToUIHandler(newState);
        }
    }

    public handleLetterChange(pos: Position): Position | undefined {
        this.syncFromUIHandler();
        const letter = this.letterAt(pos);
        const [newState, nextFocus] = this.useCase.handleLetterChange(
            this.state,
            pos,
            letter,
            this.delegate
        );
        if (!this.state.isEqual(newState)) {
            runInAction(() => {
                this.state = newState;
            });
            this.syncToUIHandler(newState);
        }
        return nextFocus;
    }

    public hintForWord(w: WordItem): void {
        this.syncFromUIHandler();
        const newState = this.useCase.hintForWord(this.state, w);
        if (!this.state.isEqual(newState)) {
            runInAction(() => {
                this.state = newState;
            });
            this.syncToUIHandler(newState);
        }
    }

    public isPositionEditable(pos: Position): boolean {
        return this.useCase.isPositionEditable(this.state, pos);
    }

    public handleGridUpdate(pos: Position, newVal: string): void {
        this.syncFromUIHandler();
        const [r, c] = this.localIndices(pos, this.state);
        const newGrid = this.state.grid.map(row => [...row]);
        if (r >= 0 && r < newGrid.length && c >= 0 && c < newGrid[r].length) {
            newGrid[r][c] = newVal;
            const updatedState = this.useCase.updateGrid(this.state, newGrid);
            if (!this.state.isEqual(updatedState)) {
                runInAction(() => {
                    this.state = updatedState;
                });
                this.syncToUIHandler(updatedState);
            }
        }
    }

    private letterAt(pos: Position): string {
        const [r, c] = this.localIndices(pos, this.state);
        if (r < 0 || r >= this.state.grid.length || c < 0 || c >= this.state.grid[r].length) {
            return "";
        }
        return this.state.grid[r][c];
    }

    private localIndices(pos: Position, state: CrosswordState): [number, number] {
        const minRow = Math.min(...Array.from(state.occupiedPositions).map(p => p.row));
        const minCol = Math.min(...Array.from(state.occupiedPositions).map(p => p.col));
        const rowIndex = pos.row - minRow;
        const colIndex = pos.col - minCol;
        return [rowIndex, colIndex];
    }

    private cloneState(s: CrosswordState): CrosswordState {
        return new CrosswordState(
            s.words,
            s.placedWords,
            s.occupiedPositions,
            s.startPositions,
            s.cellSize,
            new Set(s.hintedWords),
            new Set(s.foundWords),
            s.grid.map(row => [...row]),
            s.isCrosswordSolved,
            s.activeWord
        );
    }

    private setEquals<T>(a: Set<T>, b: Set<T>): boolean {
        if (a.size !== b.size) return false;
        for (const item of a) {
            if (!b.has(item)) return false;
        }
        return true;
    }

    private gridsAreEqual(a: string[][], b: string[][]): boolean {
        if (a.length !== b.length) return false;
        for (let i = 0; i < a.length; i++) {
            if (a[i].length !== b[i].length) return false;
            for (let j = 0; j < a[i].length; j++) {
                if (a[i][j] !== b[i][j]) {
                    return false;
                }
            }
        }
        return true;
    }
}