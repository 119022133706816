// LessonPracticeViewModel.ts
import {makeAutoObservable, runInAction} from "mobx";
import {Exercise, Lesson} from "../../../../domain/models";
import {LessonPracticeSession} from "../../../../domain/services/LessonPracticeSession";
import CompositionRoot from "../../../../compositionRoot";
import {PracticeSessionDelegate} from "../../../../domain/Exercises/PracticeSessionDelegate";
import {SessionStateInteractor} from "../../../../domain/Multiplayer/SessionStateInteractor";
import {SessionRepository} from "../../../../domain/Multiplayer/SessionRepository";
import {PracticeStateUI} from "../PracticeStateUI";
import {PracticeStateFactory} from "../PracticeStateFactory";

export class LessonPracticeViewModel implements PracticeSessionDelegate {
    lesson: Lesson | null = null;
    session: LessonPracticeSession | null = null;

    practiceState: PracticeStateUI = { type: "done" };

    public sessionStateInteractor: SessionStateInteractor;

    private storage = CompositionRoot.getExercisesStorage();
    private practiceStorage = CompositionRoot.practiceSessionStorage;
    private userManager = CompositionRoot.getAppUserManager();

    private practiceStateFactory: PracticeStateFactory;

    constructor(private lessonId: string, private sessionId: string) {
        makeAutoObservable(this);

        const sessionRepo: SessionRepository = CompositionRoot.sessionRepository;
        this.sessionStateInteractor = new SessionStateInteractor(this.sessionId, sessionRepo);

        this.practiceStateFactory = new PracticeStateFactory(this.sessionStateInteractor, this);

        this.sessionStateInteractor.onIndexChange = (newIndex: number) => {
            if (this.session && this.session.currentIndex !== newIndex) {
                runInAction(() => {
                    this.session!.currentIndex = newIndex;
                });
                this.updatePracticeState();
            }
        };

        this.loadLesson();
    }

    get totalExercises(): number {
        return this.lesson?.exercises.length ?? 0;
    }

    get currentExerciseIndex(): number {
        return this.session?.currentIndex ?? 0;
    }

    private async loadLesson() {
        try {
            console.log("Loading lesson with id:", this.lessonId);
            const lesson = await this.storage.getExerciseSet(this.lessonId);
            runInAction(() => {
                this.lesson = lesson;
            });

            const practiceSession = new LessonPracticeSession(
                lesson,
                this.sessionId,
                this.userManager,
                this.practiceStorage
            );
            runInAction(() => {
                this.session = practiceSession;
            });

            // Ставим локальный currentIndex из sessionStateInteractor
            const existingIndex = this.sessionStateInteractor.currentExerciseIndex;
            if (existingIndex !== undefined) {
                runInAction(() => {
                    this.session!.currentIndex = existingIndex;
                });
            }

            // Убеждаемся, что в Firestore создано начальное состояние
            await this.sessionStateInteractor.ensureInitialState(lesson.exercises);

            // Вызываем getExercise (как в Swift)
            runInAction(() => {
                this.updatePracticeState();
            });
        } catch (err) {
            console.error("Failed to load lesson:", err);
            runInAction(() => {
                this.practiceState = { type: "done" };
            });
        }
    }

    goNext() {
        if (!this.session) return;
        this.session.goNext();
        this.sessionStateInteractor.updateCurrentExerciseIndex(this.session.currentIndex);
        this.updatePracticeState();
    }

    goPrev() {
        if (!this.session) return;
        this.session.goPrev();
        this.sessionStateInteractor.updateCurrentExerciseIndex(this.session.currentIndex);
        this.updatePracticeState();
    }

    private updatePracticeState() {
        if (!this.session) {
            this.practiceState = { type: "done" };
            return;
        }
        const exercise = this.session.getExercise();
        this.practiceState = this.practiceStateFactory.create(exercise);
    }

    // ----- PracticeSessionDelegate -----
    saveAttempt(exercise: Exercise, answer: any) {
        this.session?.saveAttempt(exercise, answer);
    }

    currentStepDone() {
        this.goNext();
    }
}