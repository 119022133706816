import React, { useEffect } from "react";
import { AudioPlayerProps } from "./AudioPlayer.types";
import { AudioPlayerStore } from "./AudioPlayerStore";
import { AudioPlayerInteractor } from "./AudioPlayerInteractor";
import { AudioPlayerViewModel } from "./AudioPlayerViewModel";
import { AudioPlayerView } from "./AudioPlayerView";

export const AudioPlayer: React.FC<AudioPlayerProps> = (props) => {
    // Инициализируем Store
    const store = React.useMemo(() => {
        const s = new AudioPlayerStore();
        s.onError = props.onError;
        s.onTextLoaded = props.onTextLoaded;
        s.onLoadComplete = props.onLoadComplete;
        return s;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Создаём Interactor
    const interactor = React.useMemo(() => new AudioPlayerInteractor(store), [store]);
    // Создаём ViewModel
    const vm = React.useMemo(() => new AudioPlayerViewModel(store, interactor), [store, interactor]);

    useEffect(() => {
        vm.initTextDisplayMode(props.textDisplayMode);
    }, [props.textDisplayMode]);

    // Если передан audioId => грузим из стораджа
    useEffect(() => {
        if (props.audioId) {
            vm.loadExistingAudio(props.audioId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.audioId]);

    return <AudioPlayerView vm={vm} />;
};
