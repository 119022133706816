import React from "react";
import {Exercise} from "../../../../../domain/models";
import {ExerciseCreationType} from "../../Models/ExerciseCreationType";
import BaseButton from "../../../BaseButton";
import {renderSettings} from "../../../exercises_types/ExerciseCreationTypeViews";

interface Props {
    selectedExerciseType: ExerciseCreationType;
    onExercisesGenerated: (exs: Exercise[]) => void;
    onBack: () => void;
}

export function ExerciseCreationSettingsView({
                                                 selectedExerciseType,
                                                 onExercisesGenerated,
                                                 onBack,
                                             }: Props) {
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            {renderSettings(selectedExerciseType, onExercisesGenerated)}

            <div>
                <BaseButton onClick={onBack}>
                    Back
                </BaseButton>
            </div>
        </div>
    );

}