// ===================== ./PracticeSelectOption/SelectOptionView.tsx =====================
import React from "react";
import { observer } from "mobx-react-lite";
import { SelectOptionViewModel } from "./SelectOptionViewModel";
import { PracticeSelectOptionBaseView } from "../Base/PracticeSelectOptionBaseView";

interface SelectOptionViewProps {
    viewModel: SelectOptionViewModel;
}

/**
 * Аналог «MissingWordView», но для SelectOption.
 * Внутри используем базовый компонент PracticeSelectOptionBaseView,
 * а вся логика (состояние, проверка ответов, переход к следующему) —
 * внутри ViewModel & Interactor.
 */
export const SelectOptionView: React.FC<SelectOptionViewProps> = observer(
    ({ viewModel }) => {
        const { sentence, options, isCorrect, correctOption, selectedOption } = viewModel;

        // Примерный коллбэк: пользователь нажал вариант.
        const handleOptionPressed = (option: string) => {
            viewModel.selectAndCheckOption(option);
        };

        return (
            <PracticeSelectOptionBaseView
                sentence={sentence}
                options={options}
                selectedOption={selectedOption}
                isCorrect={isCorrect}
                correctOption={correctOption}
                onOptionPressed={handleOptionPressed}
                onNext={() => viewModel.goNext()}
            />
        );
    }
);