// ./SelectionState.ts
import { makeAutoObservable } from "mobx";
import { Game } from "./Game";
import { Position } from "./types";

type AnimateCallback = () => void;

export class SelectionState {
    selectedPositions: Position[] = [];
    lineColor: string = "blue";

    // Для поддержки анимационных callback-ов (shake/ripple)
    public wrongWordAnimateRef: { current?: AnimateCallback } = {};
    public correctWordAnimateRef: { current?: AnimateCallback } = {};

    // Приватные поля для отслеживания выделения
    private startPosition: Position | null = null;
    private endPosition: Position | null = null;
    private lastDragPosition: Position | null = null;

    constructor(public game: Game) {
        makeAutoObservable(this);
        this.lineColor = this.randomColor();
    }

    private randomColor(): string {
        const colors = [
            "red",
            "orange",
            "yellow",
            "green",
            "blue",
            "indigo",
            "purple",
            "pink",
            "teal",
            "mint",
        ];
        return colors[Math.floor(Math.random() * colors.length)] || "blue";
    }

    // Геттер для получения выбранного слова на основе selectedPositions
    get selectedWord(): string {
        return this.game.lettersForPositions(this.selectedPositions).join("");
    }

    // Метод смены цвета линии, объявленный как стрелочная функция
    changeLineColor = () => {
        this.lineColor = this.randomColor();
    };

    // Обработка движения указателя (drag) с сохранением контекста
    handleDragChange = (
        point: { x: number; y: number },
        size: { width: number; height: number }
    ) => {
        const rows = this.game.fieldSize.rowsCount;
        const cols = this.game.fieldSize.columnsCount;
        const position = this.positionFor(point, size, rows, cols);
        if (
            !this.lastDragPosition ||
            position.row !== this.lastDragPosition.row ||
            position.col !== this.lastDragPosition.col
        ) {
            this.lastDragPosition = position;
            this.handleSelection(position);
        }
    };

    // Обработка окончания drag-события
    handleDragEnd = () => {
        const success = this.checkWordFound();
        if (success) {
            this.correctWordAnimateRef.current?.();
        } else {
            this.wrongWordAnimateRef.current?.();
        }
        this.finishSelection();
    };

    // Позволяет принудительно установить выделенные позиции (например, при подсказке)
    simulateSelection = (positions: Position[]) => {
        this.selectedPositions = positions;
    };

    // Приватный метод для завершения выделения
    private finishSelection = () => {
        this.selectedPositions = [];
        this.lastDragPosition = null;
        this.startPosition = null;
        this.endPosition = null;
    };

    // Приватный метод для проверки, найдено ли слово
    private checkWordFound = (): boolean => {
        const selectedWord = this.game.lettersForPositions(this.selectedPositions).join("");
        if (this.game.isNewWordFound(selectedWord)) {
            this.game.addFoundedWord(selectedWord);
            if (this.selectedPositions.length > 0) {
                const start = this.selectedPositions[0];
                const end = this.selectedPositions[this.selectedPositions.length - 1];
                this.game.wordLines.push({
                    startIndex: start,
                    endIndex: end,
                    color: this.lineColor,
                });
            }
            return true;
        }
        return false;
    };

    // Приватный метод для обработки выделения позиции
    private handleSelection = (position: Position) => {
        if (this.startPosition === null) {
            this.startPosition = position;
        }
        this.endPosition = position;
        if (this.startPosition && this.endPosition) {
            this.calculateSelection(this.startPosition, this.endPosition);
        }
    };

    // Вычисляет промежуточные позиции между start и end
    private calculateSelection = (start: Position, end: Position) => {
        const rowDiff = end.row - start.row;
        const colDiff = end.col - start.col;
        const steps = Math.max(Math.abs(rowDiff), Math.abs(colDiff));
        const rowStep = rowDiff === 0 ? 0 : rowDiff > 0 ? 1 : -1;
        const colStep = colDiff === 0 ? 0 : colDiff > 0 ? 1 : -1;

        const tempPositions: Position[] = [];
        for (let stepIdx = 0; stepIdx <= steps; stepIdx++) {
            const currentRow = start.row + stepIdx * rowStep;
            const currentCol = start.col + stepIdx * colStep;
            if (this.game.isCellInBounds(currentRow, currentCol)) {
                tempPositions.push({ row: currentRow, col: currentCol });
            }
        }
        this.selectedPositions = tempPositions;
    };

    // Преобразует координаты указателя в позицию (row, col)
    private positionFor = (
        location: { x: number; y: number },
        gridSize: { width: number; height: number },
        rowsCount: number,
        columnsCount: number
    ): Position => {
        const row = Math.floor(location.y / (gridSize.height / rowsCount));
        const col = Math.floor(location.x / (gridSize.width / columnsCount));
        return { row, col };
    };
}