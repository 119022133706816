import React from "react";
import StartScreenViewModel from "../StartScreenViewModel";
import BaseButton from "../../../ui/components/BaseButton";
import RoleSwitch from "./RoleSwitch";
import ShortStatsView from "../../words/statistics/ShortStatsView";

export const TopPanel: React.FC<{
    navigate: (path: string) => void;
    viewModel: StartScreenViewModel;
    onRoleChange: (role: 'teacher' | 'student') => void;
}> = ({navigate, viewModel, onRoleChange}) => {
    return (
        <div
            style={{
                position: 'absolute',
                top: '16px',
                right: '16px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: '10px',
            }}
        >
            <BaseButton color="#ff9800" onClick={() => navigate('/settings')}>
                Settings
            </BaseButton>
            <BaseButton color="#ff9800" onClick={() => navigate('/mainStat')}>
                Statistics
            </BaseButton>

            {viewModel.user && viewModel.user.role && (
                <RoleSwitch initialRole={viewModel.user.role} onRoleChange={(e) => onRoleChange(e)}/>
            )}

            {viewModel.wordsViewModel &&
                viewModel.user &&
                viewModel.user.role === 'student' && (
                    <ShortStatsView wordsViewModel={viewModel.wordsViewModel} />
                )
            }
        </div>
    );
};