// TextStyleControls.tsx
import React from "react";
import { CanvasItem } from "../../CanvasItem";

interface TextStyleControlsProps {
    selectedItem: CanvasItem | null;
    onUpdateItem: (data: Partial<CanvasItem>) => void;
    editableRef: React.RefObject<HTMLDivElement> | null;

    onToolbarCommand?: (command: string, value?: string) => void;
}

const TextStyleControls: React.FC<TextStyleControlsProps> = ({
                                                                 selectedItem,
                                                                 onUpdateItem,
                                                                 editableRef,
                                                                 onToolbarCommand,
                                                             }) => {
    if (!selectedItem || selectedItem.type !== "text") return null;

    const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onToolbarCommand?.("c", e.target.value);
    };

    return (
        <div className="toolbar" style={{ display: "flex", alignItems: "center" }}>
            <input
                type="color"
                defaultValue="#ffffff"
                style={{ marginRight: "8px" }}
                onMouseDown={(e) => e.preventDefault()}
                onChange={handleColorChange}
            />

            <div>
                <button
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => onToolbarCommand?.("b")}
                    style={{ marginRight: "2px" }}
                >
                    B
                </button>
                <button
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => onToolbarCommand?.("i")}
                    style={{ marginRight: "2px" }}
                >
                    I
                </button>
                <button
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => onToolbarCommand?.("s")}
                    style={{ marginRight: "2px", textDecoration: "line-through" }}
                >
                    S
                </button>
                <button
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => onToolbarCommand?.("r")}
                    style={{ marginRight: "2px" }}
                >
                    N
                </button>
            </div>

            <div style={{ marginLeft: "8px" }}>
                <button
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => onToolbarCommand?.("a-")}
                    style={{ marginRight: "2px" }}
                >
                    A-
                </button>
                <button
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => onToolbarCommand?.("a+")}
                    style={{ marginRight: "2px" }}
                >
                    A+
                </button>
            </div>

            {/* Кнопки выравнивания текста */}
            <div style={{ marginLeft: "8px", borderLeft: "1px solid #ccc", paddingLeft: "8px" }}>
                <button
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => onToolbarCommand?.("al")}
                    style={{ marginRight: "2px" }}
                    title="Выровнять по левому краю"
                >
                    ⫷
                </button>
                <button
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => onToolbarCommand?.("ac")}
                    style={{ marginRight: "2px" }}
                    title="Выровнять по центру"
                >
                    ⫶
                </button>
                <button
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => onToolbarCommand?.("ar")}
                    style={{ marginRight: "2px" }}
                    title="Выровнять по правому краю"
                >
                    ⫸
                </button>
            </div>

            {/* Добавляем кнопку для маркированного списка */}
            <div style={{ marginLeft: "8px", borderLeft: "1px solid #ccc", paddingLeft: "8px" }}>
                <button
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => onToolbarCommand?.("ul")}
                    style={{ marginRight: "2px" }}
                    title="Маркированный список"
                >
                    •
                </button>
            </div>
        </div>
    );
};

export default TextStyleControls;