// src/App.tsx
import React from 'react';
import StartScreenMacOS from '../features/start/StartScreenMacOS';
import {AuthProvider} from "./context/AuthContext";
import {ThemeProvider} from "./context/ThemeContext";
import "../supporting/index.css"
import "../supporting/App.css"

const App: React.FC = () => {
    return (
        <AuthProvider>
            <ThemeProvider>
                <div className="App">
                    <StartScreenMacOS />
                </div>
            </ThemeProvider>
        </AuthProvider>
    );
};

export default App;
