import React, { useRef, useState, useEffect } from 'react';
import { Rnd } from 'react-rnd';
import EditableText from './EditableText/EditableText';
import EditableDrawing from "./EditableDrawing";
import { CanvasItem } from "../CanvasItem";
import ZoomControls from './ZoomControls';

interface CanvasViewProps {
    items: CanvasItem[];
    onChange: (updatedItems: CanvasItem[]) => void;

    editingId: string | null;
    onSetEditingId: (id: string | null) => void;

    focusedItemId: string | null;
    onSetFocusedItem: (id: string | null) => void;

    onAddTextAtPosition: (x: number, y: number) => void;
    width?: string;
    height?: string;
    onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

    // The same ref from CanvasLayout
    editingTextRef: React.RefObject<HTMLDivElement>;

    // Function so EditableText can register a command callback
    setToolbarCommandHandler: (cb: (cmd: string, val?: string) => void) => void;
}

const CanvasView: React.FC<CanvasViewProps> = ({
                                                   items,
                                                   onChange,
                                                   editingId,
                                                   onSetEditingId,
                                                   focusedItemId,
                                                   onSetFocusedItem,
                                                   onAddTextAtPosition,
                                                   width = "500px",
                                                   height = "600px",
                                                   onFileChange,
                                                   editingTextRef,
                                                   setToolbarCommandHandler
                                               }) => {
    // Add state to track hovered item
    const [hoveredItemId, setHoveredItemId] = useState<string | null>(null);

    // Состояние для зума
    const [zoomLevel, setZoomLevel] = useState(1.0);

    // Calculate the content bounds to determine necessary scroll area
    const [contentBounds, setContentBounds] = useState({
        minX: 0,
        minY: 0,
        maxX: 0,
        maxY: 0
    });

    // Хранение пропорций изображений для поддержки правильного ресайза
    const imageAspectRatios = useRef<Record<string, number>>({});

    // Track minimum content sizes for text elements
    const [minContentSizes, setMinContentSizes] = useState<Record<string, { width: number, height: number }>>({});

    // Handle minimum size updates from EditableText
    const handleMinSizeChange = (itemId: string, minWidth: number, minHeight: number) => {
        setMinContentSizes(prev => ({
            ...prev,
            [itemId]: { width: minWidth, height: minHeight }
        }));
    };

    // Ссылка на контейнер канваса
    const canvasContainerRef = useRef<HTMLDivElement>(null);

    // Ref for the scrollable area
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    // Используем численные значения для width и height, чтобы применить zoom корректно
    const canvasWidth = typeof width === 'string' ? parseInt(width) : width;
    const canvasHeight = typeof height === 'string' ? parseInt(height) : height;

    // Calculate content bounds based on items positions and dimensions
    useEffect(() => {
        if (items.length === 0) {
            // Use canvas dimensions as minimum area
            setContentBounds({
                minX: 0,
                minY: 0,
                maxX: canvasWidth,
                maxY: canvasHeight
            });
            return;
        }

        let minX = Infinity, minY = Infinity;
        let maxX = -Infinity, maxY = -Infinity;

        items.forEach(item => {
            minX = Math.min(minX, item.x);
            minY = Math.min(minY, item.y);
            maxX = Math.max(maxX, item.x + item.width);
            maxY = Math.max(maxY, item.y + item.height);
        });

        setContentBounds({
            minX: Math.max(0, minX),
            minY: Math.max(0, minY),
            maxX: Math.max(canvasWidth, maxX),
            maxY: Math.max(canvasHeight, maxY)
        });
    }, [items, canvasWidth, canvasHeight]);

    const updateItem = (id: string, newData: Partial<Omit<CanvasItem, 'type'>>) => {
        onChange(
            items.map((item) => (item.id === id ? { ...item, ...newData } : item))
        );
    };

    const handleCanvasMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        // Проверяем, что клик не был на элементе канваса
        let target = e.target as HTMLElement;
        let isOnCanvasItem = false;

        while (target && target !== e.currentTarget) {
            if (target.classList && (
                target.classList.contains('react-draggable') ||
                target.classList.contains('react-resizable')
            )) {
                isOnCanvasItem = true;
                break;
            }
            target = target.parentElement as HTMLElement;
        }

        // Если клик не на элементе канваса, сбрасываем выделение
        if (!isOnCanvasItem) {
            onSetFocusedItem(null);
            onSetEditingId(null);
        }
    };

    const handleCanvasDoubleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        // Проверяем, что клик не был на элементе канваса
        // Ищем, есть ли среди родителей элемента с классом Rnd
        let target = e.target as HTMLElement;
        let isOnCanvasItem = false;

        while (target && target !== e.currentTarget) {
            if (target.classList && (
                target.classList.contains('react-draggable') ||
                target.classList.contains('react-resizable')
            )) {
                isOnCanvasItem = true;
                break;
            }
            target = target.parentElement as HTMLElement;
        }

        // Если клик не на элементе канваса, добавляем новый текст
        if (!isOnCanvasItem) {
            const rect = canvasContainerRef.current?.getBoundingClientRect();
            if (rect) {
                // Get scroll position
                const scrollLeft = scrollContainerRef.current?.scrollLeft || 0;
                const scrollTop = scrollContainerRef.current?.scrollTop || 0;

                // Корректируем координаты с учетом зума и прокрутки
                const x = (e.clientX - rect.left + scrollLeft) / zoomLevel;
                const y = (e.clientY - rect.top + scrollTop) / zoomLevel;

                onAddTextAtPosition(x, y);
            }
        }
    };

    // Функции для управления зумом
    const handleZoomIn = () => {
        setZoomLevel(prevZoom => Math.min(prevZoom + 0.1, 3.0)); // Максимум 300%
    };

    const handleZoomOut = () => {
        setZoomLevel(prevZoom => Math.max(prevZoom - 0.1, 1.0)); // Минимум 100%
    };

    const handleResetZoom = () => {
        setZoomLevel(1.0);

        // Reset scroll position when resetting zoom
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft = 0;
            scrollContainerRef.current.scrollTop = 0;
        }
    };

    // Handle wheel events for zooming - using a direct DOM approach instead of React's synthetic events
    useEffect(() => {
        const containerElement = scrollContainerRef.current;
        if (!containerElement) return;

        const handleWheelEvent = (e: WheelEvent) => {
            if (e.ctrlKey || e.metaKey) {
                e.preventDefault();

                // Get mouse position within the canvas
                const rect = canvasContainerRef.current?.getBoundingClientRect();
                if (!rect) return;

                // Compute pointer position as percentage of viewport
                const pointX = (e.clientX - rect.left + (scrollContainerRef.current?.scrollLeft || 0)) / (rect.width * zoomLevel);
                const pointY = (e.clientY - rect.top + (scrollContainerRef.current?.scrollTop || 0)) / (rect.height * zoomLevel);

                // Получаем направление прокрутки
                const delta = e.deltaY > 0 ? -0.05 : 0.05;

                // Изменяем уровень зума в пределах от 100% до 300%
                const newZoom = Math.max(1.0, Math.min(3.0, zoomLevel + delta));
                setZoomLevel(newZoom);

                // After zoom, adjust scroll position to keep pointer at same position
                if (scrollContainerRef.current && newZoom !== zoomLevel) {
                    // Calculate new scroll position
                    const newScrollLeft = pointX * rect.width * newZoom - (e.clientX - rect.left);
                    const newScrollTop = pointY * rect.height * newZoom - (e.clientY - rect.top);

                    // Apply new scroll position after state update
                    setTimeout(() => {
                        if (scrollContainerRef.current) {
                            scrollContainerRef.current.scrollLeft = newScrollLeft;
                            scrollContainerRef.current.scrollTop = newScrollTop;
                        }
                    }, 0);
                }
            }
        };

        // Add event listener with { passive: false } to allow preventDefault()
        containerElement.addEventListener('wheel', handleWheelEvent, { passive: false });

        return () => {
            containerElement.removeEventListener('wheel', handleWheelEvent);
        };
    }, [zoomLevel]); // Include zoomLevel in dependencies

    // Предотвращаем стандартный зум в браузере при прокрутке колесом + Ctrl
    useEffect(() => {
        const preventDefaultZoom = (e: WheelEvent) => {
            if (e.ctrlKey || e.metaKey) {
                e.preventDefault();
            }
        };

        // Добавляем обработчик события wheel для всего документа
        document.addEventListener('wheel', preventDefaultZoom, { passive: false });

        return () => {
            document.removeEventListener('wheel', preventDefaultZoom);
        };
    }, []);

    const [isDraggingOver, setIsDraggingOver] = useState(false);

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDraggingOver(true);
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDraggingOver(false);
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDraggingOver(false);
        const files = e.dataTransfer.files;
        processImageFile(files[0]);
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
        const items = e.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.kind === 'file' && item.type.startsWith('image/')) {
                processImageFile(item.getAsFile());
                e.preventDefault();
            }
        }
    };

    const processImageFile = (file: File | null) => {
        if (file?.type.startsWith('image/')) {
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(file);
            const fakeEvent = {
                target: { files: dataTransfer.files },
            } as unknown as React.ChangeEvent<HTMLInputElement>;
            onFileChange(fakeEvent);
        }
    };

    // Функция для загрузки и кэширования соотношения сторон изображения
    const getImageAspectRatio = (itemId: string, src: string) => {
        // Если соотношение сторон уже вычислено, возвращаем его
        if (imageAspectRatios.current[itemId]) {
            return imageAspectRatios.current[itemId];
        }

        // Иначе вычисляем его
        const img = new Image();
        img.onload = () => {
            const aspectRatio = img.naturalWidth / img.naturalHeight;
            imageAspectRatios.current[itemId] = aspectRatio;
        };
        img.src = src;

        return 1;
    };

    // Calculate dimensions for scrollable content area
    // Make sure content is always at least as large as the viewport
    const contentWidth = contentBounds.maxX;
    const contentHeight = contentBounds.maxY;

    return (
        <div id={"qwe"} style={{ position: 'relative' }}>
            {/* Outer container with scrollbars */}
            <div
                id={"qwerty"}
                ref={scrollContainerRef}
                style={{
                    position: 'relative',
                    width: "100%",
                    height: canvasHeight,
                    border: '1px solid #ccc',
                    overflow: 'auto',
                    borderStyle: isDraggingOver ? 'dashed' : 'solid',
                    borderColor: isDraggingOver ? '#666' : '#ccc',
                }}
                // Remove the React onWheel handler since we're using a direct DOM event listener
            >
                {/* Div that defines the total scrollable area size */}
                <div style={{
                    position: 'absolute',
                    width: contentWidth * zoomLevel,
                    height: contentHeight * zoomLevel,
                    pointerEvents: 'none'
                }}></div>
                {/* Main canvas container */}
                <div
                    id={"asd"}
                    ref={canvasContainerRef}
                    onMouseDown={handleCanvasMouseDown}
                    onDoubleClick={handleCanvasDoubleClick}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    onPaste={handlePaste}
                    tabIndex={0}
                    onFocus={() => {/*...*/}}
                    style={{
                        position: 'relative',
                        width: "100%",
                        height: contentHeight,
                        transformOrigin: '0 0',
                        transform: `scale(${zoomLevel})`,
                    }}
                >
                    {items.map((item) => {
                        const isEditing = item.type === 'text' && editingId === item.id;
                        const isImage = item.type === 'image';

                        // Для изображений определяем соотношение сторон
                        let aspectRatio = 1;
                        if (isImage) {
                            aspectRatio = getImageAspectRatio(item.id, item.content);
                        }

                        // Get minimum size constraints for this item
                        const minSize = item.type === 'text' && minContentSizes[item.id]
                            ? minContentSizes[item.id]
                            : { width: 50, height: 20 }; // Default minimum if not set

                        return (
                            <Rnd
                                key={item.id}
                                size={{ width: item.width, height: item.height }}
                                position={{ x: item.x, y: item.y }}
                                disableDragging={isEditing}
                                enableUserSelectHack={false}
                                scale={zoomLevel} // Важно! Передаем масштаб в Rnd
                                // Only enable resizing if the item is focused
                                enableResizing={focusedItemId === item.id}
                                style={{
                                    outline: focusedItemId === item.id
                                        ? '1px dashed #999'
                                        : hoveredItemId === item.id
                                            ? '1px dashed #ccc'
                                            : 'none',
                                    padding: '0',
                                    boxSizing: 'border-box'
                                }}
                                // Apply minimum size constraints
                                minWidth={item.type === 'text' ? minSize.width : undefined}
                                minHeight={item.type === 'text' ? minSize.height : undefined}
                                // Блокируем изменение соотношения сторон для изображений
                                lockAspectRatio={isImage ? aspectRatio : false}
                                onMouseDownCapture={() => {
                                    onSetFocusedItem(item.id);
                                }}
                                // Add mouse enter/leave handlers for hover effect
                                onMouseEnter={() => setHoveredItemId(item.id)}
                                onMouseLeave={() => setHoveredItemId(null)}
                                onDragStop={(_, data) => {
                                    updateItem(item.id, { x: data.x, y: data.y });
                                }}
                                onResizeStop={(_, __, ref, ___, position) => {
                                    updateItem(item.id, {
                                        width: parseFloat(ref.style.width),
                                        height: parseFloat(ref.style.height),
                                        x: position.x,
                                        y: position.y,
                                    });
                                }}
                                bounds="parent"
                            >
                                {item.type === 'text' && (
                                    <EditableText
                                        item={item}
                                        isEditing={isEditing}
                                        isFocused={focusedItemId === item.id}
                                        onFocus={() => {
                                            onSetFocusedItem(item.id);
                                            onSetEditingId(item.id);
                                        }}
                                        onBlur={() => {
                                            onSetEditingId(null);
                                        }}
                                        innerRef={isEditing ? editingTextRef : undefined}
                                        onUpdate={(data) => updateItem(item.id, data)}
                                        getToolbarCommand={setToolbarCommandHandler}
                                        // Add the new minimum size callback
                                        onMinSizeChange={handleMinSizeChange}
                                    />
                                )}

                                {item.type === 'image' && (
                                    <img
                                        src={item.content}
                                        alt="canvas-item"
                                        draggable={false}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            cursor: 'move',
                                            userSelect: 'none',
                                        }}
                                    />
                                )}

                                {item.type === 'drawing' && (
                                    <EditableDrawing
                                        item={item}
                                        isActive={focusedItemId === item.id}
                                        onUpdate={(data) => updateItem(item.id, data)}
                                    />
                                )}
                            </Rnd>
                        );
                    })}
                </div>
            </div>

            {/* Добавляем элементы управления зумом */}
            <ZoomControls
                zoomLevel={zoomLevel}
                onZoomIn={handleZoomIn}
                onZoomOut={handleZoomOut}
                onResetZoom={handleResetZoom}
            />
        </div>
    );
};

export default CanvasView;