import { makeAutoObservable } from "mobx";
import { Exercise, Lesson } from "../../../../../domain/models";
import {LessonService, LessonServiceProtocol} from "../../Logic/LessonService";
import CompositionRoot from "../../../../../compositionRoot";

export class LessonCreationViewModel {
    setName: string = "";
    selectedExercises: Exercise[] = [];

    private lessonService: LessonServiceProtocol;

    constructor() {
        makeAutoObservable(this);
        this.lessonService = new LessonService(CompositionRoot.saveExerciseInteractor);
    }

    addExercises(exercises: Exercise[]) {
        this.selectedExercises.push(...exercises);
    }

    deleteSelectedExercise(index: number) {
        this.selectedExercises.splice(index, 1);
    }

    moveSelectedExercise(fromIndex: number, toIndex: number) {
        const item = this.selectedExercises.splice(fromIndex, 1)[0];
        this.selectedExercises.splice(toIndex, 0, item);
    }

    saveLesson() {
        const newLesson: Lesson = {
            id: crypto.randomUUID().toUpperCase(),
            name: this.setName,
            exercises: this.selectedExercises,
        };
        console.log("LessonCreationViewModel saving lesson: ", newLesson)
        this.lessonService.saveLesson(newLesson);
    }
}