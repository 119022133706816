// FillBlanksUIState.ts
import { FillBlanksPlaceholder } from "./FillBlanksState";

/**
 * The minimal data that you want to sync across participants.
 * Possibly you only sync the userInput & isCorrect for each placeholder,
 * not re-sending the entire rawText (which is the same for all).
 */
export class FillBlanksUIState {
    public readonly type = "fillBlanks" as const;

    constructor(
        /** Each placeholder’s userInput + isCorrect */
        public placeholders: Array<{
            id: number;
            userInput: string;
            isCorrect: boolean | null;
        }>
    ) {}

    /** Simple equality check for avoiding redundant network sends. */
    public equals(other: FillBlanksUIState): boolean {
        if (this.placeholders.length !== other.placeholders.length) {
            return false;
        }
        for (let i = 0; i < this.placeholders.length; i++) {
            const p1 = this.placeholders[i];
            const p2 = other.placeholders[i];
            if (
                p1.id !== p2.id ||
                p1.userInput !== p2.userInput ||
                p1.isCorrect !== p2.isCorrect
            ) {
                return false;
            }
        }
        return true;
    }
}