// src/onboarding/AuthScreen.tsx
import React, {useState} from 'react';
import { observer } from 'mobx-react-lite';
import { AuthViewModel } from './AuthViewModel';
import CompositionRoot from "../../../../compositionRoot";
import {AuthInteractor} from "./AuthInteractor";

export const AuthScreen: React.FC = observer(() => {
    const userManager = CompositionRoot.getAppUserManager();
    const [vm] = useState(() => new AuthViewModel(new AuthInteractor(userManager)));

    const handleModeToggle = () => {
        vm.setMode(vm.mode === 'login' ? 'register' : 'login');
    };

    const handleAction = async () => {
        await vm.doAction();
    };

    const handleSkip = async () => {
        await vm.skipAuth();
    };

    return (
        <div style={styles.center}>
            <h2>{vm.mode === 'login' ? 'Вход' : 'Регистрация'}</h2>

            {vm.error && <div style={{ color: 'red' }}>{vm.error}</div>}
            {vm.isLoading && <div>Загрузка...</div>}

            <input
                type="email"
                placeholder="Email"
                value={vm.email}
                onChange={(e) => (vm.email = e.target.value)}
            />
            <input
                type="password"
                placeholder="Пароль"
                value={vm.password}
                onChange={(e) => (vm.password = e.target.value)}
            />

            <button onClick={handleAction} disabled={vm.isLoading}>
                {vm.mode === 'login' ? 'Войти' : 'Зарегистрироваться'}
            </button>
            <button onClick={handleSkip} disabled={vm.isLoading}>
                Guest account
            </button>

            <button onClick={handleModeToggle}>
                {vm.mode === 'login'
                    ? 'У вас нет аккаунта? Регистрация'
                    : 'У вас уже есть аккаунт? Войти'}
            </button>
        </div>
    );
});

const styles = {
    center: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
    }
};