import React from "react";
import {createPortal} from "react-dom";

interface LessonWordInputViewProps {
    text: string;
    translation: string;
    example: string;
    onChangeText: (val: string) => void;
    onChangeTranslation: (val: string) => void;
    onChangeExample: (val: string) => void;
    onAddWord: () => void;
    onCancel: () => void;
}

const inputPortalContainer = document.createElement("div");
document.body.appendChild(inputPortalContainer);

export class LessonWordInputView extends React.Component<LessonWordInputViewProps> {
    // constructor(props: LessonWordInputViewProps) {
    //     super(props);
    //     this.state = {
    //         text: "",
    //         translation: "",
    //         example: ""
    //     };
    // }

    private handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChangeText(event.target.value);
    };

    private handleChangeTranslation = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChangeTranslation(event.target.value);
    };

    private handleChangeExample = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChangeExample(event.target.value);
    };

    private handleAdd = () => {
        this.props.onAddWord();
    };

    render() {
        const { text, translation, example } = this.props;

        return createPortal(
            <div style={styles.container}>
                <input
                    type="text"
                    placeholder="Word *"
                    value={text}
                    onChange={this.handleChangeText}
                    style={styles.input}
                />
                <input
                    type="text"
                    placeholder="Translation *"
                    value={translation}
                    onChange={this.handleChangeTranslation}
                    style={styles.input}
                />
                <input
                    type="text"
                    placeholder="Example"
                    value={example}
                    onChange={this.handleChangeExample}
                    style={styles.input}
                />
                <div style={styles.buttons}>
                    <button onClick={this.handleAdd} style={styles.addButton}>
                        Add
                    </button>
                    <button onClick={this.props.onCancel} style={styles.cancelButton}>
                        Cancel
                    </button>
                </div>
            </div>, inputPortalContainer
        );
    }
}

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        position: "absolute",
        bottom: "16px",
        right: "16px",
        width: "240px",
        padding: "16px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        zIndex: 10000
    },
    input: {
        padding: "8px",
        borderRadius: "4px",
        border: "1px solid #ccc"
    },
    buttons: {
        display: "flex",
        justifyContent: "space-between"
    },
    addButton: {
        backgroundColor: "#4caf50",
        border: "none",
        color: "white",
        padding: "8px 12px",
        cursor: "pointer",
        borderRadius: "4px"
    },
    cancelButton: {
        backgroundColor: "#f44336",
        border: "none",
        color: "white",
        padding: "8px 12px",
        cursor: "pointer",
        borderRadius: "4px"
    }
};