// FreePracticeInteractor.ts
import {makeAutoObservable, reaction, runInAction} from "mobx";
import { FreePracticeState } from "./FreePracticeState";
import { FreePracticeUIHandler } from "./FreePracticeUIHandler";
import { FreePracticeUseCase } from "./FreePracticeUseCase";
import { FreePracticeUIState } from "./FreePracticeUIState";
import {PracticeSessionDelegate} from "../../../../../domain/Exercises/PracticeSessionDelegate";
import {Exercise} from "../../../../../domain/models";

export class FreePracticeInteractor {
    private delegate?: PracticeSessionDelegate;
    private useCase: FreePracticeUseCase;
    private uiHandler: FreePracticeUIHandler;

    public onStateChange?: (newState: FreePracticeState) => void;

    private _state: FreePracticeState;

    constructor(exercise: Exercise, delegate?: PracticeSessionDelegate, uiHandler?: FreePracticeUIHandler, useCase?: FreePracticeUseCase) {
        if (exercise.type.kind !== "free") {
            throw new Error("Not a free exercise");
        }
        this.delegate = delegate;
        this.useCase = useCase || new FreePracticeUseCase();

        // If no UIHandler was passed, create a default one.
        this.uiHandler = uiHandler || new FreePracticeUIHandler(exercise.id);

        console.log("FreePracticeInteractor, exercise.type.data: ", exercise.type.data)
        // Convert the exercise's data into our internal state:
        this._state = {
            htmlString: exercise.type.data.htmlString,
            fields: {}, // or parse from exercise if needed
        };

        makeAutoObservable(this);

        this.setupBindings();

        this.syncFromUIHandler();
    }

    get state(): FreePracticeState {
        return this._state;
    }

    private setState(newState: FreePracticeState) {
        this._state = newState;
        this.onStateChange?.(newState);
    }

    private setupBindings(): void {
        reaction(
            () => this.uiHandler.fields,
            () => { this.syncFromUIHandler(); }
        );
    }

    // ---------------------------
    // Methods to modify the state
    // ---------------------------

    public updateFieldValue(fieldId: string, newValue: string) {
        this.syncFromUIHandler();
        const updated = this.useCase.updateFieldValue(this._state, fieldId, newValue);
        if (updated !== this._state) {
            this.setState(updated);
            this.syncToUIHandler(updated);
        }
    }

    public updateHtml(newHtml: string) {
        this.syncFromUIHandler();
        const updated = this.useCase.updateHtmlTemplate(this._state, newHtml);
        if (updated !== this._state) {
            this.setState(updated);
            this.syncToUIHandler(updated);
        }
    }

    /**
     * Pull changes from the UIHandler (if the teacher or someone updated the state externally).
     */
    private syncFromUIHandler() {
        const { htmlString, fields } = this.uiHandler;
        const incomingState: FreePracticeState = { htmlString, fields };
        if (incomingState.htmlString.length === 0) {
            return
        }
        if (JSON.stringify(incomingState) !== JSON.stringify(this._state)) {
            runInAction(() => {
                this.setState(incomingState);
            });
        }
    }

    /**
     * Push local changes to the UIHandler, so they can be broadcast externally if needed.
     */
    private syncToUIHandler(newState: FreePracticeState) {
        const uiState = new FreePracticeUIState(newState.htmlString, newState.fields);
        this.uiHandler.fields = uiState.fields
        this.uiHandler.htmlString = uiState.htmlString
    }
}