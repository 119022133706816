// FreePracticeUIHandler.ts
import {makeAutoObservable, reaction, runInAction} from "mobx";
import { FreePracticeUIState } from "./FreePracticeUIState";
import {ExerciseUIHandler} from "../../../../../domain/Multiplayer/ExerciseUIHandler";
import {SessionStateInteractor} from "../../../../../domain/Multiplayer/SessionStateInteractor";
import {ExerciseUIState} from "../../../../../domain/Multiplayer/ExerciseUIState";

export class FreePracticeUIHandler implements ExerciseUIHandler {
    public htmlString: string;
    public fields: Record<string, string>;

    private exerciseId: string;
    private interactor?: SessionStateInteractor;
    private isUpdatingFromServer = false;
    private currentUIState: FreePracticeUIState;

    constructor(exerciseId: string, interactor?: SessionStateInteractor, initialState?: FreePracticeUIState) {
        this.exerciseId = exerciseId;
        this.interactor = interactor;

        this.currentUIState = initialState || new FreePracticeUIState("", {});
        this.htmlString = this.currentUIState.htmlString;
        this.fields = { ...this.currentUIState.fields };

        makeAutoObservable(this);

        reaction(
            () => this.fields,
            () => {
                if (!this.isUpdatingFromServer) {
                    this.sendUpdateIfNeeded();
                }
            }
        );
    }

    public updateUIState(uiState: ExerciseUIState | undefined): void {
        if (!uiState || uiState.type !== "free") {
            return;
        }

        const newState = uiState.data;
        if (!newState.equals(this.currentUIState)) {
            this.isUpdatingFromServer = true;
            runInAction(() => {
                this.currentUIState = newState;
                this.htmlString = newState.htmlString;
                this.fields = { ...newState.fields };
            });
            this.isUpdatingFromServer = false;
        }

    }

    /**
     * Called from the Interactor whenever we have a new local state that we want to push out.
     */
    private sendUpdateIfNeeded() {
        if (this.isUpdatingFromServer) return;
        const newState = new FreePracticeUIState(this.htmlString, this.fields)

        if (newState.equals(this.currentUIState)) return;

        this.currentUIState = newState;
        this.htmlString = newState.htmlString;
        this.fields = { ...newState.fields };

        this.interactor?.updateFreeState(this.exerciseId, newState);
    }
}