import {Exercise} from "../../../../../domain/models";
import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {SelectOptionCreateState, SelectOptionCreateViewModel} from "./SelectOptionCreateViewModel";
import {SelectOptionCreateSettingsView} from "./SelectOptionCreateSettingsView";
import {SelectOptionSentencesCheckView} from "./SelectOptionSentencesCheckView";
import {SelectOptionEditView} from "./SelectOptionEditView";

interface SelectOptionCreateViewProps {
    onExercisesCreated: (exercises: Exercise[]) => void;
}

export const SelectOptionCreateView: React.FC<SelectOptionCreateViewProps> = observer(
    ({ onExercisesCreated }) => {
        // We create the view model once
        const [viewModel] = useState(() => new SelectOptionCreateViewModel(onExercisesCreated));

        // Show only one subview at a time based on the current state
        switch (viewModel.state) {
            case SelectOptionCreateState.Settings:
            case SelectOptionCreateState.GeneratingSentences:
                return (
                    <div style={{ width: "100%", display: "flex" }}>
                        <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <SelectOptionCreateSettingsView vm={viewModel} />
                        </div>
                    </div>
                );

            case SelectOptionCreateState.SentencesGenerated:
            case SelectOptionCreateState.Converting:
                // We'll reuse the same layout for sentences check,
                // since "Converting" is a sub-stage there.
                // But strictly speaking, you could show a loading indicator.
                return (
                    <div style={{ width: "100%", padding: "16px" }}>
                        <SelectOptionSentencesCheckView vm={viewModel} />
                    </div>
                );

            case SelectOptionCreateState.Editing:
                return (
                    <div style={{ width: "100%", padding: "16px" }}>
                        <SelectOptionEditView vm={viewModel} />
                    </div>
                );

            default:
                return null;
        }
    }
);
