// ExerciseCreationFlowView.tsx
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { ExerciseCreationFlowViewModel } from "./ExerciseCreationFlowViewModel";
import { CreateExerciseFlowStep } from "./CreateExerciseFlowStep";
import { Exercise } from "../../../../../domain/models";
import {ExerciseTypeSelectionView} from "./ExerciseTypeSelectionView";
import {ExerciseCreationSettingsView} from "./ExerciseCreationSettingsView";

interface Props {
    onSelect: (exercise: Exercise) => void;
    onClose: () => void;
}

export const ExerciseCreationFlowView = observer(({ onSelect, onClose }: Props) => {
    const [viewModel] = useState(() => new ExerciseCreationFlowViewModel());

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "center", paddingTop: 0 }}>
            <div style={{ flex: 1, borderTop: "1px solid #ccc", padding: 0, minWidth: 800, width: "100%" }}>
                {renderStep()}
            </div>
        </div>
    );

    function renderStep() {
        switch (viewModel.state.step) {
            case CreateExerciseFlowStep.selectType:
                return (
                    <ExerciseTypeSelectionView
                        onSelect={(type) => viewModel.selectType(type)}
                        onClose={onClose}
                    />
                );
            case CreateExerciseFlowStep.settings:
                return (
                    <ExerciseCreationSettingsView
                        selectedExerciseType={viewModel.state.selectedExerciseType}
                        onExercisesGenerated={(exs) => {
                            exs.forEach((ex) => {
                                onSelect(ex);
                            });
                        }}
                        onBack={() => viewModel.backToSelectType()}
                    />
                );
            default:
                return <div>Unknown step</div>;
        }
    }
});