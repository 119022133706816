import { observer } from "mobx-react-lite";
import { AudioPlayerViewModel } from "./AudioPlayerViewModel";
import { ProcessStatus } from "./AudioPlayer.types";

/** Пример простого React-компонента, наблюдающего за ViewModel */
export const AudioPlayerView: React.FC<{
    vm: AudioPlayerViewModel;
}> = observer(function AudioPlayerView({ vm }) {
    // Рендер статусов
    const renderStatusIcon = (status: ProcessStatus) => {
        switch (status) {
            case "loading":
                return <span style={{ color: "#999" }}>⏳</span>;
            case "success":
                return <span style={{ color: "green" }}>✔</span>;
            case "error":
                return <span style={{ color: "red" }}>✖</span>;
            default:
                return null;
        }
    };

    // showAddAudioButton?
    const handleClickAddAudio = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "audio/*";
        input.onchange = () => {
            if (input.files && input.files.length > 0) {
                vm.handleFileSelected(input.files[0]);
            }
        };
        input.click();
    };

    const showAudioPlayer = !!vm.fileLink || vm.uploadStatus === "success";

    return (
        <div
            style={{
                width: 550,
                minHeight: "80px",
                maxHeight: vm.textDisplayMode === "expand" && vm.isTextExpanded ? "300px" : "80px",
                border: "2px dashed #ccc",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "10px",
                boxSizing: "border-box",
                overflow: "visible",
                transition: "max-height 0.3s ease",
                position: "relative", // Добавляем для корректного позиционирования
            }}
            onDrop={vm.handleDrop}
            onDragOver={vm.handleDragOver}
            onPaste={vm.handlePaste}
        >

            <div style={{
                width: "100%",
                flexShrink: 0, // Важно: предотвращает сжатие при анимации
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                // minHeight: 80 // Фиксируем высоту контента
            }}>
                {/* Кнопка "Add Audio" */}
                {vm.showAddAudioButton && (
                    <button style={{ fontSize: 16, padding: "8px 16px", cursor: "pointer" }} onClick={handleClickAddAudio}>
                        Pick audio file
                    </button>
                )}

                {/* Блок с плеером и статусами */}
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 16, width: "100%" }}>
                    {/* Плеер */}
                    {showAudioPlayer && (
                        <audio controls style={{ width: 300 }} src={vm.fileLink} />
                    )}

                    {/* Иконки статусов */}
                    <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
                        {renderStatusIcon(vm.uploadStatus)}
                        {renderStatusIcon(vm.recognitionStatus)}
                        {renderStatusIcon(vm.storageStatus)}
                    </div>

                    {/* Иконка текста */}
                    {vm.recognizedText && (
                        vm.textDisplayMode === "tooltip" ? (
                            <div className="text-icon-wrapper" style={{ position: "relative" }}>
                                <span style={{ fontSize: 20, cursor: "pointer" }}>📄</span>
                                <div className="tooltip-content">
                                    {vm.recognizedText}
                                </div>
                            </div>
                        ) : (
                            <div
                                style={{ fontSize: 20, cursor: "pointer" }}
                                onClick={() => vm.toggleTextExpanded()}
                            >
                                📄
                            </div>
                        )
                    )}
                </div>
            </div>

            {/* Блок с текстом (только для режима expand) */}
            {vm.textDisplayMode === "expand" && vm.recognizedText && (
                <div
                    style={{
                        width: "100%",
                        padding: "10px 0",
                        fontSize: 12,
                        textAlign: "center",
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                        lineHeight: "1.4",
                        opacity: vm.isTextExpanded ? 1 : 0,
                        maxHeight: vm.isTextExpanded ? "1000px" : "0",
                        transition: "all 0.3s ease",
                        overflow: "hidden",
                    }}
                >
                    {vm.recognizedText}
                </div>
            )}

        </div>
    );
});