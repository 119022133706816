// EditableText.tsx с функцией автоматического изменения размера
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CanvasItem, TextCanvasItem } from "../../CanvasItem";

// Lexical imports
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { ListPlugin } from "@lexical/react/LexicalListPlugin"; // Import ListPlugin
import { ListItemNode, ListNode } from "@lexical/list"; // Import list nodes

import { $getRoot, EditorState, LexicalEditor } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LexicalToolbarPlugin } from "./LexicalToolbarPlugin";

interface TextDimensions {
    width: number;
    height: number;
}

// Extended props interface to include minimum size callback
interface EditableTextProps {
    item: TextCanvasItem;
    isEditing: boolean;
    isFocused?: boolean;
    onFocus: () => void;
    onBlur: () => void;
    onUpdate: (data: Partial<CanvasItem>) => void;
    innerRef?: React.RefObject<HTMLDivElement>;
    getToolbarCommand?: (cb: (cmd: string, val?: string) => void) => void;

    // New prop to pass minimum content dimensions
    onMinSizeChange?: (itemId: string, minWidth: number, minHeight: number) => void;
}

const ExternalContentSync: React.FC<{
    content: string;
    lastKnownContentRef: React.MutableRefObject<string>;
}> = ({ content, lastKnownContentRef }) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        // Если внешний контент НЕ изменился относительно локального - ничего не делаем
        if (content === lastKnownContentRef.current) {
            return;
        }

        // Skip empty content to prevent empty editor state errors
        if (!content) {
            return;
        }

        // Если изменился – значит Store прислал что-то новое, мержим в Lexical
        editor.update(() => {
            try {
                const newEditorState = editor.parseEditorState(content);

                // Ensure the editor state is not empty and has a root node
                const isEmpty = newEditorState.read(() => {
                    const root = $getRoot();
                    return root.getChildrenSize() === 0;
                });

                if (!isEmpty) {
                    editor.setEditorState(newEditorState);
                    // Обновляем локальный реф, чтобы не зациклиться
                    lastKnownContentRef.current = content;
                } else {
                    console.warn("Attempted to set empty editor state, skipping");
                }
            } catch (err) {
                console.error("Ошибка парсинга внешнего состояния:", err);
            }
        });
    }, [content, editor, lastKnownContentRef]);

    return null;
};

// This plugin measures content size and triggers resize when needed
const ContentMeasurePlugin: React.FC<{
    onResize: (width: number, height: number) => void;
}> = ({ onResize }) => {
    const [editor] = useLexicalComposerContext();
    const observerRef = useRef<ResizeObserver | null>(null);
    const rootRef = useRef<HTMLElement | null>(null);

    // Find the editable root element
    useEffect(() => {
        // Get the contentEditable element
        const rootElement = editor.getRootElement();
        if (rootElement) {
            rootRef.current = rootElement;

            // Create ResizeObserver to detect content size changes
            observerRef.current = new ResizeObserver((entries) => {
                for (const entry of entries) {
                    const { width, height } = entry.contentRect;
                    // Reduced padding to make container fit content more tightly
                    onResize(
                        Math.ceil(width),
                        Math.ceil(height) + 4   // Reduced padding from 32px to 4px
                    );
                }
            });

            // Start observing
            observerRef.current.observe(rootElement);
        }

        return () => {
            if (observerRef.current && rootRef.current) {
                observerRef.current.unobserve(rootRef.current);
                observerRef.current.disconnect();
            }
        };
    }, [editor, onResize]);

    return null;
};

// Custom plugin to remove paragraph margins
const ParagraphStylePlugin: React.FC = () => {
    const [editor] = useLexicalComposerContext();
    
    useEffect(() => {
        const applyStyles = () => {
            const rootElement = editor.getRootElement();
            if (!rootElement) return;
            
            // Find all paragraphs and remove their margins
            const paragraphs = rootElement.querySelectorAll('p');
            paragraphs.forEach(p => {
                p.style.margin = '0';
                p.style.padding = '0';
            });
        };
        
        // Apply styles initially
        applyStyles();
        
        // Set up MutationObserver to catch new paragraphs
        const observer = new MutationObserver(applyStyles);
        const rootElement = editor.getRootElement();
        if (rootElement) {
            observer.observe(rootElement, { 
                childList: true, 
                subtree: true 
            });
        }
        
        return () => observer.disconnect();
    }, [editor]);
    
    return null;
};

// Стили для редактируемого контента
const contentEditableStyle: React.CSSProperties = {
    outline: "none",
    padding: "0",
    margin: "0",
    lineHeight: "1.0",
    minHeight: "100%"
};

// Стили для плейсхолдера
const placeholderStyle: React.CSSProperties = {
    color: "#999",
    position: "absolute",
    top: "0", // Changed from 20px to 0
    left: "0", // Added left: 0 to align with text
    width: "100%",
    padding: "0", // Added to match text padding
    pointerEvents: "none",
    userSelect: "none"
};

// Default editor state with an empty paragraph
const DEFAULT_EDITOR_STATE = JSON.stringify({
    root: {
        children: [
            {
                children: [],
                direction: null,
                format: "",
                indent: 0,
                type: "paragraph",
                version: 1
            }
        ],
        direction: null,
        format: "",
        indent: 0,
        type: "root",
        version: 1
    }
});

const EditableText: React.FC<EditableTextProps> = ({
                                                       item,
                                                       isEditing,
                                                       isFocused,
                                                       onFocus,
                                                       onBlur,
                                                       onUpdate,
                                                       innerRef,
                                                       getToolbarCommand,
                                                       onMinSizeChange,
                                                   }) => {
    const localRef = useRef<HTMLDivElement>(null);
    const containerRef = innerRef || localRef;
    const lastKnownContentRef = useRef(item.content || DEFAULT_EDITOR_STATE);

    // Track if content has changed to avoid unnecessary resize updates
    const [contentChanged, setContentChanged] = useState(false);
    // Use a debounce timer for resize to avoid too frequent updates
    const resizeTimerRef = useRef<number | null>(null);
    // Keep track of minimum content dimensions
    const [minDimensions, setMinDimensions] = useState<TextDimensions>({
        width: item.width,
        height: item.height
    });

    // Add custom CSS for paragraphs
    useEffect(() => {
        const style = document.createElement('style');
        style.innerHTML = `
    .LexicalEditor p {
    margin: 0 !important;
    padding: 0 !important;
}
`;
        document.head.appendChild(style);
        
        return () => {
            document.head.removeChild(style);
        };
    }, []);

    const theme = {
        paragraph: 'zero-margin-paragraph'
    };

    const onError = useCallback((error: Error) => {
        console.error("Lexical Error:", error);
    }, []);

    // Load from item.content if valid JSON, otherwise use default state
    const initialEditorState = (() => {
        try {
            if (!item.content) {
                return DEFAULT_EDITOR_STATE;
            }

            const parsed = JSON.parse(item.content);
            // Verify that it has a root node with at least one child
            if (typeof parsed === "object" && parsed.root && Array.isArray(parsed.root.children) && parsed.root.children.length > 0) {
                return item.content;
            } else {
                console.warn("Invalid editor state structure, using default");
                return DEFAULT_EDITOR_STATE;
            }
        } catch (e) {
            console.warn("Invalid editorState:", item.content);
            return DEFAULT_EDITOR_STATE;
        }
    })();

    const initialConfig = {
        namespace: "LexicalEditor",
        theme,
        onError,
        editorState: initialEditorState,
        nodes: [ListNode, ListItemNode], // Register list nodes
    };

    // Handle content changes
    const handleChange = useCallback(
        (editorState: EditorState, editor: LexicalEditor) => {
            editorState.read(() => {
                const json = JSON.stringify(editorState.toJSON());
                if (json !== lastKnownContentRef.current) {
                    onUpdate({ content: json });
                    lastKnownContentRef.current = json;
                    setContentChanged(true);
                }
            });
        },
        [onUpdate]
    );

    // Handle resize based on content
    const handleResize = useCallback(
        (width: number, height: number) => {
            // Use a smaller fixed minimum width to allow shrinking
            const newMinWidth = 30; // Changed from Math.max(50, width) to a fixed small value
            const newMinHeight = Math.max(20, height); // Keep height calculation as is

            // Update minimum dimensions if changed
            if (newMinWidth !== minDimensions.width || newMinHeight !== minDimensions.height) {
                setMinDimensions({ width: newMinWidth, height: newMinHeight });

                // Notify parent component about new minimum dimensions
                if (onMinSizeChange) {
                    onMinSizeChange(item.id, newMinWidth, newMinHeight);
                }
            }

            // Only resize if content has changed to avoid unnecessary updates
            if (contentChanged) {
                // Clear any existing timer
                if (resizeTimerRef.current !== null) {
                    window.clearTimeout(resizeTimerRef.current);
                }

                // Debounce the resize operation to avoid too frequent updates
                resizeTimerRef.current = window.setTimeout(() => {
                    // Update dimensions to be at least as large as the content
                    const newWidth = Math.max(newMinWidth, item.width);
                    const newHeight = Math.max(newMinHeight, item.height);

                    if (newWidth > item.width || newHeight > item.height) {
                        onUpdate({
                            width: newWidth,
                            height: newHeight
                        });
                    }
                    setContentChanged(false);
                    resizeTimerRef.current = null;
                }, 300);
            }
        },
        [contentChanged, item.id, item.width, item.height, minDimensions, onMinSizeChange, onUpdate]
    );

    useEffect(() => {
        if (item.content && item.content !== lastKnownContentRef.current) {
            lastKnownContentRef.current = item.content;
        } else if (!item.content) {
            // If item.content is empty, initialize with default state
            onUpdate({ content: DEFAULT_EDITOR_STATE });
        }
    }, [item.content, onUpdate]);

    // Clean up resize timer on unmount
    useEffect(() => {
        return () => {
            if (resizeTimerRef.current !== null) {
                window.clearTimeout(resizeTimerRef.current);
            }
        };
    }, []);

    const outerStyle: React.CSSProperties = {
        width: "100%",
        minHeight: "100%",
        display: "block",
        backgroundColor: "transparent",
        cursor: isEditing ? "text" : "move",
        boxSizing: "border-box",
        padding: "0px", // Reduced from 4px to 0px
        border: "none",
        whiteSpace: "pre-wrap",
        overflow: "hidden", // Prevent content from spilling out
    };

    const handleFocus = useCallback(() => {
        onFocus();
    }, [onFocus]);

    const handleBlurInternal = useCallback(
        (event: React.FocusEvent<HTMLDivElement>) => {
            const nextTarget = event.relatedTarget as HTMLElement | null;
            if (nextTarget && nextTarget.closest(".toolbar")) {
                event.preventDefault();
                return;
            }
            onBlur();
        },
        [onBlur]
    );

    return (
        <div
            ref={containerRef}
            style={outerStyle}
            onFocus={handleFocus}
            onBlur={handleBlurInternal}
        >
            <LexicalComposer initialConfig={initialConfig}>
                <ExternalContentSync
                    content={item.content}
                    lastKnownContentRef={lastKnownContentRef}
                />

                <RichTextPlugin
                    contentEditable={
                        <ContentEditable
                            style={contentEditableStyle}
                            readOnly={!isEditing}
                        />
                    }
                    placeholder={<div style={placeholderStyle}>Type here...</div>}
                    ErrorBoundary={LexicalErrorBoundary}
                />
                <HistoryPlugin />
                <OnChangePlugin onChange={handleChange} />
                <ListPlugin />
                <ParagraphStylePlugin />

                {/* Add the resizing plugin */}
                <ContentMeasurePlugin onResize={handleResize} />

                {/* This plugin connects our external commands to Lexical */}
                <LexicalToolbarPlugin getToolbarCommand={getToolbarCommand} />
            </LexicalComposer>
        </div>
    );
};

export default EditableText;