// AnagramUIState.ts
export class AnagramUIState {
    public readonly type = "anagram" as const;

    constructor(
        public scrambledLetters: string,
        public currentAnswer: string,
        public isCompleted: boolean
    ) {}

    equals(other: AnagramUIState): boolean {
        return (
            this.scrambledLetters === other.scrambledLetters &&
            this.currentAnswer === other.currentAnswer &&
            this.isCompleted === other.isCompleted
        );
    }
}