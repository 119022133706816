import React, { useRef, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { FillBlanksViewModel } from "./FillBlanksViewModel";
import {FillBlanksViewBase} from "../Base/FillBlanksViewBase";

interface FillBlanksViewProps {
    viewModel: FillBlanksViewModel;
}

export const FillBlanksView: React.FC<FillBlanksViewProps> = observer(({ viewModel }) => {
    return (
        <div>
            {/* Если есть аудио */}
            {viewModel.audioURL && (
                <audio controls src={viewModel.audioURL}>
                    Error with audio
                </audio>
            )}

            <FillBlanksViewBase
                text={viewModel.rawText}
                placeholders={viewModel.placeholders.map((ph) => ({
                    id: ph.id,
                    userInput: ph.userInput,
                    isCorrect: ph.isCorrect,
                    correctWord: ph.correctWord,
                }))}
                onChangePlaceholder={(id, newVal) => {
                    viewModel.updatePlaceholder(id, newVal);
                }}
                onCheckPlaceholder={(id) => {
                    viewModel.checkPlaceholder(id);
                }}
                onCheckAll={() => {
                    viewModel.checkAll();
                }}
            />
        </div>
    );
});