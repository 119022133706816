import React from "react";
import {ExerciseTypeFilter} from "../../Models/ExerciseTypeFilter";

interface ExerciseTypeFilterViewProps {
    value: ExerciseTypeFilter;
    onChange: (val: ExerciseTypeFilter) => void;
}

export function ExerciseTypeFilterView({ value, onChange }: ExerciseTypeFilterViewProps) {
    const filters = Object.values(ExerciseTypeFilter);

    return (
        <div style={segmentContainerStyle}>
            {filters.map((f) => {
                const selected = f === value;
                return (
                    <button
                        key={f}
                        onClick={() => onChange(f)}
                        style={{
                            ...segmentButtonStyle,
                            backgroundColor: selected ? "#007bff" : "#fff",
                            color: selected ? "#fff" : "#000",
                        }}
                    >
                        {f}
                    </button>
                );
            })}
        </div>
    );
}

const segmentContainerStyle: React.CSSProperties = {
    display: "inline-flex",
    border: "1px solid #ccc",
    borderRadius: 4,
    overflow: "hidden",
};

const segmentButtonStyle: React.CSSProperties = {
    border: "none",
    outline: "none",
    padding: "8px 16px",
    cursor: "pointer",
    fontSize: 14,
};