import React from "react";
import CanvasView from "./CanvasView";
import Toolbar from "./Toolbar/Toolbar";
import { CanvasItem } from "../CanvasItem";

interface CanvasLayoutProps {
    items: CanvasItem[];
    onAddTextItem: () => void;
    onUpdateItem: (itemId: string, data: Partial<CanvasItem>) => void;
    onRemoveItem: (itemId: string) => void;

    focusedItemId: string | null;
    editingId: string | null;
    onSetFocusedItem: (id: string | null) => void;
    onSetEditingId: (id: string | null) => void;

    fileInputRef: React.RefObject<HTMLInputElement>;
    onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

    onAddTextAtPosition: (x: number, y: number) => void;
    onAddImage: () => void;
    onAddDrawingItem: () => void;

    onItemsChange?: (newItems: CanvasItem[]) => void;
    isUploadingImage: boolean;
    width?: string;
    height?: string;
}

export const CanvasLayout: React.FC<CanvasLayoutProps> = ({
                                                              items,
                                                              onAddTextItem,
                                                              onUpdateItem,
                                                              onRemoveItem,
                                                              focusedItemId,
                                                              editingId,
                                                              onSetFocusedItem,
                                                              onSetEditingId,
                                                              fileInputRef,
                                                              onFileChange,
                                                              onAddTextAtPosition,
                                                              onAddImage,
                                                              onAddDrawingItem,
                                                              onItemsChange,
                                                              isUploadingImage,
                                                              width,
                                                              height
                                                          }) => {
    // The "selectedItem" is the currently focused item, if any
    const selectedItem = items.find((i) => i.id === focusedItemId) || null;

    const handleItemsChange = (newItems: CanvasItem[]) => {
        if (onItemsChange) {
            onItemsChange(newItems);
        }
    };

    // Optionally ref for a contentEditable node (if you still need it):
    const editingTextRef = React.useRef<HTMLDivElement>(null);

    // 1) We store a function that EditableText sets to handle Lexical commands
    let toolbarCommandRef: ((command: string, value?: string) => void) | null = null;

    // Called by <CanvasView>/<EditableText> to register a handler
    const setToolbarCommandHandler = (cb: (command: string, value?: string) => void) => {
        toolbarCommandRef = cb;
    };

    // Called by <Toolbar> when user clicks Bold, Italic, etc.
    const handleToolbarCommand = (command: string, value?: string) => {
        if (toolbarCommandRef) {
            toolbarCommandRef(command, value);
        }
    };

    return (
        <div id={"321"} style={{ display: "flex" }}>
            <div id={"4321"} style={{ width: "100%" }} >
                <Toolbar
                    selectedItem={selectedItem}
                    onUpdateItem={(data: Partial<CanvasItem>) => {
                        if (focusedItemId) {
                            onUpdateItem(focusedItemId, data);
                        }
                    }}
                    onAddText={onAddTextItem}
                    onAddImage={onAddImage}
                    onAddDrawing={onAddDrawingItem}
                    onRemoveItem={onRemoveItem}
                    fileInputRef={fileInputRef}
                    isUploadingImage={isUploadingImage}
                    editableRef={editingTextRef} // if still needed
                    // 2) pass command function to Toolbar
                    onToolbarCommand={handleToolbarCommand}
                />

                <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={onFileChange}
                />

                <CanvasView
                    items={items}
                    onChange={handleItemsChange}
                    editingId={editingId}
                    onSetEditingId={onSetEditingId}
                    focusedItemId={focusedItemId}
                    onSetFocusedItem={onSetFocusedItem}
                    onAddTextAtPosition={onAddTextAtPosition}
                    width={width}
                    height={height}
                    onFileChange={onFileChange}
                    editingTextRef={editingTextRef}
                    // 3) pass setToolbarCommandHandler down
                    setToolbarCommandHandler={setToolbarCommandHandler}
                />
            </div>
        </div>
    );
};