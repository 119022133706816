import {
    Exercise,
    ExerciseAnagram, ExerciseFillBlanks, ExerciseFree,
    ExerciseHangman, ExerciseJustContent,
    ExerciseMatchingPairs,
    ExerciseMissingWord, ExerciseSelectOption,
    ExerciseTable
} from "../../../domain/models";
import {ExerciseViewable} from "./ExerciseViewable";
import {ExerciseAnagramViewable} from "./Anagram/Preview/AnagramViewable";
import {MissingWordViewable} from "./MissingWord/Previews/MissingWordViewable";
import {ExerciseTableViewable} from "./Table/Preview/TableViewable";
import {ExerciseHangmanViewable} from "./Hangman/Preview/HangmanViewable";
import {ExerciseMatchingPairsViewable} from "./MatchingPairs/Preview/MatchingPairsViewable";
import {ExerciseJustContentViewable} from "./JustContent/Preview/JustContentViewable";
import {ExerciseSelectOptionViewable} from "./SelectOption/Previews/SelectOptionViewable";
import {ExerciseFreeViewable} from "./Free/Previews/FreeViewable";
import {ExerciseCrosswordViewable} from "./Crossword/Previews/CrosswordViewable";
import {ExerciseCrossword} from "./Crossword/Domain/ExerciseCrossword";
import {WordSearchViewable} from "./WordSearch/Previews/WordSearchViewable";
import {FillBlanksViewable} from "./FillBlanks/Previews/FillBlanksViewable";

export function createViewable(exercise: Exercise): ExerciseViewable {
    const viewable = (() => {
        switch (exercise.type.kind) {
            case "selectOption":
                return new ExerciseSelectOptionViewable(exercise.type.data as ExerciseSelectOption);
            case "anagram":
                return new ExerciseAnagramViewable(exercise.type.data as ExerciseAnagram);
            case "missingWord":
                return new MissingWordViewable(exercise.type.data as ExerciseMissingWord);
            case "table":
                return new ExerciseTableViewable(exercise.type.data as ExerciseTable);
            case "hangman":
                return new ExerciseHangmanViewable(exercise.type.data as ExerciseHangman);
            case "matchingPairs":
                return new ExerciseMatchingPairsViewable(exercise.type.data as ExerciseMatchingPairs);
            case "justContent":
                return new ExerciseJustContentViewable(exercise.type.data as ExerciseJustContent);
            case "free":
                return new ExerciseFreeViewable(exercise.type.data as ExerciseFree);
            case "crossword":
                return new ExerciseCrosswordViewable(exercise.type.data as ExerciseCrossword);
            case "wordSearch":
                return new WordSearchViewable(exercise.type.data as ExerciseCrossword);
            case "fillBlanks":
                return new FillBlanksViewable(exercise.type.data as ExerciseFillBlanks);
            default:
                return {
                    renderView: () => <div>Unsupported exercise type</div>,
                };
        }
    })();

    // Оборачиваем renderView в div с pointer-events: "none"
    const originalRenderView = viewable.renderView;
    viewable.renderView = (exercise, width, height) => (
        <div style={{ pointerEvents: "none" }}>
            {originalRenderView(exercise, width, height)}
        </div>
    );

    return viewable;
}