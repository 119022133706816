import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import {Exercise} from "../../../../domain/models";
import BaseButton, {ButtonVariant} from "../../BaseButton";
import {ExerciseCreationFlowView} from "../../PracticeManagement/UI/ExerciseCreation/ExerciseCreationFlowView";
import {LearningPlanEditorViewModel} from "../../PracticeManagement/UI/StudentLearningPlan/LearningPlanEditorViewModel";

interface ClassroomEditorProps {
    viewModel: LearningPlanEditorViewModel;
    onExercisePress?: (index: number) => void;
}

export const ClassroomEditor = observer(({ viewModel, onExercisePress }: ClassroomEditorProps) => {
    const [creatingExercise, setCreatingExercise] = useState(false);

    useEffect(() => {
        const unsubscribe = viewModel.subscribeToPlan();
        return () => {
            unsubscribe();
        };
    }, [viewModel]);

    // Function to create an empty slide (JustContent exercise)
    const handleAddSlide = () => {
        // Create a new JustContent exercise with empty contents
        const newSlide: Exercise = {
            id: crypto.randomUUID().toUpperCase(),
            type: {
                kind: "justContent",
                data: {
                    contents: [], // Empty canvas items array
                },
            },
        };

        // Add the slide using the existing viewModel
        viewModel.addExercises([newSlide]);
    };

    if (viewModel.error) {
        return <div style={{ color: "red" }}>{viewModel.error}</div>;
    }

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                position: "relative",
            }}
        >
            <div style={{
                display: "flex",
                justifyContent: "center",
                gap: "16px", // Space between buttons
            }}>
                {/* Horizontal button layout */}
                <BaseButton
                    variant={ButtonVariant.Classic}
                    color="#4caf50"
                    onClick={handleAddSlide}
                >
                    Add Slide
                </BaseButton>

                <BaseButton
                    variant={ButtonVariant.Classic}
                    color="#2196f3"
                    onClick={() => setCreatingExercise(true)}
                >
                    Create Exercise
                </BaseButton>
            </div>

            {/* Modal for creating exercise */}
            {creatingExercise && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                    }}
                >
                    <div
                        style={{
                            width: "95%",
                            height: "92%",
                            backgroundColor: "var(--modal-bg)",
                            borderRadius: 8,
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                            padding: 20,
                            overflow: "auto",
                        }}
                    >
                        <ExerciseCreationFlowView
                            onSelect={(newExercise: Exercise) => {
                                viewModel.addExercises([newExercise]);
                                setCreatingExercise(false);
                            }}
                            onClose={() => setCreatingExercise(false)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
});