// ========= ./Base/CrosswordFocusManager.ts ==========

import { PlacedWord } from './PlacedWord';
import {Position, WordItem} from "../../../../../domain/models";

interface CrosswordFocusState {
    grid: string[][];
    positionToWords: { [key: string]: PlacedWord[] };
    hintedWords: Set<WordItem>;
    foundWords: Set<WordItem>;
}

export class CrosswordFocusManager {
    public handleInputChange(
        state: CrosswordFocusState,
        activeWord: PlacedWord | undefined,
        pos: Position,
        letter: string
    ): [Position | undefined, PlacedWord | undefined] {
        if (letter.length !== 1) {
            return [undefined, activeWord];
        }

        const key = `${pos.row},${pos.col}`;
        const relatedWords = state.positionToWords[key] || [];
        let currentActiveWord = activeWord;

        // If the current position does not belong to the active word, switch to another
        if (currentActiveWord) {
            const belongsToActive = relatedWords.some(
                (rw) => rw.wordIndex === currentActiveWord?.wordIndex
            );
            if (!belongsToActive) {
                currentActiveWord = this.selectActiveWord(relatedWords);
            }
        } else {
            currentActiveWord = this.selectActiveWord(relatedWords);
        }

        if (!currentActiveWord) {
            return [undefined, currentActiveWord];
        }

        // Move to the next cell
        let candidatePos = this.nextCellFor(currentActiveWord, pos);
        while (candidatePos && !this.isPositionEditable(state, candidatePos)) {
            candidatePos = this.nextCellFor(currentActiveWord, candidatePos);
        }

        return [candidatePos, currentActiveWord];
    }

    private selectActiveWord(words: PlacedWord[]): PlacedWord | undefined {
        return words.length > 0 ? words[0] : undefined;
    }

    private nextCellFor(word: PlacedWord, currentPos: Position): Position | undefined {
        const length = word.word.word.length;
        let index: number;
        if (word.horizontal) {
            index = currentPos.col - word.startCol;
        } else {
            index = currentPos.row - word.startRow;
        }
        const nextIndex = index + 1;
        if (nextIndex >= length) {
            return undefined;
        }
        return word.horizontal
            ? { row: word.startRow, col: word.startCol + nextIndex }
            : { row: word.startRow + nextIndex, col: word.startCol };
    }

    private isPositionEditable(state: CrosswordFocusState, pos: Position): boolean {
        const key = `${pos.row},${pos.col}`;
        const wordsAtPos = state.positionToWords[key] || [];
        for (const pw of wordsAtPos) {
            const w = pw.word;
            if (
                Array.from(state.hintedWords).some((hw) => hw.word === w.word) ||
                Array.from(state.foundWords).some((fw) => fw.word === w.word)
            ) {
                return false;
            }
        }
        return true;
    }
}