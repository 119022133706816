// ========= ./CrosswordUseCase.ts ==========

import { CrosswordState } from './CrosswordState';
import {CrosswordBuilderProtocol} from "./CrosswordBuilderProtocol";
import {Position, WordItem} from "../../../../../domain/models";
import {CrosswordFocusManager} from "./CrosswordFocusManager";
import {PlacedWord} from "./PlacedWord";
import {PracticeSessionDelegate} from "../../../../../domain/Exercises/PracticeSessionDelegate";

export interface CrosswordUseCaseProtocol {
    initializeState(words: WordItem[], builder: CrosswordBuilderProtocol): CrosswordState;
    finishCrossword(state: CrosswordState, delegate?: PracticeSessionDelegate): CrosswordState;
    clearCellContent(state: CrosswordState, pos: Position): CrosswordState;
    handleLetterChange(
        state: CrosswordState,
        pos: Position,
        letter: string,
        delegate?: PracticeSessionDelegate
    ): [CrosswordState, Position?];
    hintForWord(state: CrosswordState, w: WordItem): CrosswordState;
    isPositionEditable(state: CrosswordState, pos: Position): boolean;
    updateGrid(state: CrosswordState, newGrid: string[][]): CrosswordState;
    refreshState(state: CrosswordState): CrosswordState;
}

export class CrosswordUseCase implements CrosswordUseCaseProtocol {
    private focusManager: CrosswordFocusManager = new CrosswordFocusManager();

    constructor() {}

    initializeState(words: WordItem[], builder: CrosswordBuilderProtocol): CrosswordState {
        const result = builder.buildCrossword(words);
        const state = new CrosswordState(
            words,
            result.placedWords,
            result.occupiedPositions,
            result.startPositions,
            builder.cellSize,
            new Set<WordItem>(),
            new Set<WordItem>(),
            result.grid,
            false,
            undefined
        );

        return this.refreshState(state);
    }

    finishCrossword(state: CrosswordState, delegate?: PracticeSessionDelegate): CrosswordState {
        for (const w of state.words) {
            const success =
                state.foundWords.has(w) && !state.hintedWords.has(w);
            // delegate?.saveAttempt() TODO: !!! later
            // delegate?.markAsKnown(w, 'crossword', success);
        }
        delegate?.currentStepDone()
        return state;
    }

    clearCellContent(state: CrosswordState, pos: Position): CrosswordState {
        const [r, c] = this.localIndices(pos, state);
        if (
            r < 0 ||
            r >= state.grid.length ||
            c < 0 ||
            c >= state.grid[r].length
        ) {
            return state;
        }
        const newGrid = state.grid.map((row) => row.slice());
        newGrid[r][c] = '';
        const newState = new CrosswordState(
            state.words,
            state.placedWords,
            state.occupiedPositions,
            state.startPositions,
            state.cellSize,
            new Set(state.hintedWords),
            new Set(state.foundWords),
            newGrid,
            state.isCrosswordSolved,
            state.activeWord
        );
        return this.refreshState(newState);
    }

    handleLetterChange(
        state: CrosswordState,
        pos: Position,
        letter: string,
        delegate?: PracticeSessionDelegate
    ): [CrosswordState, Position?] {
        const [r, c] = this.localIndices(pos, state);
        if (
            r < 0 ||
            r >= state.grid.length ||
            c < 0 ||
            c >= state.grid[r].length
        ) {
            return [state, undefined];
        }
        const newGrid = state.grid.map((row) => row.slice());
        newGrid[r][c] = letter;

        // Prepare data for focusManager:
        const posToWords = this.positionToWords(state.placedWords);
        const focusState = {
            grid: newGrid,
            positionToWords: posToWords,
            hintedWords: state.hintedWords,
            foundWords: state.foundWords,
        };

        const [nextFocus, newActiveWord] = this.focusManager.handleInputChange(
            focusState,
            state.activeWord,
            pos,
            letter
        );

        let updatedState = new CrosswordState(
            state.words,
            state.placedWords,
            state.occupiedPositions,
            state.startPositions,
            state.cellSize,
            new Set(state.hintedWords),
            new Set(state.foundWords),
            newGrid,
            state.isCrosswordSolved,
            newActiveWord
        );

        // check and mark found words
        updatedState = this.checkAndMarkFoundWords(updatedState);
        // refresh again
        updatedState = this.refreshState(updatedState);

        return [updatedState, nextFocus];
    }

    hintForWord(state: CrosswordState, w: WordItem): CrosswordState {
        let newState = this.fillWord(state, w);
        newState.hintedWords.add(w);
        newState = this.checkAndMarkFoundWords(newState);
        newState = this.refreshState(newState);
        return newState;
    }

    isPositionEditable(state: CrosswordState, pos: Position): boolean {
        const posToWords = this.positionToWords(state.placedWords);
        const key = `${pos.row},${pos.col}`;
        const wordsAtPos = posToWords[key] || [];
        for (const pw of wordsAtPos) {
            const w = pw.word;
            if (state.hintedWords.has(w) || state.foundWords.has(w)) {
                return false;
            }
        }
        return true;
    }

    updateGrid(state: CrosswordState, newGrid: string[][]): CrosswordState {
        const updatedState = new CrosswordState(
            state.words,
            state.placedWords,
            state.occupiedPositions,
            state.startPositions,
            state.cellSize,
            new Set(state.hintedWords),
            new Set(state.foundWords),
            newGrid,
            state.isCrosswordSolved,
            state.activeWord
        );
        return this.refreshState(updatedState);
    }

    refreshState(state: CrosswordState): CrosswordState {
        let newState = new CrosswordState(
            state.words,
            state.placedWords,
            state.occupiedPositions,
            state.startPositions,
            state.cellSize,
            new Set(state.hintedWords),
            new Set(state.foundWords),
            state.grid.map((row) => [...row]),
            state.isCrosswordSolved,
            state.activeWord
        );
        newState.isCrosswordSolved = this.allWordsCorrectlyFilled(newState);
        return newState;
    }

    // MARK: - Helpers

    private checkAndMarkFoundWords(newState: CrosswordState): CrosswordState {
        let updatedState = new CrosswordState(
            newState.words,
            newState.placedWords,
            newState.occupiedPositions,
            newState.startPositions,
            newState.cellSize,
            new Set(newState.hintedWords),
            new Set(newState.foundWords),
            newState.grid.map((row) => [...row]),
            newState.isCrosswordSolved,
            newState.activeWord
        );

        for (const pw of updatedState.placedWords) {
            if (this.isWordCorrectlyFilled(updatedState, pw)) {
                if (!updatedState.hintedWords.has(pw.word)) {
                    updatedState.foundWords.add(pw.word);
                }
            }
        }

        return updatedState;
    }

    private fillWord(state: CrosswordState, w: WordItem): CrosswordState {
        const pw = state.placedWords.find((p) => p.word === w);
        if (!pw) return state;

        const chars = Array.from(pw.word.word.toUpperCase());
        const newGrid = state.grid.map((row) => [...row]);

        for (let i = 0; i < chars.length; i++) {
            const row = pw.startRow + (pw.horizontal ? 0 : i);
            const col = pw.startCol + (pw.horizontal ? i : 0);
            const [r, c] = this.localIndices({ row, col }, state);
            if (
                r >= 0 &&
                r < newGrid.length &&
                c >= 0 &&
                c < newGrid[r].length
            ) {
                newGrid[r][c] = chars[i];
            }
        }

        return new CrosswordState(
            state.words,
            state.placedWords,
            state.occupiedPositions,
            state.startPositions,
            state.cellSize,
            new Set(state.hintedWords),
            new Set(state.foundWords),
            newGrid,
            state.isCrosswordSolved,
            state.activeWord
        );
    }

    private allWordsCorrectlyFilled(state: CrosswordState): boolean {
        return state.placedWords.every((pw) => this.isWordCorrectlyFilled(state, pw));
    }

    private isWordCorrectlyFilled(state: CrosswordState, pw: PlacedWord): boolean {
        const chars = Array.from(pw.word.word);
        const userLetters: string[] = [];
        for (let i = 0; i < chars.length; i++) {
            const pos = pw.horizontal
                ? { row: pw.startRow, col: pw.startCol + i }
                : { row: pw.startRow + i, col: pw.startCol };
            userLetters.push(this.letterAt(state, pos).toLowerCase().trim());
        }
        const userWord = userLetters.join('');
        return userWord === pw.word.word.toLowerCase();
    }

    private letterAt(state: CrosswordState, pos: Position): string {
        const [r, c] = this.localIndices(pos, state);
        if (
            r < 0 ||
            r >= state.grid.length ||
            c < 0 ||
            c >= state.grid[r].length
        ) {
            return '';
        }
        return state.grid[r][c];
    }

    private localIndices(pos: Position, state: CrosswordState): [number, number] {
        const minRow = Math.min(...Array.from(state.occupiedPositions).map((p) => p.row));
        const minCol = Math.min(...Array.from(state.occupiedPositions).map((p) => p.col));
        const rowIndex = pos.row - minRow;
        const colIndex = pos.col - minCol;
        return [rowIndex, colIndex];
    }

    private positionToWords(placedWords: PlacedWord[]): { [key: string]: PlacedWord[] } {
        const posToWords: { [key: string]: PlacedWord[] } = {};
        for (const pw of placedWords) {
            const length = pw.word.word.length;
            for (let i = 0; i < length; i++) {
                const pos = pw.horizontal
                    ? { row: pw.startRow, col: pw.startCol + i }
                    : { row: pw.startRow + i, col: pw.startCol };
                const key = `${pos.row},${pos.col}`;
                if (!posToWords[key]) {
                    posToWords[key] = [];
                }
                posToWords[key].push(pw);
            }
        }
        return posToWords;
    }
}