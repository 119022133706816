import React from "react";
import { useSelectionState } from "./SelectionStateContext";
import { FoundLinesView } from "./FoundLinesView";
import { SelectionLineView } from "./SelectionLineView";
import { FieldView } from "./FieldView";
import {observer} from "mobx-react-lite";

interface GridViewProps {
    width: number;
    height: number;
}

export const GridView: React.FC<GridViewProps> = observer(({ width, height }) => {
    return (
        <div
            style={{
                position: "relative",
                width,
                height,
                backgroundColor: "white",
                borderRadius: 8,
                overflow: "hidden",
            }}
        >
            {/* Слой с буквами (на переднем плане) */}
            <div style={{
                position: "relative",
                width: "100%",
                height: "100%",
                zIndex: 2
            }}>
                <FieldView width={width} height={height} />
            </div>

            {/* Слой с уже найденными словами и линией выделения (на заднем плане) */}
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 1,         // меньше, чем у букв
                    pointerEvents: "none", // чтобы клики "проходили" на буквы
                }}
            >
                <FoundLinesView width={width} height={height} />
                <SelectionLineView width={width} height={height} />
            </div>
        </div>
    );
});