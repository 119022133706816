// parseHtmlWithImages.ts
import parse, { DOMNode, Element } from "html-react-parser";
import React from "react";
import { CustomImageElement } from "./CustomImageElement";
import {CustomAudioElement} from "../Base/CustomAudioElement";
import {AudioPlayerCallbacks} from "../../../Components/AudioPlayer/AudioPlayer.types";

// Доп. функция для парсинга строки style="..." в объект React.CSSProperties
function parseInlineStyle(inlineStyle?: string): React.CSSProperties {
    if (!inlineStyle) return {};
    const styleObj: React.CSSProperties = {};
    inlineStyle.split(";").forEach((rule) => {
        const [prop, val] = rule.split(":");
        if (!prop || !val) return;
        const camel = prop.trim().replace(/-([a-z])/g, (_, c) => c.toUpperCase());
        // @ts-ignore
        styleObj[camel as any] = val.trim();
    });
    return styleObj;
}

export function parseHtmlWithImagesAndAudio(
    htmlString: string,
    onReplaceImageSrc: (oldSrc: string, newSrc: string) => void,
    onReplaceAudioId: (oldAudioId: string, newAudioId: string) => void,
    audioPlayerCallbacks?: AudioPlayerCallbacks
): React.ReactNode {
    function transform(node: DOMNode) {
        if (node.type === "tag") {
            const elem = node as Element;

            // Если это <img ...>
            if (elem.name === "img") {
                const oldSrc = elem.attribs?.src || "";
                const alt = elem.attribs?.alt || "";
                // Считываем inline-стиль
                const styleObj = parseInlineStyle(elem.attribs?.style);

                return (
                    <CustomImageElement
                        src={oldSrc}
                        alt={alt}
                        style={styleObj}
                        onImageChanged={(newSrc) => {
                            // При загрузке новой картинки меняем HTML (oldSrc -> newSrc)
                            onReplaceImageSrc(oldSrc, newSrc);
                        }}
                    />
                );
            }

            if (elem.name === "audio-player") {
                // Считаем атрибут audioid
                const oldAudioId = elem.attribs?.audioid || "";
                // Здесь прокидываем нужные коллбеки (onError, onTextLoaded и т.д.)
                return (
                    <CustomAudioElement
                        audioId={oldAudioId}
                        // Эти коллбеки прокидываем дальше,
                        // чтобы при изменении audioId заменить его в HTML
                        onAudioIdChange={(newAudioId) => {
                            onReplaceAudioId(oldAudioId, newAudioId);
                        }}
                        audioPlayerCallbacks={audioPlayerCallbacks}
                    />
                );
            }
        }
        return undefined; // значит «не трансформируем»
    }

    return parse(htmlString, { replace: transform });
}