// src/components/AddingTypes.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BaseButton from '../../../ui/components/BaseButton';

const AddingTypes: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            <h1 style={{ fontSize: '2rem', marginBottom: '20px' }}>Add words</h1>

            <BaseButton color="#007bff" onClick={() => navigate('/addManually')}>
                Manually
            </BaseButton>
            <BaseButton color="#007bff" onClick={() => navigate('/addMultiple')}>
                Several words
            </BaseButton>
            <BaseButton color="#007bff" onClick={() => navigate('/addByTheme')}>
                By topic
            </BaseButton>
            <BaseButton color="#007bff" onClick={() => navigate('/addFromTextEnteringText')}>
                From text
            </BaseButton>
        </div>
    );
};

export default AddingTypes;
