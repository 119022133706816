// AnagramUIHandler.ts
import { makeAutoObservable, runInAction } from "mobx";
import { AnagramUIState } from "./AnagramUIState";
import {ExerciseUIHandler} from "../../../../../domain/Multiplayer/ExerciseUIHandler";
import {SessionStateInteractor} from "../../../../../domain/Multiplayer/SessionStateInteractor";
import {ExerciseUIState} from "../../../../../domain/Multiplayer/ExerciseUIState";

export class AnagramUIHandler implements ExerciseUIHandler {
    public scrambledLetters: string;
    public currentAnswer: string;
    public isCompleted: boolean;

    private exerciseId: string;
    private interactor?: SessionStateInteractor;
    private currentUIState: AnagramUIState;
    private isUpdatingFromServer = false;

    constructor(
        exerciseId: string,
        interactor?: SessionStateInteractor,
        initialState?: AnagramUIState
    ) {
        this.exerciseId = exerciseId;
        this.interactor = interactor;

        // Если у нас нет начального состояния, создаём "пустое"
        this.currentUIState = initialState || new AnagramUIState("", "", false);

        this.scrambledLetters = this.currentUIState.scrambledLetters;
        this.currentAnswer = this.currentUIState.currentAnswer;
        this.isCompleted = this.currentUIState.isCompleted;

        makeAutoObservable(this);
    }

    updateUIState(uiState: ExerciseUIState | undefined): void {
        if (!uiState || uiState.type !== "anagram") {
            return;
        }
        const newState = uiState.data as AnagramUIState;

        if (!newState.equals(this.currentUIState)) {
            this.isUpdatingFromServer = true;
            runInAction(() => {
                this.currentUIState = newState;
                this.scrambledLetters = newState.scrambledLetters;
                this.currentAnswer = newState.currentAnswer;
                this.isCompleted = newState.isCompleted;
            });
            this.isUpdatingFromServer = false;
        }
    }

    sendUpdateIfNeeded(): void {
        if (this.isUpdatingFromServer) return;
        const newState = new AnagramUIState(
            this.scrambledLetters,
            this.currentAnswer,
            this.isCompleted
        );
        if (newState.equals(this.currentUIState)) return;

        this.currentUIState = newState;
        // Сообщаем SessionStateInteractor, что есть обновлённое состояние
        this.interactor?.updateAnagramState(this.exerciseId, newState);
    }
}