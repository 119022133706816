// ./SelectionStateContext.tsx
import React, { createContext, useContext } from "react";
import { SelectionState } from "./SelectionState";

// Тип контекста: либо SelectionState, либо null
const SelectionStateContext = createContext<SelectionState | null>(null);

export const SelectionStateColors: string[] = [
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "indigo",
    "purple",
    "pink",
    "teal",
    "mint",
]

/**
 * Провайдер принимает СУЩЕСТВУЮЩИЙ объект SelectionState,
 * чтобы дальше его мог получить любой потомок через useSelectionState().
 */
export const SelectionStateProvider: React.FC<{
    selectionState: SelectionState;
    children: React.ReactNode;
}> = ({ selectionState, children }) => {
    return (
        <SelectionStateContext.Provider value={selectionState}>
            {children}
        </SelectionStateContext.Provider>
    );
};

/** Хук, чтобы удобно получать SelectionState внутри компонентов */
export function useSelectionState(): SelectionState {
    const ctx = useContext(SelectionStateContext);
    if (!ctx) {
        throw new Error("useSelectionState must be used inside a SelectionStateProvider");
    }
    return ctx;
}