import {NotesInteractor} from "./NotesInteractor";
import {CanvasViewModel} from "../Base/CanvasViewModel";
import {makeAutoObservable} from "mobx";

export class NotesWindowViewModel {
    public canvasViewModel: CanvasViewModel;
    private interactor: NotesInteractor;

    constructor(interactor: NotesInteractor) {
        this.interactor = interactor
        this.canvasViewModel = new CanvasViewModel(interactor.canvas);
        makeAutoObservable(this)
    }

    public destroy() {
        this.canvasViewModel.destroy()
        this.interactor.unsubscribe();
    }
}