import {MediaLoader} from "../../../../../data/implementations/Files/MediaLoader";
import {MediaUploading} from "../../../../../domain/interfaces/MediaTypes";
import compositionRoot from "../../../../../compositionRoot";

export interface IAudioUploadService {
    uploadAudio(file: File, audioId: string): Promise<string>; // возвращает ссылку
}

/** Мок для загрузки (file + audioId) -> возвращаем ссылку */
export class MockAudioUploadService implements IAudioUploadService {
    async uploadAudio(file: File, audioId: string): Promise<string> {
        await this.simulateDelay(1500);
        // Просто возвращаем фиктивную ссылку, например server.com/audio/:id
        return "https://server.com/audio/" + audioId;
    }

    private async simulateDelay(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
}

export class FirebaseAudioUploadService implements IAudioUploadService {
    private mediaLoader: MediaUploading;

    constructor() {
        this.mediaLoader = compositionRoot.mediaUploading
    }

    /**
     * Загружает аудиофайл в Firebase Storage, используя MediaLoader.
     *
     * @param file - исходный файл (File), выбранный пользователем
     * @param audioId - заранее сгенерированный ID (например, UUID),
     *                  который мы хотим использовать в качестве имени
     *                  или части имени файла
     * @returns ссылка (downloadURL) из Firebase Storage
     */
    public async uploadAudio(file: File, audioId: string): Promise<string> {
        // 1. Преобразуем File в Blob (обычно File сам является Blob'ом, но
        //    чтобы точно работать с MediaLoader, можно читать ArrayBuffer).
        const arrayBuffer = await file.arrayBuffer();
        const dataBlob = new Blob([arrayBuffer], { type: file.type });

        // 2. Пытаемся извлечь реальное расширение из исходного имени файла:
        //    "example.mp3" -> "mp3".
        let fileExtension = "mp3"; // значение по умолчанию
        const nameParts = file.name.split(".");
        if (nameParts.length > 1) {
            const candidate = nameParts.pop()?.toLowerCase();
            // Проверяем, что расширение входит в список допустимых
            // (можете дополнить свой список при необходимости)
            if (["mp3", "wav", "m4a", "aac"].includes(candidate || "")) {
                fileExtension = candidate!;
            }
        }

        // 3. Вызываем метод mediaLoader.uploadFile(data, fileName, fileExtension).
        //    В качестве fileName используем audioId (или можно обыграть иначе).
        const downloadURL = await this.mediaLoader.uploadFile(
            dataBlob,
            audioId,           // например, используем audioId как основу имени файла
            fileExtension
        );

        // 4. Возвращаем полученную ссылку (downloadURL) из Firebase.
        return downloadURL;
    }
}