import React from "react";
import { Resizer } from "../../../Resizer";
import {Exercise, WordItem} from "../../../../../domain/models";
import {DefaultCrosswordBuilder} from "../Domain/CrosswordBuilderProtocol";
import {ExerciseCrossword} from "../Domain/ExerciseCrossword";
import {CrosswordLearnViewModel} from "../Base/CrosswordLearnViewModel";
import {CrosswordLearnView} from "../Base/CrosswordLearnView";

export function CrosswordTypePreview() {
    // 1) Создаём моковые слова
    const mockWords: WordItem[] = createMockWords();

    // 2) Строим кроссворд (упражнение ExerciseCrossword)
    const builder = new DefaultCrosswordBuilder();
    const result = builder.buildCrossword(mockWords);
    const exerciseCrossword = new ExerciseCrossword(
        mockWords,
        result.grid,
        result.placedWords,
        result.occupiedPositions,
        result.startPositions
    );

    // 3) Формируем объект Exercise для "crossword"
    const exercise: Exercise = {
        id: crypto.randomUUID().toUpperCase(),
        type: {
            kind: "crossword",
            data: exerciseCrossword
        }
    };

    // 4) Создаём вьюмодель на основе этого упражнения (без делегата, uiHandler, т.к. это превью)
    const mockViewModel = new CrosswordLearnViewModel(exercise);

    // 5) Рендерим CrosswordLearnView, обёрнутый в Resizer
    return (
        <Resizer targetSize={{ width: 300, height: 150 }}>
                <CrosswordLearnView viewModel={mockViewModel} />
        </Resizer>
    );
}

/**
 * Моковые данные для демонстрации:
 * 4 слова - word и translation (будут отображаться как "слово" и "подсказка").
 */
function createMockWords(): WordItem[] {
    return [
        {
            id: "1",
            word: "APPLE",
            translation: "A fruit",
            example: "",
            repetitions: [],
            imageURL: ""
        },
        {
            id: "2",
            word: "BANANA",
            translation: "Yellow fruit",
            example: "",
            repetitions: [],
            imageURL: ""
        },
        {
            id: "3",
            word: "ORANGE",
            translation: "Citrus fruit",
            example: "",
            repetitions: [],
            imageURL: ""
        },
        {
            id: "4",
            word: "DICTUMAPP",
            translation: "App",
            example: "",
            repetitions: [],
            imageURL: ""
        },
        {
            id: "5",
            word: "ANANAS",
            translation: "ANANAS",
            example: "",
            repetitions: [],
            imageURL: ""
        },
        {
            id: "6",
            word: "SADAD",
            translation: "SAD",
            example: "",
            repetitions: [],
            imageURL: ""
        },
    ];
}