import React from "react";
import { exerciseCategories, ExerciseCreationType, typeLabel } from "../../Models/ExerciseCreationType";
import {renderPreview} from "../../../exercises_types/ExerciseCreationTypeViews";

interface Props {
    onSelect: (type: ExerciseCreationType) => void;
    onClose: () => void;
}

export function ExerciseTypeSelectionView({ onSelect, onClose }: Props) {
    const columnsStyle: React.CSSProperties = {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "20px",
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", padding: 20 }}>
            <h2 style={{ marginBottom: 20 }}>Select Exercise Type</h2>

            <div style={{ flex: 1, overflow: "auto" }}>
                {Object.entries(exerciseCategories).map(([category, types]) => (
                    <div key={category} style={{ marginBottom: 20 }}>
                        <h3 style={{ marginBottom: 10, textAlign: "left" }}>{category}</h3>
                        <div style={columnsStyle}>
                            {types.map((type) => (
                                <div
                                    key={type}
                                    style={{
                                        border: "1px solid #666",
                                        padding: 16,
                                        cursor: "pointer",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                    onClick={() => onSelect(type)}
                                >
                                    <h3 style={{ marginTop: 0, marginBottom: 8 }}>{typeLabel(type)}</h3>
                                    <div style={{ height: 150, width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        {renderPreview(type)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            <div style={{ marginTop: 20 }}>
                <button style={buttonStyle} onClick={onClose}>Cancel</button>
            </div>
        </div>
    );
}

const buttonStyle: React.CSSProperties = {
    backgroundColor: "#555",
    color: "#fff",
    border: "1px solid #888",
    borderRadius: 4,
    padding: "8px 16px",
    cursor: "pointer",
    marginRight: 8,
};