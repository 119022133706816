// MissingWordTypePreview.tsx
import React from 'react';
import { Resizer } from '../../../Resizer';
import {ExerciseMissingWord} from "../../../../../domain/models";
import {MissingWordViewBase} from "../Base/MissingWordViewBase";

export function MissingWordTypePreview() {
    const mockData: ExerciseMissingWord = createMockMissingWordData();

    return (
        <Resizer targetSize={{ width: 800, height: 150 }}>
            <div style={{ width: '800px', height: '500px', alignContent:'center'}}>
                <MissingWordViewBase
                    sentence={mockData.sentence}
                    inputText=""
                    isCorrect={null}
                    correctAnswer={mockData.correctForm}
                    onInputChange={() => {}}
                    onEnterPressed={() => {}}
                    onCheckPressed={() => {}}
                />
            </div>
        </Resizer>
    );
}

function createMockMissingWordData() {
    return {
        sentence: "Es posible que ella ___ (querer) cambiar de trabajo.\n",
        correctForm: "string"
    };
}