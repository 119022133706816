// AnagramUseCase.ts
import { AnagramState } from "./AnagramState";

export class AnagramUseCase {
    /** Положить букву в текущий ответ по индексу */
    public selectLetter(state: AnagramState, letterIndex: number): AnagramState {
        // 1) Берём массив символов из scrambledLetters
        const scrambledArr = state.scrambledLetters.split("");
        const letter = scrambledArr[letterIndex];
        if (!letter) {
            return state; // Выход за границы
        }

        // 2) Ищем первую позицию в currentAnswer, где '_'
        const answerArr = state.currentAnswer.split("");
        const freeIndex = answerArr.indexOf("_");
        if (freeIndex === -1) {
            // Нет свободных ячеек
            return state;
        }

        // 3) Ставим выбранную букву
        answerArr[freeIndex] = letter;

        // 4) Удаляем букву из scrambledArr (чтобы на UI исчезла)
        scrambledArr.splice(letterIndex, 1);

        // 5) Формируем новое состояние
        let newState: AnagramState = {
            ...state,
            currentAnswer: answerArr.join(""),
            scrambledLetters: scrambledArr.join("")
        };

        // 6) Проверяем, не собран ли ответ
        newState = this.checkComplete(newState);

        return newState;
    }

    public removeLetter(state: AnagramState, answerIndex: number): AnagramState {
        const answerArr = state.currentAnswer.split("");
        const letter = answerArr[answerIndex];

        if (letter === "_" || letter === "*") {
            // Пустая ячейка, пропускаем
            return state;
        }

        // 1) Освобождаем ячейку
        answerArr[answerIndex] = "_";

        // 2) Добавляем букву обратно в конец scrambledLetters
        const scrambledArr = state.scrambledLetters.split("");
        scrambledArr.push(letter);

        let newState: AnagramState = {
            ...state,
            currentAnswer: answerArr.join(""),
            scrambledLetters: scrambledArr.join("")
        };

        // 3) Перепроверить сборку слова
        newState = this.checkComplete(newState);

        return newState;
    }

    /** Проверяем, совпадает ли currentAnswer с исходным словом */
    public checkComplete(state: AnagramState): AnagramState {
        const isComplete = (state.currentAnswer === state.originalWord);
        return {
            ...state,
            isCompleted: isComplete
        };
    }
}