import {
    Exercise,
    ExerciseMatchingPairs,
    MatchingPairItem,
    PairElement
} from "../../../domain/models";

export function serializeMatchingPairs(exercise: Exercise): any {
    if (exercise.type.kind !== 'matchingPairs') {
        throw new Error("serializeAnagram called with non-anagram exercise");
    }

    const mp: ExerciseMatchingPairs = exercise.type.data;
    const pairsArray = mp.pairs.map((pair) => ({
        left: pairElementToDict(pair.left),
        right: pairElementToDict(pair.right)
    }));

    return {
        type: 'matchingPairs',
        authorId: exercise.authorId,
        description: exercise.description,
        pairs: pairsArray,
        rightItemsOrder: mp.rightItemsOrder?.map(uuid => uuid) || null
    };
}

export function deserializeMatchingPairs(data: any, id: string): Exercise | null {
    const { pairs, rightItemsOrder } = data;
    if (!Array.isArray(pairs)) return null;

    const mpPairs: MatchingPairItem[] = pairs
        .map((p: any) => {
            const left = p.left ? pairElementFromDict(p.left) : null;
            const right = p.right ? pairElementFromDict(p.right) : null;
            if (!left || !right) return null;
            return { left, right };
        })
        .filter((x: MatchingPairItem | null): x is MatchingPairItem => x !== null);

    return {
        id,
        authorId: data.authorId,
        description: data.description,
        type: {
            kind: 'matchingPairs',
            data: {
                pairs: mpPairs,
                rightItemsOrder: Array.isArray(rightItemsOrder) ? rightItemsOrder : undefined
            }
        }
    };
}

function pairElementToDict(pe: PairElement): any {
    return {
        id: pe.id,
        text: pe.text || null,
        contentURL: pe.contentURL || null
    };
}

function pairElementFromDict(dict: any): PairElement | null {
    if (!dict || !dict.id) return null;
    return {
        id: dict.id,
        text: dict.text || undefined,
        contentURL: dict.contentURL || undefined
    };
}