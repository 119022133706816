// ===================== ./PracticeSelectOption/SelectOptionUseCase.ts =====================
import { SelectOptionState } from "./SelectOptionState";

export class SelectOptionUseCase {
    public selectAndCheckOption(
        currentState: SelectOptionState,
        chosenOption: string
    ): SelectOptionState {
        const newState = {
            ...currentState,
            selectedOption: chosenOption,
            isCorrect: chosenOption === currentState.correctOption
        };
        return newState;
    }

    public goNext(currentState: SelectOptionState): SelectOptionState {
        return {
            ...currentState,
            selectedOption: null,
            isCorrect: null,
        };
    }
}