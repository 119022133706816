import React, { useEffect } from "react";
import { useSizeContext } from "./SizeContext";
import { useSelectionState } from "./SelectionStateContext";
import { GridView } from "./GridView";
import {observer} from "mobx-react-lite";

export const GridSizeView: React.FC = observer(() => {
    const { game } = useSelectionState();
    const { setGridSizeWidth } = useSizeContext();

    // We'll measure the container’s width and height via ref + ResizeObserver
    const ref = React.useRef<HTMLDivElement>(null);
    const [containerSize, setContainerSize] = React.useState({ width: 0, height: 0 });

    useEffect(() => {
        if (!ref.current) return;
        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.contentBoxSize) {
                    setContainerSize({
                        width: entry.contentRect.width,
                        height: entry.contentRect.height,
                    });
                }
            }
        });
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, []);

    const rowsCount = game.fieldSize.rowsCount;
    const columnsCount = game.fieldSize.columnsCount;

    const maxSizePerCell = Math.min(
        containerSize.width / columnsCount,
        containerSize.height / rowsCount
    );

    const gridViewWidth = maxSizePerCell * columnsCount;
    const gridViewHeight = maxSizePerCell * rowsCount;

    useEffect(() => {
        setGridSizeWidth(gridViewWidth);
    }, [gridViewWidth, setGridSizeWidth]);

    return (
        <div ref={ref} style={{ width: "100%", height: "50vh", display: "flex", justifyContent: "center" }}>
            <div style={{ display: "inline-block" }}>
                <GridView width={gridViewWidth} height={gridViewHeight} />
            </div>
        </div>
    );
});