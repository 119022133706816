// src/ui/screens/student/StudentScreenViewModel.ts

import { makeAutoObservable, runInAction } from 'mobx';
import { AppUser } from '../../../../domain/models/AppUser';
import {CombinedStudent} from "../CombinedStudent";
import StudentScreenInteractor from "./StudentScreenInteractor";
import {CompletedClass} from "./CompletedClass";

export default class StudentScreenViewModel {
    // userId (teacherId)
    currentUser: AppUser | null = null;
    userId: string | null = null;
    studentId: string | null = null;

    // Флаги загрузки
    loadingUser = true;     // пока не знаем userId
    loadingStudent = false; // загрузка данных о конкретном студенте
    error: string | null = null;

    // Данные о студенте
    studentData: CombinedStudent | null = null;

    completedClasses: CompletedClass[] = [];

    private interactor: StudentScreenInteractor;
    private unsubscribeFn: (() => void) | null = null;

    constructor() {
        console.log("StudentScreenViewModel init")
        this.interactor = new StudentScreenInteractor();
        makeAutoObservable(this);
    }

    init(studentId: string) {
        this.studentId = studentId;

        // Подписываемся на currentUser через Interactor
        this.unsubscribeFn = this.interactor.subscribeToCurrentUser((user: AppUser | null) => {
            runInAction(() => {
                this.currentUser = user;
                this.userId = user?.uid || null;
                this.loadingUser = false;
            });

            // Если userId есть, теперь можем грузить студента
            if (this.userId && this.studentId && !this.studentData) {
                void this.loadStudent(this.userId, this.studentId);
            }

            if (this.studentId) {
                void this.loadStudentHistory(this.studentId);
            }
        });
    }

    async startClass() {
        console.log("StudentScreenViewModel starting class, teacher: ", this.userId, " student: ", this.studentId)
        if (!this.userId || !this.studentId) return;
        const newClassId = crypto.randomUUID().toUpperCase();
        await this.interactor.startClass(this.userId, this.studentId, newClassId);
        return newClassId;
    }

    async loadStudent(teacherId: string, studentId: string) {
        this.error = null;
        this.loadingStudent = true;
        await this.interactor.loadStudent(teacherId, studentId);
        runInAction(() => {
            this.loadingStudent = this.interactor.loadingStudent;
            this.error = this.interactor.error;
            this.studentData = this.interactor.studentData;
        });
    }

    async loadStudentHistory(studentUserId: string) {
        this.error = null;
        this.loadingStudent = true;

        await this.interactor.loadCompletedClasses(studentUserId);

        runInAction(() => {
            this.loadingStudent = this.interactor.loadingStudent;
            this.error = this.interactor.error;

            // Copy the array from interactor
            this.completedClasses = this.interactor.completedClasses;
        });
    }

    dispose() {
        this.unsubscribeFn?.();
        // Если в interactor есть ещё что-то (сокеты, listener’ы), вызывайте там interactor.dispose().
        this.interactor.dispose();
    }
}