export enum ExerciseCreationType {
    MissingWord = "missingWord",
    SelectOption = "selectOption",
    WordSearch = "wordSearch",
    Anagram = "anagram",
    Crossword = "crossword",
    FillBlanks = "fillBlanks",
    MatchingPairs = "matchingPairs",
    JustContent = "justContent",
    Table = "table",
    Hangman = "hangman",
    Free = "free"
}

export const ALL_EXERCISE_CREATION_TYPES = [
    ExerciseCreationType.MissingWord,
    ExerciseCreationType.SelectOption,
    ExerciseCreationType.WordSearch,
    ExerciseCreationType.Anagram,
    ExerciseCreationType.Crossword,
    ExerciseCreationType.Table,
    ExerciseCreationType.FillBlanks,
    ExerciseCreationType.MatchingPairs,
    ExerciseCreationType.JustContent,
    ExerciseCreationType.Hangman,
    ExerciseCreationType.Free,
];

export function typeLabel(type: ExerciseCreationType): string {
    switch (type) {
        case ExerciseCreationType.MissingWord:    return "Missing Word";
        case ExerciseCreationType.SelectOption:   return "Select Option";
        case ExerciseCreationType.WordSearch:     return "Word Search";
        case ExerciseCreationType.Anagram:        return "Anagram";
        case ExerciseCreationType.Crossword:      return "Crossword";
        case ExerciseCreationType.FillBlanks:     return "Fill Blanks";
        case ExerciseCreationType.MatchingPairs:  return "Matching Pairs";
        case ExerciseCreationType.JustContent:    return "Canvas";
        case ExerciseCreationType.Table:          return "Table";
        case ExerciseCreationType.Free:           return "Recognize image";
        default: return type;
    }
}

export const exerciseCategories: { [key: string]: ExerciseCreationType[] } = {
    "Grammar": [
        ExerciseCreationType.Free,
        ExerciseCreationType.MissingWord,
        ExerciseCreationType.SelectOption
    ],
    "Word games": [
        ExerciseCreationType.Hangman,
        ExerciseCreationType.Crossword,
        ExerciseCreationType.Anagram,
        ExerciseCreationType.WordSearch
    ],
    "Other": [
        ExerciseCreationType.Table,
        ExerciseCreationType.FillBlanks,
        ExerciseCreationType.MatchingPairs,
        ExerciseCreationType.JustContent
    ]
};