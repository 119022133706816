// SingleExerciseView.tsx
import React from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { SingleExerciseViewModel } from "./SingleExerciseViewModel";
import { BaseText } from "../../exercises_types/BaseComponents/BaseText";
import {PracticeExerciseContent} from "../PracticeExerciseContent";

export const SingleExerciseView: React.FC = observer(() => {
    const { id } = useParams<{ id: string }>();

    const [vm] = React.useState(() => new SingleExerciseViewModel(id!));

    React.useEffect(() => {
        vm.loadExercise();
    }, [id]);

    if (vm.loading) {
        return <div>Loading...</div>;
    }

    if (vm.practiceState.type === "done") {
        return <div>Exercise not found or no more exercises</div>;
    }

    return (
        <div style={{ padding: 20 }}>
            <BaseText font="title">
                Single Exercise: {vm.practiceState.type.toUpperCase()}
            </BaseText>

            <PracticeExerciseContent practiceState={vm.practiceState} />


        </div>
    );
});