// ExercisesHorizontalList.tsx

import React from "react";
import { observer } from "mobx-react-lite";
import { Exercise } from "../../../../../domain/models";
import { HorizontalDraggableInfiniteList } from "../../../Components/HorizontalDragList/HorizontalDraggableList";
import { ExcercisePreviewCard } from "./ExcercisePreviewCard";
import { AddCard } from "./AddCard";

interface ExercisesHorizontalListProps {
    exercises: Exercise[];
    onReorder: (newOrder: Exercise[]) => void;
    onDelete?: (index: number) => void;
    onPress: (index: number) => void;

    // callbacks for the AddCard
    onCreateExerciseClick: () => void;
    onAddExistingClick: () => void;
}

const SPECIAL_ITEM_ID = "ADD_CARD_SPECIAL_ID";

export const ExercisesHorizontalList = observer(
    ({
         exercises,
         onReorder,
         onDelete,
         onPress,
         onCreateExerciseClick,
         onAddExistingClick,
     }: ExercisesHorizontalListProps) => {
        // 1) Add a special item at the end
        const combinedItems = [
            ...exercises,
            { id: SPECIAL_ITEM_ID } as Exercise, // fake item for the "AddCard"
        ];

        // 2) Filter out special item from the final reorder
        const handleReorder = (newOrder: Exercise[]) => {
            const filtered = newOrder.filter((ex) => ex.id !== SPECIAL_ITEM_ID);
            onReorder(filtered);
        };

        return (
            <div>
                {exercises.length === 0 ? (
                    <div style={{ color: "#bbb" }}>No exercises selected.</div>
                ) : null}

                <HorizontalDraggableInfiniteList
                    items={combinedItems}
                    getItemKey={(ex) => ex.id}
                    onReorder={handleReorder}
                    renderItem={(exercise: Exercise, index: number) => {
                        // 3) If this is the special "AddCard" item, render those 2 buttons
                        if (exercise.id === SPECIAL_ITEM_ID) {
                            return (
                                <AddCard
                                    onCreateClick={onCreateExerciseClick}
                                    onAddExistingClick={onAddExistingClick}
                                />
                            );
                        }

                        // Otherwise, render your normal card
                        const handleDelete = onDelete ? () => onDelete(index) : undefined;
                        return (
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={() => onPress(index)}
                            >
                                <ExcercisePreviewCard
                                    exercise={exercise}
                                    onDelete={(e) => {
                                        e.stopPropagation();
                                        handleDelete?.();
                                    }}
                                />
                            </div>
                        );
                    }}
                />
            </div>
        );
    }
);