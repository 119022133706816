// Toolbar.tsx
import React from 'react';
import { CanvasItem } from "../../CanvasItem";
import LoadingButton, { ButtonVariant } from "../../../../exercises_types/BaseComponents/LoadingButton";
import TextStyleControls from './TextStyleControls';

interface ToolbarProps {
    selectedItem: CanvasItem | null;
    onUpdateItem: (data: Partial<CanvasItem>) => void;
    onAddText: () => void;
    onAddImage: () => void;
    onAddDrawing: () => void;
    onRemoveItem: (id: string) => void;
    fileInputRef: React.RefObject<HTMLInputElement>;
    isUploadingImage: boolean;

    editableRef: React.RefObject<HTMLDivElement> | null;

    onToolbarCommand?: (command: string, value?: string) => void;
}

const Toolbar: React.FC<ToolbarProps> = ({
                                             selectedItem,
                                             onUpdateItem,
                                             onAddText,
                                             onAddImage,
                                             onAddDrawing,
                                             onRemoveItem,
                                             fileInputRef,
                                             isUploadingImage,
                                             editableRef,
                                             onToolbarCommand
                                         }) => {
    const handleRemoveClick = () => {
        if (selectedItem) onRemoveItem(selectedItem.id);
    };

    return (
        <div
            className="toolbar"
            style={{
                display: 'flex',
                alignItems: 'center',
                height: '40px',
                padding: '8px',
                backgroundColor: '#f9f9f9',
                borderBottom: '1px solid #ccc',
            }}
        >
            <LoadingButton
                onClick={onAddText}
                isLoading={false}
                variant={ButtonVariant.Small}
                color="#28a745"
                style={{ marginRight: '1px' }}
            >
                +T
            </LoadingButton>

            <LoadingButton
                onClick={onAddImage}
                isLoading={isUploadingImage}
                variant={ButtonVariant.Small}
                color="#28a745"
                style={{ marginRight: '1px' }}
            >
                +img
            </LoadingButton>

            <LoadingButton
                onClick={onAddDrawing}
                isLoading={false}
                variant={ButtonVariant.Small}
                color="#28a745"
                style={{ marginRight: '5px' }}
            >
                +Draw
            </LoadingButton>

            {!selectedItem && <div style={{ color: '#666' }}>No item selected</div>}

            {selectedItem && (
                <LoadingButton
                    onClick={handleRemoveClick}
                    isLoading={false}
                    variant={ButtonVariant.Small}
                    color="#FF0000"
                    style={{ marginRight: '8px' }}
                >
                    Del
                </LoadingButton>
            )}

            {selectedItem && selectedItem.type === 'image' && (
                <div style={{ color: '#666' }}>Image</div>
            )}

            {selectedItem && selectedItem.type === 'drawing' && (
                <div style={{ color: '#666' }}>Draw</div>
            )}

            {selectedItem && selectedItem.type === 'text' && (
                <TextStyleControls
                    selectedItem={selectedItem}
                    onUpdateItem={onUpdateItem}
                    editableRef={editableRef}
                    onToolbarCommand={onToolbarCommand}
                />
            )}
        </div>
    );
};

export default Toolbar;