import React from 'react';
import { Exercise } from '../../../../../domain/models';
import { ExerciseRowView } from '../../Components/ExerciseRowView';
import ShareButton from '../../../exercises_types/BaseComponents/ShareButton';
import BaseButton, {ButtonVariant} from "../../../BaseButton";

interface Props {
    exercise: Exercise;
    onEdit: (ex: Exercise) => void;
    onRemove: (ex: Exercise) => void;
    onCreateSimilar: (ex: Exercise) => void;
    onClose: () => void;
}

export function ExercisePreviewModal({
                                         exercise,
                                         onEdit,
                                         onRemove,
                                         onCreateSimilar,
                                         onClose,
                                     }: Props) {
    const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div style={modalOverlayStyle} onClick={handleOverlayClick}>
            <div style={modalContentStyle} onClick={(e) => e.stopPropagation()}>
                <div style={closeButtonStyle}>
                    <BaseButton variant={ButtonVariant.Small} onClick={onClose}>
                        X
                    </BaseButton>
                </div>

                <ExerciseRowView exercise={exercise} width={400} height={400} />

                <div style={{ marginTop: 20, display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                    <BaseButton variant={ButtonVariant.Small} onClick={() => onEdit(exercise)}>
                        Edit
                    </BaseButton>
                    <BaseButton variant={ButtonVariant.Small} onClick={() => onRemove(exercise)}>
                        Remove
                    </BaseButton>
                    <BaseButton variant={ButtonVariant.Small} onClick={() => onCreateSimilar(exercise)}>
                        Create Similar
                    </BaseButton>
                    {/* Share Button uses BaseButton internally */}
                    <ShareButton link={`${window.location.origin}/exercise/${exercise.id}`} />
                </div>
            </div>
        </div>
    );
}

/** Full-screen overlay, darker background, centered content */
const modalOverlayStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.7)', // semi-transparent dark overlay
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

/** The modal "box" itself */
const modalContentStyle: React.CSSProperties = {
    position: 'relative',
    backgroundColor: "var(--modal-bg)",
    color: '#fff',
    padding: 20,
    borderRadius: 8,
    minWidth: 300,
};

/** Close button in top-right corner inside the modal content */
const closeButtonStyle: React.CSSProperties = {
    position: 'absolute',
    top: 10,
    right: 10,
};