// src/onboarding/AuthViewModel.ts
import { makeAutoObservable } from 'mobx';
import { AuthInteractor } from './AuthInteractor';

type AuthMode = 'login' | 'register';

export class AuthViewModel {
    mode: AuthMode = 'login';

    email = '';
    password = '';

    isLoading = false;
    error: string | null = null;

    constructor(private interactor: AuthInteractor) {
        makeAutoObservable(this);
    }

    setMode(mode: AuthMode) {
        this.mode = mode;
    }

    async doAction() {
        // либо логин, либо регистрация
        this.isLoading = true;
        this.error = null;
        try {
            if (this.mode === 'login') {
                await this.interactor.login(this.email, this.password);
            } else {
                await this.interactor.register(this.email, this.password);
            }
        } catch (e: any) {
            this.error = e.message;
        } finally {
            this.isLoading = false;
        }
    }

    async skipAuth() {
        await this.interactor.skip();
    }
}