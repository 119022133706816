import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { JustContentCreateViewModel } from "./JustContentCreateViewModel";
import { Exercise } from "../../../../domain/models";
import BaseButton from "../../BaseButton";
import {CanvasContainer} from "../Base/CanvasContainer";
import {CanvasItem} from "../Base/CanvasItem";

interface JustContentCreateViewProps {
    items?: CanvasItem[];
    onExercisesCreated: (exercises: Exercise[]) => void;
}

export const JustContentCreateView: React.FC<JustContentCreateViewProps> = observer(
    ({ items, onExercisesCreated }) => {
        const [viewModel] = useState(
            () =>
                new JustContentCreateViewModel(
                    items ?? [],
                    onExercisesCreated
                )
        );

        return (
            <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
                <div style={{ flex: 1, margin: 8 }}>
                    <CanvasContainer
                        viewModel={viewModel.canvasViewModel}
                        width="800px"
                        height="490px"
                    />
                </div>

                <div
                    style={{
                        width: 200,
                        margin: 8,
                        padding: 8,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "stretch",
                    }}
                >
                    <BaseButton onClick={() => viewModel.savePressed()}>Save</BaseButton>
                </div>
            </div>
        );
    }
);