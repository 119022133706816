export enum ExerciseTypeFilter {
    all = "all",
    missingWord = "missingWord",
    selectOption = "selectOption",
    wordSearch = "wordSearch",
    anagram = "anagram",
    crossword = "crossword",
    fillBlanks = "fillBlanks",
    matchingPairs = "matchingPairs",
    justContent = "canvas",
    table = "table",
    hangman = "hangman",
    free = "free",
}