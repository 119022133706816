import React from "react";
import BaseButton, {ButtonVariant} from "../../../ui/components/BaseButton";

export const StudentActions: React.FC<{ navigate: (path: string) => void }> = ({navigate}) => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            <BaseButton color="#00bcd4" onClick={() => navigate('/mainAdd')} variant={ButtonVariant.Big}>
                Add words
            </BaseButton>
            <BaseButton color="#00bcd4" onClick={() => navigate('/mainStudy')} variant={ButtonVariant.Big}>
                Study words
            </BaseButton>
            <BaseButton
                color="#00bcd4"
                onClick={() => navigate('/lessonsList')}
                variant={ButtonVariant.Big}
            >
                Practice
            </BaseButton>
            <BaseButton
                color="#00bcd4"
                onClick={() => navigate('/allExercises')}
                variant={ButtonVariant.Big}
            >
                All exercises
            </BaseButton>
        </div>
    );
};