// FillBlanksUIHandler.ts
import { makeAutoObservable, runInAction } from "mobx";
import { SessionStateInteractor } from "../../../../../domain/Multiplayer/SessionStateInteractor";
import { ExerciseUIHandler } from "../../../../../domain/Multiplayer/ExerciseUIHandler";
import { ExerciseUIState } from "../../../../../domain/Multiplayer/ExerciseUIState";
import { FillBlanksUIState } from "./FillBlanksUIState";

export class FillBlanksUIHandler implements ExerciseUIHandler {
    /** This is the local memory of placeholders (just userInput & correctness). */
    public placeholders: Array<{ id: number; userInput: string; isCorrect: boolean | null }>;

    private exerciseId: string;
    private interactor?: SessionStateInteractor;
    private currentUIState: FillBlanksUIState;
    private isUpdatingFromServer = false;

    constructor(
        exerciseId: string,
        interactor?: SessionStateInteractor,
        initialState?: FillBlanksUIState
    ) {
        this.exerciseId = exerciseId;
        this.interactor = interactor;

        this.currentUIState = initialState || new FillBlanksUIState([]);
        this.placeholders = this.currentUIState.placeholders;

        makeAutoObservable(this);
    }

    /**
     * Called externally when we get a new state from the server
     */
    public updateUIState(uiState: ExerciseUIState | undefined): void {
        if (!uiState || uiState.type !== "fillBlanks") {
            return;
        }
        const newState = uiState.data; // FillBlanksUIState

        if (!newState.equals(this.currentUIState)) {
            this.isUpdatingFromServer = true;
            runInAction(() => {
                this.currentUIState = newState;
                this.placeholders = newState.placeholders; // adopt new placeholders
            });
            this.isUpdatingFromServer = false;
        }
    }

    /**
     * Called whenever local placeholders changed.
     * We push them to the server if they differ from current UI state
     */
    public sendUpdateIfNeeded() {
        if (this.isUpdatingFromServer) return;
        const newState = new FillBlanksUIState(this.placeholders);
        if (newState.equals(this.currentUIState)) return;

        this.currentUIState = newState;
        this.interactor?.updateFillBlanksState(this.exerciseId, newState);
    }
}