import {ExerciseViewable} from "../../ExerciseViewable";
import {Exercise, ExerciseSelectOption} from "../../../../../domain/models";
import {Resizer} from "../../../Resizer";
import React from "react";
import {SelectOptionView} from "../Practice/SelectOptionView";
import {SelectOptionViewModel} from "../Practice/SelectOptionViewModel";

export class ExerciseSelectOptionViewable implements ExerciseViewable {
    constructor(private exerciseSelectOption: ExerciseSelectOption) {}

    renderView(exercise: Exercise, width?: number, height?: number): JSX.Element {
        if (exercise.type.kind !== "selectOption") {
            throw new Error("Unsupported exercise type for ExerciseSelectOptionViewable");
        }

        const targetWidth = width ?? 300;
        const targetHeight = height ?? 150;

        return (
            <Resizer targetSize={{ width: targetWidth, height: targetHeight }}>
                <div style={{ width: '800px', height: '500px', alignContent:'center'}}>
                    <SelectOptionView
                        viewModel={
                            new SelectOptionViewModel(exercise, undefined, undefined, undefined)
                        }
                    />
                </div>
            </Resizer>
        );
    }
}