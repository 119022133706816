// src/ui/screens/learningPlan/LearningPlanEditViewModel.ts
import { makeAutoObservable, runInAction } from 'mobx';
import { AppUser } from '../../../../domain/models/AppUser';
import LearningPlanEditInteractor from "../../PracticeManagement/Logic/LearningPlanEditInteractor";
import {CombinedStudent} from "../CombinedStudent";

/**
 * ViewModel для LearningPlanEditScreen:
 * - Хранит userId (teacherId) и studentId
 * - Подписывается на изменения currentUser
 * - По готовности userId загружает данные о студенте
 * - Экран наблюдает за состояниями (loading, error, studentData)
 */
export default class LearningPlanEditViewModel {
    userId: string | null = null;     // текущий учитель
    studentId: string | null = null;  // студент, чью инфу редактируем

    loadingUser = true;     // флаг: ещё не знаем userId
    loadingStudent = false; // загрузка данных студента
    error: string | null = null;
    studentData: CombinedStudent | null = null;

    private interactor: LearningPlanEditInteractor;
    private unsubscribeFn: (() => void) | null = null;

    constructor() {
        this.interactor = new LearningPlanEditInteractor();
        makeAutoObservable(this);
    }

    /**
     * Инициализация: подписываемся на currentUser и ждём userId.
     * Как только userId определился, загружаем данные студента.
     */
    init(studentId: string) {
        this.studentId = studentId;

        this.unsubscribeFn = this.interactor.subscribeToCurrentUser((user: AppUser | null) => {
            runInAction(() => {
                this.userId = user?.uid || null;
                this.loadingUser = false;
            });

            // Если пользователь авторизован (userId != null) — загружаем студента
            if (this.userId && this.studentId) {
                void this.loadStudent(this.userId, this.studentId);
            }
        });
    }

    /**
     * Обёртка над interactor.loadStudent
     */
    async loadStudent(teacherId: string, studentId: string) {
        this.error = null;
        this.loadingStudent = true;

        await this.interactor.loadStudent(teacherId, studentId);

        runInAction(() => {
            this.loadingStudent = this.interactor.loadingStudent;
            this.error = this.interactor.error;
            this.studentData = this.interactor.studentData;
        });
    }

    dispose() {
        this.unsubscribeFn?.();          // отписываемся от currentUser
        this.interactor.dispose();       // и другие очистки
    }
}