import React from "react";
import { observer } from "mobx-react-lite";
import { WordSearchViewModel } from "./WordSearchViewModel";
import { GameView } from "../Base/GameView";
import { BaseButton } from "../../BaseComponents/BaseButton";
import {SelectionStateProvider} from "../Base/SelectionStateContext";

interface WordSearchGameViewProps {
    viewModel: WordSearchViewModel;
}

export const WordSearchGameView: React.FC<WordSearchGameViewProps> = observer(
    ({ viewModel }) => {
        const { game, selectionState, actionButtonState, hint, goToNext } = viewModel;

        return (
            <SelectionStateProvider selectionState={selectionState}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {/* Передаём game в GameView */}
                    <GameView game={game} />

                    <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
                        {actionButtonState === "hint" ? (
                            <BaseButton text="Hint" onPress={hint} />
                        ) : (
                            <BaseButton text="Next" onPress={goToNext} />
                        )}
                    </div>
                </div>
            </SelectionStateProvider>
        );
    }
);