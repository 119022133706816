import { Position, WordItem } from "../../../../../domain/models";
import { SessionStateInteractor } from "../../../../../domain/Multiplayer/SessionStateInteractor";
import { WordSearchUIState } from "./WordSearchUIState";
import { ExerciseUIState } from "../../../../../domain/Multiplayer/ExerciseUIState";
import {makeAutoObservable, reaction, runInAction } from "mobx";
import {ExerciseUIHandler} from "../../../../../domain/Multiplayer/ExerciseUIHandler";

export class WordSearchUIHandler implements ExerciseUIHandler {
    public foundWords: WordItem[] = [];
    public selectedPositions: Position[] = [];

    private exerciseId: string;
    private interactor?: SessionStateInteractor;
    private isUpdatingFromServer: boolean = false;
    private currentUIState: WordSearchUIState;

    constructor(
        exerciseId: string,
        interactor?: SessionStateInteractor,
        initialState?: WordSearchUIState
    ) {
        this.exerciseId = exerciseId;
        this.interactor = interactor;

        this.currentUIState = initialState ?? {
            foundWords: [],
            selectedPositions: [],
        };

        this.foundWords = this.currentUIState.foundWords;
        this.selectedPositions = this.currentUIState.selectedPositions;

        makeAutoObservable(this);

        reaction(
            () => ({
                foundWords: this.foundWords,
                selectedPositions: this.selectedPositions,
            }),
            () => {
                this.sendUpdateIfNeeded();
            }
        );
    }

    public updateUIState(uiState?: ExerciseUIState) {
        if (!uiState) return;
        if (uiState.type !== "wordSearch") return;

        const newState = uiState.data;
        if (!this.equalsWordSearchUIState(newState, this.currentUIState)) {
            runInAction(() => {
                this.isUpdatingFromServer = true;
                this.foundWords = newState.foundWords;
                this.selectedPositions = newState.selectedPositions;
                this.currentUIState = newState;
                this.isUpdatingFromServer = false;
            });

        }
    }

    private sendUpdateIfNeeded() {
        if (this.isUpdatingFromServer) return;

        const newState: WordSearchUIState = {
            foundWords: this.foundWords,
            selectedPositions: this.selectedPositions,
        };

        if (!this.equalsWordSearchUIState(newState, this.currentUIState)) {
            this.currentUIState = newState;
            this.interactor?.updateExerciseState(this.exerciseId, {
                type: "wordSearch",
                data: newState,
            });
        }
    }

    private equalsWordSearchUIState(a: WordSearchUIState, b: WordSearchUIState): boolean {
        if (a === b) return true;
        if (a.foundWords.length !== b.foundWords.length) return false;
        if (a.selectedPositions.length !== b.selectedPositions.length) return false;

        const aIds = a.foundWords.map((fw) => fw.id).sort();
        const bIds = b.foundWords.map((fw) => fw.id).sort();
        for (let i = 0; i < aIds.length; i++) {
            if (aIds[i] !== bIds[i]) return false;
        }

        for (let i = 0; i < a.selectedPositions.length; i++) {
            const posA = a.selectedPositions[i];
            const posB = b.selectedPositions[i];
            if (posA.row !== posB.row || posA.col !== posB.col) {
                return false;
            }
        }
        return true;
    }
}