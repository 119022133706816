import { Exercise, ExerciseHangman } from "../../../domain/models";

export function serializeHangman(exercise: Exercise): any {
    if (exercise.type.kind !== 'hangman') {
        throw new Error("serializeAnagram called with non-anagram exercise");
    }

    const h: ExerciseHangman = exercise.type.data;
    return {
        type: 'hangman',
        authorId: exercise.authorId,
        description: exercise.description,
        word: h.word,
        hint: h.hint
    };
}

export function deserializeHangman(data: any, id: string): Exercise | null {
    const { word, hint } = data;
    if (typeof word !== 'string' || typeof hint !== 'string') {
        return null;
    }
    return {
        id,
        authorId: data.authorId,
        description: data.description,
        type: {
            kind: 'hangman',
            data: { word, hint }
        }
    };
}