// src/onboarding/RoleSelectionScreen.tsx
import React, {useState} from 'react';
import { observer } from 'mobx-react-lite';
import { RoleSelectionViewModel } from './RoleSelectionViewModel';
import CompositionRoot from "../../../../compositionRoot";
import {RoleSelectionInteractor} from "./RoleSelectionInteractor";

export const RoleSelectionScreen: React.FC = observer(() => {
    const userManager = CompositionRoot.getAppUserManager();
    const [vm] = useState(() => new RoleSelectionViewModel(new RoleSelectionInteractor(userManager)));

    const handleClick = (role: 'student' | 'teacher') => {
        vm.selectRole(role);
    };

    return (
        <div style={styles.center}>
            <h2>Выберите вашу роль</h2>

            {vm.error && <div style={{ color: 'red' }}>{vm.error}</div>}
            {vm.isLoading && <div>Загрузка...</div>}

            <button onClick={() => handleClick('student')} disabled={vm.isLoading}>
                Ученик
            </button>
            <button onClick={() => handleClick('teacher')} disabled={vm.isLoading}>
                Преподаватель
            </button>
        </div>
    );
});

const styles = {
    center: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
    }
};