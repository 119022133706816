import { makeAutoObservable } from "mobx";
import { WordSearchInteractor } from "./WordSearchInteractor";
import { Exercise } from "../../../../../domain/models";
import { PracticeSessionDelegate } from "../../../../../domain/Exercises/PracticeSessionDelegate";
import { WordSearchUIHandler } from "./WordSearchUIHandler";

export class WordSearchViewModel {
    interactor: WordSearchInteractor;

    constructor(
        exercise: Exercise,
        delegate?: PracticeSessionDelegate,
        uiHandler?: WordSearchUIHandler
    ) {
        if (exercise.type.kind !== "wordSearch") {
            throw new Error("Передано упражнение не типа 'wordSearch'");
        }
        // Извлекаем данные для игры из exercise.type.data
        const { words, grid } = exercise.type.data;
        const gridSize = { rowsCount: 10, columnsCount: 10 };

        this.interactor = new WordSearchInteractor(words, gridSize, delegate, grid, uiHandler);
        makeAutoObservable(this);
    }

    // --- Геттеры для доступа к данным интерактора ---
    get game() {
        return this.interactor.game;
    }

    get selectionState() {
        return this.interactor.selectionState;
    }

    get actionButtonState(): "hint" | "next" {
        return this.interactor.actionButtonState;
    }

    // --- Методы для делегирования действий в интерактор ---
    markAllFound() {
        this.interactor.markAllFound();
    }

    goToNext = () => {
        this.interactor.goToNext();
    }

    hint = () => {
        this.interactor.hint();
    }
}