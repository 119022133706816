// ---- src/ui/screens/confirmTutor/ConfirmTutorInteractor.ts ----

import CompositionRoot from "../../../../compositionRoot";
import {AppUser, Student, Tutor} from "../../../../domain/models";

export default class ConfirmTutorInteractor {
    /**
     * Подписываемся на изменения текущего пользователя через AppUserManager.
     * Возвращаем функцию для отписки.
     */
    subscribeToCurrentUser(callback: (user: AppUser | null) => void): () => void {
        const appUserManager = CompositionRoot.getAppUserManager();
        const publisher = appUserManager.currentUserPublisher();
        const subscription = publisher.subscribe(callback);
        return () => subscription.unsubscribe();
    }

    async getTutorInfo(tutorId: string): Promise<AppUser | null> {
        const userStorage = CompositionRoot.getUserStorage();
        return userStorage.getUserData(tutorId);
    }

    /**
     * Подтверждаем связь между студентом (studentId) и преподавателем (tutorId).
     * 1) Грузим UserData для них из Firestore
     * 2) Формируем объекты Tutor и Student
     * 3) Вызываем confirmTutor(tutor, student) у userStorage
     */
    async confirmTutor(tutorId: string, studentId: string): Promise<void> {
        const userStorage = CompositionRoot.getUserStorage();

        const tutorUserData = await userStorage.getUserData(tutorId);
        if (!tutorUserData) {
            throw new Error("Tutor user data not found");
        }

        const studentUserData = await userStorage.getUserData(studentId);
        if (!studentUserData) {
            throw new Error("Student user data not found");
        }

        const tutor: Tutor = {
            id: tutorUserData.uid,
            email: tutorUserData.email || "",
            addDate: new Date(),
            isConfirmed: false,
        };

        const student: Student = {
            id: studentUserData.uid,
            email: studentUserData.email || "",
            addDate: new Date(),
            isConfirmed: false,
        };

        await userStorage.confirmTutor(tutor, student);
    }
}