// LessonPracticeContainerViewModel.ts
import { makeAutoObservable, runInAction } from "mobx";
import CompositionRoot from "../../../../compositionRoot";
import { Lesson } from "../../../../domain/models";
import {SessionInfo} from "../ARefactor/Models/SessionInfo";

export class LessonPracticeContainerViewModel {
    isLoading = true;
    lesson: Lesson | null = null;
    error: string | null = null;

    sessionInfos: SessionInfo[] = [];
    studentSessionId: string | null = null;
    selectedSessionId: string | null = null;

    private readonly lessonId: string;
    private exercisesStorage = CompositionRoot.getExercisesStorage();
    private sessionInfoRepo = CompositionRoot.sessionInfoRepository;
    private userManager = CompositionRoot.getAppUserManager();

    constructor(lessonId: string) {
        makeAutoObservable(this);
        this.lessonId = lessonId;
        this.initData();
    }

    private async initData() {
        try {
            if (!this.lessonId) {
                runInAction(() => {
                    this.error = "No lessonId in URL";
                    this.isLoading = false;
                });
                return;
            }

            const loadedLesson = await this.exercisesStorage.getExerciseSet(this.lessonId);

            runInAction(() => {
                this.lesson = loadedLesson;
            });

            const user = this.userManager.currentUser;
            if (!user) {
                runInAction(() => {
                    this.error = "User not authenticated";
                    this.isLoading = false;
                });
                return;
            }

            // Если пользователь – автор урока: учительский сценарий
            if (loadedLesson && loadedLesson.authorId === user.uid) {
                const sList = await this.sessionInfoRepo.getSessionsByLesson(this.lessonId);
                runInAction(() => {
                    this.sessionInfos = sList;
                });
            } else {
                // Если пользователь – ученик, создаём / получаем единственную сессию
                const sid = await this.sessionInfoRepo.createOrGetSession(this.lessonId, user.uid);
                runInAction(() => {
                    this.studentSessionId = sid;
                });
            }
        } catch (err) {
            console.error("initData error:", err);
            runInAction(() => {
                this.error = "initData error";
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    selectSession(sessionId: string) {
        this.selectedSessionId = sessionId;
    }
}