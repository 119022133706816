import React from "react";
import { observer } from "mobx-react-lite";
import { WordSearchViewModel } from "./WordSearchViewModel";
import { WordSearchGameView } from "./WordSearchGameView";

interface PracticeWordSearchViewProps {
    vm: WordSearchViewModel;
}

export const PracticeWordSearchView: React.FC<PracticeWordSearchViewProps> = observer(
    ({ vm }) => {
        return <WordSearchGameView viewModel={vm} />;
    }
);