// PracticeAnagramView.swift -> PracticeAnagramView.tsx
import React from 'react';
import { AnagramLearnViewModel } from './AnagramLearnViewModel';
import {observer} from "mobx-react-lite";
import {AnagramViewBase} from "../Base/AnagramViewBase";

interface PracticeAnagramViewProps {
    vm: AnagramLearnViewModel;
}

export const PracticeAnagramView: React.FC<PracticeAnagramViewProps> = observer(({ vm }) => {
    return (
        <AnagramViewBase
            displayedWord={vm.displayedWord}
            currentAnswer={Array.from(vm.state.currentAnswer)}
            scrambledLetters={Array.from(vm.state.scrambledLetters)}
            onLetterTap={(index) => vm.selectLetter(index)}
            onAnswerTap={(index) => vm.removeLetterFromAnswer(index)}
        />
    );
});