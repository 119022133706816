import React from "react";

const floatingPlusButtonStyle: React.CSSProperties = {
    position: "absolute",
    right: 16,
    bottom: 40,
    width: 60,
    height: 60,
    borderRadius: 30,
    backgroundColor: "blue",
    color: "white",
    fontSize: 24,
    border: "none",
    boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
    cursor: "pointer",
};

interface FloatingPlusButtonProps {
    onClick: () => void;
}

export const FloatingPlusButton: React.FC<FloatingPlusButtonProps> = ({ onClick }) => {
    return (
        <button style={floatingPlusButtonStyle} onClick={onClick}>
            +
        </button>
    );
};