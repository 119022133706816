// ========= ./Base/ExerciseCrossword.ts ==========

import { PlacedWord } from './PlacedWord';
import {Position, WordItem} from "../../../../../domain/models";

export type CGFloat = number;

// Swift's ExerciseCrossword struct
// We'll store rowsCount, columnsCount, flattenedGrid similarly
export class ExerciseCrossword {
    public words: WordItem[];
    private rowsCount: number;
    private columnsCount: number;
    private flattenedGrid: string[];
    public placedWords: PlacedWord[];
    public occupiedPositions: Set<Position>;
    public startPositions: { [key: string]: number };

    constructor(
        words: WordItem[],
        grid: string[][],
        placedWords: PlacedWord[],
        occupiedPositions: Set<Position>,
        startPositions: { [key: string]: number }
    ) {
        this.words = words;
        this.rowsCount = grid.length;
        this.columnsCount = grid.length > 0 ? grid[0].length : 0;
        this.flattenedGrid = grid.flat();
        this.placedWords = placedWords;
        this.occupiedPositions = occupiedPositions;
        this.startPositions = startPositions;
    }

    get grid(): string[][] {
        const result: string[][] = [];
        for (let i = 0; i < this.rowsCount; i++) {
            const start = i * this.columnsCount;
            const end = start + this.columnsCount;
            const row = this.flattenedGrid.slice(start, end);
            result.push(row);
        }
        return result;
    }
}

// Helper function for converting Position -> "row,col" string and back
function positionKey(pos: Position): string {
    return `${pos.row},${pos.col}`;
}

function parsePositionKey(key: string): Position {
    const [r, c] = key.split(',').map(Number);
    return { row: r, col: c };
}

export class ExerciseCrosswordMapping {
    // Serialization to a compact dictionary
    static async toDictionary(cw: ExerciseCrossword): Promise<{ [key: string]: any }> {
        // placedWords
        const placedWordsArray = cw.placedWords.map((pw) => {
            return {
                word: { ...pw.word }, // naive shallow copy
                horizontal: pw.horizontal,
                startRow: pw.startRow,
                startCol: pw.startCol,
                wordIndex: pw.wordIndex,
            };
        });

        return {
            words: cw.words.map((w) => ({ ...w })), // naive copy
            placedWords: placedWordsArray,
        };
    }

    // Init from dictionary
    static async fromDictionary(
        dictionary: { [key: string]: any }
    ): Promise<ExerciseCrossword | null> {
        const wordsData = dictionary['words'] as { [key: string]: any }[];
        const placedWordsData = dictionary['placedWords'] as { [key: string]: any }[];

        if (!wordsData || !placedWordsData) {
            return null;
        }
        const words: WordItem[] = wordsData.map((wd) => {
            return {
                id: wd.id,
                word: wd.word,
                translation: wd.translation,
                example: wd.example,
                repetitions: wd.repetitions,
                imageURL: wd.imageURL,
            };
        });

        const placedWords: PlacedWord[] = placedWordsData.map((dict) => {
            return {
                horizontal: dict.horizontal,
                startRow: dict.startRow,
                startCol: dict.startCol,
                wordIndex: dict.wordIndex,
                word: {
                    id: dict.word.id,
                    word: dict.word.word,
                    translation: dict.word.translation,
                    example: dict.word.example,
                    repetitions: dict.word.repetitions,
                    imageURL: dict.word.imageURL,
                },
            };
        });

        // Rebuild positions from placedWords
        const allPositions: Position[] = [];
        placedWords.forEach((pw) => {
            const length = pw.word.word.length;
            for (let i = 0; i < length; i++) {
                const pos: Position = {
                    row: pw.startRow + (pw.horizontal ? 0 : i),
                    col: pw.startCol + (pw.horizontal ? i : 0),
                };
                allPositions.push(pos);
            }
        });

        if (allPositions.length === 0) {
            return null;
        }

        const minRow = Math.min(...allPositions.map((p) => p.row));
        const maxRow = Math.max(...allPositions.map((p) => p.row));
        const minCol = Math.min(...allPositions.map((p) => p.col));
        const maxCol = Math.max(...allPositions.map((p) => p.col));
        const rowsCount = maxRow - minRow + 1;
        const columnsCount = maxCol - minCol + 1;

        const grid: string[][] = Array.from({ length: rowsCount }, () =>
            Array.from({ length: columnsCount }, () => '')
        );
        const occupiedPositions = new Set<Position>();
        const startPositions: { [key: string]: number } = {};

        // Fill them with empty cells
        for (const pw of placedWords) {
            const chars = Array.from(pw.word.word.toUpperCase());
            for (let i = 0; i < chars.length; i++) {
                const rr = pw.startRow + (pw.horizontal ? 0 : i);
                const cc = pw.startCol + (pw.horizontal ? i : 0);
                const localRow = rr - minRow;
                const localCol = cc - minCol;
                // Mark it as empty in the final grid (like original Swift code)
                grid[localRow][localCol] = '';
                occupiedPositions.add({ row: rr, col: cc });
            }
            startPositions[`${pw.startRow},${pw.startCol}`] = pw.wordIndex;
        }

        const exercise = new ExerciseCrossword(words, grid, placedWords, occupiedPositions, startPositions);
        return exercise;
    }
}