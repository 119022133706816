import { Exercise, ExerciseFillBlanks, WordItem } from "../../../domain/models";
import { serializeWordItem, deserializeWordItem } from "./WordItemSerializer";

export function serializeFillBlanks(exercise: Exercise): any {
    if (exercise.type.kind !== 'fillBlanks') {
        throw new Error("serializeAnagram called with non-anagram exercise");
    }

    const fb: ExerciseFillBlanks = exercise.type.data;
    const wordsArray = fb.words.map(w => serializeWordItem(w));

    return {
        type: 'fillBlanks',
        authorId: exercise.authorId,
        description: exercise.description,
        text: fb.text,
        words: wordsArray,
        audioURL: fb.audioURL || null
    };
}

export function deserializeFillBlanks(data: any, id: string): Exercise | null {
    const { text, words, audioURL } = data;
    if (typeof text !== 'string' || !Array.isArray(words)) {
        return null;
    }

    const wordItems: WordItem[] = words
        .map((w: any) => deserializeWordItem(w))
        .filter((wi: WordItem | null): wi is WordItem => wi !== null);

    return {
        id,
        authorId: data.authorId,
        description: data.description,
        type: {
            kind: 'fillBlanks',
            data: {
                text,
                words: wordItems,
                audioURL: audioURL || undefined
            }
        }
    };
}
