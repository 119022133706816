import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { ClassroomViewModel } from "./ClassroomViewModel";
import { PracticeExerciseContent } from "../PracticeExerciseContent";
import { LessonWordsContainer } from "../../LessonWords/logic/LessonWordsContainer";
import BaseButton, { ButtonVariant } from "../../BaseButton";
import { PracticeStateFactory } from "../PracticeStateFactory";
import { PracticeStateUI } from "../PracticeStateUI";
import { ClassroomEditor } from "./ClassroomEditor";
import { LearningPlanEditorViewModel } from "../../PracticeManagement/UI/StudentLearningPlan/LearningPlanEditorViewModel";
import { LearningPlanEditor } from "../../PracticeManagement/UI/StudentLearningPlan/LearningPlanEditor";

const Classroom: React.FC = observer(() => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [isEditorVisible, setIsEditorVisible] = useState(false);

    const [vm] = React.useState(() => new ClassroomViewModel(id!));
    const [exerciseStates, setExerciseStates] = useState<PracticeStateUI[]>([]);
    const [practiceStateFactory] = useState(() => new PracticeStateFactory(
        vm.interactor.sessionStateInteractor,
        vm.interactor
    ));

    const [planEditorVM, setPlanEditorVM] = useState<LearningPlanEditorViewModel | null>(null);

    // Create states for all exercises whenever the lesson changes
    useEffect(() => {
        if (vm.lesson && vm.lesson.exercises) {
            const states = vm.lesson.exercises.map(exercise => {
                return practiceStateFactory.create(exercise);
            });
            setExerciseStates(states);
        }
    }, [vm.lesson, practiceStateFactory]);

    useEffect(() => {
        if (vm.teacherId && vm.studentId) {
            setPlanEditorVM(
                new LearningPlanEditorViewModel(vm.teacherId, vm.studentId)
            );
        }
    }, [vm.teacherId, vm.studentId]);

    // Main container styles
    const containerStyle: React.CSSProperties = {
        display: "grid",
        gridTemplateColumns: "1fr auto",
        gridTemplateRows: "1fr",
        height: "100vh",
        width: "100%",
        position: "relative",
        overflow: "hidden"
    };

    // Left column (exercises) styles
    const exercisesColumnStyle: React.CSSProperties = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowY: "auto",
        padding: "20px 0",
        width: "100%",
        position: "relative"
    };

    // Exercises container styles
    const exercisesContainerStyle: React.CSSProperties = {
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        width: "100%",
        marginBottom: "40px"
    };

    // Right column (LessonWordsContainer) styles
    const rightColumnStyle: React.CSSProperties = {
        width: "300px",
        height: "100%",
        borderLeft: "1px solid #ccc",
        display: "flex",
        flexDirection: "column",
        padding: "0 0 20px 0"
    };

    // Stop button container styles
    const stopButtonContainerStyle: React.CSSProperties = {
        padding: "16px",
        display: "flex",
        justifyContent: "center",
        marginTop: "auto"
    };

    // Calculate height for LessonWordsContainer
    const calculateLessonWordsHeight = () => {
        return window.innerHeight - 80;
    };

    // Toggle button styles
    const toggleButtonStyle: React.CSSProperties = {
        position: "fixed",
        bottom: isEditorVisible ? "280px" : "-10px",
        left: "20px",
        transform: "translateX(-50%)",
        zIndex: 1000,
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        backgroundColor: "#2196f3",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
        transition: "bottom 0.3s ease-in-out",
        border: "none"
    };

    // Learning plan editor container styles
    const learningPlanEditorContainerStyle: React.CSSProperties = {
        position: "fixed",
        bottom: isEditorVisible ? "0" : "-300px",
        left: "0",
        width: "100%",
        backgroundColor: "var(--modal-bg)",
        boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
        transition: "bottom 0.3s ease-in-out",
        zIndex: 999,
        height: "300px",
        display: "flex",
        flexDirection: "column",
        padding: "0px",
        overflow: "auto"
    };

    // Arrow icon for toggle button
    const ArrowIcon = ({ direction }: { direction: 'up' | 'down' }) => (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                transform: direction === 'up' ? 'rotate(0deg)' : 'rotate(180deg)',
                transition: 'transform 0.3s ease'
            }}
        >
            <path
                d="M8 3L15 10H1L8 3Z"
                fill="white"
            />
        </svg>
    );

    return (
        <div style={containerStyle}>
            {/* Left column - Exercises and ClassroomEditor */}
            <div style={exercisesColumnStyle}>
                <div style={exercisesContainerStyle}>
                    {exerciseStates.map((state, index) => (
                        <div key={`exercise-${index}`}>
                            <PracticeExerciseContent practiceState={state} />
                        </div>
                    ))}
                </div>

                {planEditorVM && (
                    <div style={{ width: "800px", marginTop: "30px", marginBottom: "60px" }}>
                        <ClassroomEditor
                            viewModel={planEditorVM}
                            onExercisePress={(index) => vm.goToExercise(index)}
                        />
                    </div>
                )}
            </div>

            {/* Right column - LessonWordsContainer and Stop button */}
            <div style={rightColumnStyle}>
                {/* LessonWordsContainer takes the available space */}
                <div style={{ flex: 1, overflow: "auto" }}>
                    {vm.studentId && (
                        <LessonWordsContainer
                            sessionId={id!}
                            studentId={vm.studentId}
                            height={calculateLessonWordsHeight()}
                        />
                    )}
                </div>

                {/* Stop button at the bottom */}
                <div style={stopButtonContainerStyle}>
                    <BaseButton
                        variant={ButtonVariant.Classic}
                        color="#dc3545"
                        onClick={async () => {
                            await vm.stopClass();
                            navigate("/");
                        }}
                    >
                        Stop class
                    </BaseButton>
                </div>
            </div>

            {/* Toggle button for Learning Plan Editor */}
            <button
                style={toggleButtonStyle}
                onClick={() => setIsEditorVisible(!isEditorVisible)}
                aria-label={isEditorVisible ? "Hide learning plan editor" : "Show learning plan editor"}
            >
                <ArrowIcon direction={isEditorVisible ? 'down' : 'up'} />
            </button>

            {/* Sliding Learning Plan Editor */}
            {planEditorVM && (
                <div style={learningPlanEditorContainerStyle}>
                    <LearningPlanEditor
                        viewModel={planEditorVM}
                        onExercisePress={(index) => vm.goToExercise(index)}
                    />
                </div>
            )}
        </div>
    );
});

export default Classroom;