// src/components/navigation/ScreenForType.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddMultipleWordsView from '../words/adding/AddMultipleWordsView';
import AddWordsByThemeView from '../words/adding/AddWordsByThemeView';
import { WordItem } from '../../domain/models/WordItem';
import WordsToAddPreview from "../words/adding/WordsToAddPreview";
import CompositionRoot from "../../compositionRoot";
import WordsViewModel from "../../ui/viewmodels/WordsViewModel";
import LearningStatisticsView from "../words/statistics/LearningStatisticsView";
import {LearnWordsContainerView} from "../words/learning/LearnWordsContainerView";

type ScreenType = 'mainStat' | 'mainAdd' | 'addFromTextEnteringText' | 'addByTheme' | 'addMultiple' | 'addManually' | 'mainStudy';

interface ScreenForTypeProps {
    screen: ScreenType;
}

const ScreenForType: React.FC<ScreenForTypeProps> = ({ screen }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const wordsViewModel = new WordsViewModel(CompositionRoot.getWordsService());

    const mockWords: WordItem[] = [
        { id: '1', word: 'hello', translation: 'привет', example: 'Hello, how are you?', repetitions: [], imageURL: '' },
        { id: '2', word: 'world', translation: 'мир', example: 'The world is beautiful.', repetitions: [], imageURL: '' },
    ];

    const handleMockFetch = (callback: () => void) => {
        setIsLoading(true);
        setTimeout(() => {
            callback();
            setIsLoading(false);
        }, 1000);
    };

    const fetchWordsByTheme = (theme: string) => {
        handleMockFetch(() => {
            navigate('/addManually', { state: { words: mockWords } });
        });
    };

    switch (screen) {
        case "mainStudy":
            return (
                <LearnWordsContainerView
                    wordsService={ CompositionRoot.getWordsService() }
                />
            );
        case 'addMultiple':
            console.log("screen for type addMultiple")
            return <AddMultipleWordsView />;
        case 'addFromTextEnteringText':
            return <div>addFromTextEnteringText</div>;
        case 'addByTheme':
            return (
                <AddWordsByThemeView
                    onFetchWordsByTheme={fetchWordsByTheme}
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                />
            );
        case 'addManually':
            return (
                <WordsToAddPreview
                    onSave={(newWords) => {
                        wordsViewModel.addWords(newWords);
                        navigate('/');
                    }}
                    onDelay={(newWords) => {
                        wordsViewModel.delayWords(newWords);
                        navigate('/');
                    }}
                />
            );
        case 'mainStat':
            return  (
                <LearningStatisticsView
                    wordsViewModel={wordsViewModel}
                />
            );
        default:
            return <div>Выберите экран</div>;
    }
};

export default ScreenForType;
