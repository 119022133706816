import {CanvasItem} from "./CanvasItem";

export function findFreePosition(
    items: CanvasItem[],
    startX: number,
    startY: number
): { x: number; y: number } {
    let x = startX;
    let y = startY;

    // Пока есть элемент, чьи координаты совпадают, сдвигаем еще на (10,10).
    while (items.some((it) => it.x === x && it.y === y)) {
        x += 10;
        y += 10;
    }
    return {x, y};
}