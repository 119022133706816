import {
    MissingWordCreateState,
    MissingWordCreateViewModel,
} from "./MissingWordCreateViewModel";
import { observer } from "mobx-react-lite";
import { ExerciseOptions } from "../../../../../domain/models/ExerciseCreate";

interface MissingWordCreateSettingsViewProps {
    vm: MissingWordCreateViewModel;
}
export const MissingWordCreateSettingsView: React.FC<MissingWordCreateSettingsViewProps> = observer(
    ({ vm }) => {
        const handleInputChange = (field: keyof ExerciseOptions, value: string) => {
            vm.setExerciseOptions({ ...vm.exerciseOptions, [field]: value });
        };

        const isGenerating = vm.state === MissingWordCreateState.GeneratingSentences;

        return (
            <div style={{ maxWidth: "500px", padding: "16px" }}>
                <div>
                    <label>Amount</label>
                    <input
                        type="text"
                        value={vm.amount}
                        onChange={(e) => vm.setAmount(e.target.value)}
                    />
                </div>
                <div>
                    <label>Language</label>
                    <input
                        type="text"
                        value={vm.exerciseOptions.learnlang}
                        onChange={(e) => handleInputChange("learnlang", e.target.value)}
                    />
                </div>
                <div>
                    <label>Level</label>
                    <input
                        type="text"
                        placeholder="B1"
                        value={vm.exerciseOptions.level}
                        onChange={(e) => handleInputChange("level", e.target.value)}
                    />
                </div>
                <div>
                    <label>Theme</label>
                    <textarea
                        value={vm.exerciseOptions.theme}
                        onChange={(e) => handleInputChange("theme", e.target.value)}
                        style={{ fontSize: "1.2em", height: "100px", width: "100%" }}
                    />
                </div>
                <div>
                    <label>Comment</label>
                    <textarea
                        value={vm.exerciseOptions.comment}
                        onChange={(e) => handleInputChange("comment", e.target.value)}
                        style={{ fontSize: "1.2em", height: "80px", width: "100%" }}
                    />
                </div>

                {isGenerating ? (
                    <div>Loading...</div>
                ) : (
                    <button onClick={() => vm.generate()}>Generate</button>
                )}

                <div>
                    <p>Prompt:</p>
                    <p style={{ fontSize: "1.2em" }}>{vm.startPrompt}</p>
                </div>
            </div>
        );
    }
);