// src/data/implementations/ExerciseSerializer.ts

import {
    deserializeMissingWord, serializeMissingWord
} from "./MissingWordSerializer";
import {
    deserializeSelectOption, serializeSelectOption
} from "./SelectOptionSerializer";
import {
    deserializeWordSearch, serializeWordSearch
} from "./WordSearchSerializer";
import {
    deserializeAnagram, serializeAnagram
} from "./AnagramSerializer";
import {
    deserializeCrossword, serializeCrossword
} from "./CrosswordSerializer";
import {
    deserializeFillBlanks, serializeFillBlanks
} from "./FillBlanksSerializer";
import {
    deserializeMatchingPairs, serializeMatchingPairs
} from "./MatchingPairsSerializer";
import {
    deserializeJustContent, serializeJustContent
} from "../../../ui/components/CanvasRefactor/Base/network/JustContentSerializer";
import {
    deserializeTable, serializeTable
} from "./TableSerializer";
import {
    deserializeHangman, serializeHangman
} from "./HangmanSerializer";
import {Exercise} from "../../../domain/models";
import {deserializeFree, serializeFree} from "./FreeSerializer";

/**
 * Общая функция сериализации упражнения.
 * Возвращает PlainObject, готовый для сохранения в Firestore.
 */
export function serializeExercise(exercise: Exercise): any {
    switch (exercise.type.kind) {
        case "missingWord":
            return serializeMissingWord(exercise);
        case "selectOption":
            return serializeSelectOption(exercise);
        case "wordSearch":
            return serializeWordSearch(exercise);
        case "anagram":
            return serializeAnagram(exercise);
        case "crossword":
            return serializeCrossword(exercise);
        case "fillBlanks":
            return serializeFillBlanks(exercise);
        case "matchingPairs":
            return serializeMatchingPairs(exercise);
        case "justContent":
            return serializeJustContent(exercise);
        case "table":
            return serializeTable(exercise);
        case "hangman":
            return serializeHangman(exercise);
        case "free":
            return serializeFree(exercise);
        default:
            throw new Error("Unknown ExerciseType kind");
    }
}

/**
 * Общая функция десериализации упражнения (из данных Firestore).
 */
export function deserializeExercise(data: any, id: string): Exercise | null {
    const type = data.type;
    if (!type) return null;

    switch (type) {
        case "missingWord":
            return deserializeMissingWord(data, id);
        case "selectOption":
            return deserializeSelectOption(data, id);
        case "wordSearch":
            return deserializeWordSearch(data, id);
        case "anagram":
            return deserializeAnagram(data, id);
        case "crossword":
            return deserializeCrossword(data, id);
        case "fillBlanks":
            return deserializeFillBlanks(data, id);
        case "matchingPairs":
            return deserializeMatchingPairs(data, id);
        case "justContent":
            return deserializeJustContent(data, id);
        case "table":
            return deserializeTable(data, id);
        case "hangman":
            return deserializeHangman(data, id);
        case "free":
            return deserializeFree(data, id);
        default:
            return null;
    }
}