// ZoomControls.tsx
import React from 'react';

interface ZoomControlsProps {
    zoomLevel: number;
    onZoomIn: () => void;
    onZoomOut: () => void;
    onResetZoom: () => void;
}

const ZoomControls: React.FC<ZoomControlsProps> = ({
                                                       zoomLevel,
                                                       onZoomIn,
                                                       onZoomOut,
                                                       onResetZoom
                                                   }) => {
    return (
        <div
            style={{
                position: 'absolute',
                bottom: '10px',
                right: '10px',
                display: 'flex',
                alignItems: 'center',
                background: 'rgba(249, 249, 249, 0.9)',
                padding: '4px 8px',
                borderRadius: '4px',
                border: '1px solid #ccc',
                zIndex: 100,
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
            }}
        >
            <button
                onClick={onZoomOut}
                style={{
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '8px',
                    cursor: 'pointer',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    background: 'white'
                }}
                title="Уменьшить"
            >
                −
            </button>

            <div
                style={{
                    color: '#666',
                    minWidth: '50px',
                    textAlign: 'center',
                    fontFamily: 'sans-serif',
                    fontSize: '14px'
                }}
            >
                {Math.round(zoomLevel * 100)}%
            </div>

            <button
                onClick={onZoomIn}
                style={{
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '8px',
                    cursor: 'pointer',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    background: 'white'
                }}
                title="Увеличить"
            >
                +
            </button>
        </div>
    );
};

export default ZoomControls;