// WordSearchViewable.tsx

import React from "react";
import { Resizer } from "../../../Resizer";
import { ExerciseViewable } from "../../ExerciseViewable";
import {Exercise, ExerciseWordSearch} from "../../../../../domain/models";
import {PracticeWordSearchView} from "../Practice/PracticeWordSearchView";
import {WordSearchViewModel} from "../Practice/WordSearchViewModel";

/**
 * Класс, реализующий интерфейс ExerciseViewable для упражнений типа "wordSearch".
 * Аналог ExerciseCrosswordViewable.
 */
export class WordSearchViewable implements ExerciseViewable {
    constructor(private exerciseWordSearch: ExerciseWordSearch) {}

    renderView(exercise: Exercise, width?: number, height?: number): JSX.Element {
        if (exercise.type.kind !== "wordSearch") {
            throw new Error("Unsupported exercise type for WordSearchViewable");
        }
        const targetWidth = width ?? 300;
        const targetHeight = height ?? 350;

        // Создаем вью-модель без делегата и без UI-хендлера (для простого просмотра)
        const vm = new WordSearchViewModel(exercise);

        return (
            <Resizer targetSize={{ width: targetWidth, height: targetHeight }}>
                <div style={{ width: 800, height: 500 }}>
                    <PracticeWordSearchView vm={vm} />
                </div>
            </Resizer>
        );
    }
}