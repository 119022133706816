// MatchingPairsViewable.tsx
import React from 'react';
import { ExerciseViewable } from '../../ExerciseViewable';
import { MatchingPairsExerciseViewModel } from '../Practice/MatchingPairsExerciseViewModel';
import { MatchingPairsExerciseView } from '../Practice/MatchingPairsExerciseView';
import { BaseView } from '../../BaseComponents/BaseView';
import {Exercise, ExerciseMatchingPairs} from "../../../../../domain/models";
import {
    MatchingPairsLearnInteractor
} from "../../../../../domain/Exercises/MatchingPairs/Practice/MatchingPairsLearnInteractor";
import {Resizer} from "../../../Resizer";

// Аналог AnyView - просто возвращаем JSX.Element.
// В Swift есть frame(height: 400), в вебе можно указать высоту через пропы или стили.

export class ExerciseMatchingPairsViewable implements ExerciseViewable {
    private exercise: ExerciseMatchingPairs;
    constructor(exercise: ExerciseMatchingPairs) {
        this.exercise = exercise;
    }

    renderView(exercise: Exercise, width?: number, height?: number): JSX.Element {
        const vm = new MatchingPairsExerciseViewModel(exercise, undefined, undefined, undefined);
        const targetWidth = width ?? 300;
        const targetHeight = height ?? 150;

        return (
            <Resizer targetSize={{ width: targetWidth, height: targetHeight }}>
                <MatchingPairsExerciseView viewModel={vm} />
            </Resizer>
        );
    }
}
