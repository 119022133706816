// AnagramLearnViewModel.ts
import { makeAutoObservable } from "mobx";
import { AnagramInteractor } from "./AnagramInteractor";
import { AnagramState } from "./AnagramState";
import {Exercise, WordItem} from "../../../../../domain/models";
import {PracticeSessionDelegate} from "../../../../../domain/Exercises/PracticeSessionDelegate";
import {AnagramUIHandler} from "./AnagramUIHandler";
import {AnagramUseCase} from "./AnagramUseCase";

export class AnagramLearnViewModel {
    private interactor: AnagramInteractor;
    private _state: AnagramState;

    public onStateChange?: (newState: AnagramState) => void;

    constructor(
        exercise: Exercise,
        delegate?: PracticeSessionDelegate,
        uiHandler?: AnagramUIHandler,
        useCase?: AnagramUseCase
    ) {
        this.interactor = new AnagramInteractor(
            exercise,
            delegate,
            uiHandler,
            useCase
        );
        this._state = this.interactor.state;

        makeAutoObservable(this);

        this.interactor.onStateChange = (st) => {
            this._state = st;
            this.onStateChange?.(st);
        };
    }

    get state(): AnagramState {
        return this._state;
    }

    get displayedWord() {
        return this._state.displayedWord;
    }

    get currentAnswer() {
        return this._state.currentAnswer;
    }

    get scrambledLetters() {
        return this._state.scrambledLetters;
    }

    selectLetter(index: number) {
        this.interactor.selectLetter(index);
    }

    removeLetterFromAnswer(index: number) {
        this.interactor.removeLetterFromAnswer(index);
    }

    goNext() {
        this.interactor.goNext();
    }
}