// ./ui/components/exercises_types/FillBlanks/Previews/FillBlanksTypePreview.tsx

import React from "react";
import { Resizer } from "../../../Resizer";
import { ExerciseFillBlanks, FillBlanksCreationType } from "../../../../../domain/models";
import {FillBlanksViewBase, FillBlanksPlaceholderData} from "../Base/FillBlanksViewBase";

/**
 * Небольшая функция, которая создаёт моковые данные для превью.
 */
function createMockFillBlanksData(): ExerciseFillBlanks {
    return {
        words: [], // Для превью можно не заполнять или добавить символические слова
        text: "Necesito [agujeros] para [colgar] mis cuadros. ¿De qué color te gustan más? " +
            "¿[Violeta], [lila] o [celeste]? Me gusta mucho el [lila], es un color muy relajante. " +
            "¿Sabes cómo [amasar] arcilla? No, nunca lo he intentado.",
        creationType: "generated" as FillBlanksCreationType,
        audioURL: undefined,
    };
}

function createMockPlaceholders(): FillBlanksPlaceholderData[] {
    return [
        { id: 0, userInput: "", isCorrect: false, correctWord: "agujeros" },
        { id: 1, userInput: "", isCorrect: false, correctWord: "colgar" },
        { id: 2, userInput: "", isCorrect: false, correctWord: "Violeta" },
        { id: 3, userInput: "", isCorrect: false, correctWord: "lila" },
        { id: 4, userInput: "", isCorrect: false, correctWord: "celeste" },
        { id: 5, userInput: "", isCorrect: false, correctWord: "lila" },
        { id: 6, userInput: "", isCorrect: false, correctWord: "amasar" },
    ];
}

export function FillBlanksTypePreview() {
    const mockData = createMockFillBlanksData();
    const placeholders = createMockPlaceholders();

    return (
        <Resizer targetSize={{ width: 800, height: 150 }}>
            <div style={{ width: "800px", height: "500px", alignContent: "center" }}>
                <FillBlanksViewBase
                    text={mockData.text}
                    placeholders={placeholders}
                />
            </div>
        </Resizer>
    );
}