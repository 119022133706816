// ----------------- src/ui/screens/students/StudentsScreenInteractor.ts -----------------
import CompositionRoot from '../../../../compositionRoot';
import {AppUser} from '../../../../domain/models/AppUser';
import {CombinedStudent} from "../CombinedStudent";

export default class StudentsScreenInteractor {
    /**
     * Подписываемся на изменения текущего пользователя через AppUserManager.
     */
    subscribeToCurrentUser(callback: (user: AppUser | null) => void): () => void {
        const appUserManager = CompositionRoot.getAppUserManager();
        const publisher = appUserManager.currentUserPublisher();
        const subscription = publisher.subscribe(callback);
        return () => subscription.unsubscribe();
    }
    //
    // /**
    //  * Загружаем список студентов и для каждого подгружаем AppUser-данные.
    //  */
    // async loadStudents(userId: string): Promise<CombinedStudent[]> {
    //     const userStorage = CompositionRoot.getUserStorage();
    //     const students = await userStorage.getStudents(userId);
    //
    //     const result: CombinedStudent[] = [];
    //     for (const student of students) {
    //         const userData = await userStorage.getUserData(student.id);
    //         result.push({ student, userData });
    //     }
    //     return result;
    // }

    subscribeToStudents(
        userId: string,
        callback: (students: CombinedStudent[]) => void,
        errorCallback?: (error: Error) => void
    ): () => void {
        const userStorage = CompositionRoot.getUserStorage();

        // 1) Подписываемся на коллекцию студентов
        return userStorage.observeStudents(
            userId,
            async (students) => {
                // 2) Для каждого студента подгружаем AppUser
                const result: CombinedStudent[] = [];
                for (const student of students) {
                    const userData = await userStorage.getUserData(student.id);
                    result.push({ student, userData });
                }
                callback(result);
            },
            errorCallback
        );
    }

}