// src/ui/components/settings/auth/SignedUserView.tsx
import React from 'react';
import { useAuth } from '../../../ui/context/AuthContext';

const SignedUserView: React.FC = () => {
    const { currentUser, logout } = useAuth();

    return (
        <div style={{ padding: '10px' }}>
            {currentUser ? (
                <div>
                    <p>Logged in as {currentUser.email || "Anonymous"}</p>
                    <p>ID: {currentUser.uid}</p>
                    <p>Status: {currentUser.status || "no status"}</p>
                    <button onClick={logout}>Sign Out</button>
                </div>
            ) : (
                <p>No user logged in</p>
            )}
        </div>
    );
};

export default SignedUserView;
