// src/context/AuthContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import {AppUser} from "../../domain/models";
import {AppUserManagerProtocol} from "../../domain/interfaces/AppUserManagerProtocol";
import CompositionRoot from "../../compositionRoot";

interface AuthContextType {
    currentUser: AppUser | null;
    loading: boolean;
    login: (email: string, password: string) => Promise<void>;
    register: (email: string, password: string) => Promise<void>;
    signInWithGoogle: () => Promise<void>;
    signInWithApple: () => Promise<void>;
    signUpWithApple: () => Promise<void>;
    logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const appUserManager: AppUserManagerProtocol = CompositionRoot.getAppUserManager();

    const [currentUser, setCurrentUser] = useState<AppUser | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Подписываемся на поток текущего пользователя из AppUserManager
        const subscription = appUserManager.currentUserPublisher().subscribe((user: any) => {
            setCurrentUser(user);
            setLoading(false);
        });
        return () => subscription.unsubscribe();
    }, [appUserManager]);

    // Просто пробрасываем методы менеджера:
    const contextValue: AuthContextType = {
        currentUser,
        loading,
        login: appUserManager.loginUser.bind(appUserManager),
        register: appUserManager.registerUser.bind(appUserManager),
        signInWithGoogle: appUserManager.signInWithGoogle.bind(appUserManager),
        signInWithApple: appUserManager.signInWithApple.bind(appUserManager),
        signUpWithApple: appUserManager.signUpWithApple.bind(appUserManager),
        logout: appUserManager.signOut.bind(appUserManager),
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {/* Можно показать children даже если loading=true, решайте сами */}
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within AuthProvider');
    }
    return context;
};