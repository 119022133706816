import { Exercise, ExerciseSelectOption } from "../../../domain/models";

export function serializeSelectOption(exercise: Exercise): any {
    if (exercise.type.kind !== 'selectOption') {
        throw new Error("serializeAnagram called with non-anagram exercise");
    }

    const so: ExerciseSelectOption = exercise.type.data;
    return {
        type: 'selectOption',
        authorId: exercise.authorId,
        description: exercise.description,
        sentence: so.sentence,
        correctOption: so.correctOption,
        option1: so.option1,
        option2: so.option2,
        option3: so.option3,
        option4: so.option4
    };
}

export function deserializeSelectOption(data: any, id: string): Exercise | null {
    const { sentence, correctOption, option1, option2, option3, option4 } = data;
    if (
        typeof sentence !== 'string' ||
        typeof correctOption !== 'string' ||
        typeof option1 !== 'string' ||
        typeof option2 !== 'string' ||
        typeof option3 !== 'string' ||
        typeof option4 !== 'string'
    ) {
        return null;
    }
    return {
        id,
        authorId: data.authorId,
        description: data.description,
        type: {
            kind: 'selectOption',
            data: {
                sentence,
                correctOption,
                option1,
                option2,
                option3,
                option4
            }
        }
    };
}