import {Lesson} from "../../../../domain/models";
import {SaveExerciseInteractor} from "../../../../domain/interfaces/SaveExerciseInteractor";

export interface LessonServiceProtocol {
    saveLesson(lesson: Lesson): void;
}

export class LessonService implements LessonServiceProtocol {
    private saveInteractor: SaveExerciseInteractor;

    constructor(saveInteractor: SaveExerciseInteractor) {
        this.saveInteractor = saveInteractor;
    }

    saveLesson(lesson: Lesson): void {
        this.saveInteractor.save(lesson);
    }
}