import React from "react";
import BaseButton, {ButtonVariant} from "../../../ui/components/BaseButton";

export const RoleSelection: React.FC<{ onSelectRole: (role: 'teacher' | 'student') => void }> = ({
                                                                                                     onSelectRole,
                                                                                                 }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                gap: '20px',
            }}
        >
            <h2>Please choose your role</h2>
            <BaseButton
                color="#00bcd4"
                variant={ButtonVariant.Big}
                onClick={() => onSelectRole('student')}
            >
                I'm a Student
            </BaseButton>
            <BaseButton
                color="#0070d2"
                variant={ButtonVariant.Big}
                onClick={() => onSelectRole('teacher')}
            >
                I'm a Teacher
            </BaseButton>
        </div>
    );
};