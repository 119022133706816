// ./src/data/implementations/FirestoreExercisesService.ts

import { ExercisesStorage } from '../../domain/interfaces/ExercisesStorage';
import { db } from '../config/firebase';
import {
    doc,
    setDoc,
    getDoc,
    collection,
    getDocs,
    QuerySnapshot,
    Timestamp
} from 'firebase/firestore';

import {
    Exercise,
    Lesson
} from "../../domain/models";
import {deserializeExercise, serializeExercise} from "./FirestoreExercises/ExerciseSerializer";

export class FirestoreExercisesService implements ExercisesStorage {
    constructor() {}

    public async saveExercise(exercise: Exercise): Promise<void> {
        const docRef = doc(db, 'exercises', exercise.id);
        const data = serializeExercise(exercise);
        const sanitizedData = removeUndefinedFields(data);
        await setDoc(docRef, sanitizedData);
    }

    public async getAllExercises(): Promise<Exercise[]> {
        const collectionRef = collection(db, 'exercises');
        const snapshot = await getDocs(collectionRef);
        const exercises: Exercise[] = [];
        snapshot.forEach(docSnap => {
            const data = docSnap.data();
            const ex = deserializeExercise(data, docSnap.id);
            if (ex) exercises.push(ex);
        });
        return exercises;
    }

    public async saveExerciseSet(exerciseSet: Lesson): Promise<void> {
        if (!exerciseSet.authorId) {
            throw new Error('Author ID is required to save ExerciseSet');
        }

        const exerciseSetRef = doc(db, 'exerciseSets', exerciseSet.id);

        const exercisesInfo = exerciseSet.exercises.map(ex => ({
            id: ex.id,
            type: ex.type.kind,
        }));

        const exerciseSetData = {
            id: exerciseSet.id,
            creationDate: Timestamp.fromDate(new Date()),
            authorId: exerciseSet.authorId,
            name: exerciseSet.name || '',
            exercisesInfo
        };

        // Сохраняем основной документ
        const sanitizedData = removeUndefinedFields(exerciseSetData);
        await setDoc(exerciseSetRef, sanitizedData);

        // Сохраняем упражнения как поддокументы в коллекции "exercises"
        const exercisesCollectionRef = collection(exerciseSetRef, 'exercises');
        for (let i = 0; i < exerciseSet.exercises.length; i++) {
            const exercise = exerciseSet.exercises[i];
            const exerciseData = serializeExercise(exercise);
            exerciseData["orderIndex"] = i;
            const sanitizedData = removeUndefinedFields(exerciseData);
            await setDoc(doc(exercisesCollectionRef, exercise.id), sanitizedData);
        }
    }

    public async getExerciseSet(id: string): Promise<Lesson> {
        const exerciseSetRef = doc(db, 'exerciseSets', id);
        const exerciseSetDoc = await getDoc(exerciseSetRef);

        if (!exerciseSetDoc.exists()) {
            throw new Error(`ExerciseSet not found for ID: ${id}`);
        }

        const exerciseSetData = exerciseSetDoc.data();
        const name = exerciseSetData.name || id;

        const exercisesCollectionRef = collection(exerciseSetRef, 'exercises');
        const exercisesSnapshot: QuerySnapshot = await getDocs(exercisesCollectionRef);

        const exercises: Exercise[] = exercisesSnapshot.docs
            .map((docSnapshot) => {
                const data = docSnapshot.data();
                return deserializeExercise(data, docSnapshot.id);
            })
            .filter((exercise): exercise is Exercise => exercise !== null);

        return {
            id: exerciseSetData.id,
            exercises: exercises,
            authorId: exerciseSetData.authorId,
            name: name
        };
    }

    public async getAllExerciseSets(): Promise<Lesson[]> {
        const lessons: Lesson[] = [];
        const exerciseSetsRef = collection(db, 'exerciseSets');
        const snapshot = await getDocs(exerciseSetsRef);

        for (const docSnap of snapshot.docs) {
            try {
                const exerciseSetData = docSnap.data();
                const lessonId = docSnap.id;

                const exercises = (exerciseSetData.exercisesInfo || []).map((exInfo: any) => ({
                    id: exInfo.id,
                    type: { kind: exInfo.type },
                    // or any other fields you stored
                }));

                // const exercisesCollectionRef = collection(doc(db, 'exerciseSets', lessonId), 'exercises');
                // const exercisesSnapshot = await getDocs(exercisesCollectionRef);
                //
                // // Для каждой под-документ упражнения — десериализуем.
                // // Если в каком-то упражнении возникает ошибка, можно пропустить только это упражнение
                // // или выбросить ошибку и пропустить весь урок. Ниже — пропускаем только некорректные упражнения.
                // const exercises: Exercise[] = [];
                // for (const exDoc of exercisesSnapshot.docs) {
                //     try {
                //         const data = exDoc.data();
                //         const exercise = deserializeExercise(data, exDoc.id);
                //         if (exercise) {
                //             exercises.push(exercise);
                //         }
                //     } catch (exerciseError) {
                //         console.error(`Skipping exercise in lesson ${lessonId} due to error: `, exerciseError);
                //     }
                // }
                //
                // // Если после фильтрации осталось хоть что-то, сохраняем урок
                // // (или можно целиком пропустить урок, если exercises пуст)
                lessons.push({
                    id: lessonId,
                    authorId: exerciseSetData.authorId,
                    name: exerciseSetData.name || lessonId,
                    exercises
                });
            } catch (lessonError) {
                // Если при обработке этого урока возникла ошибка — пропускаем его, но не прерываем общий цикл
                console.error(`Skipping lesson with ID ${docSnap.id} due to error: `, lessonError);
            }
        }

        return lessons;
    }

    public async getExercise(id: string): Promise<Exercise> {
        const exerciseRef = doc(db, 'exercises', id);
        const exerciseDoc = await getDoc(exerciseRef);

        if (!exerciseDoc.exists()) {
            throw new Error(`Exercise not found for ID: ${id}`);
        }

        const exerciseData = exerciseDoc.data();
        const exercise = deserializeExercise(exerciseData, exerciseDoc.id);
        if (exercise === null) {
            throw new Error(`Exercise deserializeExercise failed for ID: ${id}`);
        }
        return exercise;
    }
}

export function removeUndefinedFields(obj: any): any {
    if (Array.isArray(obj)) {
        return obj.map(removeUndefinedFields);
    } else if (obj !== null && typeof obj === 'object') {
        return Object.entries(obj).reduce((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = removeUndefinedFields(value);
            }
            return acc;
        }, {} as any);
    }
    return obj;
}