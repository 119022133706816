// LessonPracticeContainer.tsx
import React from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { LessonPracticeContainerViewModel } from "./LessonPracticeContainerViewModel";
import TeacherLessonSessionsList from "./TeacherLessonSessionsList";
import LessonPracticeView from "./LessonPracticeView";

const LessonPracticeContainer: React.FC = observer(() => {
    const { id: lessonId } = useParams<{ id: string }>();
    const [vm] = React.useState(() => new LessonPracticeContainerViewModel(lessonId!));

    if (vm.isLoading) {
        return <p>Loading...</p>;
    }

    if (vm.error) {
        return <p style={{ color: "red" }}>Error: {vm.error}</p>;
    }

    if (!vm.lesson) {
        return <p>Lesson not found?</p>;
    }

    // Ученик (если есть studentSessionId – переходим сразу в LessonPractice)
    if (vm.studentSessionId) {
        return (
            <LessonPracticeView
                lessonId={vm.lesson.id}
                sessionId={vm.studentSessionId}
            />
        );
    }

    // Учитель (если не выбрана сессия – показываем список, иначе LessonPractice)
    if (!vm.selectedSessionId) {
        return (
            <TeacherLessonSessionsList
                lessonId={vm.lesson.id}
                sessionInfos={vm.sessionInfos}
                onSelectSession={(sid) => vm.selectSession(sid)}
            />
        );
    }

    return (
        <LessonPracticeView
            lessonId={vm.lesson.id}
            sessionId={vm.selectedSessionId}
        />
    );
});

export default LessonPracticeContainer;