// FreePracticeView.tsx
import React from "react";
import { observer } from "mobx-react-lite";
import { FreePracticeViewModel } from "./FreePracticeViewModel";
import parse, { DOMNode, Element } from "html-react-parser";
import {renderHtmlWithFields} from "./renderHtmlWithFields";

interface FreePracticeViewProps {
    viewModel: FreePracticeViewModel;
}

export const FreePracticeView: React.FC<FreePracticeViewProps> = observer(
    ({ viewModel }) => {
        const { htmlString, fields } = viewModel.state;

        const parsedContent = renderHtmlWithFields(
            htmlString,
            fields,
            (fieldId, newValue) => {
                viewModel.updateField(fieldId, newValue);
            }
        );

        return (
            <div
                style={{
                    textAlign: "left"
                }}
            >
                {parsedContent}
            </div>
        );
    }
);