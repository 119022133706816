import {ExerciseOptions, ExerciseSentence} from "../models/ExerciseCreate";
import {Exercise, ExerciseMissingWord, ExerciseSelectOption} from "../models";
import {OpenAIRequester} from "../../data/implementations/OpenAIRequester";
import {apiKey} from "../../data/config/environment";
import {ExerciseCreationType} from "../../ui/components/PracticeManagement/Models/ExerciseCreationType";

export interface ExerciseCreateInteractor {
    /**
     * Генерирует начальный промпт для запроса на создание предложений.
     * @param options Настройки упражнения (язык, тема, уровень и т.д.).
     * @param amount Количество предложений.
     * @returns Сформированный текст начального промпта.
     */
    generateStartPrompt(options: ExerciseOptions, amount: number): string;

    /**
     * Генерирует финальный промпт для преобразования предложений в упражнения.
     * @param exerciseType Тип создаваемого упражнения (например, MissingWord или SelectOption).
     * @param options Настройки упражнения.
     * @param sentences Список предложений.
     * @returns Сформированный текст финального промпта.
     */
    generateFinalPrompt(
        exerciseType: ExerciseCreationType,
        options: ExerciseOptions,
        sentences: ExerciseSentence[]
    ): string;

    /**
     * Асинхронно генерирует список предложений для заданных настроек.
     * @param options Настройки упражнения.
     * @param amount Количество предложений.
     * @returns Промис, возвращающий список сгенерированных предложений.
     */
    generateSentences(options: ExerciseOptions, amount: number): Promise<ExerciseSentence[]>;

    /**
     * Асинхронно генерирует список упражнений для заданных предложений.
     * @param exerciseType Тип создаваемого упражнения.
     * @param options Настройки упражнения.
     * @param sentences Список предложений.
     * @returns Промис, возвращающий список созданных упражнений.
     */
    generateExercises(
        exerciseType: ExerciseCreationType,
        options: ExerciseOptions,
        sentences: ExerciseSentence[]
    ): Promise<Exercise[]>;
}


export class MockExerciseCreateInteractor implements ExerciseCreateInteractor {
    generateStartPrompt(options: ExerciseOptions, amount: number): string {
        return `[MOCK] Create ${amount} sentences in the language: ${options.learnlang} to practice the topic: ${options.theme} at the level: ${options.level}.
Each sentence must include a target word that should be conjugated according to the practiced topic.
Return those sentences and target words.
${options.comment}`;
    }

    generateFinalPrompt(
        exerciseType: ExerciseCreationType,
        options: ExerciseOptions,
        sentences: ExerciseSentence[]
    ): string {
        let base: string;
        switch (exerciseType) {
            case ExerciseCreationType.MissingWord:
                base = `[MOCK] Creating a test for learners of language ${options.learnlang} on the topic ${options.theme}. Here are some sentences that include the practiced topic and conjugated words for testing.
Divide each sentence into two parts—before and after the target word. Ensure that the target word is placed precisely between these two parts.
Ensure that the target word is not included in the parts of the sentence.
Provide the target word in its base form.`;
                break;
            case ExerciseCreationType.SelectOption:
                base = `[MOCK] Creating a test for learners of language ${options.learnlang} on the topic ${options.theme}. Here are some sentences that include the practiced topic and conjugated words for testing.
Remove the target word from each sentence, leaving a blank in its place.
Provide 4 different options, where one is the correct option and 3 others are incorrect.`;
                break;
            default:
                base = '';
        }
        const additional = sentences.map((resp) => `=====
Sentence: ${resp.sentence}
Target word: ${resp.targetWord}`).join('\n\n');

        return base + '\n' + additional;
    }

    async generateSentences(options: ExerciseOptions, amount: number): Promise<ExerciseSentence[]> {
        await new Promise((resolve) => setTimeout(resolve, 3000)); // Задержка 3 секунды
        return Array.from({ length: amount }, (_, index) => ({
            id: `${index + 1}`,
            sentence: `This is a test sentence ${index + 1}`,
            targetWord: `word${index + 1}`,
        }));
    }

    async generateExercises(
        exerciseType: ExerciseCreationType,
        options: ExerciseOptions,
        sentences: ExerciseSentence[]
    ): Promise<Exercise[]> {
        await new Promise((resolve) => setTimeout(resolve, 3000)); // Задержка 3 секунды
        switch (exerciseType) {
            case ExerciseCreationType.MissingWord:
                return sentences.map((sentence) => {
                    const exerciseData: ExerciseMissingWord = {
                        sentence: `sentence start ... (baseform) sentece finish`,
                        correctForm: `${sentence.targetWord}_correct`,
                    };
                    return {
                        id: sentence.id,
                        type: { kind: 'missingWord', data: exerciseData },
                    };
                });
            case ExerciseCreationType.SelectOption:
                return sentences.map((sentence) => {
                    const exerciseData: ExerciseSelectOption = {
                        sentence: sentence.sentence,
                        correctOption: sentence.targetWord,
                        option1: `${sentence.targetWord}_1`,
                        option2: `${sentence.targetWord}_2`,
                        option3: `${sentence.targetWord}_3`,
                        option4: sentence.targetWord,
                    };
                    return {
                        id: sentence.id,
                        type: { kind: 'selectOption', data: exerciseData },
                    };
                });
            default:
                return [];
        }
    }
}

export class RealExerciseCreateInteractor implements ExerciseCreateInteractor {
    private requester: OpenAIRequester;

    public constructor(api: string = apiKey) {
        this.requester = new OpenAIRequester(api);
    }

    generateStartPrompt(options: ExerciseOptions, amount: number): string {
        return `Create ${amount} sentences in the language: ${options.learnlang} to practice the topic: ${options.theme} at the level: ${options.level}.
Each sentence must include a target word that should be conjugated according to the practiced topic.
Return those sentences and target words.
${options.comment}`;
    }

    generateFinalPrompt(
        exerciseType: ExerciseCreationType,
        options: ExerciseOptions,
        sentences: ExerciseSentence[]
    ): string {
        let base: string;
        switch (exerciseType) {
            case ExerciseCreationType.MissingWord:
                base = `
Creating a test for learners of language ${options.learnlang} on the topic ${options.theme}. 
For each sentence provided, replace the target word with a gap and include its base form in parentheses immediately after the gap. 
Ensure that the target word is removed from the sentence. 
`;
                break;
            case ExerciseCreationType.SelectOption:
                base = `
Creating a test for learners of language ${options.learnlang} on the topic ${options.theme}.
For each sentence provided, remove the target word, leaving a blank in its place. 
Ensure that one of the four provided options is the correct answer, corresponding to the removed word, while the other three options are incorrect but plausible in the context.
`;
                break;
            default:
                base = '';
        }
        const additional = sentences.map((resp) => `=====
Sentence: ${resp.sentence}
Target word: ${resp.targetWord}`).join('\n\n');

        return base + '\n' + additional;
    }

    async generateSentences(options: ExerciseOptions, amount: number): Promise<ExerciseSentence[]> {
        const prompt = this.generateStartPrompt(options, amount);
        const startPromptExample: ExerciseSentence = {id: "", sentence: "", targetWord: "" };
        const sentences = await this.requester.requestWithCustomFormat<ExerciseSentence>(
            prompt,
            startPromptExample
        );
        return sentences;
    }

    async generateExercises(
        exerciseType: ExerciseCreationType,
        options: ExerciseOptions,
        sentences: ExerciseSentence[]
    ): Promise<Exercise[]> {
        const finalPrompt = this.generateFinalPrompt(exerciseType, options, sentences);
        switch (exerciseType) {
            case ExerciseCreationType.MissingWord:
                const exerciseExampleMissingWord: ExerciseMissingWord = {
                    sentence: "",
                    correctForm: ""
                };
                const exercisesDataMissingWord = await this.requester.requestWithCustomFormat<ExerciseMissingWord>(
                    finalPrompt,
                    exerciseExampleMissingWord
                );
                return exercisesDataMissingWord.map((data, index) => ({
                    id: crypto.randomUUID().toUpperCase(),
                    type: { kind: 'missingWord', data }
                }));
            case ExerciseCreationType.SelectOption:
                const exerciseExampleSelectOption: ExerciseSelectOption = {
                    sentence: "",
                    correctOption: "",
                    option1: "",
                    option2: "",
                    option3: "",
                    option4: ""
                };
                const exercisesDataSelectOption = await this.requester.requestWithCustomFormat<ExerciseSelectOption>(
                    finalPrompt,
                    exerciseExampleSelectOption,
                );
                return exercisesDataSelectOption.map((data, index) => ({
                    id: `${crypto.randomUUID().toUpperCase()}`,
                    type: { kind: 'selectOption', data }
                }));
            default:
                return [];
        }
    }
}
