import React from "react";
import { observer } from "mobx-react-lite";
import { MissingWordViewModel } from "./MissingWordViewModel";
import { MissingWordViewBase } from "../Base/MissingWordViewBase";

interface MissingWordViewProps {
    viewModel: MissingWordViewModel;
}

export const MissingWordView: React.FC<MissingWordViewProps> = observer(
    ({ viewModel }) => {
        return (
            <MissingWordViewBase
                sentence={viewModel.sentence}
                inputText={viewModel.currentInput}
                isCorrect={viewModel.isCorrect}
                correctAnswer={viewModel.correctForm}
                onInputChange={(val) => viewModel.updateInput(val)}
                onEnterPressed={() => viewModel.checkAnswer()}
                onCheckPressed={() => viewModel.checkAnswer()}
            />
        );
    }
);