// ExerciseMissingWordPreview.tsx
import React from 'react';
import {ExerciseMissingWord} from "../../../../../domain/models";

interface ExerciseMissingWordPreviewProps {
    exercise: ExerciseMissingWord;
}

export const ExerciseMissingWordPreview: React.FC<ExerciseMissingWordPreviewProps> = ({
                                                                                          exercise,
                                                                                      }) => {
    return (
        <div style={{ border: '1px solid #ccc', padding: '8px', maxWidth: '600px' }}>
            <p>
                <strong>Sentence:</strong> {exercise.sentence}
            </p>
        </div>
    );
};
