import { Exercise } from "../../../../domain/models";
import { PracticeSessionDelegate } from "../../../../domain/Exercises/PracticeSessionDelegate";
import { JustContentPracticeUIHandler } from "./JustContentPracticeUIHandler";
import { JustContentPracticeInteractor } from "./JustContentPracticeInteractor";
import {CanvasViewModel} from "../Base/CanvasViewModel";
import {CanvasItem} from "../Base/CanvasItem";
import {makeAutoObservable} from "mobx";

export class JustContentPracticeViewModel {
    public canvasViewModel: CanvasViewModel;
    private interactor: JustContentPracticeInteractor;

    public get contents(): CanvasItem[] {
        return this.canvasViewModel.items;
    }

    constructor(
        exercise: Exercise,
        delegate?: PracticeSessionDelegate,
        uiHandler?: JustContentPracticeUIHandler,
    ) {
        if (exercise.type.kind !== "justContent") {
            throw new Error("Not a justContent exercise");
        }
        this.interactor = new JustContentPracticeInteractor(exercise, delegate, uiHandler);
        this.canvasViewModel = new CanvasViewModel(this.interactor.canvas);
        makeAutoObservable(this)
    }
}