import { makeAutoObservable, runInAction } from "mobx";
import { Exercise } from "../../../../../domain/models";
import { LoadExercisesInteractor } from "../../../../../domain/AllExercises/LoadExercisesInteractor";
import { ExercisesFilterInteractor } from "../../Logic/ExercisesFilterInteractor";
import { ExerciseTypeFilter } from "../../Models/ExerciseTypeFilter";

export class FilteredExercisesGridViewModel {
    exercises: Exercise[] = [];
    selectedFilter: ExerciseTypeFilter = ExerciseTypeFilter.all;

    constructor(
        private loadExercisesInteractor: LoadExercisesInteractor,
        private exercisesFilterInteractor: ExercisesFilterInteractor
    ) {
        makeAutoObservable(this);
    }

    async load() {
        try {
            const allExercises = await this.loadExercisesInteractor.getAllExercises();
            runInAction(() => {
                this.exercises = allExercises;
            });
        } catch (error) {
            console.error("Failed to load exercises:", error);
        }
    }

    get filteredExercises(): Exercise[] {
        return this.exercisesFilterInteractor.filterExercises(this.exercises, this.selectedFilter);
    }
}