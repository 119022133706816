import {useState} from "react";

interface RoleSwitchProps {
    initialRole: 'teacher' | 'student';
    onRoleChange: (role: 'teacher' | 'student') => void;
}

const RoleSwitch: React.FC<RoleSwitchProps> = ({ initialRole, onRoleChange }) => {
    const [role, setRole] = useState(initialRole);

    const handleStudentClick = () => {
        setRole('student');
        onRoleChange('student');
    };

    const handleTeacherClick = () => {
        setRole('teacher');
        onRoleChange('teacher');
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <div
                id="role-switch"
                style={{
                    backgroundColor: role === 'teacher' ? '#4CAF50' : '#2196F3',
                    color: 'white',
                    padding: '8px 16px',
                    border: 'none',
                    borderRadius: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '150px',
                    transition: 'background-color 0.3s ease',
                }}
            >
                <span
                    onClick={handleStudentClick}
                    style={{
                        cursor: 'pointer',
                        opacity: role === 'student' ? 1 : 0.5,
                        transition: 'opacity 0.3s ease'
                    }}
                >
                    Student
                </span>
                <span
                    onClick={handleTeacherClick}
                    style={{
                        cursor: 'pointer',
                        opacity: role === 'teacher' ? 1 : 0.5,
                        transition: 'opacity 0.3s ease'
                    }}
                >
                    Teacher
                </span>
            </div>
        </div>
    );
};

export default RoleSwitch;