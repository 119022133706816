// SmallPreview/JustContentTypePreview.tsx

import React, { useRef } from 'react';
import { Resizer } from '../../../Resizer';
import {CanvasLayout} from "../../../CanvasRefactor/Base/ui/CanvasLayout";
import {CanvasItem} from "../../../CanvasRefactor/Base/CanvasItem";

export function JustContentTypePreview() {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const mockItems: CanvasItem[] = createMockItems();

    return (
        <Resizer targetSize={{ width: 200, height: 150 }}>
            <CanvasLayout
                items={mockItems}
                onAddTextItem={() => {}}
                onAddDrawingItem={() => {}}
                onUpdateItem={() => {}}
                onRemoveItem={() => {}}
                focusedItemId={null}
                editingId={null}
                onSetFocusedItem={() => {}}
                onSetEditingId={() => {}}
                fileInputRef={fileInputRef}
                onFileChange={() => {}}
                onAddTextAtPosition={() => {}}
                onAddImage={() => {}}
                onItemsChange={() => {}}
                isUploadingImage={false}
            />
        </Resizer>
    );
}

function createMockItems(): CanvasItem[] {
    return [
        {
            id: '1',
            type: 'text',
            content: 'DICTUM',
            x: 10,
            y: 10,
            width: 300,
            height: 100,
        },
        {
            id: '2',
            type: 'text',
            content: 'DICTUM',
            x: 250,
            y: 120,
            width: 250,
            height: 100,
        },
        {
            id: '3',
            type: 'text',
            content: 'DICTUM',
            x: 100,
            y: 320,
            width: 300,
            height: 100,
        },
        {
            id: '4',
            type: 'text',
            content: 'DICTUM',
            x: 10,
            y: 520,
            width: 300,
            height: 100,
        },
        {
            id: '5',
            type: 'image',
            content: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUA', // тестовый dataURL
            x: 10,
            y: 250,
            width: 100,
            height: 100,
        },
    ];
}