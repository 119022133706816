// FillBlanksInteractor.ts
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { FillBlanksState } from "./FillBlanksState";
import { FillBlanksUseCase } from "./FillBlanksUseCase";
import { FillBlanksUIHandler } from "./FillBlanksUIHandler";
import { Exercise } from "../../../../../domain/models";
import { PracticeSessionDelegate } from "../../../../../domain/Exercises/PracticeSessionDelegate";

/**
 * Interactor — the liaison between UIHandler (online sync), UseCase (business logic),
 * and local state. The parent sets up the Interactor with an Exercise that has
 * the full text, placeholders, audioURL, etc.
 */
export class FillBlanksInteractor {
    private delegate?: PracticeSessionDelegate;
    private useCase: FillBlanksUseCase;
    private uiHandler: FillBlanksUIHandler;
    private _state: FillBlanksState;

    public onStateChange?: (newState: FillBlanksState) => void;

    constructor(
        exercise: Exercise,
        delegate?: PracticeSessionDelegate,
        uiHandler?: FillBlanksUIHandler,
        useCase?: FillBlanksUseCase
    ) {
        if (exercise.type.kind !== "fillBlanks") {
            throw new Error("Not a FillBlanks exercise!");
        }
        this.delegate = delegate;
        this.useCase = useCase || new FillBlanksUseCase();

        // If no UIHandler is provided, we create a default.
        // The exerciseId is typically exercise.id
        this.uiHandler = uiHandler || new FillBlanksUIHandler(exercise.id);

        // Build our initial local state from the exercise data
        this._state = {
            rawText: exercise.type.data.text,
            placeholders: [],
            audioURL: exercise.type.data.audioURL,
            creationType: exercise.type.data.creationType,
        };

        makeAutoObservable(this);

        // 1) parse the text into placeholders
        this._state = this.useCase.parseText(this._state);

        // 2) sync placeholders from UIHandler if we already have them from server
        this.syncFromUIHandler();

        // 3) watch for changes from UIHandler (network updates)
        this.setupBindings();
    }

    get state(): FillBlanksState {
        return this._state;
    }

    private setState(newState: FillBlanksState) {
        this._state = newState;
        this.onStateChange?.(newState);
    }

    private setupBindings(): void {
        // Listen to changes in UIHandler placeholders array
        reaction(
            () => this.uiHandler.placeholders.map((p) => ({ ...p })),
            () => {
                this.syncFromUIHandler();
            }
        );
    }

    /**
     * Pull in the placeholders from the UIHandler if they differ
     */
    private syncFromUIHandler() {
        const placeholdersFromNet = this.uiHandler.placeholders;

        // We'll merge them with the local placeholders in `_state`.
        const newPlaceholders = this._state.placeholders.map((localP) => {
            const netP = placeholdersFromNet.find((n) => n.id === localP.id);
            if (!netP) return localP;
            return {
                ...localP,
                userInput: netP.userInput,
                isCorrect: netP.isCorrect
            };
        });

        const newState: FillBlanksState = {
            ...this._state,
            placeholders: newPlaceholders,
        };

        if (JSON.stringify(newState) !== JSON.stringify(this._state)) {
            runInAction(() => {
                this.setState(newState);
            });
        }
    }

    /**
     * Push local placeholders up to the UIHandler => which pushes them to server
     */
    private syncToUIHandler(newState: FillBlanksState) {
        this.uiHandler.placeholders = newState.placeholders.map((p) => ({
            id: p.id,
            userInput: p.userInput,
            isCorrect: p.isCorrect,
        }));
        this.uiHandler.sendUpdateIfNeeded();
    }

    // ---------------------
    // Public update methods
    // ---------------------

    /** The user typed something in a placeholder with a certain ID */
    public updatePlaceholder(placeholderId: number, newInput: string) {
        this.syncFromUIHandler();
        const updated = this.useCase.updatePlaceholder(this._state, placeholderId, newInput);
        if (JSON.stringify(updated) !== JSON.stringify(this._state)) {
            this.setState(updated);
            this.syncToUIHandler(updated);
        }
    }

    /** The user pressed “Enter” or “Check” on a placeholder ID */
    public checkPlaceholder(placeholderId: number) {
        this.syncFromUIHandler();
        const updated = this.useCase.checkPlaceholder(this._state, placeholderId);
        if (JSON.stringify(updated) !== JSON.stringify(this._state)) {
            this.setState(updated);
            this.syncToUIHandler(updated);
        }
    }

    /** Check ALL placeholders at once (optional) */
    public checkAll() {
        this.syncFromUIHandler();
        const updated = this.useCase.checkAll(this._state);
        if (JSON.stringify(updated) !== JSON.stringify(this._state)) {
            this.setState(updated);
            this.syncToUIHandler(updated);
        }
    }
}