import {ExerciseViewable} from "../../ExerciseViewable";
import {Exercise, ExerciseJustContent} from "../../../../../domain/models";
import {Resizer} from "../../../Resizer";
import React from "react";
import {JustContentPracticeView} from "../../../CanvasRefactor/JustContentPractice/JustContentPracticeView";
import {JustContentPracticeViewModel} from "../../../CanvasRefactor/JustContentPractice/JustContentPracticeViewModel";

export class ExerciseJustContentViewable implements ExerciseViewable {
    constructor(private exerciseJustContent: ExerciseJustContent) {}

    renderView(exercise: Exercise, width?: number, height?: number): JSX.Element {
        if (exercise.type.kind !== 'justContent') {
            throw new Error('Unsupported exercise type for ExerciseTable: ExerciseViewable');
        }
        const targetWidth = width ?? 300;
        const targetHeight = height ?? 150;
        return (
            <Resizer targetSize={{ width: targetWidth, height: targetHeight }}>
                <JustContentPracticeView viewModel={ new JustContentPracticeViewModel(exercise, undefined, undefined)} />
            </Resizer>
        );
    }
}