import { makeAutoObservable } from "mobx";
import {Exercise} from "../../../../../domain/models";
import {ExerciseOptions, ExerciseSentence} from "../../../../../domain/models/ExerciseCreate";
import {ExerciseCreateInteractor} from "../../../../../domain/interfaces/ExerciseCreateInteractor";
import CompositionRoot from "../../../../../compositionRoot";
import {ExerciseCreationType} from "../../../PracticeManagement/Models/ExerciseCreationType";


// ----------------------------------
// 1. Define an enum for view states
// ----------------------------------
export enum SelectOptionCreateState {
    Settings = "Settings",
    GeneratingSentences = "GeneratingSentences",
    SentencesGenerated = "SentencesGenerated",
    Converting = "Converting",
    Editing = "Editing",
}

// -----------------------------------------------------
// 2. ViewModel class (analogous to JustContentCreateViewModel)
// -----------------------------------------------------
export class SelectOptionCreateViewModel {
    // Publicly observed states
    public state = SelectOptionCreateState.Settings;
    public amount = "10";
    public finalPrompt: string | null = null;
    public exercises: Exercise[] = [];
    public exerciseOptions: ExerciseOptions = {
        learnlang: "Spanish",
        theme: "subjunctive",
        level: "B1",
        comment: "",
    };
    public sentences: ExerciseSentence[] = [];

    private interactor: ExerciseCreateInteractor;
    private onExercisesCreated: (exercises: Exercise[]) => void;

    constructor(onExercisesCreated: (exercises: Exercise[]) => void) {
        makeAutoObservable(this);
        this.onExercisesCreated = onExercisesCreated;
        this.interactor = CompositionRoot.exerciseCreateInteractor;
    }

    // Computed prompt based on exerciseOptions and amount
    public get startPrompt(): string {
        return this.interactor.generateStartPrompt(
            this.exerciseOptions,
            parseInt(this.amount, 10) || 0
        );
    }

    // Setters
    public setAmount(value: string) {
        this.amount = value;
    }

    public setExerciseOptions(options: ExerciseOptions) {
        this.exerciseOptions = options;
    }

    public setSentences(sentences: ExerciseSentence[]) {
        this.sentences = sentences;
    }

    // -------------------------------------
    // Actions to handle the "wizard" flow
    // -------------------------------------
    public async generate() {
        this.state = SelectOptionCreateState.GeneratingSentences;
        try {
            const generatedSentences = await this.interactor.generateSentences(
                this.exerciseOptions,
                parseInt(this.amount, 10) || 0
            );

            const generatedFinalPrompt = this.interactor.generateFinalPrompt(
                ExerciseCreationType.SelectOption, // always select option
                this.exerciseOptions,
                generatedSentences
            );

            this.sentences = generatedSentences;
            this.finalPrompt = generatedFinalPrompt;
            this.state = SelectOptionCreateState.SentencesGenerated;
        } catch (error) {
            console.error("Ошибка при генерации предложений:", error);
            this.state = SelectOptionCreateState.Settings;
        }
    }

    public async convert() {
        this.state = SelectOptionCreateState.Converting;
        try {
            const generatedExercises = await this.interactor.generateExercises(
                ExerciseCreationType.SelectOption,
                this.exerciseOptions,
                this.sentences
            );
            this.exercises = generatedExercises;
            this.state = SelectOptionCreateState.Editing;
        } catch (error) {
            console.error("Ошибка при конвертации упражнений:", error);
            this.state = SelectOptionCreateState.SentencesGenerated;
        }
    }

    public save() {
        // In this flow, we don't use a save interactor – we call the callback
        // with the newly created exercises
        this.onExercisesCreated(this.exercises);
    }

    // Helpers to modify a single exercise
    public setExercises(exercises: Exercise[]) {
        this.exercises = exercises;
    }
}