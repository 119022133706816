// src/onboarding/LanguageSelectionScreen.tsx
import React, {useState} from 'react';
import { observer } from 'mobx-react-lite';
import { LanguageSelectionViewModel } from './LanguageSelectionViewModel';
import {LanguageSelectionInteractor} from "./LanguageSelectionInteractor";
import CompositionRoot from "../../../../compositionRoot";

export const LanguageSelectionScreen: React.FC = observer(() => {
    const userManager = CompositionRoot.getAppUserManager();
    const [vm] = useState(() => new LanguageSelectionViewModel(new LanguageSelectionInteractor(userManager)));

    const handleSave = async () => {
        await vm.saveLanguages();
    };

    return (
        <div style={styles.center}>
            <h2>Выбор языков</h2>

            {vm.error && <div style={{ color: 'red' }}>{vm.error}</div>}
            {vm.isLoading && <div>Сохранение...</div>}

            <div>
                <label>Язык приложения:</label>
                <select
                    value={vm.appLanguage}
                    onChange={(e) => (vm.appLanguage = e.target.value)}
                >
                    <option value="">(не выбрано)</option>
                    <option value="ru">Русский</option>
                    <option value="en">Английский</option>
                </select>
            </div>

            <div>
                <label>Изучаемый / Преподаваемый язык:</label>
                <select
                    value={vm.learningLanguage}
                    onChange={(e) => (vm.learningLanguage = e.target.value)}
                >
                    <option value="">(не выбрано)</option>
                    <option value="en">Английский</option>
                    <option value="de">Немецкий</option>
                </select>
            </div>

            <div>
                <label>Родной язык:</label>
                <select
                    value={vm.nativeLanguage}
                    onChange={(e) => (vm.nativeLanguage = e.target.value)}
                >
                    <option value="">(не выбрано)</option>
                    <option value="ru">Русский</option>
                    <option value="en">Английский</option>
                </select>
            </div>

            <button onClick={handleSave} disabled={vm.isLoading}>
                Сохранить
            </button>
        </div>
    );
});

const styles = {
    center: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
    }
};