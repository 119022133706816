import React from "react";

interface IFloatingActionButtonProps {
    onClick: () => void;
}

export class FloatingActionButton extends React.Component<IFloatingActionButtonProps> {
    render() {
        return (
            <button style={styles.fab} onClick={this.props.onClick}>
                +
            </button>
        );
    }
}

const styles: { [key: string]: React.CSSProperties } = {
    fab: {
        position: "absolute",
        bottom: "16px",
        right: "16px",
        width: "56px",
        height: "56px",
        borderRadius: "50%",
        border: "none",
        backgroundColor: "#1976d2",
        color: "#fff",
        cursor: "pointer",
        fontSize: "24px",
        transition: "background-color 0.3s ease"
    }
};