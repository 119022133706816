// LessonWordsWindowView.tsx
import React from "react";
import { observer } from "mobx-react-lite";
import { LessonWordsWindowViewModel } from "./LessonWordsWindowViewModel";
import { LessonWordInputView } from "../ui/LessonWordInputView";
import { LessonWordsGridView } from "../ui/LessonWordsGridView";
import { FloatingActionButton } from "../ui/FloatingActionButton";

interface WordsWindowViewProps {
    viewModel: LessonWordsWindowViewModel;
    width?: number;
    height?: number;
}

export const LessonWordsWindowView: React.FC<WordsWindowViewProps> = observer(({
                                                                                   viewModel,
                                                                                   width,
                                                                                   height
                                                                               }) => {
    const containerStyle: React.CSSProperties = {
        position: "relative",
        border: "1px solid #ccc",
        width: `${width}px`,
        height: "100%"
    };

    const handleAddWord = () => {
        // Используем draft из вью-модели
        const text = viewModel.draftText.trim();
        const translation = viewModel.draftTranslation.trim();
        const example = viewModel.draftExample.trim() || undefined;

        if (text && translation) {
            viewModel.addWord(text, translation, example);
        }
        // По завершении сбросим поля
        viewModel.setDraftText("");
        viewModel.setDraftTranslation("");
        viewModel.setDraftExample("");
        viewModel.setIsAdding(false);
    };

    const handleCancel = () => {
        // Сбросим поля, закроем вьюшку
        viewModel.setDraftText("");
        viewModel.setDraftTranslation("");
        viewModel.setDraftExample("");
        viewModel.setIsAdding(false);
    };

    return (
        <div style={containerStyle}>
            <LessonWordsGridView words={viewModel.items} />
            {viewModel.isAdding ? (
                <LessonWordInputView
                    // Теперь вместо локального стейта
                    // мы напрямую связываемся с viewModel
                    text={viewModel.draftText}
                    translation={viewModel.draftTranslation}
                    example={viewModel.draftExample}
                    onChangeText={(val) => viewModel.setDraftText(val)}
                    onChangeTranslation={(val) => viewModel.setDraftTranslation(val)}
                    onChangeExample={(val) => viewModel.setDraftExample(val)}
                    onAddWord={handleAddWord}
                    onCancel={handleCancel}
                />
            ) : (
                <FloatingActionButton onClick={() => viewModel.setIsAdding(true)} />
            )}
        </div>
    );
});