// FirestoreSessionRepository.ts
import {
    getFirestore,
    doc,
    onSnapshot,
    getDoc,
    setDoc,
    updateDoc,
    deleteField
} from "firebase/firestore";
import { SessionRepository } from "../../domain/Multiplayer/SessionRepository";
import { SessionState } from "../../domain/Multiplayer/SessionState";
import {decodeSessionState, encodeSessionState} from "../../domain/Multiplayer/SessionStateCodec";


export class FirestoreSessionRepository implements SessionRepository {
    private db = getFirestore();
    private unsubMap: Map<string, () => void> = new Map();

    constructor() {}

    subscribeToSession(
        sessionId: string,
        onUpdate: (state: SessionState | undefined) => void
    ): () => void {
        const ref = doc(this.db, "sessions", sessionId);

        const unsubscribe = onSnapshot(ref, (snapshot) => {
            if (!snapshot.exists()) {
                onUpdate(undefined);
                return;
            }
            const data = snapshot.data();
            try {
                // ЗДЕСЬ вызываем наш декодер
                const state = decodeSessionState(data);
                onUpdate(state);
            } catch (err) {
                console.error("Error decoding session state:", err);
                onUpdate(undefined);
            }
        });

        this.unsubMap.set(sessionId, unsubscribe);

        return () => {
            unsubscribe();
            this.unsubMap.delete(sessionId);
        };
    }

    async getSessionState(sessionId: string): Promise<SessionState | undefined> {
        const ref = doc(this.db, "sessions", sessionId);
        const snapshot = await getDoc(ref);
        if (!snapshot.exists()) {
            return undefined;
        }
        const data = snapshot.data();
        try {
            // Снова используем наш декодер
            const state = decodeSessionState(data);
            return state;
        } catch (err) {
            console.error("Error decoding session state:", err);
            return undefined;
        }
    }

    async updateSessionState(
        sessionId: string,
        transform: (draft: SessionState) => void
    ): Promise<void> {
        const ref = doc(this.db, "sessions", sessionId);
        const snapshot = await getDoc(ref);

        let oldState: SessionState;
        if (snapshot.exists()) {
            try {
                oldState = decodeSessionState(snapshot.data());
            } catch (err) {
                console.error("Error decoding old session state:", err);
                oldState = {
                    exercises: [],
                    currentExerciseIndex: 0,
                    uiStateByExerciseId: {},
                    version: 0,
                };
            }
        } else {
            oldState = {
                exercises: [],
                currentExerciseIndex: 0,
                uiStateByExerciseId: {},
                version: 0,
            };
        }

        // Копируем oldState (можно глубоко через JSON)
        let newState: SessionState = JSON.parse(JSON.stringify(oldState));
        transform(newState);


        // Проверяем, изменилось ли
        if (isSessionStateEqual(oldState, newState)) {
            console.log("No changes in session state, skipping update");
            return;
        }

        // Кодируем старое и новое состояние
        const encodedOld = encodeSessionState(oldState);
        const encodedNew = encodeSessionState(newState);


        // Вычисляем diff
        const diff = diffDictionaries(encodedOld, encodedNew);

        if (Object.keys(diff).length === 0) {
            console.log("No actual diff found, skipping update");
            return;
        }

        if (!snapshot.exists()) {
            console.log("Document does not exist, creating it");
            await setDoc(ref, encodedNew);
        } else {
            await updateDoc(ref, diff);
        }
    }
}

/** Проверяем поверхностно, изменился ли SessionState */
function isSessionStateEqual(a: SessionState, b: SessionState): boolean {
    // Можно делать глубокий compare, тут для примера JSON.stringify
    return JSON.stringify(a) === JSON.stringify(b);
}

/** diffDictionaries — аналог Swift diffDictionaries
 * Возвращаем только изменённые/новые ключи, либо FieldValue.delete() для удалённых
 */
function diffDictionaries(
    oldDict: Record<string, any>,
    newDict: Record<string, any>
): Record<string, any> {
    const diff: Record<string, any> = {};

    // Проверяем изменённые/новые
    for (const key in newDict) {
        if (!isEqualFirestoreValues(oldDict[key], newDict[key])) {
            diff[key] = newDict[key];
        }
    }

    // Проверяем удалённые ключи
    for (const key in oldDict) {
        if (!(key in newDict)) {
            diff[key] = deleteField();
        }
    }

    return diff;
}

/** Проверка равенства, аналог isEqualFirestoreValues в Swift */
function isEqualFirestoreValues(lhs: any, rhs: any): boolean {
    if (lhs === rhs) return true;
    // Если оба массива или объекты — сравним по JSON
    if (typeof lhs === "object" && lhs !== null && typeof rhs === "object" && rhs !== null) {
        return JSON.stringify(lhs) === JSON.stringify(rhs);
    }
    return false;
}