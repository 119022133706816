import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {LearningPlanEditorViewModel} from "./LearningPlanEditorViewModel";
import {ExerciseCreationFlowView} from "../ExerciseCreation/ExerciseCreationFlowView";
import BaseButton, {ButtonVariant} from "../../../BaseButton";
import {AllExercisesPickerView} from "../../Components/AllExercisesPickerView";
import {Exercise} from "../../../../../domain/models";
import {ExercisesHorizontalList} from "../../Components/ExercisesHorizontalList/ExercisesHorizontalList";

export const LearningPlanEditor = observer(
    ({
         viewModel,
         onExercisePress,
     }: {
        viewModel: LearningPlanEditorViewModel;
        onExercisePress?: (index: number) => void;
    }) => {
        const [creatingExercise, setCreatingExercise] = useState(false);
        const [pickingExistingExercise, setPickingExistingExercise] = useState(false);

        useEffect(() => {
            const unsubscribe = viewModel.subscribeToPlan();
            return () => {
                unsubscribe();
            };
        }, [viewModel]);

        if (viewModel.error) {
            return <div style={{ color: "red" }}>{viewModel.error}</div>;
        }

        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    backgroundColor: "var(--modal-bg)",
                    position: "relative",
                }}
            >
                <div style={{
                    width: "100%",
                }}>
                    <ExercisesHorizontalList
                        exercises={viewModel.exercises}
                        onReorder={viewModel.setExercises}
                        onDelete={viewModel.deleteExercise}
                        onPress={(exerciseIndex) => onExercisePress?.(exerciseIndex)}
                        onCreateExerciseClick={() => setCreatingExercise(true)}
                        onAddExistingClick={() => setPickingExistingExercise(true)}
                    />

                </div>

                {/* Модалка для создания упражнения */}
                {creatingExercise && (
                    <div
                        style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100vw",
                            height: "100vh",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 9999,
                        }}
                    >
                        <div
                            style={{
                                width: "95%",
                                height: "92%",
                                backgroundColor: "var(--modal-bg)",
                                borderRadius: 8,
                                display: "flex",
                                flexDirection: "column",
                                position: "relative",
                                padding: 20,
                                overflow: "auto",
                            }}
                        >
                            <ExerciseCreationFlowView
                                onSelect={(newExercise: Exercise) => {
                                    viewModel.addExercises([newExercise]);
                                    setCreatingExercise(false);
                                }}
                                onClose={() => setCreatingExercise(false)}
                            />
                        </div>
                    </div>
                )}

                {/* Модалка для выбора существующего упражнения */}
                {pickingExistingExercise && (
                    <div
                        style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100vw",
                            height: "100vh",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 9999,
                        }}
                    >
                        <div
                            style={{
                                width: "95%",
                                height: "92%",
                                backgroundColor: "var(--modal-bg)",
                                borderRadius: 8,
                                display: "flex",
                                flexDirection: "column",
                                position: "relative",
                                padding: 20,
                                overflow: "auto",
                            }}
                        >
                            <AllExercisesPickerView
                                onSelect={(existingExercise: Exercise) => {
                                    const copy = {
                                        ...existingExercise,
                                        id: crypto.randomUUID().toUpperCase(),
                                    };
                                    viewModel.addExercises([copy]);
                                    setPickingExistingExercise(false);
                                }}
                                onClose={() => setPickingExistingExercise(false)}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
);