// ===================== ./PracticeSelectOption/SelectOptionUIHandler.ts =====================
import { makeAutoObservable, runInAction } from "mobx";
import { ExerciseUIHandler } from "../../../../../domain/Multiplayer/ExerciseUIHandler";
import { SessionStateInteractor } from "../../../../../domain/Multiplayer/SessionStateInteractor";
import { ExerciseUIState } from "../../../../../domain/Multiplayer/ExerciseUIState";
import { SelectOptionUIState } from "./SelectOptionUIState";

export class SelectOptionUIHandler implements ExerciseUIHandler {
    public selectedOption: string | null;
    public isCorrect: boolean | null;

    private exerciseId: string;
    private interactor?: SessionStateInteractor;
    private isUpdatingFromServer = false;
    private currentUIState: SelectOptionUIState;

    constructor(
        exerciseId: string,
        interactor?: SessionStateInteractor,
        initialState?: SelectOptionUIState
    ) {
        this.exerciseId = exerciseId;
        this.interactor = interactor;

        // Если initialState не задан, то создаём пустое
        this.currentUIState =
            initialState || new SelectOptionUIState(null, null);

        this.selectedOption = this.currentUIState.selectedOption;
        this.isCorrect = this.currentUIState.isCorrect;

        makeAutoObservable(this);
    }

    /**
     * Получаем новое состояние от Firestore (или другого репо) и обновляем локально.
     */
    public updateUIState(uiState: ExerciseUIState | undefined): void {
        console.log("SelectOptionUIHandler updateUIState: ", uiState)
        if (!uiState || uiState.type !== "selectOption") {
            return;
        }

        const newState = uiState.data as SelectOptionUIState;
        // Сравним старое и новое состояние
        if (!newState.equals(this.currentUIState)) {
            this.isUpdatingFromServer = true;
            runInAction(() => {
                this.currentUIState = newState;
                this.selectedOption = newState.selectedOption;
                this.isCorrect = newState.isCorrect;
            });
            this.isUpdatingFromServer = false;
        }
    }

    /**
     * Отправляем новое состояние в сеть, если оно реально изменилось.
     */
    public sendUpdateIfNeeded() {
        console.log("SelectOptionUIHandler sendUpdateIfNeeded selectedOption: ", this.selectedOption, ", this.isCorrect: ", this.isCorrect)
        if (this.isUpdatingFromServer) return;
        console.log("SelectOptionUIHandler isUpdatingFromServer passed")

        const newState = new SelectOptionUIState(
            this.selectedOption,
            this.isCorrect
        );

        if (newState.equals(this.currentUIState)) return;
        console.log("SelectOptionUIHandler equals chech passed")

        // Обновляем локальную копию
        this.currentUIState = newState;

        // Передаём в SessionStateInteractor
        this.interactor?.updateSelectOptionState(this.exerciseId, newState);
    }
}