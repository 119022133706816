import { makeAutoObservable } from "mobx";
import { ClassroomInteractor } from "./ClassroomInteractor";
import { Exercise } from "../../../../domain/models";
import {PracticeStateUI} from "../PracticeStateUI";

export class ClassroomViewModel {
    interactor: ClassroomInteractor;

    constructor(public lessonId: string) {
        this.interactor = new ClassroomInteractor(lessonId);
        makeAutoObservable(this);
    }

    get lesson() {
        return this.interactor.lesson;
    }

    get practiceState(): PracticeStateUI {
        return this.interactor.practiceState;
    }

    get totalExercises(): number {
        return this.interactor.totalExercises;
    }

    get currentExerciseIndex(): number {
        return this.interactor.currentExerciseIndex;
    }

    get studentId(): string | null {
        return this.interactor.studentId
    }
    get teacherId(): string | null {
        return this.interactor.teacherId
    }

    goToExercise(index: number) {
        this.interactor.goToExercise(index);
    }

    goNext() {
        this.interactor.currentStepDone();
    }

    insertCanvas() {
        this.interactor.insertCanvas();
    }

    goPrev() {
        this.interactor.goPrev();
    }

    async stopClass() {
        await this.interactor.stopClass();
    }
}