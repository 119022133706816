// LessonPreview.tsx
import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { ExerciseRowView } from "../../Components/ExerciseRowView";
import { LessonCreationViewModel } from "./LessonCreationViewModel";
import BaseButton, {ButtonVariant} from "../../../BaseButton";

interface Props {
    viewModel: LessonCreationViewModel;
    onCreateExercise: () => void;
    onAddFromExisting: () => void;
}

export const LessonPreview = observer(({ viewModel, onCreateExercise, onAddFromExisting }: Props) => {
    const { selectedExercises } = viewModel;

    const handleDeleteExercise = (index: number) => {
        viewModel.deleteSelectedExercise(index);
    };

    const navigate = useNavigate(); // Хук для управления навигацией
    const handleSaveLesson = () => {
        viewModel.saveLesson();
        navigate(-1); // Вернуться на предыдущую страницу
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
            {/* Lesson title и инпут в одной строке, больший шрифт */}
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 16 }}>
                <div style={{ fontSize: 24, fontWeight: "bold", color: "var(--text-color)" }}>
                    Lesson Title:
                </div>
                <input
                    type="text"
                    placeholder="Enter lesson name..."
                    style={{
                        fontSize: 24,
                        color: "var(--text-color)",
                        backgroundColor: "var(--modal-bg)",
                        border: "1px solid #555555",
                        borderRadius: 4,
                        padding: "4px 8px",
                        flex: 1,
                    }}
                    value={viewModel.setName}
                    onChange={(e) => (viewModel.setName = e.target.value)}
                />
            </div>

            {/* Список упражнений */}
            <div
                style={{
                    border: "1px solid #444",
                    borderRadius: 4,
                    padding: 8,
                    backgroundColor: "var(--modal-bg)",
                }}
            >
                {selectedExercises.length === 0 ? (
                    <div style={{ color: "#bbb" }}>No exercises selected.</div>
                ) : (
                    selectedExercises.map((exercise, idx) => (
                        <div
                            key={exercise.id}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: "4px 0",
                                borderBottom: "1px solid #555",
                            }}
                        >
                            <ExerciseRowView exercise={exercise} />

                            {/* Кнопка "Удалить" */}
                            <BaseButton
                                color="#d32f2f"
                                variant={ButtonVariant.Classic}
                                onClick={() => handleDeleteExercise(idx)}
                            >
                                Delete
                            </BaseButton>
                        </div>
                    ))
                )}
            </div>

            {/* Кнопки "Create exercise" и "Add from existing" — горизонтально под списком */}
            <div style={{ display: "flex", flexDirection: "row", gap: 16 }}>
                <BaseButton
                    color="#2196f3"
                    variant={ButtonVariant.Classic}
                    onClick={onCreateExercise}
                >
                    Create exercise
                </BaseButton>

                <BaseButton
                    color="#4caf50"
                    variant={ButtonVariant.Classic}
                    onClick={onAddFromExisting}
                >
                    Add from existing
                </BaseButton>
            </div>

            {/* Кнопка Save lesson внизу превью */}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <BaseButton
                    color="#ff9800"
                    variant={ButtonVariant.Classic}
                    onClick={handleSaveLesson}
                >
                    Save lesson
                </BaseButton>
            </div>
        </div>
    );
});