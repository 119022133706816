import {ExerciseViewable} from "../../ExerciseViewable";
import {Exercise, ExerciseFree} from "../../../../../domain/models";
import {Resizer} from "../../../Resizer";
import React from "react";
import {FreePracticeView} from "../Practice/FreePracticeView";
import {FreePracticeViewModel} from "../Practice/FreePracticeViewModel";
import {PracticeWordSearchView} from "../../WordSearch/Practice/PracticeWordSearchView";

export class ExerciseFreeViewable implements ExerciseViewable {
    constructor(private exerciseFree: ExerciseFree) {}

    renderView(exercise: Exercise, width?: number, height?: number): JSX.Element {
        if (exercise.type.kind !== 'free') {
            throw new Error('Unsupported exercise type for ExerciseFree: ExerciseViewable');
        }
        const targetWidth = width ?? 300;
        const targetHeight = height ?? 150;
        return (
            <Resizer targetSize={{ width: targetWidth, height: targetHeight }}>
                <div style={{width: targetWidth, height: targetHeight}}>
                    <FreePracticeView viewModel={ new FreePracticeViewModel(exercise, undefined, undefined, undefined)} />
                </div>
            </Resizer>
        );
    }
}