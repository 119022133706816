// domain/interfaces/FillBlanksCreationInteractor.ts

import { Exercise, WordItem } from "../../../../../domain/models";

export interface FillBlanksCreationInteractor {
    /**
     * Generates the text from a set of words and the chosen model.
     */
    generateText(words: string, model: string): Promise<string>;

    /**
     * Final creation of a FillBlanks exercise, with `creationType = 'generated'`.
     */
    confirmCreation(rawText: string, rawWords: string): Promise<Exercise>;
}

export class FillBlanksCreationInteractorImpl
    implements FillBlanksCreationInteractor
{
    private speechGenerator: any;   // e.g. OpenAISpeechGenerator
    private audioUploader: any;     // e.g. MediaLoader or FirebaseAudioUploadService
    private textGenerator: any;     // e.g. CreateFillBlanksServiceImpl or custom

    constructor(textGenerator: any, speechGenerator: any, audioUploader: any) {
        this.textGenerator = textGenerator;
        this.speechGenerator = speechGenerator;
        this.audioUploader = audioUploader;
    }

    async generateText(words: string, model: string): Promise<string> {
        // Delegate to textGenerator for AI text generation
        const result = await this.textGenerator.createText(words, model);
        return result;
    }

    async confirmCreation(rawText: string, rawWords: string): Promise<Exercise> {
        // (1) Generate optional TTS (remove if not needed)
        const textForSpeech = rawText.replace(/\[|\]/g, "");
        const audioData = await this.speechGenerator.generateSpeech(textForSpeech);
        const audioBlob = new Blob([audioData], { type: "audio/mpeg" });

        // (2) Upload audio => get URL
        const fileName = crypto.randomUUID().toUpperCase();
        const audioURL = await this.audioUploader.uploadAudio(audioBlob, fileName);

        // (3) Convert rawWords => WordItem[]
        const lines = rawWords.split("\n").map((line) => line.trim()).filter(Boolean);
        const splittedWords: WordItem[] = lines.map((w, i) => ({
            id: `w_${i}`,
            word: w,
            translation: "",
            repetitions: [],
        }));

        // (4) Build final Exercise object with creationType='generated'
        const exerciseId = crypto.randomUUID().toUpperCase();
        const newExercise: Exercise = {
            id: exerciseId,
            // No separate "name" or "description" for fillBlanks in this example
            type: {
                kind: "fillBlanks",
                data: {
                    words: splittedWords,
                    text: rawText,
                    audioURL: audioURL,
                    creationType: "generated",
                },
            },
        };

        return newExercise;
    }
}