// SingleExerciseViewModel.ts
import { makeAutoObservable, runInAction } from "mobx";
import CompositionRoot from "../../../../compositionRoot";
import {PracticeStateUI} from "../PracticeStateUI";
import {PracticeStateFactory} from "../PracticeStateFactory";

export class SingleExerciseViewModel {
    loading = false;

    practiceState: PracticeStateUI = { type: "done" };

    private storage = CompositionRoot.getExercisesStorage();
    private practiceStateFactory = new PracticeStateFactory();

    constructor(private exerciseId: string) {
        makeAutoObservable(this);
    }

    async loadExercise() {
        try {
            runInAction(() => {
                this.loading = true;
            });

            const exercise = await this.storage.getExercise(this.exerciseId);

            runInAction(() => {
                this.loading = false;
            });

            if (!exercise) {
                return;
            }

            runInAction(() => {
                this.practiceState = this.practiceStateFactory.create(exercise);
            });
        } catch (error) {
            console.error("Failed to load exercise:", error);
            runInAction(() => {
                this.loading = false;
                this.practiceState = { type: "done" };
            });
        }
    }
}