// ===================== ./PracticeSelectOption/SelectOptionUIState.ts =====================
export class SelectOptionUIState {
    public readonly type = "selectOption" as const;

    constructor(
        public selectedOption: string | null,
        public isCorrect: boolean | null
    ) {}

    public equals(other: SelectOptionUIState): boolean {
        return (
            this.selectedOption === other.selectedOption &&
            this.isCorrect === other.isCorrect
        );
    }
}