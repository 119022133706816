// ui/viewmodels/FillBlanksCreationViewModel.ts

import { makeAutoObservable } from "mobx";
import { FillBlanksCreationInteractor } from "./FillBlanksCreationInteractor";
import { Exercise } from "../../../../../domain/models";

export class FillBlanksCreationViewModel {
    words: string = `amasar
agujeros
violeta
lila
celeste
colgar`;
    text: string = "";
    isLoading: boolean = false;
    selectedModel: "openai" | "gemini" = "openai";

    constructor(private interactor: FillBlanksCreationInteractor) {
        makeAutoObservable(this);
    }

    /**
     * Generate text from AI model
     */
    async generateText() {
        if (!this.words.trim()) return;
        this.isLoading = true;
        try {
            const result = await this.interactor.generateText(
                this.words,
                this.selectedModel
            );
            this.text = result;
        } catch (err) {
            console.error(err);
            alert("Error generating text.");
        } finally {
            this.isLoading = false;
        }
    }

    /**
     * Confirm => produce an Exercise
     */
    async confirmCreation(): Promise<Exercise | null> {
        if (!this.text.trim()) {
            alert("No text to confirm. Please generate or type something first.");
            return null;
        }
        this.isLoading = true;
        try {
            // No "exerciseName" needed for FillBlanks => rawText, rawWords only
            const newExercise = await this.interactor.confirmCreation(this.text, this.words);
            return newExercise;
        } catch (err) {
            console.error(err);
            alert("Error creating Fill Blanks exercise.");
            return null;
        } finally {
            this.isLoading = false;
        }
    }
}