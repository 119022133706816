// LoadCompletedClassesUseCase.ts

import {Exercise} from "../../../../domain/models";
import CompositionRoot from "../../../../compositionRoot";
import {CompletedClass} from "./CompletedClass";
import {ClassData} from "../../Practice/ARefactor/Models/ClassData";


export class LoadCompletedClassesUseCase {
    async execute(userId: string): Promise<CompletedClass[]> {
        // Resolve storages from CompositionRoot:
        const userStorage = CompositionRoot.getUserStorage();
        const classStorage = CompositionRoot.getClassStorage();

        // 1) Load user data, read completedClasses
        const user = await userStorage.getUserData(userId);
        if (!user) {
            return [];
        }

        const result: CompletedClass[] = [];

        // 2) For each classId in completedClasses
        for (const classId of (user.completedClasses ?? [])) {
            // a) Load classData: getClassSession
            const classData = await classStorage.getClassSession(classId);
            if (!classData) {
                // classDoc doesn't exist, skip or handle error
                continue;
            }

            // b) Load exercises: getClassExercises
            const exercises = await classStorage.getClassExercises(classId);

            // c) Add to our result array
            result.push({
                classId,
                classData,
                exercises,
            });
        }

        return result;
    }
}