// Previews/TableViewable.tsx
import React from 'react';
import {Exercise, ExerciseHangman, ExerciseTable} from "../../../../../domain/models";
import {ExerciseViewable} from "../../ExerciseViewable";
import {Resizer} from "../../../Resizer";
import {PracticeHangmanViewModel} from "../Practice/PracticeHangmanViewModel";
import {PracticeHangmanView} from "../Practice/PracticeHangmanView";

export class ExerciseHangmanViewable implements ExerciseViewable {
    constructor(private exerciseData: ExerciseHangman) {}

    renderView(exercise: Exercise, width?: number, height?: number): JSX.Element {
        if (exercise.type.kind !== 'hangman') {
            throw new Error('Unsupported exercise type for ExerciseHangman: ExerciseViewable');
        }

        const targetWidth = width ?? 300;
        const targetHeight = height ?? 150;

        return (
            <Resizer targetSize={{ width: targetWidth, height: targetHeight }}>
                <PracticeHangmanView viewModel={ new PracticeHangmanViewModel(exercise, undefined, undefined)} />
            </Resizer>
        );
    }
}