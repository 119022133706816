// src/onboarding/RoleSelectionInteractor.ts

import {AppUserManagerProtocol} from "../../../../domain/interfaces/AppUserManagerProtocol";

export class RoleSelectionInteractor {
    constructor(private userManager: AppUserManagerProtocol) {}

    async updateRole(role: 'student' | 'teacher'): Promise<void> {
        await this.userManager.updateUserRole(role);
    }
}