/***************************************************
 * COMPONENT: WordsListView
 * Equivalent to SwiftUI's "WordsListView"
 ***************************************************/
import {WordItem} from "../../../../../domain/models";

interface WordsListViewProps {
    words: WordItem[];
    foundWords: Set<WordItem>;
    hintedWords: Set<WordItem>;
    onHint: (w: WordItem) => void;
}

export function WordsListView({
                                  words,
                                  foundWords,
                                  hintedWords,
                                  onHint,
                              }: WordsListViewProps) {
    const colorForWord = (w: WordItem): string => {
        if (hintedWords.has(w)) return 'orange';
        if (foundWords.has(w)) return 'green';
        return 'white';
    };

    const containerStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '1rem',
        backgroundColor: '#222',
        color: '#fff',
    };

    const buttonStyle: React.CSSProperties = {
        fontSize: '0.7rem',
        padding: '0.25rem 0.5rem',
        backgroundColor: 'rgba(255,255,255,0.2)',
        borderRadius: '4px',
        marginLeft: '1rem',
        cursor: 'pointer',
    };

    return (
        <div style={containerStyle}>
            <h4 style={{ marginBottom: '0.5rem' }}>HINTS:</h4>
            {words.map((w, index) => (
                <div key={w.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
          <span
              style={{
                  fontSize: '1.3rem',
                  color: colorForWord(w),
              }}
          >
            {index + 1}. {w.translation}
          </span>
                    <button
                        onClick={() => onHint(w)}
                        style={buttonStyle}
                    >
                        Подсказать
                    </button>
                </div>
            ))}
        </div>
    );
}