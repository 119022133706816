// LessonCard.tsx
import React from 'react';
import ShareButton from "../../../exercises_types/BaseComponents/ShareButton";

interface ExerciseSummary {
    id: string;
    info: string;
}

export interface LessonCardData {
    id: string;
    name: string;
    authorId?: string;
    exercises: ExerciseSummary[];
}

interface LessonCardProps {
    lesson: LessonCardData;
    onOpenLesson: (lessonId: string) => void;
}

const LessonCard: React.FC<LessonCardProps> = ({ lesson, onOpenLesson }) => {
    const handleOpenLesson = () => {
        onOpenLesson(lesson.id);
    };

    return (
        <div style={styles.cardContainer}>
            <div style={styles.headerRow}>
                <h3 style={styles.lessonName}>{lesson.name}</h3>
            </div>
            <div style={styles.infoRow}>
                <span style={styles.label}>Lesson ID:</span>
                <span style={styles.value}>{lesson.id}</span>
            </div>
            {lesson.authorId && (
                <div style={styles.infoRow}>
                    <span style={styles.label}>Author ID:</span>
                    <span style={styles.value}>{lesson.authorId}</span>
                </div>
            )}
            <div style={styles.exercisesInfo}>
                <span style={styles.label}>Exercises:</span>
                <ul style={styles.exerciseList}>
                    {lesson.exercises.map((ex) => (
                        <li key={ex.id} style={styles.exerciseItem}>
                            {ex.info}
                        </li>
                    ))}
                </ul>
            </div>
            <div style={styles.actionsRow}>
                <button style={styles.openButton} onClick={handleOpenLesson}>
                    Open lesson
                </button>
                <ShareButton link={`${window.location.origin}/lessonPractice/${lesson.id}`} />
            </div>
        </div>
    );
};

export default LessonCard;

const styles: { [key: string]: React.CSSProperties } = {
    cardContainer: {
        border: '1px solid #444',
        borderRadius: '8px',
        padding: '16px',
        marginBottom: '16px',
        backgroundColor: '#1C1C1C',
    },
    headerRow: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '8px',
    },
    lessonName: {
        margin: 0,
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#fff',
    },
    infoRow: {
        display: 'flex',
        gap: '8px',
        marginBottom: '4px',
        alignItems: 'center',
    },
    label: {
        color: '#aaa',
        fontWeight: 'bold',
    },
    value: {
        color: '#fff',
    },
    exercisesInfo: {
        marginTop: '12px',
        marginBottom: '12px',
    },
    exerciseList: {
        margin: 0,
        paddingLeft: '16px',
        color: '#ccc',
    },
    exerciseItem: {
        marginBottom: '4px',
    },
    actionsRow: {
        display: 'flex',
        gap: '8px',
    },
    openButton: {
        padding: '8px 16px',
        fontSize: '16px',
        cursor: 'pointer',
        backgroundColor: '#2E2E2E',
        color: '#fff',
        border: '1px solid #555',
        borderRadius: '4px',
    },
};
