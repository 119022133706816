import { MissingWordState } from "./MissingWordState";

/**
 * Логика изменения состояния (UseCase).
 * Здесь мы инкапсулируем все операции, меняющие состояние.
 */
export class MissingWordUseCase {
    /**
     * Обновить текущее значение, которое ввёл пользователь.
     */
    public updateInput(
        currentState: MissingWordState,
        newText: string
    ): MissingWordState {
        return {
            ...currentState,
            currentInput: newText,
        };
    }

    /**
     * Проверить, правильно ли введён ответ.
     */
    public checkAnswer(currentState: MissingWordState): MissingWordState {
        const isCorrect = currentState.currentInput === currentState.correctForm;
        return {
            ...currentState,
            isCorrect,
        };
    }

    /**
     * Перейти к следующему примеру (условный метод; вы можете дописать любую логику).
     * Здесь для примера просто сбрасываем состояние.
     */
    public goNext(currentState: MissingWordState): MissingWordState {
        return {
            ...currentState,
            currentInput: "",
            isCorrect: null,
        };
    }
}