// StartScreenViewModel.ts
import { makeAutoObservable, runInAction } from 'mobx';
import { AppUser } from '../../domain/models/AppUser';
import CompositionRoot from '../../compositionRoot';
import WordsViewModel from '../../ui/viewmodels/WordsViewModel';

class StartScreenViewModel {
    user: AppUser | null = null;
    wordsViewModel: WordsViewModel | null = null;
    private subscription: { unsubscribe: () => void } | null = null;

    constructor() {

        const appUserManager = CompositionRoot.getAppUserManager();
        // console.log("StartScreenViewModel constructor: appUserManager.currentUser =", appUserManager.currentUser); // <---------- ADD THIS LINE

        this.user = appUserManager.currentUser;

        const wordsService = CompositionRoot.getWordsService();
        this.wordsViewModel = new WordsViewModel(wordsService);

        const userPublisher = appUserManager.currentUserPublisher();

        // console.log("StartScreenViewModel constructor: Subscribing to userPublisher"); // <---------- ADD THIS LINE
        this.subscription = userPublisher.subscribe((newUser: AppUser | null) => {
            // console.log("StartScreenViewModel constructor: userPublisher emitted newUser =", newUser); // <---------- ADD THIS LINE
            runInAction(() => {
                // console.log("StartScreenViewModel constructor: runInAction - setting user =", newUser)
                this.user = newUser;
            });
        });

        makeAutoObservable(this);
    }

    async setUserRole(role: 'teacher' | 'student') {
        const appUserManager = CompositionRoot.getAppUserManager();
        await appUserManager.updateUserRole(role);
    }

    dispose() {
        //TODO: fix
        // if (this.subscription) {
        //     this.subscription.unsubscribe();
        // }
        // if (this.wordsViewModel) {
        //     this.wordsViewModel.dispose();
        // }
    }
}

export default StartScreenViewModel;