import { makeAutoObservable } from "mobx";
import { Exercise } from "../../../../../domain/models";
import { LoadExercisesInteractor } from "../../../../../domain/AllExercises/LoadExercisesInteractor";
import { SaveSingleExerciseInteractor } from "../../../../../domain/AllExercises/SaveSingleExerciseInteractor";
import { CreateSimilarInteractor } from "../../../../../domain/AllExercises/CreateSimilarInteractor";
import { ExercisesFilterInteractor } from "../../Logic/ExercisesFilterInteractor";
import {
    FilteredExercisesGridViewModel
} from "../../Components/FilteredExercisesGridView/FilteredExercisesGridViewModel";

export class AllExercisesViewModel {
    gridViewModel: FilteredExercisesGridViewModel;
    previewingExercise: Exercise | null = null;
    editingExercise: Exercise | null = null;

    private saveExerciseInteractor: SaveSingleExerciseInteractor;
    private createSimilarInteractor: CreateSimilarInteractor;

    constructor(
        loadExercisesInteractor: LoadExercisesInteractor,
        saveExerciseInteractor: SaveSingleExerciseInteractor,
        createSimilarInteractor: CreateSimilarInteractor,
        exercisesFilterInteractor: ExercisesFilterInteractor
    ) {
        makeAutoObservable(this);

        this.gridViewModel = new FilteredExercisesGridViewModel(
            loadExercisesInteractor,
            exercisesFilterInteractor
        );

        this.saveExerciseInteractor = saveExerciseInteractor;
        this.createSimilarInteractor = createSimilarInteractor;

        this.gridViewModel.load();
    }

    /**
     * Утилита добавить новое упражнение
     */
    addExercise(exercise: Exercise) {
        // Сохраняем в хранилище
        this.saveExerciseInteractor.saveExercise(exercise);
        // Добавляем в локальный список
        this.gridViewModel.exercises.push(exercise);
    }

    /**
     * Утилита удалить упражнение
     */
    removeExercise(exercise: Exercise) {
        this.gridViewModel.exercises = this.gridViewModel.exercises.filter(
            (e) => e.id !== exercise.id
        );
    }

    /**
     * Начинаем процесс редактирования (откроем Edit-модалку)
     */
    editExercise(exercise: Exercise) {
        this.previewingExercise = null;
        this.editingExercise = exercise;
    }

    /**
     * Сохраняем отредактированное упражнение
     */
    saveEditedExercise(exercise: Exercise) {
        const idx = this.gridViewModel.exercises.findIndex((e) => e.id === exercise.id);
        if (idx >= 0) {
            this.gridViewModel.exercises[idx] = exercise;
        } else {
            this.gridViewModel.exercises.push(exercise);
        }
        // Сохраняем в хранилище
        this.saveExerciseInteractor.saveExercise(exercise);
    }

    /**
     * Генерируем (создаем) упражнение, похожее на базовое
     */
    async generateSimilarExercise(basedOn: Exercise) {
        try {
            const newEx = await this.createSimilarInteractor.execute(basedOn);
            // Сразу предлагаем отредактировать (или как-то иначе показать пользователю)
            this.previewingExercise = null;
            this.editingExercise = newEx;
        } catch (error) {
            console.error("Failed to generate similar exercise:", error);
        }
    }
}