import { makeAutoObservable, runInAction, observable } from "mobx";
import { Exercise } from "../../../../../domain/models";
import LearningPlanEditInteractor from "../../Logic/LearningPlanEditInteractor";
import { LearningPlan } from "../../Models/LearningPlan";
import CompositionRoot from "../../../../../compositionRoot";

export class LearningPlanEditorViewModel {
    planName: string = "";
    exercises = observable.array<Exercise>([]);

    loading = false;
    error: string | null = null;

    private teacherId: string;
    private studentId: string;
    private planInteractor: LearningPlanEditInteractor;
    private planStorage = CompositionRoot.planStorage;

    constructor(teacherId: string, studentId: string) {
        this.teacherId = teacherId;
        this.studentId = studentId;
        this.planInteractor = new LearningPlanEditInteractor();
        makeAutoObservable(this);
    }

    subscribeToPlan() {
        this.loading = true;
        this.error = null;

        const unsubscribe = this.planStorage.subscribeToPlan(
            this.teacherId,
            this.studentId,
            (plan) => {
                runInAction(() => {
                    this.loading = false;
                    if (plan) {
                        this.planName = plan.name;
                        this.exercises.replace(plan.exercises);
                    } else {
                        // Если плана нет, очистим поля
                        this.planName = "";
                        this.exercises.clear();
                    }
                });
            }
        );

        return unsubscribe;
    }

    addExercises = (newExercises: Exercise[]) => {
        this.exercises.push(...newExercises);
        void this.savePlan();
    }

    deleteExercise = (index: number) => {
        this.exercises.splice(index, 1);
        void this.savePlan();
    }

    setExercises = (newOrder: Exercise[]) => {
        this.exercises.replace(newOrder);
        void this.savePlan();
    }

    async savePlan() {
        runInAction(() => {
            this.loading = true;
            this.error = null;
        });

        const plan: LearningPlan = {
            teacherId: this.teacherId,
            studentId: this.studentId,
            name: this.planName,
            exercises: this.exercises,
        };

        try {
            console.log("viewmodel saving plan: ", plan)
            await this.planInteractor.savePlan(plan);
        } catch (err: any) {
            runInAction(() => {
                this.error = err.message ?? "Failed to save plan";
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}