// src/data/implementations/FirestoreLearningPlanService.ts

import {
    doc,
    setDoc,
    getDoc,
    collection,
    getDocs,
    Timestamp, deleteDoc, QuerySnapshot, onSnapshot, writeBatch
} from "firebase/firestore";
import {PlanStorage} from "../../ui/components/PracticeManagement/Logic/PlanStorage";
import {LearningPlan} from "../../ui/components/PracticeManagement/Models/LearningPlan";
import {db} from "../config/firebase";
import {Exercise, Lesson} from "../../domain/models";
import {deserializeExercise, serializeExercise} from "./FirestoreExercises/ExerciseSerializer";

/**
 * Утилита для удаления undefined-полей (может быть уже есть в проекте)
 */
function removeUndefinedFields(obj: any): any {
    if (Array.isArray(obj)) {
        return obj.map(removeUndefinedFields);
    } else if (obj !== null && typeof obj === 'object') {
        return Object.entries(obj).reduce((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = removeUndefinedFields(value);
            }
            return acc;
        }, {} as any);
    }
    return obj;
}

/**
 * Реализация PlanStorage на Firestore.
 */
export class FirestoreLearningPlanService implements PlanStorage {
    async savePlan(plan: LearningPlan): Promise<void> {
        const planDocRef = doc(db, "learningPlans", plan.teacherId, "plans", plan.studentId);
        const batch = writeBatch(db);

        // Обновляем основной документ плана
        const planData = {
            teacherId: plan.teacherId,
            studentId: plan.studentId,
            name: plan.name,
            createdAt: Timestamp.now(),
        };
        batch.set(planDocRef, removeUndefinedFields(planData));

        // Работаем с подколлекцией "exercises"
        const exercisesColl = collection(planDocRef, "exercises");

        // Удаляем старые упражнения
        const oldExercises = await getDocs(exercisesColl);
        oldExercises.forEach(docSnap => {
            batch.delete(docSnap.ref);
        });

        // Добавляем новые упражнения
        plan.exercises.forEach((ex, i) => {
            const exRef = doc(exercisesColl, ex.id);
            const exData = serializeExercise(ex);
            exData.orderIndex = i;
            batch.set(exRef, removeUndefinedFields(exData));
        });

        // Коммитим батч-операцию
        await batch.commit();
    }

    async getPlan(teacherId: string, studentId: string): Promise<LearningPlan | null> {
        const planDocRef = doc(db, "learningPlans", teacherId, "plans", studentId);
        const planSnap = await getDoc(planDocRef);
        if (!planSnap.exists()) {
            return null;
        }

        const planData = planSnap.data();
        const exercisesColl = collection(planDocRef, "exercises");
        const exSnap = await getDocs(exercisesColl);

        const exercises: Exercise[] = exSnap.docs.map(docSnap => {
            const data = docSnap.data();
            const ex = deserializeExercise(data, docSnap.id);
            (ex as any).orderIndex = data.orderIndex;
            return ex as Exercise;
        });

        exercises.sort((a, b) => (a as any).orderIndex - (b as any).orderIndex);

        return {
            teacherId: planData.teacherId,
            studentId: planData.studentId,
            name: planData.name || "",
            exercises: exercises,
        };
    }

    subscribeToPlan(
        teacherId: string,
        studentId: string,
        callback: (plan: LearningPlan | null) => void
    ): () => void {
        const planDocRef = doc(db, "learningPlans", teacherId, "plans", studentId);

        // Будем хранить отписку от сабколлекции, чтобы пересоздавать её при необходимости
        let unsubscribeExercises: (() => void) | null = null;

        // Слушаем сам документ плана
        const unsubscribePlan = onSnapshot(planDocRef, async (docSnap) => {
            if (!docSnap.exists()) {
                // Если документа нет, отцепляемся от сабколлекции и шлём null
                if (unsubscribeExercises) {
                    unsubscribeExercises();
                    unsubscribeExercises = null;
                }
                callback(null);
                return;
            }

            // Документ плана существует
            const planData = docSnap.data();

            // Переподключаемся к сабколлекции exercises
            if (unsubscribeExercises) {
                unsubscribeExercises();
                unsubscribeExercises = null;
            }

            const exercisesColl = collection(planDocRef, "exercises");
            unsubscribeExercises = onSnapshot(exercisesColl, (exSnap) => {
                const exercises: Exercise[] = [];
                exSnap.forEach(exDoc => {
                    const data = exDoc.data();
                    const ex = deserializeExercise(data, exDoc.id);
                    (ex as any).orderIndex = data.orderIndex;
                    exercises.push(ex as Exercise);
                });

                exercises.sort((a, b) => (a as any).orderIndex - (b as any).orderIndex);

                callback({
                    teacherId,
                    studentId,
                    name: planData.name || "",
                    exercises: exercises,
                });
            });
        });

        // Общая отписка
        return () => {
            unsubscribePlan();
            if (unsubscribeExercises) {
                unsubscribeExercises();
            }
        };
    }
}