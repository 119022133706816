import { makeAutoObservable, reaction, runInAction } from "mobx";
import { WordItem } from "../../../../../domain/models";
import { Game } from "../Base/Game";
import { WordSearchUIHandler } from "./WordSearchUIHandler";
import { PracticeSessionDelegate } from "../../../../../domain/Exercises/PracticeSessionDelegate";
import { WordSearchUIState } from "./WordSearchUIState";
import { Position } from "../Base/types";
import { Placer3 } from "../Create/Placer3";
import { GridGenerating } from "../Create/GridGenerating";
import { SelectionState } from "../Base/SelectionState";

export class WordSearchInteractor {
    id: string = crypto.randomUUID().toUpperCase();
    // Слова, реально размещённые на сетке
    private words: WordItem[] = [];
    // Сам объект игры
    game: Game;
    // Вот тут теперь хранится MobX-класс SelectionState
    selectionState: SelectionState;
    // UI-хендлер (синхронизируем foundWords и выбранные позиции)
    uiHandler: WordSearchUIHandler;
    // Для учета, какие слова уже "подсказаны"
    hintedWords: WordItem[] = [];
    // Делегат для сохранения результатов и перехода на след. задание
    delegate?: PracticeSessionDelegate;

    constructor(
        words: WordItem[],
        size: { rowsCount: number; columnsCount: number } = { rowsCount: 10, columnsCount: 10 },
        delegate?: PracticeSessionDelegate,
        grid?: string[][],
        uiHandler?: WordSearchUIHandler
    ) {
        this.delegate = delegate;

        if (!grid) {
            const uppercased = words.map((w) => w.word.toUpperCase());
            const generator: GridGenerating = new Placer3(size.rowsCount, size.columnsCount);
            const [generatedGrid, placedWordsStrings] = generator.generateWordGrid(uppercased);
            const placedWordsItems = placedWordsStrings
                .map((wordStr) =>
                    words.find((w) => w.word.toLowerCase() === wordStr.toLowerCase())
                )
                .filter((w): w is WordItem => w !== undefined);
            this.words = placedWordsItems;
            this.game = new Game(
                placedWordsItems.map((w) => w.word),
                size,
                generatedGrid
            );
        } else {
            this.words = words;
            this.game = new Game(words.map((w) => w.word), size, grid);
        }

        // Создаём экземпляр SelectionState, чтобы реактивно хранить логику выделения
        this.selectionState = new SelectionState(this.game);

        // Инициализируем UIHandler (если нужно)
        const initialState: WordSearchUIState = { foundWords: [], selectedPositions: [] };
        this.uiHandler =
            uiHandler ?? new WordSearchUIHandler(crypto.randomUUID().toUpperCase(), undefined, initialState);

        // Настраиваем MobX-рекции, чтобы поддерживать синхронизацию
        makeAutoObservable(this);

        this.setupBindings();

        // Первичная синхронизация
        setTimeout(() => {
            this.syncFromUIHandler();
        }, 0);
    }

    private syncFromUIHandler(): void {
        // Синхронизируем найденные слова
        const newUIFoundWords = this.uiHandler.foundWords.map((w) => w.word.toUpperCase());
        newUIFoundWords.forEach((word) => {
            if (!this.game.foundWords.has(word)) {
                runInAction(() => {
                    this.game.setWordFound(word);
                });
            }
        });

        // Синхронизируем выделенные позиции
        if (
            JSON.stringify(this.uiHandler.selectedPositions) !==
            JSON.stringify(this.selectionState.selectedPositions)
        ) {
            runInAction(() => {
                this.selectionState.selectedPositions = this.uiHandler.selectedPositions.slice();
            });
        }
    }

    /**
     * Отправляем текущее локальное состояние (this.game, this.selectionState)
     * обратно в uiHandler (foundWords, selectedPositions).
     */
    private syncToUIHandler(): void {
        // Обновляем foundWords, чтобы UI видел последние изменения
        const computedFoundWords = this.foundWords; // WordItem[]
        const currentUIWords = this.uiHandler.foundWords.map((w) => w.word.toLowerCase());
        const newWords = computedFoundWords.map((w) => w.word.toLowerCase());
        if (JSON.stringify(newWords) !== JSON.stringify(currentUIWords)) {
            runInAction(() => {
                this.uiHandler.foundWords = computedFoundWords;
            });
        }

        // Обновляем выделенные позиции
        const newPositions = this.selectionState.selectedPositions.slice();
        if (JSON.stringify(newPositions) !== JSON.stringify(this.uiHandler.selectedPositions)) {
            runInAction(() => {
                this.uiHandler.selectedPositions = newPositions;
            });
        }
    }


    /** Синхронизируем foundWords и selectedPositions с UIHandler */
    private setupBindings() {
        // 1) Когда в game.foundWords что-то меняется => обновляем uiHandler.foundWords
        reaction(
            () => Array.from(this.game.foundWords),
            () => {
                const computedFoundWords = this.foundWords;
                const currentUIWords = this.uiHandler.foundWords.map((w) => w.word.toLowerCase());
                const newWords = computedFoundWords.map((w) => w.word.toLowerCase());
                if (JSON.stringify(newWords) !== JSON.stringify(currentUIWords)) {
                    runInAction(() => {
                        this.uiHandler.foundWords = computedFoundWords;
                    });
                }
            }
        );

        // 2) Если uiHandler.foundWords изменился извне => отмечаем эти слова как найденные в game
        reaction(
            () => this.uiHandler.foundWords.slice(),
            (newUIFoundWords) => {
                const newSet = new Set(newUIFoundWords.map((w) => w.word.toUpperCase()));
                newSet.forEach((word) => {
                    if (!this.game.foundWords.has(word)) {
                        this.game.setWordFound(word);
                    }
                });
            }
        );

        // 3) selectionState.selectedPositions => uiHandler
        reaction(
            () => this.selectionState.selectedPositions.slice(),
            (newPositions) => {
                if (JSON.stringify(newPositions) !== JSON.stringify(this.uiHandler.selectedPositions)) {
                    runInAction(() => {
                        this.uiHandler.selectedPositions = newPositions;
                    });
                }
            }
        );

        // 4) uiHandler.selectedPositions => selectionState
        reaction(
            () => this.uiHandler.selectedPositions.slice(),
            (newPositions) => {
                if (JSON.stringify(newPositions) !== JSON.stringify(this.selectionState.selectedPositions)) {
                    runInAction(() => {
                        this.selectionState.selectedPositions = newPositions;
                    });
                }
            }
        );
    }

    // --- Геттеры, чтобы удобно получать данные из Interactor ---

    get placedWords(): WordItem[] {
        const gameWordsLower = this.game.words.map((w) => w.toLowerCase());
        return this.words.filter((w) => gameWordsLower.includes(w.word.toLowerCase()));
    }

    get foundWords(): WordItem[] {
        const foundLower = new Set(Array.from(this.game.foundWords).map((w) => w.toLowerCase()));
        const foundWords = this.placedWords.filter((w) => foundLower.has(w.word.toLowerCase()));
        return foundWords
    }

    get actionButtonState(): "hint" | "next" {
        return this.foundWords.length === this.placedWords.length ? "next" : "hint";
    }

    // --- Методы для кнопок ---

    markAllFound() {
        // Синхронизируем локальное состояние и UI
        this.syncFromUIHandler();
        runInAction(() => {
            this.game.markAllFound();
        });
        // Отправляем обратно в UI
        this.syncToUIHandler();
    }


    private saveResult() {
        for (const wordItem of this.foundWords) {
            const individualSuccess = !this.hintedWords.includes(wordItem);
            // Если нужно, передаём эту инфу в делегат / сохраняем результат
            // this.delegate?.saveAttempt( ... );
        }
    }

    goToNext() {
        this.saveResult();
        this.delegate?.currentStepDone();
    }

    hint() {
        this.syncFromUIHandler();

        const wordsPositions = this.game.findWordsPositions();
        const unfoundWords = Object.keys(wordsPositions).filter(
            (w) => !this.game.foundWords.has(w)
        );
        if (unfoundWords.length === 0) return;

        // Берём случайное не найденное слово
        const randomWord = unfoundWords[Math.floor(Math.random() * unfoundWords.length)];
        const positions = wordsPositions[randomWord];
        if (!positions) return;

        // Отмечаем, что для этого слова была подсказка
        const wordItem = this.placedWords.find(
            (w) => w.word.toUpperCase() === randomWord.toUpperCase()
        );
        if (wordItem) {
            this.hintedWords.push(wordItem);
        }

        // "Подсвечиваем" ячейки через selectionState, чтобы пользователь увидел
        setTimeout(() => {
            this.selectionState.simulateSelection(positions);
        }, 0);

        this.syncToUIHandler();
    }
}