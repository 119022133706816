// AnagramViewBase.swift -> AnagramViewBase.tsx
import React from 'react';
import { BaseVStack } from '../../BaseComponents/BaseVStack';
import { BaseHStack } from '../../BaseComponents/BaseHStack';
import { BaseText } from '../../BaseComponents/BaseText';
import { BaseView } from '../../BaseComponents/BaseView';

interface AnagramViewBaseProps {
    title?: string;
    displayedWord: string;
    currentAnswer: string[];
    scrambledLetters: string[];
    onLetterTap?: (index: number) => void;
    onAnswerTap?: (index: number) => void;
}

export function AnagramViewBase({
                                    title = "Assemble the word",
                                    displayedWord,
                                    currentAnswer,
                                    scrambledLetters,
                                    onLetterTap = () => {},
                                    onAnswerTap = () => {}
                                }: AnagramViewBaseProps) {
    return (
        <BaseVStack padding={16}>
            <BaseText font="headline" paddingBottom={10}>
                {title}
            </BaseText>

            <BaseText font="title" paddingBottom={20}>
                {displayedWord}
            </BaseText>

            <BaseHStack padding={0} paddingBottom={20} style={{justifyContent: 'center'}}>
                {currentAnswer.map((char, index) => (
                    <BaseText
                        key={index}
                        font="largeTitle"
                        frameWidth={40}
                        frameHeight={40}
                        backgroundColor="rgba(128,128,128,0.2)"
                        cornerRadius={4}
                        onClick={() => onAnswerTap(index)}
                    >
                        {char}
                    </BaseText>
                ))}
            </BaseHStack>

            <BaseText font="headline" paddingBottom={10}>
                Available letters:
            </BaseText>

            <BaseHStack style={{justifyContent: 'center'}}>
                {scrambledLetters.map((char, index) => (
                    <BaseText
                        key={index}
                        font="title"
                        frameWidth={40}
                        frameHeight={40}
                        backgroundColor="rgba(0,0,255,0.2)"
                        cornerRadius={4}
                        onClick={() => onLetterTap(index)}
                    >
                        {char}
                    </BaseText>
                ))}
            </BaseHStack>
        </BaseVStack>
    );
}
