import { CrosswordCellView } from "./CrosswordCellView";
import { Position } from "../../../../../domain/models";
import { useCallback, useEffect, useMemo, useState } from "react";

interface CrosswordGridModel {
    occupiedPositions: Position[];
    startPositions: { [key: string]: number };
    gridData: string[][];
    minRow: number;
    maxRow: number;
    minCol: number;
    maxCol: number;
    cellSize: number;
}

interface CrosswordGridViewProps {
    model: CrosswordGridModel;
    isPositionEditable: (pos: Position) => boolean;
    handleGridUpdate: (pos: Position, newVal: string) => void;
    handleLetterChange: (pos: Position) => Position | undefined;
    clearCellContent: (pos: Position) => void;
}

export function CrosswordGridView({
                                      model,
                                      isPositionEditable,
                                      handleGridUpdate,
                                      handleLetterChange,
                                      clearCellContent,
                                  }: CrosswordGridViewProps) {
    const [focusedCell, setFocusedCell] = useState<Position | null>(null);

    // Функция для получения значения ячейки по позиции
    const letterAt = useCallback(
        (pos: Position): string => {
            const r = pos.row - model.minRow;
            const c = pos.col - model.minCol;
            if (r < 0 || r >= model.gridData.length) return "";
            if (c < 0 || c >= model.gridData[r].length) return "";
            return model.gridData[r][c];
        },
        [model]
    );

    // Вычисляем уникальные позиции, чтобы не было дубликатов
    const uniqueSortedPositions = useMemo(() => {
        const posMap = new Map<string, Position>();
        model.occupiedPositions.forEach((pos) => {
            const key = `${pos.row},${pos.col}`;
            if (!posMap.has(key)) {
                posMap.set(key, pos);
            }
        });
        return Array.from(posMap.values()).sort((p1, p2) => {
            if (p1.row === p2.row) return p1.col - p2.col;
            return p1.row - p2.row;
        });
    }, [model.occupiedPositions]);

    const frameWidth = (model.maxCol - model.minCol + 1) * model.cellSize + 20;
    const frameHeight = (model.maxRow - model.minRow + 1) * model.cellSize + 20;

    // При изменении фокуса, если ячейка уже заполнена, очищаем её
    useEffect(() => {
        if (!focusedCell) return;
        const letter = letterAt(focusedCell);
        if (letter.trim() !== "") {
            clearCellContent(focusedCell);
        }
    }, [focusedCell, clearCellContent, letterAt]);

    const xPos = (col: number): number => {
        const offsetCols = col - model.minCol;
        return offsetCols * model.cellSize + model.cellSize / 2 + 10;
    };

    const yPos = (row: number): number => {
        const offsetRows = row - model.minRow;
        return offsetRows * model.cellSize + model.cellSize / 2 + 10;
    };

    return (
        <div
            style={{
                position: "relative",
                width: frameWidth,
                height: frameHeight,
                border: "2px solid black",
                boxSizing: "border-box",
            }}
        >
            {uniqueSortedPositions.map((pos) => {
                const key = `${pos.row},${pos.col}`;
                const currentLetter = letterAt(pos);
                const wordNumber = model.startPositions[key];
                const editable = isPositionEditable(pos);

                const onCellUpdate = (newVal: string) => {
                    handleGridUpdate(pos, newVal);
                };

                const onCellChange = () => {
                    const newFocus = handleLetterChange(pos);
                    if (newFocus) {
                        setFocusedCell(newFocus);
                    }
                };

                const onCellFocusRequest = () => {
                    setFocusedCell(pos);
                };

                const cellStyle: React.CSSProperties = {
                    position: "absolute",
                    left: xPos(pos.col),
                    top: yPos(pos.row),
                    transform: "translate(-50%, -50%)",
                };

                return (
                    <div key={key} style={cellStyle}>
                        <CrosswordCellView
                            position={pos}
                            letter={currentLetter}
                            wordNumber={wordNumber}
                            cellSize={model.cellSize}
                            editable={editable}
                            onLetterChange={(val) => {
                                onCellUpdate(val);
                            }}
                            onChange={onCellChange}
                            onFocusRequest={onCellFocusRequest}
                        />
                    </div>
                );
            })}
        </div>
    );
}