// FreePracticeViewModel.ts
import { makeAutoObservable, reaction } from "mobx";
import { FreePracticeState } from "./FreePracticeState";
import { FreePracticeInteractor } from "./FreePracticeInteractor";
import {Exercise} from "../../../../../domain/models";
import {PracticeSessionDelegate} from "../../../../../domain/Exercises/PracticeSessionDelegate";
import {FreePracticeUIHandler} from "./FreePracticeUIHandler";
import {FreePracticeUseCase} from "./FreePracticeUseCase";

export class FreePracticeViewModel {
    private interactor: FreePracticeInteractor;
    public state: FreePracticeState;

    public focusedFieldId: string | null = null;

    constructor(exercise: Exercise, delegate?: PracticeSessionDelegate, uiHandler?: FreePracticeUIHandler, useCase?: FreePracticeUseCase) {
        this.interactor = new FreePracticeInteractor(exercise, delegate, uiHandler, useCase);
        this.state = this.interactor.state;

        // Listen for state changes from the interactor
        this.interactor.onStateChange = (newState) => {
            this.state = newState;
        };

        makeAutoObservable(this);
    }

    public setFocusedField(id: string | null) {
        this.focusedFieldId = id;
    }

    public updateField(fieldId: string, newValue: string) {
        this.interactor.updateFieldValue(fieldId, newValue);
    }

    public updateHtml(newHtml: string) {
        this.interactor.updateHtml(newHtml);
    }
}