import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { HtmlGenerationViewModel } from "./HtmlGenerationViewModel";
import ImageSelector from "./ImageSelector";
import HtmlEditor from "./HtmlEditor";
import BaseButton, { ButtonVariant } from "../../../BaseButton";

interface HtmlGenerationViewProps {
    viewModel: HtmlGenerationViewModel;
}

/**
 * Главный компонент, объединяющий ImageSelector и HtmlEditor
 */
export const HtmlGenerationView = observer(({ viewModel }: HtmlGenerationViewProps) => {
    useEffect(() => {
        console.log("parsedHtml: ", viewModel.parsedHtml);
        console.log("generatedHtml: ", viewModel.generatedHtml);
    }, [viewModel.parsedHtml, viewModel.generatedHtml]);

    const handleGenerate = async () => {
        await viewModel.generate();
    };

    const handleImprove = async () => {
        await viewModel.improveHtml();
    };

    const handleImprovementChange = (text: string) => {
        viewModel.setImprovementComment(text);
    };

    const handleImageSelect = async (file: File) => {
        viewModel.setImageFile(file);
        viewModel.generatedHtml = null;  // сбрасываем предыдущий результат
        await viewModel.generate();       // генерируем сразу же
    };

    const imageFile = viewModel.imageFile;
    const imageUrl = imageFile ? URL.createObjectURL(imageFile) : null;

    const handleAddAudio = () => {
        viewModel.addAudio();
    };

    return (
        <div style={{ maxWidth: 600, margin: "0 auto", position: "relative",
            width: "100%",                  // Во всю ширину контейнера-родител
        }}>
            {/* ImageSelector всегда виден, чтобы после генерации HTML картинка продолжала отображаться */}
            <ImageSelector
                imageUrl={imageUrl}
                isLoading={viewModel.isLoading}
                onImageSelect={handleImageSelect}
            />

            {/* HtmlEditor показывается, если идёт процесс (спиннер) или уже есть HTML */}
            {(viewModel.isLoading || viewModel.generatedHtml) && (
                <div style={{ marginTop: 20 }}>
                    <HtmlEditor
                        isLoading={viewModel.isLoading}
                        generatedHtml={viewModel.generatedHtml}
                        parsedHtml={viewModel.parsedHtml}
                        improvementComment={viewModel.improvementComment}
                        onImprovementCommentChange={handleImprovementChange}
                        onImprove={handleImprove}
                        onAddAudio={handleAddAudio}
                    />
                </div>
            )}
        </div>
    );
});