// CanvasHistory.ts
import { CanvasItem } from "./CanvasItem";

export class CanvasHistory {
    private undoStack: CanvasItem[][] = [];
    private redoStack: CanvasItem[][] = [];
    private isRecording: boolean = true;

    // Ограничение на количество состояний, которые мы храним
    private maxHistorySize: number = 100;

    constructor() {}

    // Добавляем новое состояние в историю
    public pushState(items: CanvasItem[]): void {
        if (!this.isRecording) return;

        // Создаем глубокую копию массива элементов, чтобы избежать мутаций
        const snapshot = JSON.parse(JSON.stringify(items));

        // Добавляем в стек отмены
        this.undoStack.push(snapshot);

        // Очищаем стек повтора при новых действиях
        this.redoStack = [];

        // Ограничиваем размер истории
        if (this.undoStack.length > this.maxHistorySize) {
            this.undoStack.shift(); // Удаляем самое старое состояние
        }
    }

    // Отмена действия
    public undo(currentItems: CanvasItem[]): CanvasItem[] | null {
        if (this.undoStack.length === 0) return null;

        // Временно отключаем запись истории, чтобы избежать
        // добавления отмененного действия в историю
        this.isRecording = false;

        // Сохраняем текущее состояние в стек повтора
        const currentSnapshot = JSON.parse(JSON.stringify(currentItems));
        this.redoStack.push(currentSnapshot);

        // Возвращаем предыдущее состояние
        const previousState = this.undoStack.pop() || null;

        // Включаем запись обратно
        this.isRecording = true;

        return previousState;
    }

    // Повтор действия
    public redo(currentItems: CanvasItem[]): CanvasItem[] | null {
        if (this.redoStack.length === 0) return null;

        // Временно отключаем запись истории
        this.isRecording = false;

        // Сохраняем текущее состояние в стек отмены
        const currentSnapshot = JSON.parse(JSON.stringify(currentItems));
        this.undoStack.push(currentSnapshot);

        // Возвращаем следующее состояние
        const nextState = this.redoStack.pop() || null;

        // Включаем запись обратно
        this.isRecording = true;

        return nextState;
    }

    // Очистка истории
    public clear(): void {
        this.undoStack = [];
        this.redoStack = [];
    }

    // Геттеры для проверки возможности отмены/повтора
    public get canUndo(): boolean {
        return this.undoStack.length > 0;
    }

    public get canRedo(): boolean {
        return this.redoStack.length > 0;
    }
}