import { Exercise, ExerciseFree } from "../../../domain/models";

export function serializeFree(exercise: Exercise): any {
    if (exercise.type.kind !== 'free') {
        throw new Error("serializeFree called with non-free exercise");
    }

    const freeData: ExerciseFree = exercise.type.data;
    return {
        type: 'free',
        authorId: exercise.authorId,
        description: exercise.description,
        htmlString: freeData.htmlString,
    };
}

export function deserializeFree(data: any, id: string): Exercise | null {
    const { htmlString } = data;
    if (typeof htmlString !== 'string') {
        return null;
    }

    return {
        id,
        authorId: data.authorId,
        description: data.description,
        type: {
            kind: 'free',
            data: {
                htmlString,
            },
        },
    };
}