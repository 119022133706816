// src/ui/screens/student/StudentWordsList.tsx

import React, { useEffect, useState } from 'react';
import WordsViewModel from "../../../viewmodels/WordsViewModel";
import {WordItem} from "../../../../domain/models";
import FirestoreWordsService from "../../../../data/implementations/FirestoreWordsService";

interface StudentWordsListProps {
    studentId: string;
}

const StudentWordsList: React.FC<StudentWordsListProps> = ({ studentId }) => {
    const [wordsViewModel, setWordsViewModel] = useState<WordsViewModel | null>(null);
    const [words, setWords] = useState<WordItem[]>([]);

    useEffect(() => {
        // Создаем WordsService для конкретного студента
        const serviceForStudent = FirestoreWordsService.getInstance().wordsService(studentId);
        const vm = new WordsViewModel(serviceForStudent);
        setWordsViewModel(vm);
        setWords(vm.words);

        // Подписываемся на обновления списка слов
        const subscription = vm.subscribeToWordsUpdates().subscribe((updatedWords) => {
            setWords(updatedWords);
        });

        return () => {
            subscription.unsubscribe();
            vm.dispose();
        };
    }, [studentId]);

    return (
        <div style={styles.container}>
            <h3>Student's Words ({words.length})</h3>
            {words.length === 0 ? (
                <div>No words yet.</div>
            ) : (
                <ul>
                    {words.map((word) => (
                        <li key={word.id}>
                            <strong>{word.word}</strong> — {word.translation}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default StudentWordsList;

const styles: Record<string, React.CSSProperties> = {
    container: {
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '4px',
        marginTop: '10px',
    },
};