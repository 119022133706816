import React from "react";
import BaseButton, {ButtonVariant} from "../../../ui/components/BaseButton";

export const TeacherActions: React.FC<{ navigate: (path: string) => void }> = ({navigate}) => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            <BaseButton
                color="#0070d2"
                onClick={() => navigate('/allExercises')}
                variant={ButtonVariant.Big}
            >
                My exercises
            </BaseButton>

            <div style={{ height: '20px' }} />

            <BaseButton
                color="#0070d2"
                onClick={() => navigate('/createLesson')}
                variant={ButtonVariant.Big}
            >
                Create lesson
            </BaseButton>

            <BaseButton
                color="#0070d2"
                onClick={() => navigate('/lessonsList?mode=my')}
                variant={ButtonVariant.Big}
            >
                My lessons
            </BaseButton>
            <BaseButton
                color="#0070d2"
                onClick={() => navigate('/lessonsList?mode=all')}
                variant={ButtonVariant.Big}
            >
                All lessons
            </BaseButton>

            <div style={{ height: '20px' }} />

            <BaseButton
                color="#0070d2"
                onClick={() => navigate('/students')}
                variant={ButtonVariant.Big}
            >
                My students
            </BaseButton>
        </div>
    );
};