import { makeAutoObservable, runInAction } from "mobx";
import { ExerciseUIHandler } from "../../../../../domain/Multiplayer/ExerciseUIHandler";
import { SessionStateInteractor } from "../../../../../domain/Multiplayer/SessionStateInteractor";
import { ExerciseUIState } from "../../../../../domain/Multiplayer/ExerciseUIState";
import { MissingWordUIState } from "./MissingWordUIState";

/**
 * Класс-«прокладка» между локальной логикой и сетевой логикой (SessionStateInteractor).
 * Следит за изменениями локального стейта и при необходимости отправляет их по сети.
 * Аналогично — если пришли изменения по сети, обновляет локальный стейт.
 */
export class MissingWordUIHandler implements ExerciseUIHandler {
    public currentInput: string;
    public isCorrect: boolean | null;

    private exerciseId: string;
    private interactor?: SessionStateInteractor;
    private isUpdatingFromServer = false;
    private currentUIState: MissingWordUIState;

    constructor(
        exerciseId: string,
        interactor?: SessionStateInteractor,
        initialState?: MissingWordUIState
    ) {
        this.exerciseId = exerciseId;
        this.interactor = interactor;

        // Если initialState не задан, то создаём пустое
        this.currentUIState =
            initialState || new MissingWordUIState("", null);

        this.currentInput = this.currentUIState.currentInput;
        this.isCorrect = this.currentUIState.isCorrect;

        makeAutoObservable(this);
    }

    /**
     * Метод, который вызывается снаружи при получении нового состояния с сервера.
     */
    public updateUIState(uiState: ExerciseUIState | undefined): void {
        if (!uiState || uiState.type !== "missingWord") {
            return;
        }

        const newState = uiState.data;
        // Сравним старое и новое состояние
        if (!newState.equals(this.currentUIState)) {
            this.isUpdatingFromServer = true;
            runInAction(() => {
                this.currentUIState = newState;
                this.currentInput = newState.currentInput;
                this.isCorrect = newState.isCorrect;
            });
            this.isUpdatingFromServer = false;
        }
    }

    /**
     * Отправить состояние в сеть, если оно изменилось.
     */
    public sendUpdateIfNeeded() {
        if (this.isUpdatingFromServer) return;
        const newState = new MissingWordUIState(
            this.currentInput,
            this.isCorrect,
        );

        if (newState.equals(this.currentUIState)) return;

        // Обновляем локальную копию
        this.currentUIState = newState;

        // Передаём в SessionStateInteractor (если он есть)
        this.interactor?.updateMissingWordState(this.exerciseId, newState);
    }
}