// CreateExerciseFlowStep.ts

import {ExerciseCreationType} from "../../Models/ExerciseCreationType";

export enum CreateExerciseFlowStep {
    selectType = 1,
    settings = 2,
}

export type CreateExerciseFlowState =
    | { step: CreateExerciseFlowStep.selectType }
    | { step: CreateExerciseFlowStep.settings; selectedExerciseType: ExerciseCreationType };