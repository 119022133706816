// ---- src/ui/screens/confirmTutor/ConfirmTutorViewModel.ts ----
import {makeAutoObservable, runInAction} from 'mobx';
import ConfirmTutorInteractor from './ConfirmTutorInteractor';
import CompositionRoot from "../../../../compositionRoot";
import {AppUser} from "../../../../domain/models";

export default class ConfirmTutorViewModel {
    loadingUser = true;          // Отдельный флажок для загрузки пользователя
    loadingConfirm = false;      // Флажок для загрузки при подтверждении
    error: string | null = null;
    confirmed = false;

    student: AppUser | null = null;  // Текущий пользователь (студент)
    tutorInfo: AppUser | null = null; // Инфа о предполагаемом преподавателе

    private tutorId: string | null = null;
    private unsubscribeFn: (() => void) | null = null;

    constructor(private interactor: ConfirmTutorInteractor = new ConfirmTutorInteractor()) {
        makeAutoObservable(this);
    }

    /**
     * Стартовая инициализация:
     * 1) Подписываемся на текущего пользователя (студента)
     * 2) Как только получили пользователя, грузим данные преподавателя
     */
    init(tutorId: string) {
        this.tutorId = tutorId;

        // 1. Подписываемся на изменения currentUser
        this.unsubscribeFn = this.interactor.subscribeToCurrentUser((user) => {
            runInAction(() => {
                this.student = user;
                this.loadingUser = false;
                // Если user пришёл впервые, можем тут же попытаться загрузить TutorInfo:
                // но делаем это только если у нас ещё нет ошибок или подтверждений
                if (!this.error && !this.confirmed && this.tutorId) {
                    this.loadTutorInfo(this.tutorId);
                }
            });
        });
    }

    /**
     * Запрашиваем информацию о предполагаемом преподавателе
     */
    private async loadTutorInfo(tutorId: string) {
        try {
            const tutor = await this.interactor.getTutorInfo(tutorId);
            runInAction(() => {
                if (!tutor) {
                    this.error = "Tutor not found in the system";
                }
                this.tutorInfo = tutor;
            });
        } catch (e: any) {
            runInAction(() => {
                this.error = e.message || "Failed to load tutor info";
            });
        }
    }

    /**
     * Студент нажимает кнопку "Confirm"
     */
    async confirmTutor() {
        if (!this.student) {
            this.error = "Current user (student) not loaded yet";
            return;
        }
        if (!this.tutorId) {
            this.error = "No tutorId specified";
            return;
        }

        try {
            this.loadingConfirm = true;
            await this.interactor.confirmTutor(this.tutorId, this.student.uid);
            runInAction(() => {
                this.confirmed = true;
            });
        } catch (e: any) {
            runInAction(() => {
                this.error = e.message || "Failed to confirm tutor";
            });
        } finally {
            runInAction(() => {
                this.loadingConfirm = false;
            });
        }
    }

    /**
     * Вызвать при размонтировании экрана, чтобы отписаться от подписчика
     */
    dispose() {
        this.unsubscribeFn?.();
    }
}