import React from "react";

interface PracticeSelectOptionBaseViewProps {
    sentence: string;
    options: string[];
    selectedOption: string | null;
    isCorrect: boolean | null;
    correctOption: string;
    onOptionPressed: (option: string) => void;
    onNext: () => void;
}

export const PracticeSelectOptionBaseView: React.FC<PracticeSelectOptionBaseViewProps> = ({
                                                                                              sentence,
                                                                                              options,
                                                                                              selectedOption,
                                                                                              isCorrect,
                                                                                              correctOption,
                                                                                              onOptionPressed,
                                                                                              onNext,
                                                                                          }) => {
    return (
        <div style={{width: "100%", minWidth: '400px',}}>
            <h2>{sentence}</h2>
            {options.map((option, index) => {
                // Определяем, выделять ли эту кнопку:
                const isThisOptionSelected = selectedOption === option;
                const buttonStyle: React.CSSProperties = isThisOptionSelected
                    ? { backgroundColor: "yellow", fontWeight: "bold" }
                    : {};

                return (
                    <button
                        key={index}
                        onClick={() => onOptionPressed(option)}
                        style={buttonStyle}
                    >
                        {option}
                    </button>
                );
            })}

            {isCorrect === false && (
                <div>
                    <p style={{ color: "red" }}>Wrong</p>
                    <p>Correct answer: {correctOption}</p>
                </div>
            )}

            {isCorrect === true && (
                <div>
                    <p style={{ color: "green" }}>Correct</p>
                    <p>Correct answer: {correctOption}</p>
                </div>
            )}
        </div>
    );
};