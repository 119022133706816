import React from "react";
import { observer } from "mobx-react-lite";
import { JustContentPracticeViewModel } from "./JustContentPracticeViewModel";
import {CanvasContainer} from "../Base/CanvasContainer";

interface JustContentPracticeViewProps {
    viewModel: JustContentPracticeViewModel;
    width?: string;
    height?: string;
}

export const JustContentPracticeView: React.FC<JustContentPracticeViewProps> = observer(
    ({ viewModel, width, height }) => {
        return (
            <div id={"12345"} style={{ display: "flex", height: "100%", width: "100%" }}>
                <div id={"123456"} style={{ flex: 1 }}>
                    <CanvasContainer
                        viewModel={viewModel.canvasViewModel}
                        height={height}
                    />
                </div>
            </div>
        );
    }
);