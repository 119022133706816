// FreePracticeUIState.ts

export class FreePracticeUIState {
    type = "free" as const;

    /**
     * The raw HTML string for this "Free" exercise.
     */
    htmlString: string;

    /**
     * A dictionary of input values keyed by fieldId (if you have multiple inputs).
     * This can be anything the UI needs to reconstruct the full state.
     */
    fields: Record<string, string>;

    constructor(htmlString: string, fields?: Record<string, string>) {
        this.htmlString = htmlString;
        this.fields = fields || {};
    }

    /**
     * Compare two UI states for equality (to avoid redundant updates).
     */
    public equals(other: FreePracticeUIState): boolean {
        return (
            this.htmlString === other.htmlString &&
            JSON.stringify(this.fields) === JSON.stringify(other.fields)
        );
    }
}