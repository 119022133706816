import React from "react";
import BaseButton, {ButtonVariant} from "../../../ui/components/BaseButton";

export const ClassButton: React.FC<{ navigate: (path: string) => void; currentClass: string }> = ({
                                                                                               navigate,
                                                                                               currentClass,
                                                                                           }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <BaseButton
                color="#0070d2"
                variant={ButtonVariant.Big}
                onClick={() => navigate(`/class/${currentClass}`)}
            >
                Enter class
            </BaseButton>
        </div>
    );
};