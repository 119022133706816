import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { FilteredExercisesGridViewModel } from "./FilteredExercisesGridViewModel";
import { Exercise } from "../../../../../domain/models";
import { ExerciseTextPreview } from "../../../exercises_types/ExerciseTextPreview";
import { ExerciseTypeFilterView } from "./ExerciseTypeFilterView";

interface FilteredExercisesGridViewProps {
    viewModel: FilteredExercisesGridViewModel;
    onSelect?: (exercise: Exercise) => void;
    onContextMenu?: (exercise: Exercise, e: React.MouseEvent<HTMLDivElement>) => void;
    cardStyle?: React.CSSProperties;
}

export const FilteredExercisesGridView = observer((props: FilteredExercisesGridViewProps) => {
    const { viewModel, onSelect, onContextMenu, cardStyle } = props;

    useEffect(() => {
        viewModel.load();
    }, [viewModel]);

    return (
        <div>
            {/* Фильтр по типу упражнения */}
            <div style={{ padding: 16 }}>
                <ExerciseTypeFilterView
                    value={viewModel.selectedFilter}
                    onChange={(newVal) => {
                        viewModel.selectedFilter = newVal;
                    }}
                />
            </div>

            {/* Сетка упражнений */}
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                    gap: "16px",
                    padding: "16px",
                }}
            >
                {viewModel.filteredExercises.map((exercise) => (
                    <ExerciseTextPreview
                        key={exercise.id}
                        exercise={exercise}
                        style={cardStyle ?? { border: "1px solid #ccc", cursor: "pointer" }}
                        onClick={() => onSelect?.(exercise)}
                        onContextMenu={(e) => onContextMenu?.(exercise, e)}
                    />
                ))}
            </div>
        </div>
    );
});