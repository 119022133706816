import {makeAutoObservable, runInAction} from "mobx";
import { HtmlGenerationInteractor } from "./HtmlGenerationInteractor";
import React from "react";
import {parseHtmlWithImagesAndAudio} from "../Create/parseHtmlWithImages";
import {WordItem} from "../../../../../domain/models";
import FirestoreWordsService from "../../../../../data/implementations/FirestoreWordsService";
import {Subscription} from "rxjs";
import WordsViewModel from "../../../../viewmodels/WordsViewModel";

export class HtmlGenerationViewModel {
    imageFile: File | null = null;
    comment: string = "";
    generatedHtml: string | null = null;
    isLoading: boolean = false;
    words: WordItem[] = [];

    private wordsSubscription: Subscription | null = null;
    useStudentWords: boolean = true;

    /**
     * New field: what the user wants to improve about the generated HTML.
     */
    improvementComment: string = "";

    constructor(private readonly interactor: HtmlGenerationInteractor) {
        makeAutoObservable(this);
    }

    loadStudentWords(studentId: string) {
        // 1. Получаем сервис, умеющий работать со словами нужного студента
        const serviceForStudent = FirestoreWordsService
            .getInstance()
            .wordsService(studentId);

        const vm = new WordsViewModel(serviceForStudent);

        // 2. (Опционально) если уже есть старая подписка — отписываемся
        this.wordsSubscription?.unsubscribe();

        // 3. Подписываемся на обновления, сохраняем subscription
        const subscription = vm.subscribeToWordsUpdates().subscribe(wordItems => {
            // Меняем observable-свойство в экшене MobX
            runInAction(() => {
                this.words = wordItems;
            });
        });

        this.wordsSubscription = subscription;
    }

    dispose() {
        // Чтобы избежать утечек подписок, обязательно отписываемся
        this.wordsSubscription?.unsubscribe();
        this.wordsSubscription = null;
    }


    setImageFile(file: File | null) {
        this.imageFile = file;
    }

    setComment(text: string) {
        this.comment = text;
    }

    setImprovementComment(text: string) {
        this.improvementComment = text;
    }

    setUseStudentWords(value: boolean) {
        this.useStudentWords = value;
    }

    /**
     * 1) Generate initial HTML from image+comment
     */
    async generate() {
        this.isLoading = true;
        try {
            this.generatedHtml = await this.interactor.generateHtml(
                this.imageFile,
                this.comment,
                this.words
            );
        } finally {
            this.isLoading = false;
        }
    }

    /**
     * 2) Improve existing HTML with new user instructions
     */
    async improveHtml() {
        if (!this.generatedHtml) return;
        if (!this.improvementComment.trim()) return;

        this.isLoading = true;
        try {
            const improved = await this.interactor.improveHtml(
                this.generatedHtml,
                this.improvementComment
            );
            this.generatedHtml = improved;
        } finally {
            this.isLoading = false;
        }
    }

    /**
     * Returns "React-элементы", полученные путём парсинга this.generatedHtml
     * (если она не null). Каждый <img> превращается в CustomImageElement,
     * при замене src вызывается replaceImageSrcInGeneratedHtml().
     */
    get parsedHtml(): React.ReactNode {
        if (!this.generatedHtml) return null;

        return parseHtmlWithImagesAndAudio(
            this.generatedHtml,
            (oldSrc, newSrc) => {
                this.replaceImageSrcInGeneratedHtml(oldSrc, newSrc);
            },
            (oldAudioId, newAudioId) => {
                this.replaceAudioIdInGeneratedHtml(oldAudioId, newAudioId);
            },
            {
                // пробросим коллбеки AudioPlayerCallbacks (необязательно)
                onError: (msg) => console.error(msg),
                onTextLoaded: (txt) => console.log("Text loaded:", txt),
                onLoadComplete: (audioId) => {
                    console.log("Audio loaded, id:", audioId);
                }
            }
        );
    }

    /**
     * Заменяет в generatedHtml старый src на новый src.
     */
    private replaceImageSrcInGeneratedHtml(oldSrc: string, newSrc: string) {
        if (!this.generatedHtml) return;
        // naive replace of first occurrence
        this.generatedHtml = this.generatedHtml.replace(oldSrc, newSrc);
    }

    private replaceAudioIdInGeneratedHtml(oldAudioId: string, newAudioId: string) {
        if (!this.generatedHtml) return;
        // Простейшая замена первого вхождения
        // В реальном проекте желательно аккуратнее менять атрибуты (RegExp / парс DOM и т.д.)
        this.generatedHtml = this.generatedHtml.replace(
            `audioid="${oldAudioId}"`,
            `audioid="${newAudioId}"`
        );
    }

    addAudio() {
        if (!this.generatedHtml) {
            this.generatedHtml = "<audio-player audioid=\"\"></audio-player>";
        } else {
            // Добавляем сверху
            this.generatedHtml = "<audio-player audioid=\"\"></audio-player>\n" + this.generatedHtml;
        }
    }
}