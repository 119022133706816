// SessionStateInteractor.ts

import { ExerciseUIHandler } from "./ExerciseUIHandler";
import {SessionState} from "./SessionState";
import {SessionRepository} from "./SessionRepository";
import {PracticeTableUIState} from "../Exercises/Table/Practice/PracticeTableUIState";
import {PracticeTableUIHandler} from "../Exercises/Table/Practice/PracticeTableUIHandler";
import {
    PracticeHangmanUIState,
    PracticeHangmanUIStateUtils
} from "../Exercises/Hangman/Practice/PracticeHangmanUIState";
import {PracticeHangmanUIHandler} from "../Exercises/Hangman/Practice/PracticeHangmanUIHandler";
import {MatchingPairsUIHandler} from "../Exercises/MatchingPairs/Practice/MatchingPairsUIHandler";
import {ExerciseUIState} from "./ExerciseUIState";
import {JustContentPracticeUIHandler} from "../../ui/components/CanvasRefactor/JustContentPractice/JustContentPracticeUIHandler";
import {JustContentPracticeUIState} from "../../ui/components/CanvasRefactor/JustContentPractice/JustContentPracticeUIState";
import {FreePracticeUIState} from "../../ui/components/exercises_types/Free/Practice/FreePracticeUIState";
import {FreePracticeUIHandler} from "../../ui/components/exercises_types/Free/Practice/FreePracticeUIHandler";
import {CrosswordUIHandler} from "../../ui/components/exercises_types/Crossword/Domain/CrosswordUIHandler";
import {CrosswordUIState} from "../../ui/components/exercises_types/Crossword/Domain/CrosswordUIState";
import {WordSearchUIState} from "../../ui/components/exercises_types/WordSearch/Practice/WordSearchUIState";
import {WordSearchUIHandler} from "../../ui/components/exercises_types/WordSearch/Practice/WordSearchUIHandler";
import {MissingWordUIHandler} from "../../ui/components/exercises_types/MissingWord/Practice/MissingWordUIHandler";
import {MissingWordUIState} from "../../ui/components/exercises_types/MissingWord/Practice/MissingWordUIState";
import {FillBlanksUIState} from "../../ui/components/exercises_types/FillBlanks/Practice/FillBlanksUIState";
import {FillBlanksUIHandler} from "../../ui/components/exercises_types/FillBlanks/Practice/FillBlanksUIHandler";
import {AnagramUIHandler} from "../../ui/components/exercises_types/Anagram/Practice/AnagramUIHandler";
import {AnagramUIState} from "../../ui/components/exercises_types/Anagram/Practice/AnagramUIState";
import {SelectOptionUIState} from "../../ui/components/exercises_types/SelectOption/Practice/SelectOptionUIState";
import {SelectOptionUIHandler} from "../../ui/components/exercises_types/SelectOption/Practice/SelectOptionUIHandler";

export class SessionStateInteractor {
    public onIndexChange?: (newIndex: number) => void;

    private sessionId: string;
    private repository: SessionRepository;
    private currentState?: SessionState;
    private uiHandlers: Map<string, ExerciseUIHandler>;

    constructor(sessionId: string, repository: SessionRepository) {
        this.sessionId = sessionId;
        this.repository = repository;
        this.uiHandlers = new Map();

        // Аналог subscribeToSession(sessionId: onUpdate:)
        this.repository.subscribeToSession(sessionId, (newState) => {
            this.handleSessionUpdate(newState);
        });
    }

    // ---- 1) Обработка входящих данных из репозитория (onSnapshot) ----
    private handleSessionUpdate(state: SessionState | undefined): void {
        if (!state) {
            // Документ не существует -> currentState = undefined
            this.currentState = undefined;
            return;
        }

        if (this.currentState) {
            // Сравним версии (аналог if newState.version <= current.version)
            if (state.version <= this.currentState.version) {
                // Получили «не более новую» версию, игнорируем
                return;
            } else {
                // Новая версия state
                this.currentState = state;
            }
        } else {
            // У нас не было текущего state
            this.currentState = state;
        }

        // Обновляем UIHandlers
        for (const exId of state.exercises) {
            const uiState = state.uiStateByExerciseId[exId];
            const handler = this.uiHandlers.get(exId);
            handler?.updateUIState(uiState);
        }

        // Вызываем onIndexChange, если есть
        this.onIndexChange?.(state.currentExerciseIndex);
    }

    // ---- 2) currentExerciseIndex ----
    public get currentExerciseIndex(): number | undefined {
        return this.currentState?.currentExerciseIndex;
    }

    public setExercises(exercises: any[]) {
        if (this.currentState) {
            this.currentState.exercises = exercises.map((ex) => ex.id)
        }
        (async () => {
            try {
                await this.repository.updateSessionState(this.sessionId, (draft) => {
                    draft.exercises = exercises.map((ex) => ex.id);
                    draft.version++;
                });
            } catch (err) {
                console.error("Failed to update setExercises:", err);
            }
        })();
    }

    // ---- 3) ensureInitialState / setInitialStateIfNeeded ----
    public async ensureInitialState(exercises: any[]): Promise<void> {
        try {
            const existingState = await this.repository.getSessionState(this.sessionId);
            // Если состояние уже есть, ничего не делаем
            if (existingState) {
                return;
            }
        } catch (err) {
            console.error("Failed to check existing session state:", err);
            return;
        }
        // Если нет, создаём
        await this.setInitialStateIfNeeded(exercises);
    }

    public async setInitialStateIfNeeded(exercises: any[]): Promise<void> {
        if (this.currentState) return; // уже есть локальное состояние

        const initialState: SessionState = {
            exercises: exercises.map((ex) => ex.id), // Предположим ex.id — string
            currentExerciseIndex: 0,
            uiStateByExerciseId: {},
            version: 0
        };

        try {
            await this.repository.updateSessionState(this.sessionId, (draft) => {
                // Полностью перезаписываем
                Object.assign(draft, initialState);
            });
        } catch (err) {
            console.error("Failed to initialize session state:", err);
        }
    }

    // ---- 4) updateCurrentExerciseIndex ----
    public updateCurrentExerciseIndex(newIndex: number): void {
        // Обновляем локально
        if (this.currentState) {
            this.currentState.currentExerciseIndex = newIndex;
            this.currentState.version++;
        }
        // Асинхронно обновляем в репо
        (async () => {
            try {
                await this.repository.updateSessionState(this.sessionId, (draft) => {
                    draft.currentExerciseIndex = newIndex;
                    draft.version++;
                });
            } catch (err) {
                console.error("Failed to update currentExerciseIndex:", err);
            }
        })();
    }

    // ---- 5) tableHandler + updateTableState ----
    // Аналог Swift extension для table.

    public tableHandler(exerciseId: string): PracticeTableUIHandler {
        // Пробуем найти уже созданный handler
        let handler = this.uiHandlers.get(exerciseId) as PracticeTableUIHandler;
        if (handler) {
            return handler;
        } else {
            // Создаём новый
            handler = new PracticeTableUIHandler(exerciseId, this);
            // Если в currentState есть uiStateByExerciseId для этого exerciseId
            const uiState = this.currentState?.uiStateByExerciseId[exerciseId];
            if (uiState && uiState.type === "table") {
                // Передаём handler.updateUIState(...)
                handler.updateUIState(uiState);
            }
            this.uiHandlers.set(exerciseId, handler);
            return handler;
        }
    }

    public updateTableState(exerciseId: string, newState: PracticeTableUIState): void {
        // 1) Обновляем локально
        if (this.currentState) {
            this.currentState.uiStateByExerciseId[exerciseId] = {
                type: "table",
                data: newState
            };
            this.currentState.version++;
        }
        // 2) Асинхронно - репозиторий
        (async () => {
            try {
                await this.repository.updateSessionState(this.sessionId, (draft) => {
                    if (!draft.uiStateByExerciseId) {
                        draft.uiStateByExerciseId = {};
                    }
                    draft.uiStateByExerciseId[exerciseId] = {
                        type: "table",
                        data: newState
                    };
                    draft.version++;
                });
            } catch (err) {
                console.error("Failed to update table state:", err);
            }
        })();
    }

    public hangmanHandler(exerciseId: string): PracticeHangmanUIHandler {
        // Пробуем найти уже созданный handler
        let handler = this.uiHandlers.get(exerciseId) as PracticeHangmanUIHandler;
        if (handler) {
            return handler;
        } else {
            // Создаём новый
            handler = new PracticeHangmanUIHandler(exerciseId, this);
            // Если в currentState есть uiStateByExerciseId для этого exerciseId
            const uiState = this.currentState?.uiStateByExerciseId[exerciseId];
            if (uiState && uiState.type === "hangman") {
                handler.updateUIState(uiState);
            }
            this.uiHandlers.set(exerciseId, handler);
            return handler;
        }
    }

    /**
     * Вызывается из PracticeHangmanUIHandler, когда нужно сохранить
     * обновлённое состояние (guessedLetters, mistakes и т.д.) в SessionState.
     */
    public updateHangmanState(exerciseId: string, newState: PracticeHangmanUIState): void {
        console.log("SessionStateInteractor updateHangmanState")

        if (this.currentState) {
            this.currentState.uiStateByExerciseId[exerciseId] = {
                type: "hangman",
                data: PracticeHangmanUIStateUtils.toFirestore(newState)
            };
            this.currentState.version++;
        }
        (async () => {
            try {
                await this.repository.updateSessionState(this.sessionId, (draft) => {
                    if (!draft.uiStateByExerciseId) {
                        draft.uiStateByExerciseId = {};
                    }
                    draft.uiStateByExerciseId[exerciseId] = {
                        type: "hangman",
                        data: PracticeHangmanUIStateUtils.toFirestore(newState)
                    };
                    draft.version++;
                });
            } catch (err) {
                console.error("Failed to update hangman state:", err);
            }
        })();
    }




    public matchingPairsHandler(exerciseId: string): MatchingPairsUIHandler {
        let handler = this.uiHandlers.get(exerciseId) as MatchingPairsUIHandler;
        if (!handler) {
            // Создаём новый
            handler = new MatchingPairsUIHandler(exerciseId, this);
            // Если есть currentState, пытаемся подгрузить UIState
            const uiState = this.currentState?.uiStateByExerciseId[exerciseId];
            if (uiState && uiState.type === "matchingPairs") {
                handler.updateUIState(uiState);
            }
            this.uiHandlers.set(exerciseId, handler);
        }
        return handler;
    }

    /** Вызывается из UIHandler, чтобы сохранить новое состояние (matchingPairs(...)) */
    public updateExerciseState(exerciseId: string, newState: ExerciseUIState): void {
        if (!this.currentState) return;
        this.currentState.uiStateByExerciseId[exerciseId] = newState;
        this.currentState.version++;

        // Асинхронное сохранение в репозиторий
        (async () => {
            try {
                await this.repository.updateSessionState(this.sessionId, (draft) => {
                    if (!draft.uiStateByExerciseId) draft.uiStateByExerciseId = {};
                    draft.uiStateByExerciseId[exerciseId] = newState;
                    draft.version++;
                });
            } catch (err) {
                console.error("Failed to update exerciseId state:", err, exerciseId);
            }
        })();
    }

    public justContentHandler(exerciseId: string): JustContentPracticeUIHandler {
        // Пробуем найти уже созданный handler
        let handler = this.uiHandlers.get(exerciseId) as JustContentPracticeUIHandler;
        if (handler) {
            return handler;
        } else {
            // Создаём новый handler
            handler = new JustContentPracticeUIHandler(exerciseId, this);

            // Если в currentState есть uiStateByExerciseId для этого exerciseId
            // и type === "justContent", то подгружаем его в handler
            const uiState = this.currentState?.uiStateByExerciseId[exerciseId];
            if (uiState && uiState.type === "justContent") {
                handler.updateUIState(uiState);
            }

            this.uiHandlers.set(exerciseId, handler);
            return handler;
        }
    }

    /**
     * Аналог updateTableState / updateHangmanState, но для justContent.
     * Вызывается из JustContentPracticeUIHandler, когда нужно сохранить
     * обновлённое состояние (contents) в SessionState.
     */
    public updateJustContentState(exerciseId: string, newState: JustContentPracticeUIState): void {
        console.log("SessionStateInteractor updateJustContentState");

        // 1) Обновляем локально
        if (this.currentState) {
            this.currentState.uiStateByExerciseId[exerciseId] = {
                type: "justContent",
                // Можно хранить объект напрямую, но ниже — сериализуем через toFirestore()
                data: newState,
            };
            this.currentState.version++;
        }

        // 2) Асинхронно обновляем в репозитории
        (async () => {
            try {
                await this.repository.updateSessionState(this.sessionId, (draft: any) => {
                    if (!draft.uiStateByExerciseId) {
                        draft.uiStateByExerciseId = {};
                    }
                    draft.uiStateByExerciseId[exerciseId] = {
                        type: "justContent",
                        data: newState.toFirestore(),
                    };
                    draft.version++;
                });
            } catch (err) {
                console.error("Failed to update justContent state:", err);
            }
        })();
    }

    public freeHandler(exerciseId: string): FreePracticeUIHandler {
        // Check if we already have a handler in the map
        let handler = this.uiHandlers.get(exerciseId) as FreePracticeUIHandler;
        if (handler) {
            return handler;
        } else {
            // Create a new FreePracticeUIHandler
            handler = new FreePracticeUIHandler(exerciseId, this);

            // If there's a currentState for this exerciseId and it's of type 'free'
            const uiState = this.currentState?.uiStateByExerciseId[exerciseId];
            if (uiState && uiState.type === "free") {
                // Pass the UIState to the handler so it can update local fields/htmlString
                handler.updateUIState(uiState);
            }

            this.uiHandlers.set(exerciseId, handler);
            return handler;
        }
    }

    /**
     * Called from FreePracticeUIHandler when we need to save
     * the updated FreePracticeUIState in SessionState (Firestore, etc.).
     */
    public updateFreeState(exerciseId: string, newState: FreePracticeUIState): void {
        console.log("SessionStateInteractor updateFreeState");

        // 1) Update local currentState (if present)
        if (this.currentState) {
            this.currentState.uiStateByExerciseId[exerciseId] = {
                type: "free",
                data: newState, // Or you could store a serialized form if you want
            };
            this.currentState.version++;
        }

        // 2) Async update in the repository
        (async () => {
            try {
                await this.repository.updateSessionState(this.sessionId, (draft) => {
                    if (!draft.uiStateByExerciseId) {
                        draft.uiStateByExerciseId = {};
                    }
                    draft.uiStateByExerciseId[exerciseId] = {
                        type: "free",
                        data: newState,
                    };
                    draft.version++;
                });
            } catch (err) {
                console.error("Failed to update free state:", err);
            }
        })();
    }

    public crosswordHandler(exerciseId: string): CrosswordUIHandler {
        // Пробуем найти уже созданный handler
        let handler = this.uiHandlers.get(exerciseId) as CrosswordUIHandler;
        if (handler) {
            return handler;
        } else {
            // Создаем новый CrosswordUIHandler, передавая exerciseId и ссылку на interactor (this)
            handler = new CrosswordUIHandler(exerciseId, this);
            // Если в currentState уже есть uiState для данного упражнения и его тип "crossword"
            const uiState = this.currentState?.uiStateByExerciseId[exerciseId];
            if (uiState && uiState.type === "crossword") {
                handler.updateUIState(uiState);
            }
            this.uiHandlers.set(exerciseId, handler);
            return handler;
        }
    }

    // Метод для обновления состояния кроссворда
    public updateCrosswordState(exerciseId: string, newState: CrosswordUIState): void {
        // 1) Обновляем локально текущее состояние, если оно существует
        if (this.currentState) {
            this.currentState.uiStateByExerciseId[exerciseId] = {
                type: "crossword",
                data: newState
            };
            this.currentState.version++;
        }
        // 2) Асинхронно сохраняем изменения в репозитории
        (async () => {
            try {
                await this.repository.updateSessionState(this.sessionId, (draft) => {
                    if (!draft.uiStateByExerciseId) {
                        draft.uiStateByExerciseId = {};
                    }
                    draft.uiStateByExerciseId[exerciseId] = {
                        type: "crossword",
                        data: newState
                    };
                    draft.version++;
                });
            } catch (err) {
                console.error("Failed to update crossword state:", err);
            }
        })();
    }

    public wordSearchHandler(exerciseId: string): WordSearchUIHandler {
        // Пробуем найти уже созданный handler
        let handler = this.uiHandlers.get(exerciseId) as WordSearchUIHandler;
        if (handler) {
            return handler;
        } else {
            // Создаём новый handler
            handler = new WordSearchUIHandler(exerciseId, this);
            // Если в текущем состоянии (currentState) есть uiState для этого exerciseId
            // и его тип "wordSearch", то подгружаем его в handler
            const uiState = this.currentState?.uiStateByExerciseId[exerciseId];
            if (uiState && uiState.type === "wordSearch") {
                handler.updateUIState(uiState);
            }
            this.uiHandlers.set(exerciseId, handler);
            return handler;
        }
    }

    /**
     * Вызывается из PracticeWordSearchUIHandler, чтобы сохранить новое состояние wordSearch в SessionState.
     */
    public updateWordSearchState(exerciseId: string, newState: WordSearchUIState): void {
        // 1) Обновляем локальное состояние, если оно существует
        if (this.currentState) {
            this.currentState.uiStateByExerciseId[exerciseId] = {
                type: "wordSearch",
                data: newState,
            };
            this.currentState.version++;
        }
        // 2) Асинхронно сохраняем изменения в репозитории
        (async () => {
            try {
                await this.repository.updateSessionState(this.sessionId, (draft) => {
                    if (!draft.uiStateByExerciseId) {
                        draft.uiStateByExerciseId = {};
                    }
                    draft.uiStateByExerciseId[exerciseId] = {
                        type: "wordSearch",
                        data: newState,
                    };
                    draft.version++;
                });
            } catch (err) {
                console.error("Failed to update wordSearch state:", err);
            }
        })();
    }


    public missingWordHandler(exerciseId: string): MissingWordUIHandler {
        // Если UIHandler уже существует, возвращаем его
        let handler = this.uiHandlers.get(exerciseId) as MissingWordUIHandler;
        if (handler) {
            return handler;
        }

        // Иначе создаём новый handler
        handler = new MissingWordUIHandler(exerciseId, this);

        // Если в currentState для данного упражнения есть uiState.type === "missingword",
        // подгружаем его в handler
        const uiState = this.currentState?.uiStateByExerciseId[exerciseId];
        if (uiState && uiState.type === "missingWord") {
            handler.updateUIState(uiState);
        }

        this.uiHandlers.set(exerciseId, handler);
        return handler;
    }

    public updateMissingWordState(exerciseId: string, newState: MissingWordUIState): void {
        // 1) Обновляем локальное состояние (если оно у нас уже подгружено)
        if (this.currentState) {
            this.currentState.uiStateByExerciseId[exerciseId] = {
                type: "missingWord",
                data: newState,
            };
            this.currentState.version++;
        }

        // 2) Асинхронно сохраняем новое состояние в репозитории (например, Firestore)
        (async () => {
            try {
                await this.repository.updateSessionState(this.sessionId, (draft) => {
                    if (!draft.uiStateByExerciseId) {
                        draft.uiStateByExerciseId = {};
                    }
                    draft.uiStateByExerciseId[exerciseId] = {
                        type: "missingWord",
                        data: newState,
                    };
                    draft.version++;
                });
            } catch (err) {
                console.error("Failed to update missingword state:", err);
            }
        })();
    }


    public fillBlanksHandler(exerciseId: string): FillBlanksUIHandler {
        // Check if we already have a handler in the map
        let handler = this.uiHandlers.get(exerciseId) as FillBlanksUIHandler;
        if (handler) {
            return handler;
        } else {
            // Create a new FillBlanksUIHandler
            handler = new FillBlanksUIHandler(exerciseId, this);

            // If there's a currentState for this exerciseId and it’s of type 'fillBlanks',
            // update the handler’s state from that snapshot
            const uiState = this.currentState?.uiStateByExerciseId[exerciseId];
            if (uiState && uiState.type === "fillBlanks") {
                handler.updateUIState(uiState);
            }

            this.uiHandlers.set(exerciseId, handler);
            return handler;
        }
    }

    /**
     * Called from FillBlanksUIHandler when we need to save
     * the updated FillBlanksUIState in SessionState (Firestore, etc.).
     */
    public updateFillBlanksState(exerciseId: string, newState: FillBlanksUIState): void {
        // 1) Update local currentState (if present)
        if (this.currentState) {
            this.currentState.uiStateByExerciseId[exerciseId] = {
                type: "fillBlanks",
                data: newState,
            };
            this.currentState.version++;
        }

        // 2) Async update in the repository
        (async () => {
            try {
                await this.repository.updateSessionState(this.sessionId, (draft) => {
                    if (!draft.uiStateByExerciseId) {
                        draft.uiStateByExerciseId = {};
                    }
                    draft.uiStateByExerciseId[exerciseId] = {
                        type: "fillBlanks",
                        data: newState,
                    };
                    draft.version++;
                });
            } catch (err) {
                console.error("Failed to update fillBlanks state:", err);
            }
        })();
    }

    public anagramHandler(exerciseId: string): AnagramUIHandler {
        let handler = this.uiHandlers.get(exerciseId) as AnagramUIHandler;
        if (handler) {
            return handler;
        } else {
            handler = new AnagramUIHandler(exerciseId, this);
            const uiState = this.currentState?.uiStateByExerciseId[exerciseId];
            if (uiState && uiState.type === "anagram") {
                handler.updateUIState(uiState);
            }
            this.uiHandlers.set(exerciseId, handler);
            return handler;
        }
    }

    public updateAnagramState(exerciseId: string, newState: AnagramUIState): void {
        if (!this.currentState) return;
        this.currentState.uiStateByExerciseId[exerciseId] = {
            type: "anagram",
            data: newState
        };
        this.currentState.version++;

        (async () => {
            try {
                await this.repository.updateSessionState(this.sessionId, (draft) => {
                    if (!draft.uiStateByExerciseId) draft.uiStateByExerciseId = {};
                    draft.uiStateByExerciseId[exerciseId] = {
                        type: "anagram",
                        data: newState
                    };
                    draft.version++;
                });
            } catch (err) {
                console.error("Failed to update anagram state:", err);
            }
        })();
    }

    public selectOptionHandler(exerciseId: string): SelectOptionUIHandler {
        let handler = this.uiHandlers.get(exerciseId) as SelectOptionUIHandler;
        if (handler) {
            return handler;
        }
        handler = new SelectOptionUIHandler(exerciseId, this);

        // Если есть сохранённое UIState в currentState, подгружаем:
        const uiState = this.currentState?.uiStateByExerciseId[exerciseId];
        if (uiState && uiState.type === "selectOption") {
            handler.updateUIState(uiState);
        }

        this.uiHandlers.set(exerciseId, handler);
        return handler;
    }

    public updateSelectOptionState(exerciseId: string, newState: SelectOptionUIState): void {
        if (this.currentState) {
            this.currentState.uiStateByExerciseId[exerciseId] = {
                type: "selectOption",
                data: newState,
            };
            this.currentState.version++;
        }
        (async () => {
            try {
                await this.repository.updateSessionState(this.sessionId, (draft) => {
                    if (!draft.uiStateByExerciseId) {
                        draft.uiStateByExerciseId = {};
                    }
                    draft.uiStateByExerciseId[exerciseId] = {
                        type: "selectOption",
                        data: newState,
                    };
                    draft.version++;
                });
            } catch (err) {
                console.error("Failed to update selectOption state:", err);
            }
        })();
    }
}