// src/components/adding/WordsToAddPreview.tsx
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import EditAddingWordView from './EditAddingWordView';
import { WordItem } from '../../../domain/models/WordItem';
import BaseButton from "../../../ui/components/BaseButton";

interface WordsToAddPreviewProps {
    onSave?: (words: WordItem[]) => void;
    onDelay?: (words: WordItem[]) => void;
}

const createNewWord = (): WordItem => ({
    id: String(Date.now()),
    word: '',
    translation: '',
    example: '',
    repetitions: [],
    imageURL: ''
});

const WordsToAddPreview: React.FC<WordsToAddPreviewProps> = ({ onSave, onDelay }) => {
    const location = useLocation();
    const initialWords = location.state?.words || [createNewWord()];
    const [words, setWords] = useState<WordItem[]>(initialWords);

    const addNewWord = () => setWords([...words, createNewWord()]);
    const removeWord = (id: string) => setWords(words.filter(word => word.id !== id));

    const handleSave = () => {
        onSave && onSave(words); // Вызываем onSave, если он передан
    };

    return (
        <div style={containerStyle}>
            <div style={listContainerStyle}>
                <div style={scrollContainerStyle}>
                    {words.map(word => (
                        <EditAddingWordView
                            key={word.id}
                            wordItem={word}
                            onDelete={() => removeWord(word.id)}
                            onChange={updatedWord => setWords(words.map(w => (w.id === word.id ? updatedWord : w)))}
                        />
                    ))}
                </div>
            </div>

            {/* Колонка для кнопок справа */}
            <div style={buttonContainerStyle}>
                <BaseButton onClick={addNewWord} color="#007bff">+</BaseButton>
                <BaseButton onClick={handleSave} color="#28a745">Save</BaseButton>
                <BaseButton onClick={() => onDelay && onDelay(words)} color="#ffc107">Delay</BaseButton>
            </div>
        </div>
    );
};

// Основной контейнер с двумя колонками
const containerStyle: React.CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '1fr auto', // Первая колонка (список) гибкая, вторая (кнопки) автоширина
    gap: '20px',
    padding: '20px',
    alignItems: 'start', // Выравнивание по верхнему краю
};

// Контейнер для списка с прокруткой
const listContainerStyle: React.CSSProperties = {
    maxHeight: '80vh',
    overflowY: 'auto',
};

// Контейнер для кнопок
const buttonContainerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    position: 'sticky', // Фиксированное положение при прокрутке
    top: '20px', // Отступ от верхнего края
};


// Прокручиваемый контейнер для списка слов
const scrollContainerStyle: React.CSSProperties = {
    maxHeight: '70vh',
    overflowY: 'auto',
};

export default WordsToAddPreview;
