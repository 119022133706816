import { makeAutoObservable, runInAction } from "mobx";
import { CanvasItem } from "./CanvasItem";
import { CanvasUseCase } from "./CanvasUseCase";
import { ImageUploading } from "../../../../data/implementations/Files/ImageUploader";
import CompositionRoot from "../../../../compositionRoot";
import { getImageDimensionsFromFile } from "./ImageUtils";
import { CanvasHistory } from "./CanvasHistory"; // Импортируем новый класс истории

export class CanvasInteractor {
    public items: CanvasItem[] = [];

    private useCase: CanvasUseCase;
    private imageUploader: ImageUploading;
    private history: CanvasHistory; // Добавляем историю

    constructor() {
        this.imageUploader = CompositionRoot.imageUploading;
        this.useCase = new CanvasUseCase();
        this.history = new CanvasHistory(); // Инициализируем историю
        makeAutoObservable(this)
    }

    setItems(newItems: CanvasItem[]) {
        runInAction(() => {
            this.items = newItems;
        });
    }

    // ---------- Методы истории операций ----------

    // Отмена последнего действия
    public undoAction(): void {
        const prevState = this.history.undo(this.items);
        if (prevState) {
            this.setItems(prevState);
        }
    }

    // Повтор ранее отмененного действия
    public redoAction(): void {
        const nextState = this.history.redo(this.items);
        if (nextState) {
            this.setItems(nextState);
        }
    }

    // ---------- Методы CRUD ----------

    public addTextItem(position?: { x: number; y: number }): CanvasItem {
        // Сохраняем состояние перед изменением
        this.history.pushState(this.items);

        const { newItems, newItem } = this.useCase.addTextItem(this.items, position);
        this.setItems(newItems);
        return newItem;
    }

    public addDrawing(): CanvasItem {
        // Сохраняем состояние перед изменением
        this.history.pushState(this.items);

        const { newItems, newItem } = this.useCase.addDrawing(this.items);
        this.setItems(newItems);
        return newItem;
    }

    public finalizeDrawing(itemId: string): CanvasItem | null {
        // Сохраняем состояние перед изменением
        this.history.pushState(this.items);

        const { newItems, newItem } = this.useCase.finalizeDrawing(this.items, itemId);
        if (newItem) {
            this.setItems(newItems);
            return newItem;
        }
        return null;
    }

    public duplicateItem(itemId: string): CanvasItem | null {
        // Сохраняем состояние перед изменением
        this.history.pushState(this.items);

        const { newItems, newItem } = this.useCase.duplicateItem(this.items, itemId);
        if (newItem) {
            this.setItems(newItems);
        }
        return newItem;
    }

    public removeItem(itemId: string): void {
        // Сохраняем состояние перед изменением
        this.history.pushState(this.items);

        const newItems = this.useCase.removeItem(this.items, itemId);
        this.setItems(newItems);
    }

    public updateItem(itemId: string, patch: Partial<CanvasItem>): void {
        // Сохраняем состояние перед изменением
        this.history.pushState(this.items);

        const newItems = this.useCase.updateItem(this.items, itemId, patch);
        this.setItems(newItems);
    }

    public setAllItems(newItems: CanvasItem[]) {
        this.history.pushState(this.items);

        const updatedItems = this.useCase.setAllItems(this.items, newItems);
        this.setItems(updatedItems);
    }

    public async handleImageFileSelected(file: File): Promise<void> {
        // Сохраняем состояние перед изменением
        this.history.pushState(this.items);

        try {
            //TODO: делать параллельно
            // 1. Сначала получаем размеры изображения из File
            // 2. Затем загружаем изображение на сервер
            const dimensions = await getImageDimensionsFromFile(file);
            const downloadURL = await this.imageUploader.uploadImage(file, crypto.randomUUID());

            // 3. Добавляем изображение, используя уже известные размеры
            const updatedItems = this.useCase.addImageItemWithDimensions(
                this.items,
                downloadURL,
                dimensions
            );

            // Обновляем состояние
            this.setItems(updatedItems);
        } catch (err) {
            console.error("Ошибка при обработке изображения:", err);
        }
    }
}