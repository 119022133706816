// AnagramViewable.tsx
import React from "react";
import { ExerciseAnagram, Exercise } from "../../../../../domain/models";
import { ExerciseViewable } from "../../ExerciseViewable";
import {Resizer} from "../../../Resizer";
import {AnagramLearnViewModel} from "../Practice/AnagramLearnViewModel";
import {PracticeAnagramView} from "../Practice/PracticeAnagramView";

export class ExerciseAnagramViewable implements ExerciseViewable {
    private exerciseData: ExerciseAnagram;

    constructor(anagramData: ExerciseAnagram) {
        this.exerciseData = anagramData;
    }

    renderView(exercise: Exercise, width?: number, height?: number): JSX.Element {
        if (exercise.type.kind !== "anagram") {
            throw new Error("Unsupported exercise type for anagram viewable");
        }

        const targetWidth = width ?? 300;
        const targetHeight = height ?? 150;

        const vm = new AnagramLearnViewModel(exercise, undefined, undefined, undefined)

        return (
            <Resizer targetSize={{ width: targetWidth, height: targetHeight }}>
                <div style={{ width: "400px", height: "250px", alignContent: "center" }}>
                    <PracticeAnagramView vm={vm} />
                </div>
            </Resizer>
        );
    }
}