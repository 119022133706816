// StopClassUseCase.ts

import CompositionRoot from "../../../../../compositionRoot";
import {Lesson} from "../../../../../domain/models";

export class StopClassUseCase {
    async execute(
        classId: string,
        teacherId: string,
        studentId: string,
        lesson: Lesson,
        currentIndex: number
    ) {
        const userStorage = CompositionRoot.getUserStorage();
        const classStorage = CompositionRoot.getClassStorage();
        const planStorage = CompositionRoot.planStorage;

        const plan = await planStorage.getPlan(teacherId, studentId);
        if (!plan) {
            console.warn("No plan found for teacher:", teacherId, "student:", studentId);
            return;
        }

        const doneCount = currentIndex + 1;
        const doneExercises = lesson.exercises.slice(0, doneCount);
        const remainingExercises = lesson.exercises.slice(doneCount);

        console.log("StopClassUseCase, doneExercises: ", doneExercises)
        console.log("StopClassUseCase, remainingExercises: ", remainingExercises)
        await classStorage.saveClassExercises(classId, doneExercises);

        plan.exercises = remainingExercises;
        await planStorage.savePlan(plan);

        await userStorage.completeClass(teacherId, classId);
        await userStorage.completeClass(studentId, classId);

        await classStorage.stopClassSession(classId);
    }
}