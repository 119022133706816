// FillBlanksCommon.tsx
import React from "react";

/**
 * placeholderId — уникальный ID для конкретного плейсхолдера
 * userInput — текущий ввод пользователя
 * isCorrect — null (не проверен), true/false (результат проверки)
 * correctWord — опционально, если нужно рендерить зелёный текст
 */
export interface FillBlanksPlaceholderData {
    id: number;            // id или string, главное — уникальность
    userInput: string;     // текущая строка
    isCorrect: boolean | null;
    correctWord: string;   // правильное слово
}

/**
 * Интерфейс пропсов для единого компонента
 */
interface FillBlanksCommonProps {
    /** Текст упражнения, содержащий [ ... ] */
    text: string;

    /** Массив плейсхолдеров в том же порядке, что и скобки [ ... ] в тексте */
    placeholders: FillBlanksPlaceholderData[];

    /** Вызывается, когда пользователь меняет ввод для конкретного placeholder. */
    onChangePlaceholder?(placeholderId: number, newValue: string): void;

    /** Вызывается, когда пользователь "проверяет" конкретный placeholder (например, Enter). */
    onCheckPlaceholder?(placeholderId: number): void;

    /** Если нужно - колбэк "Check All" (можно сделать необязательным). */
    onCheckAll?(): void;
}

/** Локальный тип для парсинга текста.
 *  Обратите внимание, что id можно хранить строкой или number+префикс — лишь бы уникально.
 */
type TextElement =
    | { type: "text"; content: string; id: string }
    | { type: "placeholder"; id: string }
    | { type: "lineBreak"; id: string };

/**
 * Единый компонент, который:
 *  1) Парсит text => массив text/placeholder/lineBreak
 *  2) Проходит по каждому элементу, если placeholder => берёт данные из props.placeholders
 *  3) Отрисовывает либо <input>, либо зелёный текст
 *  4) Коллбэки onChangePlaceholder, onCheckPlaceholder вызываются, если заданы
 */
export function FillBlanksViewBase({
                                     text,
                                     placeholders,
                                     onChangePlaceholder,
                                     onCheckPlaceholder,
                                     onCheckAll,
                                 }: FillBlanksCommonProps) {
    // Парсим строку
    const elements = parseText(text);

    // Перебираем placeholders по порядку
    let placeholderIndex = 0;

    return (
        <div style={{ marginTop: 8 }}>
            {elements.map((el) => {
                if (el.type === "text") {
                    return <span key={el.id}>{el.content}</span>;
                } else if (el.type === "lineBreak") {
                    return <br key={el.id} />;
                } else if (el.type === "placeholder") {
                    // Берём очередной placeholder из массива props.placeholders
                    const ph = placeholders[placeholderIndex];
                    placeholderIndex++;

                    if (!ph) {
                        // Если по какой-то причине плейсхолдеров меньше, чем скобок в тексте
                        return (
                            <span key={`missing-${el.id}`} style={{ color: "red" }}>
                [No placeholder data!]
              </span>
                        );
                    }

                    // Если isCorrect === true, показываем зелёный текст
                    if (ph.isCorrect) {
                        return (
                            <span key={`plch-${ph.id}`} style={{ color: "green", fontWeight: "bold" }}>
                {ph.correctWord}
              </span>
                        );
                    } else {
                        // Иначе показываем <input>
                        return (
                            <input
                                key={`plch-${ph.id}`}
                                style={{ width: 100, marginRight: 5 }}
                                value={ph.userInput}
                                onChange={(e) => {
                                    onChangePlaceholder?.(ph.id, e.target.value);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        onCheckPlaceholder?.(ph.id);
                                    }
                                }}
                            />
                        );
                    }
                }
                return null;
            })}

            {/* Дополнительно кнопка Check All, если нужно */}
            {onCheckAll && (
                <div style={{ marginTop: 12 }}>
                    <button onClick={() => onCheckAll()}>Check All</button>
                </div>
            )}
        </div>
    );
}

/**
 * Утилита для парсинга строки `text` на фрагменты:
 *   text, placeholder, lineBreak
 */
function parseText(text: string): TextElement[] {
    const elements: TextElement[] = [];
    let currentText = "";
    let isInPlaceholder = false;
    let idCounter = 0;

    for (let i = 0; i < text.length; i++) {
        const char = text[i];
        if (char === "[") {
            // Выгружаем текущий накопленный текст
            if (currentText) {
                elements.push({ type: "text", content: currentText, id: `txt-${idCounter++}` });
                currentText = "";
            }
            isInPlaceholder = true;
        } else if (char === "]") {
            // Завершаем placeholder
            elements.push({ type: "placeholder", id: `ph-${idCounter++}` });
            isInPlaceholder = false;
        } else if (char === "\n") {
            // Перенос строки
            if (!isInPlaceholder) {
                if (currentText) {
                    elements.push({ type: "text", content: currentText, id: `txt-${idCounter++}` });
                    currentText = "";
                }
                elements.push({ type: "lineBreak", id: `lb-${idCounter++}` });
            }
        } else {
            // Если внутри плейсхолдера – мы игнорируем текст (правильное слово сидит в props.placeholders)
            if (!isInPlaceholder) {
                currentText += char;
            }
        }
    }
    if (currentText) {
        elements.push({ type: "text", content: currentText, id: `txt-${idCounter++}` });
    }
    return elements;
}