// Дополним ImageUtils.ts новой функцией для работы с File

/**
 * Получает размеры изображения из объекта File
 * @param file Объект File с изображением
 * @returns Promise с размерами изображения
 */
export function getImageDimensionsFromFile(file: File): Promise<{ width: number, height: number }> {
    return new Promise((resolve, reject) => {
        // Создаем временный URL для доступа к файлу
        const objectUrl = URL.createObjectURL(file);

        const img = new Image();
        img.onload = () => {
            // Получаем размеры
            const dimensions = {
                width: img.naturalWidth,
                height: img.naturalHeight
            };

            // Освобождаем ресурсы
            URL.revokeObjectURL(objectUrl);

            resolve(dimensions);
        };

        img.onerror = () => {
            // Освобождаем ресурсы в случае ошибки
            URL.revokeObjectURL(objectUrl);
            reject(new Error('Не удалось загрузить изображение'));
        };

        img.src = objectUrl;
    });
}

/**
 * Вычисляет размеры изображения с сохранением пропорций
 * @param originalWidth Исходная ширина
 * @param originalHeight Исходная высота
 * @param maxWidth Максимальная желаемая ширина
 * @param maxHeight Максимальная желаемая высота
 * @returns Новые размеры с сохранением пропорций
 */
export function calculateProportionalDimensions(
    originalWidth: number,
    originalHeight: number,
    maxWidth: number,
    maxHeight: number
): { width: number, height: number } {
    let newWidth = originalWidth;
    let newHeight = originalHeight;

    // Если изображение больше максимальных размеров, масштабируем его пропорционально
    if (originalWidth > maxWidth || originalHeight > maxHeight) {
        const widthRatio = maxWidth / originalWidth;
        const heightRatio = maxHeight / originalHeight;

        // Используем меньшее соотношение, чтобы изображение точно влезло
        const ratio = Math.min(widthRatio, heightRatio);

        newWidth = originalWidth * ratio;
        newHeight = originalHeight * ratio;
    }

    return {
        width: Math.round(newWidth),
        height: Math.round(newHeight)
    };
}