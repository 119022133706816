import React from "react";
import { LessonWord } from "../LessonWord";
import { TooltipPortal } from "./TooltipPortal";

interface LessonWordCardProps {
    word: LessonWord;
}

interface LessonWordCardState {
    isHovered: boolean;
    tooltipX: number;
    tooltipY: number;
}

// Карточка слова
export class LessonWordCard extends React.Component<LessonWordCardProps, LessonWordCardState> {
    private cardRef = React.createRef<HTMLDivElement>();

    constructor(props: LessonWordCardProps) {
        super(props);
        this.state = {
            isHovered: false,
            tooltipX: 0,
            tooltipY: 0
        };
    }

    private handleMouseEnter = () => {
        // При наведении вычисляем позицию элемента и сохраняем в стейте
        const rect = this.cardRef.current?.getBoundingClientRect();
        if (rect) {
            this.setState({
                isHovered: true,
                tooltipX: rect.left,
                tooltipY: rect.top + window.scrollY
            });
        }
    };

    private handleMouseLeave = () => {
        this.setState({ isHovered: false });
    };

    render() {
        const { text, translation, example } = this.props.word;
        const { isHovered, tooltipX, tooltipY } = this.state;

        const style: React.CSSProperties = {
            color: 'var(--text-color)'
        };

        const tooltipContent = (
            <div style={style}>
                <div>{translation}</div>
                {example && <div>{example}</div>}
            </div>
        );

        return (
            <div
                ref={this.cardRef}
                style={styles.card}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                {text}
                <TooltipPortal
                    x={tooltipX}
                    y={tooltipY}
                    content={tooltipContent}
                    visible={isHovered}
                />
            </div>
        );
    }
}

const styles: { [key: string]: React.CSSProperties } = {
    card: {
        border: "none",
        padding: "8px",
        margin: "4px",
        cursor: "pointer",
        backgroundColor: "#ffffff22",
        boxSizing: "border-box",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        height: "40px",
        display: "flex",
        alignItems: "center"
    }
};