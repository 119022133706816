// src/onboarding/AuthInteractor.ts

import {AppUserManagerProtocol} from "../../../../domain/interfaces/AppUserManagerProtocol";

export class AuthInteractor {
    constructor(private userManager: AppUserManagerProtocol) {}

    async login(email: string, password: string): Promise<void> {
        await this.userManager.loginUser(email, password);
    }

    async register(email: string, password: string): Promise<void> {
        await this.userManager.registerUser(email, password);
    }

    async skip() {
        // ничего делать не нужно, пользователь остаётся анонимным
    }
}