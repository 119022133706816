// HtmlGenerationInteractor.ts
import { HtmlRecognizer } from "./HtmlRecognizer";
import { OpenAIRequester } from "../../../../../data/implementations/OpenAIRequester";
import {WordItem} from "../../../../../domain/models";

interface ImproveHtmlResponse {
    improvedHtml: string;
}

/**
 * Interactor orchestrates both:
 *  - generating new HTML via HtmlRecognizer (image+comment)
 *  - improving existing HTML via requestObjectWithCustomFormat
 */
export class HtmlGenerationInteractor {
    constructor(
        private readonly htmlRecognizer: HtmlRecognizer,
        private readonly requester: OpenAIRequester
    ) {}

    async generateHtml(image: File | null, comment: string, words?: WordItem[]): Promise<string> {
        const result = await this.htmlRecognizer.recognize(image, comment, words);
        const sanitized = this.removeCodeFences(result);

        return sanitized;

    }

    /**
     * Improves the old HTML using user-provided instructions.
     * We use requestObjectWithCustomFormat to parse JSON like:
     *    { "improvedHtml": "<section>Better stuff</section>" }
     */
    async improveHtml(oldHtml: string, improvementComment: string): Promise<string> {
        // 1) Build a prompt that instructs the model to produce JSON with improvedHtml field
        const prompt = `
    You have existing HTML:
    ${oldHtml}

    Please improve it according to these instructions:
    ${improvementComment}

    Return ONLY JSON with a single field "improvedHtml" containing the final HTML string. 
    Example output:
    {"improvedHtml": "<section>Better HTML goes here</section>"}
    Do not include code fences or extra text.
    `;

        // 2) Create an example object for the schema generator
        const example: ImproveHtmlResponse = {
            improvedHtml: "<div>Sample HTML</div>",
        };

        // 3) We call requestObjectWithCustomFormat, expecting a single object with "improvedHtml"
        const result = await this.requester.requestObjectWithCustomFormat<ImproveHtmlResponse>(
            prompt,
            example
        );

        const sanitized = this.removeCodeFences(result.improvedHtml);

        return sanitized;
    }

    private removeCodeFences(input: string): string {
        // Regex approach: remove a leading ``` + optional language + newline,
        // and a trailing ``` if present. Then trim.
        // Adjust if your model includes additional whitespace or variations.
        return input
            .replace(/^```[a-zA-Z]*\r?\n?/, "") // remove ```html or ```js (etc) at start
            .replace(/```\s*$/, "")            // remove final ``` at end
            .trim();
    }
}