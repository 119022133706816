/* src/data/implementations/FirestoreClassService.ts */
import { db } from '../../../../../data/config/firebase';
import {
    collection,
    doc, getDoc, getDocs,
    setDoc,
    Timestamp, updateDoc
} from 'firebase/firestore';
import {Exercise} from "../../../../../domain/models";
import {deserializeExercise, serializeExercise} from "../../../../../data/implementations/FirestoreExercises/ExerciseSerializer";
import {removeUndefinedFields} from "../../../../../data/implementations/FirestoreExercisesService";
import {ClassData} from "../Models/ClassData";

export interface ClassStorage {
    createClassSession(
        classId: string,
        teacherId: string,
        studentId: string,
        startTime: Date
    ): Promise<void>;

    stopClassSession(classId: string): Promise<void>

    getClassSession(classId: string): Promise<ClassData | null>;

    saveClassExercises(classId: string, exercises: Exercise[]): Promise<void>

    getClassExercises(classId: string): Promise<Exercise[]>;
}

export default class FirestoreClassService implements ClassStorage {
    /**
     * Создаёт новую запись о классе (уроке) по пути /classes/{classId}.
     */
    async createClassSession(
        classId: string,
        teacherId: string,
        studentId: string,
        startTime: Date
    ): Promise<void> {
        const classRef = doc(db, 'classes', classId);
        const classData = {
            teacherId,
            studentId,
            startTime: Timestamp.fromDate(startTime),
        };
        await setDoc(classRef, classData, { merge: true });
    }

    async stopClassSession(classId: string): Promise<void> {
        const classRef = doc(db, 'classes', classId);
        await updateDoc(classRef, {
            stopTime: Timestamp.now()
        });
    }

    async getClassSession(classId: string): Promise<ClassData | null> {
        const classRef = doc(db, 'classes', classId);
        const snapshot = await getDoc(classRef);
        if (!snapshot.exists()) {
            return null;
        }

        const data = snapshot.data();
        return {
            teacherId: data.teacherId,
            studentId: data.studentId,
            startTime: (data.startTime as Timestamp).toDate(),
            stopTime: data.stopTime ? (data.stopTime as Timestamp).toDate() : undefined
        };
    }

    async saveClassExercises(
        classId: string,
        exercises: Exercise[]
    ): Promise<void> {
        const classRef = doc(db, 'classes', classId);
        const exercisesCollRef = collection(classRef, 'exercises');

        for (let i = 0; i < exercises.length; i++) {
            const exercise = exercises[i];
            const exerciseDocRef = doc(exercisesCollRef, exercise.id);

            // Convert your Exercise to a plain object
            const exerciseData = serializeExercise(exercise);
            exerciseData["orderIndex"] = i;

            // Remove undefined fields if needed
            const sanitizedData = removeUndefinedFields(exerciseData);

            // Write the doc
            await setDoc(exerciseDocRef, sanitizedData, { merge: true });
        }
    }

    async getClassExercises(classId: string): Promise<Exercise[]> {
        const classRef = doc(db, 'classes', classId);
        const exercisesCollRef = collection(classRef, 'exercises');

        const snapshot = await getDocs(exercisesCollRef);
        const exercises: Exercise[] = [];

        snapshot.forEach(docSnap => {
            const data = docSnap.data();
            const exerciseOrNull = deserializeExercise(data, docSnap.id);
            (exerciseOrNull as any).orderIndex = data.orderIndex;


            // If deserializeExercise returned a valid Exercise, push it
            if (exerciseOrNull) {
                exercises.push(exerciseOrNull);
            }
            // Otherwise, skip or handle the null case as needed
        });

        exercises.sort((a: any, b: any) => (a.orderIndex ?? 0) - (b.orderIndex ?? 0));
        return exercises;
    }
}
