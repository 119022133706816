// src/onboarding/ProfileInteractor.ts

// Допустим, у нас AppUserManagerProtocol уже умеет updateUserProfile
import {AppUserManagerProtocol} from "../../../../domain/interfaces/AppUserManagerProtocol";

export interface ProfileData {
    name?: string;
    avatar?: string;
    about?: string;
}

export class ProfileInteractor {
    constructor(private userManager: AppUserManagerProtocol) {}

    async saveProfile(data: ProfileData) {
        // Предположим, метод:
        await this.userManager.updateUserProfile(data);
    }

    async skipProfile() {
        // ничего не делаем
    }
}