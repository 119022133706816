import { Exercise, ExerciseTable } from "../../../domain/models";

export function serializeTable(exercise: Exercise): any {
    if (exercise.type.kind !== 'table') {
        throw new Error("serializeAnagram called with non-anagram exercise");
    }

    const tbl: ExerciseTable = exercise.type.data;
    return {
        type: 'table',
        authorId: exercise.authorId,
        description: exercise.description,
        columns: tbl.columns,
        stableCells: tbl.stableCells
    };
}

export function deserializeTable(data: any, id: string): Exercise | null {
    const { columns, stableCells } = data;
    if (!Array.isArray(columns) || !Array.isArray(stableCells)) {
        return null;
    }
    return {
        id,
        authorId: data.authorId,
        description: data.description,
        type: {
            kind: 'table',
            data: {
                columns,
                stableCells
            }
        }
    };
}