import React, { useCallback } from "react";
import {AudioPlayerCallbacks} from "../../../Components/AudioPlayer/AudioPlayer.types";
import {AudioPlayer} from "../../../Components/AudioPlayer/AudioPlayerContainer";

interface CustomAudioElementProps {
    audioId: string;
    onAudioIdChange: (newAudioId: string) => void;
    audioPlayerCallbacks?: AudioPlayerCallbacks;
}

/**
 * Обёртка, чтобы подхватить изменение audioId и сообщить о нём наверх
 */
export const CustomAudioElement: React.FC<CustomAudioElementProps> = ({
                                                                          audioId,
                                                                          onAudioIdChange,
                                                                          audioPlayerCallbacks
                                                                      }) => {
    // Обрабатываем коллбек onLoadComplete,
    // чтобы при загрузке аудио уведомить ViewModel о новом audioId
    const handleLoadComplete = useCallback((newAudioId: string) => {
        // Сообщаем наверх: "audioId поменялся"
        onAudioIdChange(newAudioId);
        // Если у нас есть внешний коллбек - тоже вызываем
        audioPlayerCallbacks?.onLoadComplete?.(newAudioId);
    }, [onAudioIdChange, audioPlayerCallbacks]);

    const handleError = useCallback((msg: string) => {
        audioPlayerCallbacks?.onError?.(msg);
    }, [audioPlayerCallbacks]);

    const handleTextLoaded = useCallback((text: string) => {
        audioPlayerCallbacks?.onTextLoaded?.(text);
    }, [audioPlayerCallbacks]);

    return (
        <AudioPlayer
            audioId={audioId}
            onError={handleError}
            onTextLoaded={handleTextLoaded}
            onLoadComplete={handleLoadComplete}
        />
    );
};