import { JustContentCreateInteractor } from "./JustContentCreateInteractor";
import { Exercise } from "../../../../domain/models";
import {CanvasViewModel} from "../Base/CanvasViewModel";
import {CanvasItem} from "../Base/CanvasItem";

export class JustContentCreateViewModel {
    public canvasViewModel: CanvasViewModel;
    interactor: JustContentCreateInteractor

    constructor(items: CanvasItem[], onExercisesCreated: (exs: Exercise[]) => void) {
        this.interactor = new JustContentCreateInteractor(items, onExercisesCreated)
        this.canvasViewModel = new CanvasViewModel(this.interactor.canvas);
    }

    public savePressed() {
        this.interactor.saveExercise()
    }
}