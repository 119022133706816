// =======================
// JustContentPracticeUIState.ts
// =======================
import {CanvasItem} from "../Base/CanvasItem";
import {
    encodeCanvasItem,
    decodeCanvasItem
} from "../Base/network/FirestoreNoteRepository";

/**
 * Описание того, как храним элемент в Firestore.
 * Теперь поддерживаем и "drawing", поэтому расширяем type.
 */
export type FirestoreJustContentItem = {
    id: string;
    type: "text" | "image" | "drawing";
    content: string;
    x: number;
    y: number;
    width: number;
    height: number;
    baseWidth?: number | null;
    baseFontSize?: number | null;
    textColor?: string | null;
    fontWeight?: "normal" | "bold" | null;
    fontStyle?: "normal" | "italic" | null;
    paths?: Array<Array<{ x: number; y: number }>> | null;
    canDraw?: boolean | null;
};

export type FirestoreJustContentArray = FirestoreJustContentItem[];


/**
 * JustContentPracticeUIState: класс для хранения массива CanvasItem[].
 * Имеет методы (де)сериализации и сравнения, аналогично PracticeTableUIState.
 */
export class JustContentPracticeUIState {
    public contents: CanvasItem[];

    constructor(contents?: CanvasItem[]) {
        this.contents = contents ?? [];
    }

    /**
     * Сериализуем в FirestoreJustContentArray (каждый CanvasItem
     * превращаем в объект с нужными для Firestore полями).
     * Используем encodeCanvasItem, чтобы не дублировать логику.
     */
    public toFirestore(): FirestoreJustContentArray {
        return this.contents.map((item) => encodeCanvasItem(item));
    }

    /**
     * Десериализуем из FirestoreJustContentArray → JustContentPracticeUIState.
     * Используем decodeCanvasItem, чтобы не дублировать логику.
     */
    public static fromFirestore(
        arr: FirestoreJustContentArray
    ): JustContentPracticeUIState {
        const items: CanvasItem[] = arr.map((raw) => decodeCanvasItem(raw));
        return new JustContentPracticeUIState(items);
    }

    /**
     * Сравнение с другим UIState: грубо сравниваем JSON.
     */
    public equals(other: JustContentPracticeUIState): boolean {
        return JSON.stringify(this.contents) === JSON.stringify(other.contents);
    }
}
