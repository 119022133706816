// src/domain/models/AppUser.ts

// Импорт интерфейса
export interface AppUser {
    uid: string;
    email?: string | null;
    status?: string | null;
    trialStartDate?: Date | null;
    delayedSets: string[];
    exerciseSets: string[];
    role?: 'teacher' | 'student';
    languages?: AppUserLanguages;

    profileName?: string;
    profileAvatar?: string;
    profileAbout?: string;
    currentClass?: string | null;
    completedClasses?: string[];
}

export interface AppUserLanguages {
    appLanguage: string;       // Язык приложения
    learningLanguage: string;  // Язык, который учим (для ученика) или преподаём (для учителя)
    nativeLanguage: string;    // Родной язык
}

export interface AppUserProfileData {
    name?: string;
    avatar?: string;
    about?: string;
}



// Функция для создания AppUser из данных Firebase
export const fromDictionary = (data: Record<string, any>): AppUser | null => {
    const uid = data.uid as string;
    if (!uid) return null;

    const email = data.email as string | null;
    const status = data.status as string | null;

    // Если trialStartDate храним в Firestore как Timestamp,
    // то там может быть data.trialStartDate.seconds
    const trialStartTimestamp = data.trialStartDate?.seconds as number | undefined;
    const trialStartDate = trialStartTimestamp ? new Date(trialStartTimestamp * 1000) : null;

    const delayedSets = (data.delayedSets as string[]) || [];
    const exerciseSets = (data.exerciseSets as string[]) || [];

    // Считываем дополнительные поля
    const role = data.role as 'teacher' | 'student' | undefined;
    const languages = data.languages as AppUserLanguages | undefined;

    const profileName = data.profileName as string | undefined;
    const profileAvatar = data.profileAvatar as string | undefined;
    const profileAbout = data.profileAbout as string | undefined;

    const currentClass = data.currentClass as string | null;
    const completedClasses = (data.completedClasses as string[]) || [];


    return {
        uid,
        email,
        status,
        trialStartDate,
        delayedSets,
        exerciseSets,
        role,
        languages,

        profileName,
        profileAvatar,
        profileAbout,
        currentClass,
        completedClasses
    };
};