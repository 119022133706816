// ============================
// HangmanGameView.tsx
// ============================
import React, { useEffect } from "react";
import HangmanView from "./HangmanView";        // Компонент, рисующий графику виселицы
import { HangmanStage } from "./HangmanStage"; // enum или type (0..6)
import SpanishKeyboardView from "./SpanishKeyboardView"; // клавиатура
import { defaultHangmanLayoutConfig } from "./HangmanLayoutConfig";

/**
 * Это «базовая» вьюшка, не знает ни о каком ViewModel или Interactor,
 * принимает на вход пропы, которые уже подготовлены выше.
 * Весь стейт / логику мы вынесли во ViewModel / Interactor.
 */
interface HangmanGameViewProps {
    word: string;
    hint: string;
    onGameEnd: (didWin: boolean) => void;
    guessLetter: (letter: string) => void;

    // Доп. поля для отрисовки
    currentStage: number;
    displayedWord: string;
    isGameOver: boolean;
    isWin: boolean;
}

const HangmanGameView: React.FC<HangmanGameViewProps> = ({
                                                             word,
                                                             hint,
                                                             onGameEnd,
                                                             guessLetter,
                                                             currentStage,
                                                             displayedWord,
                                                             isGameOver,
                                                             isWin,
                                                         }) => {
    // Если игра завершается, вызываем onGameEnd(didWin)
    useEffect(() => {
        if (isGameOver) {
            onGameEnd(isWin);
        }
    }, [isGameOver, isWin, onGameEnd]);

    const handleLetterTap = (letter: string) => {
        if (isGameOver) return;
        guessLetter(letter);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 8, alignItems: "center" }}>
            {/* 1) Отрисовка виселицы */}
            <HangmanView
                stage={currentStage as HangmanStage}
                config={defaultHangmanLayoutConfig}
                width={250}
                height={250}
            />

            {/* 2) Подсказка */}
            <div style={{ color: "gray", fontStyle: "italic" }}>Hint: {hint}</div>

            {/* 3) Загаданное слово (с подчеркиваниями) */}
            <div style={{ fontSize: 28, fontWeight: "bold", fontFamily: "monospace" }}>
                {displayedWord}
            </div>

            {/* 4) Клавиатура */}
            <SpanishKeyboardView
                onLetterTap={handleLetterTap}
                disabled={isGameOver}
            />
        </div>
    );
};

export default HangmanGameView;