// ========= ./CrosswordUIState.ts ==========

// Swift's `CrosswordUIState: Codable, Hashable`
export class CrosswordUIState {
    public rowsCount: number;
    public columnsCount: number;
    public flattenedGrid: string[];
    public foundWords: Set<string>;
    public hintedWords: Set<string>;

    constructor(
        rowsCount: number,
        columnsCount: number,
        flattenedGrid: string[],
        foundWords: Set<string>,
        hintedWords: Set<string>
    ) {
        this.rowsCount = rowsCount;
        this.columnsCount = columnsCount;
        this.flattenedGrid = flattenedGrid;
        this.foundWords = foundWords;
        this.hintedWords = hintedWords;
    }

    // Swift's convenience init(grid: [[String]], foundWords, hintedWords)
    static fromGrid(
        grid: string[][],
        foundWords: Set<string>,
        hintedWords: Set<string>
    ): CrosswordUIState {
        const rowsCount = grid.length;
        const columnsCount = rowsCount > 0 ? grid[0].length : 0;
        const flattenedGrid = grid.flat();
        return new CrosswordUIState(rowsCount, columnsCount, flattenedGrid, foundWords, hintedWords);
    }

    get grid(): string[][] {
        const result: string[][] = [];
        for (let i = 0; i < this.rowsCount; i++) {
            const start = i * this.columnsCount;
            const end = start + this.columnsCount;
            const row = this.flattenedGrid.slice(start, end);
            result.push(row);
        }
        return result;
    }

    // Equality check (like Swift's `Equatable`)
    isEqual(other: CrosswordUIState): boolean {
        if (this.rowsCount !== other.rowsCount) return false;
        if (this.columnsCount !== other.columnsCount) return false;
        if (this.flattenedGrid.length !== other.flattenedGrid.length) return false;
        for (let i = 0; i < this.flattenedGrid.length; i++) {
            if (this.flattenedGrid[i] !== other.flattenedGrid[i]) {
                return false;
            }
        }
        if (this.foundWords.size !== other.foundWords.size) return false;
        if (this.hintedWords.size !== other.hintedWords.size) return false;
        // Quick set comparison
        for (const w of this.foundWords) {
            if (!other.foundWords.has(w)) {
                return false;
            }
        }
        for (const w of this.hintedWords) {
            if (!other.hintedWords.has(w)) {
                return false;
            }
        }
        return true;
    }
}