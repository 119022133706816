// src/onboarding/LanguageSelectionInteractor.ts

import {AppUserLanguages} from "../../../../domain/models";
import {AppUserManagerProtocol} from "../../../../domain/interfaces/AppUserManagerProtocol";

export class LanguageSelectionInteractor {
    constructor(private userManager: AppUserManagerProtocol) {}

    async updateLanguages(langs: AppUserLanguages): Promise<void> {
        await this.userManager.updateUserLanguages(langs);
    }
}