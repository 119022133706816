/***************************************************
 * COMPONENT: CrosswordCellView
 * Equivalent to SwiftUI's "CrosswordCellView"
 ***************************************************/
import {Position} from "../../../../../domain/models";

interface CrosswordCellViewProps {
    position: Position;
    letter: string;
    wordNumber?: number;
    cellSize: number;
    editable: boolean;
    onLetterChange: (newVal: string) => void; // Called after we filter to 1 char
    onChange: () => void; // Called after the letter changes
    onFocusRequest: () => void; // Called when user taps/clicks to focus
}

export function CrosswordCellView({
                                      position,
                                      letter,
                                      wordNumber,
                                      cellSize,
                                      editable,
                                      onLetterChange,
                                      onChange,
                                      onFocusRequest,
                                  }: CrosswordCellViewProps) {
    // We'll manage our input as a controlled input
    // Swift code does filtering in set
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value.toUpperCase().trim();
        if (val.length > 1) {
            val = val.substring(0, 1);
        }
        onLetterChange(val);
        onChange();
    };

    const handleClick = () => {
        if (editable) {
            onFocusRequest();
        }
    };

    const wrapperStyle: React.CSSProperties = {
        width: cellSize,
        height: cellSize,
        backgroundColor: '#fff',
        border: '1px solid gray',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    };

    const inputStyle: React.CSSProperties = {
        width: '100%',
        height: '100%',
        textAlign: 'center',
        fontSize: '20px',
        border: 'none',
        outline: 'none',
        backgroundColor: 'transparent',
    };

    const badgeStyle: React.CSSProperties = {
        position: 'absolute',
        top: 2,
        left: 2,
        fontSize: '8px',
        color: 'red',
    };

    return (
        <div style={wrapperStyle} onClick={handleClick}>
            <input
                type="text"
                value={letter}
                onChange={handleInputChange}
                disabled={!editable}
                style={inputStyle}
            />
            {wordNumber && (
                <div style={badgeStyle}>
                    {wordNumber}
                </div>
            )}
        </div>
    );
}
