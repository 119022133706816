// =======================
// JustContentPracticeUIHandler.ts
// =======================
import {makeAutoObservable, reaction, runInAction} from "mobx";
import {ExerciseUIHandler} from "../../../../domain/Multiplayer/ExerciseUIHandler";
import {CanvasItem} from "../Base/CanvasItem";
import {SessionStateInteractor} from "../../../../domain/Multiplayer/SessionStateInteractor";
import {ExerciseUIState} from "../../../../domain/Multiplayer/ExerciseUIState";
import {JustContentPracticeUIState} from "./JustContentPracticeUIState";

export class JustContentPracticeUIHandler implements ExerciseUIHandler {
    public contents: CanvasItem[];

    private exerciseId: string;
    private interactor?: SessionStateInteractor;
    private isUpdatingFromServer = false;

    private currentUIState: JustContentPracticeUIState;

    constructor(
        exerciseId: string,
        interactor?: SessionStateInteractor,
        initialState?: JustContentPracticeUIState
    ) {
        this.exerciseId = exerciseId;
        this.interactor = interactor;

        // Если не передали initialState, создаём пустое состояние
        this.currentUIState = initialState ?? new JustContentPracticeUIState();
        // Копируем массив CanvasItem для удобства MobX-отслеживания
        this.contents = [...this.currentUIState.contents];

        makeAutoObservable(this);

        // Аналог реактивного слежения за cellTexts в PracticeTableUIHandler,
        // только здесь следим за JSON(contents).
        reaction(
            () => JSON.stringify(this.contents),
            () => {
                if (!this.isUpdatingFromServer) {
                    this.sendUpdateIfNeeded();
                }
            }
        );
    }

    /**
     * Вызывается, когда приходит новое UI-состояние (в режиме мультиплеера или из Firestore).
     */
    public updateUIState(uiState: ExerciseUIState | undefined): void {
        // Проверяем, что тип == "justContent"
        if (!uiState || uiState.type !== "justContent") {
            return;
        }
        // uiState.data должен быть сериализованным массивом
        // десериализуем его:
        const newState = uiState.data;

        // Сравниваем с currentUIState
        if (!newState.equals(this.currentUIState)) {
            this.isUpdatingFromServer = true;
            runInAction(() => {
                // Обновляем локальный стейт
                this.currentUIState = newState;
                // Копируем contents в публичное поле
                this.contents = [...newState.contents];
            });
            this.isUpdatingFromServer = false;
        }
    }

    /**
     * Проверяем, изменилось ли состояние. Если да — вызываем interactor.updateJustContentState()
     */
    private sendUpdateIfNeeded() {
        if (this.isUpdatingFromServer) return;

        // Создаём новый UIState из текущих contents
        const newState = new JustContentPracticeUIState([...this.contents]);
        if (newState.equals(this.currentUIState)) {
            return;
        }

        // Обновляем currentUIState
        this.currentUIState = newState;

        // Отправляем обновлённое состояние в interactor
        this.interactor?.updateJustContentState(this.exerciseId, newState);
    }
}