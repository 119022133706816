// ===================== ./PracticeSelectOption/SelectOptionViewModel.ts =====================
import { makeAutoObservable } from "mobx";
import { SelectOptionInteractor } from "./SelectOptionInteractor";
import { SelectOptionState } from "./SelectOptionState";
import { Exercise } from "../../../../../domain/models";
import { PracticeSessionDelegate } from "../../../../../domain/Exercises/PracticeSessionDelegate";
import { SelectOptionUIHandler } from "./SelectOptionUIHandler";
import { SelectOptionUseCase } from "./SelectOptionUseCase";

export class SelectOptionViewModel {
    private interactor: SelectOptionInteractor;
    public state: SelectOptionState;

    constructor(
        exercise: Exercise,
        delegate?: PracticeSessionDelegate,
        uiHandler?: SelectOptionUIHandler,
        useCase?: SelectOptionUseCase
    ) {
        this.interactor = new SelectOptionInteractor(
            exercise,
            delegate,
            uiHandler,
            useCase
        );
        this.state = this.interactor.state;

        this.interactor.onStateChange = (newState) => {
            this.state = newState;
        };

        makeAutoObservable(this);
    }

    get sentence(): string {
        return this.state.sentence;
    }

    get options(): string[] {
        return this.state.options;
    }

    get selectedOption(): string | null {
        return this.state.selectedOption;
    }

    get correctOption(): string {
        return this.state.correctOption;
    }

    get isCorrect(): boolean | null {
        return this.state.isCorrect;
    }

    public selectAndCheckOption(value: string) {
        this.interactor.selectAndCheckOption(value);
    }

    public goNext() {
        this.interactor.goNext();
    }
}