import { LessonWordsState } from "./LessonWordsState";
import { LessonWord } from "../LessonWord";

export function encodeWordsState(state: LessonWordsState): any {
    return {
        version: state.version,
        items: state.items.map(encodeWord),
        isAdding: state.isAdding,
        newWordDraft: {
            text: state.newWordDraft.text ?? "",
            translation: state.newWordDraft.translation ?? "",
            example: state.newWordDraft.example ?? ""
        }
    };
}

function encodeWord(word: LessonWord): any {
    return {
        id: word.id ?? "",
        text: word.text ?? "",
        translation: word.translation ?? "",
        example: word.example ?? null
    };
}

export function decodeWordsState(data: any): LessonWordsState {
    return {
        version: typeof data.version === "number" ? data.version : 0,
        items: Array.isArray(data.items) ? data.items.map(decodeWord) : [],
        isAdding: !!data.isAdding, // приводим к boolean
        newWordDraft: {
            text: typeof data.newWordDraft?.text === "string" ? data.newWordDraft.text : "",
            translation: typeof data.newWordDraft?.translation === "string" ? data.newWordDraft.translation : "",
            example: typeof data.newWordDraft?.example === "string" ? data.newWordDraft.example : ""
        }
    };
}

function decodeWord(raw: any): LessonWord {
    return {
        id: typeof raw.id === "string" ? raw.id : "",
        text: typeof raw.text === "string" ? raw.text : "",
        translation: typeof raw.translation === "string" ? raw.translation : "",
        example: typeof raw.example === "string" ? raw.example : undefined
    };
}