import {allDirections, Direction, getVector, Grid, GridGenerating} from "./GridGenerating";
import {Position} from "../Base/types";

export class Placer3 implements GridGenerating {
    rowsCount: number;
    columnsCount: number;
    grid: Grid;

    constructor(rowsCount: number, columnsCount: number) {
        this.rowsCount = rowsCount;
        this.columnsCount = columnsCount;
        // Инициализируем сетку пустыми строками
        this.grid = Array.from({ length: rowsCount }, () => Array(columnsCount).fill(""));
    }

    public generateWordGrid(words: string[]): [Grid, string[]] {
        const successWords: string[] = [];
        // 1. Приоритет для более длинных слов
        const sortedWords = words.slice().sort((a, b) => b.length - a.length);
        for (const word of sortedWords) {
            const success = this.wordProcess(word);
            if (success) {
                successWords.push(word);
            }
        }
        this.fillEmptySpaces();
        return [this.grid, successWords];
    }

    private wordProcess(word: string): boolean {
        if (this.attemptToPlaceWithOverlap(word)) {
            return true;
        }

        // 2. Снижение случайности – систематический перебор
        const directions = allDirections.slice().sort(() => Math.random() - 0.5);
        for (const direction of directions) {
            for (let row = 0; row < this.rowsCount; row++) {
                for (let col = 0; col < this.columnsCount; col++) {
                    const position: Position = { row, col };
                    if (this.canPlaceWord(word, position, direction)) {
                        this.placeWord(word, position, direction);
                        return true;
                    }
                }
            }
        }
        // Если слово разместить не удалось – возвращаем false
        return false;
    }

    private attemptToPlaceWithOverlap(word: string): boolean {
        // Для каждой буквы в слове пытаемся найти совпадающие буквы в сетке
        for (let index = 0; index < word.length; index++) {
            const letter = word.charAt(index);
            for (let row = 0; row < this.rowsCount; row++) {
                for (let col = 0; col < this.columnsCount; col++) {
                    if (this.grid[row][col] === letter) {
                        const directions = allDirections.slice().sort(() => Math.random() - 0.5);
                        for (const direction of directions) {
                            const vector = getVector(direction);
                            const startRow = row - vector.rowDelta * index;
                            const startCol = col - vector.colDelta * index;
                            const position: Position = { row: startRow, col: startCol };
                            if (this.canPlaceWord(word, position, direction)) {
                                this.placeWord(word, position, direction);
                                return true;
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    private canPlaceWord(word: string, position: Position, direction: Direction): boolean {
        const vector = getVector(direction);
        let currentRow = position.row;
        let currentCol = position.col;

        for (const char of word) {
            // Проверка границ
            if (
                currentRow < 0 ||
                currentRow >= this.rowsCount ||
                currentCol < 0 ||
                currentCol >= this.columnsCount
            ) {
                return false;
            }
            const gridChar = this.grid[currentRow][currentCol];
            if (gridChar !== "" && gridChar !== char) {
                return false;
            }
            currentRow += vector.rowDelta;
            currentCol += vector.colDelta;
        }
        return true;
    }

    private placeWord(word: string, position: Position, direction: Direction): void {
        const vector = getVector(direction);
        let currentRow = position.row;
        let currentCol = position.col;
        for (const char of word) {
            this.grid[currentRow][currentCol] = char;
            currentRow += vector.rowDelta;
            currentCol += vector.colDelta;
        }
    }

    private fillEmptySpaces(): void {
        for (let row = 0; row < this.grid.length; row++) {
            for (let col = 0; col < this.grid[row].length; col++) {
                if (this.grid[row][col] === "") {
                    this.grid[row][col] = this.randomLetter();
                }
            }
        }
    }

    private randomLetter(): string {
        const letters = "EARIOTNSLCUDPMHGBFYWKVXZJQ";
        const weights = [
            12.7, 9.1, 8.2, 7.5, 7.0, 6.3, 6.1, 5.7, 4.3, 4.0,
            2.8, 2.8, 2.4, 2.4, 2.0, 1.9, 1.5, 1.0, 0.8, 0.8,
            0.5, 0.5, 0.2, 0.2, 0.1, 0.1
        ];
        const total = weights.reduce((sum, w) => sum + w, 0);
        const random = Math.random() * total;
        let cumulative = 0;
        for (let i = 0; i < weights.length; i++) {
            cumulative += weights[i];
            if (random < cumulative) {
                return letters.charAt(i);
            }
        }
        return "E"; // По умолчанию
    }
}