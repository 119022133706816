// 1. Создаем общий модуль для работы с рисунками
// ============================
// common/DrawingManager.ts
// ============================
import { CanvasItem } from "./CanvasItem";

export class DrawingManager {
    static createDrawingItem(): CanvasItem {
        return {
            id: crypto.randomUUID(),
            type: "drawing",
            content: "",
            x: 0,
            y: 0,
            width: 1200,
            height: 1200,
            paths: [],
            canDraw: true,
        };
    }

    static finalizeDrawing(item: CanvasItem): CanvasItem {
        if (item.type !== 'drawing' || !item.canDraw) return item;
        const newId = crypto.randomUUID();

        let minX = Infinity, maxX = -Infinity;
        let minY = Infinity, maxY = -Infinity;

        item.paths?.forEach(stroke => {
            stroke.forEach(pt => {
                minX = Math.min(minX, pt.x);
                maxX = Math.max(maxX, pt.x);
                minY = Math.min(minY, pt.y);
                maxY = Math.max(maxY, pt.y);
            });
        });

        const newWidth = maxX - minX || 100;
        const newHeight = maxY - minY || 100;

        return {
            ...item,
            id: newId,
            x: item.x + minX,
            y: item.y + minY,
            width: newWidth,
            height: newHeight,
            canDraw: false,
            paths: item.paths?.map(stroke =>
                stroke.map(pt => ({
                    x: pt.x - minX,
                    y: pt.y - minY
                }))
            )
        };
    }
}