// InsertCanvasUseCase.ts
import { runInAction } from "mobx";
import { Exercise, Lesson } from "../../../../../domain/models";
import { LessonPracticeSession } from "../../../../../domain/services/LessonPracticeSession";
import {PlanStorage} from "../../../PracticeManagement/Logic/PlanStorage";

function createEmptyCanvasExercise(): Exercise {
    const newId = Math.random().toString(36).substring(2, 15);
    return {
        id: newId,
        description: "New Canvas",
        type: {
            kind: "justContent",
            data: {
                contents: []
            }
        }
    };
}

interface InsertCanvasParams {
    lesson: Lesson;
    teacherId: string;
    studentId: string;
    session: LessonPracticeSession;
    currentIndex: number;
}

/**
 * This use case handles the logic of creating and inserting
 * a new “canvas” exercise into the lesson plan, then saving it.
 */
export class InsertCanvasUseCase {
    constructor(private planStorage: PlanStorage) {}

    /**
     * Inserts a "canvas" exercise into the lesson's exercises array,
     * then saves the updated plan for the teacher+student.
     */
    async execute(params: InsertCanvasParams) {
        const { lesson, teacherId, studentId, session, currentIndex } = params;

        // 1) Create new exercise
        const newExercise = createEmptyCanvasExercise();

        // 2) Decide where to insert it (after currentIndex or at currentIndex)
        const insertIndex = currentIndex + 1;

        // 3) Avoid going out of bounds
        const safeIndex = Math.min(insertIndex, lesson.exercises.length);

        // 4) Insert the new exercise
        runInAction(() => {
            lesson.exercises.splice(safeIndex, 0, newExercise);
        });

        // 5) Save updated plan
        const updatedPlan = {
            teacherId,
            studentId,
            name: lesson.name ?? "",
            exercises: lesson.exercises
        };
        await this.planStorage.savePlan(updatedPlan);
    }
}