// SelectOptionTypePreview.tsx
import React from 'react';
import { Resizer } from '../../../Resizer';
import {PracticeSelectOptionBaseView} from "../Base/PracticeSelectOptionBaseView";

export function SelectOptionTypePreview() {
    const mockData = createMockSelectOptionData();

    return (
        <Resizer targetSize={{ width: 200, height: 150 }}>
            <PracticeSelectOptionBaseView
                sentence={mockData.sentence}
                options={mockData.options}
                selectedOption={null}
                isCorrect={mockData.isCorrect}
                correctOption={mockData.correctOption}
                onOptionPressed={(option) => {}}
                onNext={() => {}}
            />
        </Resizer>
    );
}

function createMockSelectOptionData() {
    return {
        sentence: "Which word is spelled correctly?",
        options: ["Recieve", "Receive", "Receeve", "Recive"],
        isCorrect: null,
        correctOption: "Receive",
    };
}