// PracticeStateFactory.ts
import { Exercise } from "../../../domain/models";
import { PracticeSessionDelegate } from "../../../domain/Exercises/PracticeSessionDelegate";
import { SessionStateInteractor } from "../../../domain/Multiplayer/SessionStateInteractor";
import { PracticeTableViewModel } from "../exercises_types/Table/Practice/PracticeTableViewModel";
import { AnagramLearnViewModel } from "../exercises_types/Anagram/Practice/AnagramLearnViewModel";
import { PracticeHangmanViewModel } from "../exercises_types/Hangman/Practice/PracticeHangmanViewModel";
import { MatchingPairsExerciseViewModel } from "../exercises_types/MatchingPairs/Practice/MatchingPairsExerciseViewModel";
import { MatchingPairsUseCase } from "../../../domain/Exercises/MatchingPairs/Practice/MatchingPairsUseCase";
import { JustContentPracticeViewModel } from "../CanvasRefactor/JustContentPractice/JustContentPracticeViewModel";
import {FreePracticeUseCase} from "../exercises_types/Free/Practice/FreePracticeUseCase";
import {FreePracticeViewModel} from "../exercises_types/Free/Practice/FreePracticeViewModel";
import {DefaultCrosswordBuilder} from "../exercises_types/Crossword/Domain/CrosswordBuilderProtocol";
import {CrosswordLearnViewModel} from "../exercises_types/Crossword/Base/CrosswordLearnViewModel";
import {WordSearchViewModel} from "../exercises_types/WordSearch/Practice/WordSearchViewModel";
import {MissingWordUseCase} from "../exercises_types/MissingWord/Practice/MissingWordUseCase";
import {MissingWordViewModel} from "../exercises_types/MissingWord/Practice/MissingWordViewModel";
import {FillBlanksUseCase} from "../exercises_types/FillBlanks/Practice/FillBlanksUseCase";
import {FillBlanksViewModel} from "../exercises_types/FillBlanks/Practice/FillBlanksViewModel";
import {AnagramUseCase} from "../exercises_types/Anagram/Practice/AnagramUseCase";
import {PracticeStateUI} from "./PracticeStateUI";
import {SelectOptionViewModel} from "../exercises_types/SelectOption/Practice/SelectOptionViewModel";
import {SelectOptionUseCase} from "../exercises_types/SelectOption/Practice/SelectOptionUseCase";


export class PracticeStateFactory {
    private interactor?: SessionStateInteractor;
    private delegate?: PracticeSessionDelegate;

    constructor(
        interactor: SessionStateInteractor | null = null,
        delegate: PracticeSessionDelegate | null = null
    ) {
        this.interactor = interactor ?? undefined;
        this.delegate = delegate ?? undefined;
    }

    create(exercise: Exercise | null): PracticeStateUI {
        if (!exercise) {
            return { type: "done" };
        }

        const delegate = this.delegate ?? ({} as PracticeSessionDelegate);
        const interactor = this.interactor; // might be undefined

        switch (exercise.type.kind) {
            case "table": {
                // If we have interactor, call tableHandler, else undefined
                const handler = interactor?.tableHandler
                    ? interactor.tableHandler(exercise.id)
                    : undefined;
                const vm = new PracticeTableViewModel(exercise, delegate, handler);
                return { type: "table", id: exercise.id, vm };
            }

            case "anagram": {
                const handler = interactor?.anagramHandler
                    ? interactor.anagramHandler(exercise.id)
                    : undefined;
                const useCase = new AnagramUseCase();
                const vm = new AnagramLearnViewModel(exercise, delegate, handler, useCase);
                return { type: "anagram", id: exercise.id, vm };
            }

            case "hangman": {
                const handler = interactor?.hangmanHandler
                    ? interactor.hangmanHandler(exercise.id)
                    : undefined;
                const vm = new PracticeHangmanViewModel(exercise, delegate, handler);
                return { type: "hangman", id: exercise.id, vm };
            }

            case "matchingPairs": {
                const handler = interactor?.matchingPairsHandler
                    ? interactor.matchingPairsHandler(exercise.id)
                    : undefined;
                const useCase = new MatchingPairsUseCase();
                const vm = new MatchingPairsExerciseViewModel(exercise, delegate, handler, useCase);
                return { type: "matchingPairs", id: exercise.id, vm };
            }

            case "justContent": {
                const handler = interactor?.justContentHandler
                    ? interactor.justContentHandler(exercise.id)
                    : undefined;
                const vm = new JustContentPracticeViewModel(exercise, delegate, handler);
                return { type: "justContent", id: exercise.id, vm };
            }

            case "free": {
                const handler = interactor?.freeHandler
                    ? interactor.freeHandler(exercise.id.toUpperCase())
                    : undefined;
                const useCase = new FreePracticeUseCase();
                const vm = new FreePracticeViewModel(exercise, delegate, handler, useCase);
                return { type: "free", id: exercise.id, vm };
            }

            case "crossword": {
                const builder = new DefaultCrosswordBuilder();
                const handler = interactor?.crosswordHandler
                    ? interactor.crosswordHandler(exercise.id)
                    : undefined;
                const vm = new CrosswordLearnViewModel(exercise, delegate, builder, handler);
                return { type: "crossword", id: exercise.id, vm };
            }

            case "wordSearch": {
                const handler = interactor?.wordSearchHandler
                    ? interactor.wordSearchHandler(exercise.id)
                    : undefined;
                const vm = new WordSearchViewModel(exercise, delegate, handler);
                return { type: "wordSearch", id: exercise.id, vm };
            }

            case "missingWord": {
                const handler = interactor?.missingWordHandler
                    ? interactor.missingWordHandler(exercise.id)
                    : undefined;
                const useCase = new MissingWordUseCase();
                const vm = new MissingWordViewModel(exercise, delegate, handler, useCase);
                return { type: "missingWord", id: exercise.id, vm };
            }

            case "fillBlanks": {
                const handler = interactor?.fillBlanksHandler
                    ? interactor.fillBlanksHandler(exercise.id)
                    : undefined;
                const useCase = new FillBlanksUseCase();
                const vm = new FillBlanksViewModel(exercise, delegate, handler, useCase);
                return { type: "fillBlanks", id: exercise.id, vm };
            }

            case "selectOption": {
                const handler = interactor?.selectOptionHandler
                    ? interactor.selectOptionHandler(exercise.id)
                    : undefined;
                const useCase = new SelectOptionUseCase()
                const vm = new SelectOptionViewModel(exercise, delegate, handler, useCase)
                return { type: "selectOption", id: exercise.id, vm };
            }

            default:
                return { type: "done" };
        }
    }
}