// src/onboarding/ProfileScreen.tsx
import React, {useState} from 'react';
import { observer } from 'mobx-react-lite';
import { ProfileViewModel } from './ProfileViewModel';

import {ProfileInteractor} from "./ProfileInteractor";
import CompositionRoot from "../../../../compositionRoot";

export const ProfileScreen: React.FC = observer(() => {
    const userManager = CompositionRoot.getAppUserManager();
    const [vm] = useState(() => new ProfileViewModel(new ProfileInteractor(userManager)));

    const handleSave = async () => {
        await vm.saveProfile();
    };
    const handleSkip = async () => {
        await vm.skip();
    };

    return (
        <div style={styles.center}>
            <h2>Ваш профиль</h2>
            {vm.error && <div style={{ color: 'red' }}>{vm.error}</div>}
            {vm.isLoading && <div>Загрузка...</div>}

            <label>Имя / Никнейм:</label>
            <input
                type="text"
                value={vm.name}
                onChange={(e) => (vm.name = e.target.value)}
            />

            <label>О себе:</label>
            <textarea
                value={vm.about}
                onChange={(e) => (vm.about = e.target.value)}
            />

            <label>Аватар (URL):</label>
            <input
                type="text"
                value={vm.avatar}
                onChange={(e) => (vm.avatar = e.target.value)}
            />

            <button onClick={handleSave} disabled={vm.isLoading}>
                Сохранить
            </button>
            <button onClick={handleSkip} disabled={vm.isLoading}>
                Пропустить
            </button>
        </div>
    );
});

const styles = {
    center: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
    }
};