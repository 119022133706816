import {makeAutoObservable, reaction} from "mobx";
import {Exercise} from "../../../../domain/models";
import {CanvasInteractor} from "../Base/CanvasInteractor";
import {CanvasItem} from "../Base/CanvasItem";

export class JustContentCreateInteractor {
    private onExercisesCreated: (exercises: Exercise[]) => void;

    public canvas: CanvasInteractor;

    constructor(
        items: CanvasItem[],
        onExercisesCreated: (exercises: Exercise[]) => void
    ) {
        this.canvas = new CanvasInteractor()
        this.canvas.items = [...items];

        this.onExercisesCreated = onExercisesCreated;
        makeAutoObservable(this);

        // reaction(
        //     () => this.canvas.items,
        //     (newItems, oldItems) => {
        //         if (newItems !== oldItems) {
        //             this.onItemsChanged(newItems);
        //         }
        //     }
        // );
    }

    public saveExercise() {
        const newExercise: Exercise = {
            id: crypto.randomUUID().toUpperCase(),
            type: {
                kind: "justContent",
                data: {
                    contents: this.canvas.items,
                },
            },
        };
        this.onExercisesCreated([newExercise]);
    }
}

