import { makeAutoObservable, runInAction } from "mobx";
import CompositionRoot from "../../../../compositionRoot";
import { AppUser } from "../../../../domain/models/AppUser";
import { Student } from "../../../../domain/models/Student";
import { CombinedStudent } from "../../Students/CombinedStudent";
import { PlanStorage } from "./PlanStorage";
import { LearningPlan } from "../Models/LearningPlan";

export default class LearningPlanEditInteractor {
    // Состояния
    loadingStudent = false;      // для загрузки данных о студенте
    loadingPlan = false;         // для загрузки/сохранения плана
    error: string | null = null;

    // Данные
    studentData: CombinedStudent | null = null;

    // Зависимости
    private planStorage: PlanStorage;

    constructor() {
        this.planStorage = CompositionRoot.planStorage;
        makeAutoObservable(this);
    }

    /**
     * Подписываемся на изменения текущего пользователя через AppUserManager
     */
    subscribeToCurrentUser(callback: (user: AppUser | null) => void): () => void {
        const appUserManager = CompositionRoot.getAppUserManager();
        const publisher = appUserManager.currentUserPublisher();

        const subscription = publisher.subscribe((user) => {
            callback(user);
        });

        // Возвращаем функцию отписки
        return () => subscription.unsubscribe();
    }

    /**
     * Загрузить данные студента (например, через userStorage)
     */
    async loadStudent(teacherId: string, studentId: string) {
        this.loadingStudent = true;
        this.error = null;
        try {
            const userStorage = CompositionRoot.getUserStorage();
            const students = await userStorage.getStudents(teacherId);
            const foundStudent = students.find((s) => s.id === studentId);

            if (!foundStudent) {
                runInAction(() => {
                    this.error = 'Student not found for this teacher';
                });
                return;
            }

            // Если нужно, подгружаем ещё userData:
            const userData = await userStorage.getUserData(studentId);

            runInAction(() => {
                this.studentData = {
                    student: foundStudent,
                    userData: userData,
                };
            });
        } catch (err: any) {
            runInAction(() => {
                this.error = err.message ?? 'Failed to load student data';
            });
        } finally {
            runInAction(() => {
                this.loadingStudent = false;
            });
        }
    }

    /**
     * Загрузить план из план-хранилища
     */
    async loadPlan(teacherId: string, studentId: string): Promise<LearningPlan | null> {
        this.loadingPlan = true;
        this.error = null;
        try {
            return await this.planStorage.getPlan(teacherId, studentId);
        } catch (err: any) {
            runInAction(() => {
                this.error = err.message ?? "Failed to load plan.";
            });
            return null;
        } finally {
            runInAction(() => {
                this.loadingPlan = false;
            });
        }
    }

    /**
     * Сохранить план
     */
    async savePlan(plan: LearningPlan): Promise<void> {
        this.loadingPlan = true;
        this.error = null;
        try {
            console.log("interactor saving plan: ", plan)
            await this.planStorage.savePlan(plan);
        } catch (err: any) {
            runInAction(() => {
                this.error = err.message ?? "Failed to save plan.";
            });
        } finally {
            runInAction(() => {
                this.loadingPlan = false;
            });
        }
    }

    dispose() {
        // если нужно, убрать подписки, очистить что-то в памяти и т.д.
    }
}