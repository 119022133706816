import { Exercise } from "../../../../../domain/models";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
    MissingWordCreateState,
    MissingWordCreateViewModel,
} from "./MissingWordCreateViewModel";
import { MissingWordCreateSettingsView } from "./MissingWordCreateSettingsView";
import { MissingWordSentencesCheckView } from "./MissingWordSentencesCheckView";
import { MissingWordEditView } from "./MissingWordEditView";

interface MissingWordCreateViewProps {
    onExercisesCreated: (exercises: Exercise[]) => void;
}

export const MissingWordCreateView: React.FC<MissingWordCreateViewProps> = observer(
    ({ onExercisesCreated }) => {
        // We create the view model once
        const [viewModel] = useState(() => new MissingWordCreateViewModel(onExercisesCreated));

        // Show only one subview at a time based on the current state
        switch (viewModel.state) {
            case MissingWordCreateState.Settings:
            case MissingWordCreateState.GeneratingSentences:
                return (
                    <div style={{ width: "100%", display: "flex" }}>
                        <div
                            style={{
                                flex: 1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <MissingWordCreateSettingsView vm={viewModel} />
                        </div>
                    </div>
                );

            case MissingWordCreateState.SentencesGenerated:
            case MissingWordCreateState.Converting:
                // We'll reuse the same layout for sentences check,
                // since "Converting" is a sub-stage there.
                return (
                    <div style={{ width: "100%", padding: "16px" }}>
                        <MissingWordSentencesCheckView vm={viewModel} />
                    </div>
                );

            case MissingWordCreateState.Editing:
                return (
                    <div style={{ width: "100%", padding: "16px" }}>
                        <MissingWordEditView vm={viewModel} />
                    </div>
                );

            default:
                return null;
        }
    }
);