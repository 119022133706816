// src/domain/services/AppUserManager.ts
import { BehaviorSubject } from 'rxjs';
import {AppUser, AppUserLanguages, AppUserProfileData, fromDictionary} from '../models/AppUser';
import { AuthService } from '../interfaces/AuthService';
import { UserStorage } from '../interfaces/UserStorage';
import { AppUserManagerProtocol } from '../interfaces/AppUserManagerProtocol';

class AppUserManager implements AppUserManagerProtocol {
    private _currentUserSubject = new BehaviorSubject<AppUser | null>(null);

    constructor(
        private authService: AuthService,
        private userStorage: UserStorage
    ) {
        this.setupAuthStateListener();
    }

    get currentUser(): AppUser | null {
        return this._currentUserSubject.value;
    }

    currentUserPublisher() {
        return this._currentUserSubject.asObservable();
    }

    private setupAuthStateListener() {
        this.authService.addStateDidChangeListener((userId, email) => {
            if (!userId) {
                this.signInAnonymously().catch(console.error);
            } else {
                if (!this.currentUser || this.currentUser.uid !== userId) {
                    this.observeUserData(userId, email);
                }
            }
        });
    }

    private observeUserData(userId: string, email: string | null) {
        this.userStorage.observeUserData(userId, (data) => {
            if (!data || Object.keys(data).length === 0) {
                this.createDefaultUserData(userId, email);
            } else {
                const appUser = fromDictionary({ ...data, uid: userId });
                if (appUser) {
                    this._currentUserSubject.next(appUser);
                }
            }
        });
    }

    private createDefaultUserData(userId: string, email: string | null) {
        const defaultUser: AppUser = {
            uid: userId,
            email,
            status: 'free',
            trialStartDate: null,
            delayedSets: [],
            exerciseSets: [],
        };
        this.userStorage.saveUser(defaultUser).catch(console.error);
        this._currentUserSubject.next(defaultUser);
    }

    async updateUserStatus(status: string, trialStartDate: Date | null) {
        if (!this.currentUser) return;
        const updatedUser = { ...this.currentUser, status, trialStartDate };
        await this.userStorage.saveUser(updatedUser);
        this._currentUserSubject.next(updatedUser);
    }

    // Пример метода для обновления роли, к которому обратится интерактор
    async updateUserRole(role: 'student' | 'teacher'): Promise<void> {
        if (!this.currentUser) return;
        const updatedUser = { ...this.currentUser, role };
        await this.userStorage.saveUser(updatedUser);
        this._currentUserSubject.next(updatedUser);
    }

    // Остальные методы авторизации
    async signInAnonymously(): Promise<void> {
        await this.authService.signInAnonymously();
    }

    async registerUser(email: string, password: string): Promise<void> {
        await this.authService.registerUser(email, password);
    }

    async loginUser(email: string, password: string): Promise<void> {
        await this.authService.loginUser(email, password);
    }

    async signInWithGoogle(): Promise<void> {
        await this.authService.signInWithGoogle();
    }

    async signInWithApple(): Promise<void> {
        await this.authService.signInWithApple();
    }

    async signOut(): Promise<void> {
        await this.authService.signOut();
        this._currentUserSubject.next(null);
    }

    async addExerciseSet(id: string, userId: string): Promise<void> {
        return this.userStorage.addExerciseSet(id, userId);
    }

    signUpWithApple(): Promise<void> {
        //not now, later
        return Promise.resolve(undefined);
    }

    async updateUserLanguages(languages: AppUserLanguages): Promise<void> {
        if (!this.currentUser) return;
        const updatedUser = { ...this.currentUser, languages };
        await this.userStorage.saveUser(updatedUser);
        this._currentUserSubject.next(updatedUser);
    }

    async updateUserProfile(profileData: AppUserProfileData): Promise<void> {
        if (!this.currentUser) return;

        // Допустим, у нас в AppUser есть поле profile или отдельные поля
        const updatedUser: AppUser = {
            ...this.currentUser,
            // profile: { ...profileData } // либо раскладывайте
            profileName: profileData.name ?? '',
            profileAvatar: profileData.avatar ?? '',
            profileAbout: profileData.about ?? ''
        };

        await this.userStorage.saveUser(updatedUser);
        this._currentUserSubject.next(updatedUser);
    }

}

export default AppUserManager;
