// SessionStateCodec.ts

import { PracticeTableUIState } from "../Exercises/Table/Practice/PracticeTableUIState";
import { SessionState } from "./SessionState";
import { ExerciseUIState } from "./ExerciseUIState";
import { CellCoordinate } from "../Exercises/Table/Models/CellCoordinate";
import {PracticeHangmanUIState, PracticeHangmanUIStateUtils} from "../Exercises/Hangman/Practice/PracticeHangmanUIState";
import {MatchingPairsUIState} from "../Exercises/MatchingPairs/Practice/MatchingPairsUIState";
import {MatchedPair} from "../Exercises/MatchingPairs/Practice/MatchedPair";
import {JustContentPracticeUIState} from "../../ui/components/CanvasRefactor/JustContentPractice/JustContentPracticeUIState";
import {CanvasItem} from "../../ui/components/CanvasRefactor/Base/CanvasItem";
import {decodeCanvasItem, encodeCanvasItem} from "../../ui/components/CanvasRefactor/Base/network/FirestoreNoteRepository";
import {FreePracticeUIState} from "../../ui/components/exercises_types/Free/Practice/FreePracticeUIState";
import {CrosswordUIState} from "../../ui/components/exercises_types/Crossword/Domain/CrosswordUIState";
import {WordSearchUIState} from "../../ui/components/exercises_types/WordSearch/Practice/WordSearchUIState";
import {deserializeWordItem, serializeWordItem} from "../../data/implementations/FirestoreExercises/WordItemSerializer";
import {WordItem} from "../models";
import {MissingWordUIState} from "../../ui/components/exercises_types/MissingWord/Practice/MissingWordUIState";
import {FillBlanksUIState} from "../../ui/components/exercises_types/FillBlanks/Practice/FillBlanksUIState";
import {AnagramUIState} from "../../ui/components/exercises_types/Anagram/Practice/AnagramUIState";
import {SelectOptionUIState} from "../../ui/components/exercises_types/SelectOption/Practice/SelectOptionUIState";

/**
 * Преобразуем наш SessionState в «Swift-подобный» формат:
 *   {
 *     exercises: [...],
 *     currentExerciseIndex: N,
 *     version: N,
 *     uiStateByExerciseId: {
 *       UPPERCASE_ID: { table: { _0: { cellTexts: [...] } } },
 *       ...
 *     }
 *   }
 */
export function encodeSessionState(state: SessionState): Record<string, unknown> {
    return {
        exercises: state.exercises.map((exercise) => exercise.toUpperCase()),
        currentExerciseIndex: state.currentExerciseIndex,
        version: state.version,
        uiStateByExerciseId: encodeUiStateMap(state.uiStateByExerciseId),
    };
}

/**
 * Обратное преобразование: берём Firestore-объект (как в Swift JSON)
 * и восстанавливаем SessionState (где, например, { type: "table", data: PracticeTableUIState }).
 */
export function decodeSessionState(obj: any): SessionState {
    return {
        exercises: obj.exercises ?? [],
        currentExerciseIndex: obj.currentExerciseIndex ?? 0,
        version: obj.version ?? 0,
        uiStateByExerciseId: decodeUiStateMap(obj.uiStateByExerciseId),
    };
}

/* =========================
   ВСПОМОГАТЕЛЬНЫЕ ФУНКЦИИ
========================= */

/**
 * Кодируем uiStateByExerciseId: { [exerciseId]: ExerciseUIState },
 * но ключ записываем в верхнем регистре.
 */
function encodeUiStateMap(
    uiMap: Record<string, ExerciseUIState>
): Record<string, unknown> {
    const result: Record<string, unknown> = {};

    for (const exerciseId of Object.keys(uiMap)) {
        const uiState = uiMap[exerciseId];

        // ВАЖНО: переводим ключ в верхний регистр:
        const uppercaseId = exerciseId.toUpperCase();

        switch (uiState.type) {
            case "table": {
                // Кодируем PracticeTableUIState -> { table: { _0: { cellTexts: [...] } } }
                const encodedTable = encodePracticeTable(uiState.data);
                result[uppercaseId] = { table: { _0: encodedTable } };
                break;
            }
            case "hangman": {
                // Кодируем PracticeHangmanUIState -> { hangman: { _0: {...} } }
                const encodedHangman = encodePracticeHangman(uiState.data);
                result[uppercaseId] = { hangman: { _0: encodedHangman } };
                break;
            }

            case "matchingPairs": {
                // Вот новая ветка
                // uiState.data — это MatchingPairsUIState
                const encodedMp = encodeMatchingPairs(uiState.data);
                // Сохраняем под ключом matchingPairs: { _0: ... }
                result[uppercaseId] = {matchingPairs: {_0: encodedMp}};
                break;
            }

            case "justContent": {
                // Validate and wrap if needed
                const data = Array.isArray(uiState.data)
                    ? new JustContentPracticeUIState(uiState.data) // Wrap as expected type
                    : uiState.data;

                // Encode
                const encodedJc = encodeJustContent(data);
                result[uppercaseId] = { justContent: { _0: encodedJc } };
                break;
            }

            case "free": {
                // uiState.data is your FreePracticeUIState
                const encodedFree = encodeFreePractice(uiState.data);
                // In your Firestore-like JSON, you'll store something like:
                //   { free: { _0: ...encoded data... } }
                result[uppercaseId] = { free: { _0: encodedFree } };
                break;
            }

            case "crossword": {
                // Новый случай: сериализация CrosswordUIState
                const encodedCW = encodeCrosswordUIState(uiState.data);
                result[uppercaseId] = { crossword: { _0: encodedCW } };
                break;
            }

            case "wordSearch": {
                // Новая ветка для wordSearch
                const encodedWordSearch = encodeWordSearch(uiState.data);
                result[uppercaseId] = { wordSearch: { _0: encodedWordSearch } };
                break;
            }

            case "missingWord": {
                const encodedMW = encodeMissingWord(uiState.data);
                result[uppercaseId] = { missingword: { _0: encodedMW } };
                break;
            }

            case "fillBlanks": {
                const encodedFB = encodeFillBlanks(uiState.data);
                // We store it exactly like your other modes, e.g.: { fillBlanks: { _0: {...} } }
                result[uppercaseId] = { fillBlanks: { _0: encodedFB } };
                break;
            }

            case "anagram": {
                const encoded = encodeAnagramUIState(uiState.data);
                result[uppercaseId] = { anagram: { _0: encoded } };
                break;
            }

            case "selectOption": {
                const encodedSO = encodeSelectOption(uiState.data);
                result[uppercaseId] = { selectoption: { _0: encodedSO } };
                break;
            }


            default:
                console.warn(`Unknown UI state type: ${(uiState as any).type}`);
                break;
        }
    }
    return result;
}

/** Декодируем uiStateByExerciseId: { UPPERCASE_ID: { table: { _0: ... } }, ... } */
function decodeUiStateMap(raw: any): Record<string, ExerciseUIState> {
    const result: Record<string, ExerciseUIState> = {};

    for (const exerciseId of Object.keys(raw || {})) {
        const val = raw[exerciseId];
        // Ищем формат { table: { _0: { cellTexts: [...] } } }
        if (val && val.table && val.table._0 && val.table._0.cellTexts) {
            const practiceTableUI = decodePracticeTable(val.table._0);
            result[exerciseId] = {
                type: "table",
                data: practiceTableUI,
            };
            continue
        }

        // 2) hangman
        if (val.hangman && val.hangman._0) {
            const practiceHangmanUI = decodePracticeHangman(val.hangman._0);
            result[exerciseId] = {
                type: "hangman",
                data: practiceHangmanUI,
            };
            continue;
        }

        // 3) MatchingPairs
        // Проверяем, есть ли matchingPairs: { _0: {...} }
        if (val?.matchingPairs?._0) {
            const matchingPairsData = decodeMatchingPairs(val.matchingPairs._0);
            result[exerciseId] = {
                type: "matchingPairs",
                data: matchingPairsData,
            };
            continue;
        }

        if (val?.justContent?._0) {
            const justContentData = decodeJustContent(val.justContent._0);
            result[exerciseId] = {
                type: "justContent",
                data: justContentData,
            };
            continue;
        }

        if (val?.free?._0) {
            const freeData = decodeFreePractice(val.free._0);
            result[exerciseId] = {
                type: "free",
                data: freeData,
            };
            continue;
        }

        if (val?.crossword?._0) {
            const cwData = decodeCrosswordUIState(val.crossword._0);
            result[exerciseId] = {
                type: "crossword",
                data: cwData,
            };
            continue;
        }

        if (val?.wordSearch?._0) {
            const wsData = decodeWordSearch(val.wordSearch._0);
            result[exerciseId] = {
                type: "wordSearch",
                data: wsData,
            };
            continue;
        }

        if (val?.missingword?._0) {
            const mwData = decodeMissingWord(val.missingword._0);
            result[exerciseId] = {
                type: "missingWord",
                data: mwData,
            };
            continue;
        }

        if (val?.fillBlanks?._0) {
            const fbData = decodeFillBlanks(val.fillBlanks._0);
            result[exerciseId] = {
                type: "fillBlanks",
                data: fbData,
            };
            continue;
        }

        if (val?.anagram?._0) {
            const anData = decodeAnagramUIState(val.anagram._0);
            result[exerciseId] = {
                type: "anagram",
                data: anData
            };
            continue;
        }

        if (val?.selectoption?._0) {
            const soData = decodeSelectOption(val.selectoption._0);
            result[exerciseId] = {
                type: "selectOption",
                data: soData,
            };
            continue;
        }

        console.warn(`decodeUiStateMap: неизвестный формат`, val);

    }
    return result;
}

/** Функция кодирования CrosswordUIState в plain-объект */
function encodeCrosswordUIState(cwState: CrosswordUIState): Record<string, unknown> {
    return {
        rowsCount: cwState.rowsCount,
        columnsCount: cwState.columnsCount,
        flattenedGrid: cwState.flattenedGrid,
        foundWords: Array.from(cwState.foundWords),
        hintedWords: Array.from(cwState.hintedWords),
    };
}

/** Функция декодирования plain-объекта в CrosswordUIState */
function decodeCrosswordUIState(raw: any): CrosswordUIState {
    if (typeof raw.rowsCount !== "number" || typeof raw.columnsCount !== "number" || !Array.isArray(raw.flattenedGrid)) {
        throw new Error("Invalid crossword UI state format");
    }
    return new CrosswordUIState(
        raw.rowsCount,
        raw.columnsCount,
        raw.flattenedGrid,
        new Set(raw.foundWords || []),
        new Set(raw.hintedWords || [])
    );
}


/** Кодируем PracticeTableUIState -> { cellTexts: [ {col,row}, "Text", {col,row}, "Text", ... ] } */
function encodePracticeTable(tableData: PracticeTableUIState): Record<string, unknown> {
    return {
        cellTexts: encodeCellTexts(tableData.cellTexts),
    };
}

/** Декодируем { cellTexts: [...] } обратно в PracticeTableUIState */
function decodePracticeTable(raw: any): PracticeTableUIState {
    const map = decodeCellTexts(raw.cellTexts);
    return new PracticeTableUIState(map);
}

/**
 * Превращаем Map<CellCoordinate, string> → массив пар [ {col,row}, text, ... ].
 * При этом храним { col,row } как plain-объект, чтобы Firestore не ругался.
 */
function encodeCellTexts(
    cellMap: Map<CellCoordinate, string> | any
): Array<{ columnIndex: number; rowIndex: number } | string> {
    if (!(cellMap instanceof Map)) {
        console.warn("encodeCellTexts: cellMap не является Map. Преобразуем из объекта.", cellMap);
        cellMap = convertObjectToMap(cellMap);
    }
    const entries = [...cellMap.entries()];

    // Сортируем, чтобы в JSON был стабильный порядок (опционально)
    entries.sort(([coordA], [coordB]) => {
        if (coordA.rowIndex !== coordB.rowIndex) {
            return coordA.rowIndex - coordB.rowIndex;
        }
        return coordA.columnIndex - coordB.columnIndex;
    });

    const array: Array<{ columnIndex: number; rowIndex: number } | string> = [];
    for (const [coord, text] of entries) {
        // Firestore не принимает класс CellCoordinate, поэтому пишем plain-объект
        array.push({ columnIndex: coord.columnIndex, rowIndex: coord.rowIndex }, text);
    }
    return array;
}

/**
 * Массив вида [ { col, row }, "Text", { col, row }, "Text", ... ] -> Map<CellCoordinate, string>.
 */
function decodeCellTexts(cellsArray: Array<any>): Map<CellCoordinate, string> {
    const map = new Map<CellCoordinate, string>();
    for (let i = 0; i < (cellsArray?.length ?? 0); i += 2) {
        const coordPart = cellsArray[i];
        const textPart = cellsArray[i + 1];
        if (!coordPart || typeof textPart !== "string") {
            continue;
        }
        // Восстанавливаем CellCoordinate через .create()
        const coord: CellCoordinate = CellCoordinate.create(
            coordPart.columnIndex ?? 0,
            coordPart.rowIndex ?? 0
        );
        map.set(coord, textPart);
    }
    return map;
}

/**
 * Если cellMap оказался обычным объектом (например {"0,0": "Hello"}), переводим в Map<CellCoordinate,string>.
 */
function convertObjectToMap(obj: any): Map<CellCoordinate, string> {
    if (!obj || typeof obj !== "object" || Array.isArray(obj)) {
        return new Map<CellCoordinate, string>();
    }
    const map = new Map<CellCoordinate, string>();
    for (const key of Object.keys(obj)) {
        const textValue = obj[key];
        if (typeof textValue !== "string") {
            continue;
        }
        // Преобразуем ключ "col,row" в CellCoordinate
        const [colStr, rowStr] = key.split(",");
        const col = parseInt(colStr, 10) || 0;
        const row = parseInt(rowStr, 10) || 0;
        map.set(CellCoordinate.create(col, row), textValue);
    }
    return map;
}


function encodePracticeHangman(hangmanData: PracticeHangmanUIState): Record<string, unknown> {
    return PracticeHangmanUIStateUtils.toFirestore(hangmanData);
}

/**
 * Обратное: { guessedLetters: [...], mistakes: N, ... } -> PracticeHangmanUIState
 */
function decodePracticeHangman(raw: any): PracticeHangmanUIState {
    return PracticeHangmanUIStateUtils.fromFirestore(raw);
}

function encodeMatchingPairs(mpData: MatchingPairsUIState): Record<string, unknown> {
    // mpData содержит поля:
    // {
    //   matchedPairs: MatchedPair[],
    //   selectedLeft?: string,
    //   selectedRight?: string,
    //   lastWrongAttempt?: MatchedPair
    // }
    // Нужно вернуть объект, который Firestore сохранит
    return {
        matchedPairs: mpData.matchedPairs.map(mp => ({
            leftId: mp.leftId,
            rightId: mp.rightId
        })),
        selectedLeft: mpData.selectedLeft ?? null,
        selectedRight: mpData.selectedRight ?? null,
        lastWrongAttempt: mpData.lastWrongAttempt
            ? {
                leftId: mpData.lastWrongAttempt.leftId,
                rightId: mpData.lastWrongAttempt.rightId
            }
            : null
    };
}

function decodeMatchingPairs(raw: any): MatchingPairsUIState {
    // raw = {
    //   matchedPairs: [ { leftId, rightId }, ... ],
    //   selectedLeft: "...",
    //   selectedRight: "...",
    //   lastWrongAttempt: { leftId, rightId }
    // }
    const matchedPairs: MatchedPair[] = Array.isArray(raw?.matchedPairs)
        ? raw.matchedPairs.map((mp: any) => ({
            leftId: mp.leftId,
            rightId: mp.rightId,
        }))
        : [];

    return {
        matchedPairs,
        selectedLeft: raw?.selectedLeft || undefined,
        selectedRight: raw?.selectedRight || undefined,
        lastWrongAttempt: raw?.lastWrongAttempt
            ? {
                leftId: raw.lastWrongAttempt.leftId,
                rightId: raw.lastWrongAttempt.rightId,
            }
            : undefined,
    };
}

function encodeJustContent(jcState: JustContentPracticeUIState): Record<string, unknown> {
    return {
        contents: jcState.contents.map((item) => encodeCanvasItem(item)),
    };
}

function decodeJustContent(raw: any): JustContentPracticeUIState {
    // Expecting raw = { contents: [ { id, type, content, ... }, ... ] }
    if (!raw || !Array.isArray(raw.contents)) {
        return new JustContentPracticeUIState([]);
    }
    const array: CanvasItem[] = raw.contents.map((it: any) => decodeCanvasItem(it));
    return new JustContentPracticeUIState(array)
}


// =====================
// Encode / Decode FREE
// =====================

function encodeFreePractice(freeData: FreePracticeUIState): Record<string, unknown> {
    // Simply store the properties you need:
    return {
        htmlString: freeData.htmlString,
        fields: freeData.fields,
    };
}

function decodeFreePractice(raw: any): FreePracticeUIState {
    // Expecting an object like { htmlString: "...", fields: { key: value } }
    if (!raw || typeof raw.htmlString !== "string") {
        // Fallback if invalid
        return new FreePracticeUIState("");
    }
    // fields might be missing or not an object, so handle carefully:
    const fieldsObj = (raw.fields && typeof raw.fields === "object") ? raw.fields : {};
    return new FreePracticeUIState(raw.htmlString, fieldsObj);
}

function encodeWordSearch(wsData: WordSearchUIState): Record<string, unknown> {
    return {
        foundWords: wsData.foundWords.map(wordItem => serializeWordItem(wordItem)),
        selectedPositions: wsData.selectedPositions
    };
}
function decodeWordSearch(raw: any): WordSearchUIState {
    return {
        foundWords: Array.isArray(raw.foundWords)
            ? raw.foundWords
                .map(deserializeWordItem)
                .filter((wordItem: WordItem): wordItem is WordItem => wordItem !== null)
            : [],
        selectedPositions: raw.selectedPositions || []
    };
}

function encodeMissingWord(mwData: MissingWordUIState): Record<string, unknown> {
    return {
        currentInput: mwData.currentInput,
        isCorrect: mwData.isCorrect,
    };
}
function decodeMissingWord(raw: any): MissingWordUIState {
    const currentInput = typeof raw.currentInput === "string" ? raw.currentInput : "";
    const isCorrect = typeof raw.isCorrect === "boolean" ? raw.isCorrect : null;

    return new MissingWordUIState(currentInput, isCorrect);
}

function encodeFillBlanks(fbData: FillBlanksUIState): Record<string, unknown> {
    // We store placeholders as an array of {id, userInput, isCorrect}, for example
    return {
        placeholders: fbData.placeholders.map((p) => ({
            id: p.id,
            userInput: p.userInput,
            isCorrect: p.isCorrect,
        })),
    };
}

/** Decode plain object -> FillBlanksUIState */
function decodeFillBlanks(raw: any): FillBlanksUIState {
    // Expect raw to look like { placeholders: [ { id, userInput, isCorrect }, ... ] }
    const placeholders = Array.isArray(raw?.placeholders)
        ? raw.placeholders.map((p: any) => ({
            id: typeof p.id === "number" ? p.id : 0,
            userInput: typeof p.userInput === "string" ? p.userInput : "",
            isCorrect: typeof p.isCorrect === "boolean" ? p.isCorrect : null,
        }))
        : [];

    return new FillBlanksUIState(placeholders);
}

function encodeAnagramUIState(anData: AnagramUIState): Record<string, unknown> {
    return {
        scrambledLetters: anData.scrambledLetters,
        currentAnswer: anData.currentAnswer,
        isCompleted: anData.isCompleted,
    };
}

function decodeAnagramUIState(raw: any): AnagramUIState {
    return new AnagramUIState(
        raw?.scrambledLetters ?? "",
        raw?.currentAnswer ?? "",
        !!raw?.isCompleted
    );
}

function encodeSelectOption(soData: SelectOptionUIState): Record<string, unknown> {
    return {
        selectedOption: soData.selectedOption,
        isCorrect: soData.isCorrect,
    };
}

function decodeSelectOption(raw: any): SelectOptionUIState {
    const selectedOption = typeof raw.selectedOption === "string" ? raw.selectedOption : null;
    const isCorrect = typeof raw.isCorrect === "boolean" ? raw.isCorrect : null;
    return new SelectOptionUIState(selectedOption, isCorrect);
}
