// FreeExerciseCreateView.tsx
import React, {useEffect, useState} from "react";
import { observer } from "mobx-react-lite";
import {Exercise, WordItem} from "../../../../../domain/models";
import { OpenAIRequester } from "../../../../../data/implementations/OpenAIRequester";
import { apiKey } from "../../../../../data/config/environment";
import { OpenAIImageHtmlRecognizer } from "../Base/OpenAIImageHtmlRecognizer";
import { HtmlGenerationInteractor } from "../Base/HtmlGenerationInteractor";
import { HtmlGenerationViewModel } from "../Base/HtmlGenerationViewModel";
import { HtmlGenerationView } from "../Base/HtmlGenerationView";
import BaseButton, { ButtonVariant } from "../../../BaseButton";
import {useParams} from "react-router-dom"; // Добавляем импорт

interface Props {
    onExercisesCreated: (exs: Exercise[]) => void;
}

export const FreeExerciseCreateView = observer(({ onExercisesCreated }: Props) => {
    // Получаем слова студента из контекста
    const { studentId } = useParams<{ studentId: string }>();

    // Флаг, использовать ли слова (по умолчанию true, но отображать будем только если words есть)
    const [useStudentWords, setUseStudentWords] = useState(true);

    // Создаём ViewModel (один раз)
    const [viewModel] = useState(() => {
        const openAIRequester = new OpenAIRequester(apiKey);
        const htmlRecognizer = new OpenAIImageHtmlRecognizer(openAIRequester);
        const interactor = new HtmlGenerationInteractor(htmlRecognizer, openAIRequester);
        return new HtmlGenerationViewModel(interactor);
    });

    useEffect(() => {
        if (studentId) {
            viewModel.loadStudentWords(studentId);
        }
        // При размонтировании отписываемся
        return () => {
            viewModel.dispose();
        };
    }, [studentId, viewModel]);


    function handleSave() {
        const newExercise: Exercise = {
            id: generateRandomId(),
            type: {
                kind: "free",
                data: {
                    htmlString: viewModel.generatedHtml || "",
                },
            },
        };
        onExercisesCreated([newExercise]);
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
            {viewModel.words.length > 0 && (
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={viewModel.useStudentWords}
                            onChange={(e) => viewModel.setUseStudentWords(e.target.checked)}
                        />
                        Use student's words
                    </label>
                    {viewModel.useStudentWords && (
                        <div>
                            <h3>Student's Words:</h3>
                            <ul>
                                {viewModel.words.map((w) => (
                                    <li key={w.id}>
                                        {w.word} — {w.translation}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            )}

            <HtmlGenerationView viewModel={viewModel} />

            {!!(viewModel.generatedHtml && viewModel.generatedHtml.trim()) && (
                <div>
                    <BaseButton
                        onClick={handleSave}
                        variant={ButtonVariant.Classic}
                    >
                        Save
                    </BaseButton>
                </div>
            )}
        </div>
    );
});

function generateRandomId(): string {
    return crypto.randomUUID().toUpperCase();
}