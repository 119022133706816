// src/onboarding/LanguageSelectionViewModel.ts
import { makeAutoObservable } from 'mobx';
import { LanguageSelectionInteractor } from './LanguageSelectionInteractor';
import {AppUserLanguages} from "../../../../domain/models";

export class LanguageSelectionViewModel {
    appLanguage = '';
    learningLanguage = '';
    nativeLanguage = '';

    isLoading = false;
    error: string | null = null;

    constructor(private interactor: LanguageSelectionInteractor) {
        makeAutoObservable(this);
    }

    async saveLanguages() {
        this.isLoading = true;
        this.error = null;
        try {
            const langs: AppUserLanguages = {
                appLanguage: this.appLanguage,
                learningLanguage: this.learningLanguage,
                nativeLanguage: this.nativeLanguage
            };
            await this.interactor.updateLanguages(langs);
        } catch (e: any) {
            this.error = e.message;
        } finally {
            this.isLoading = false;
        }
    }
}