import React from "react";
import BaseButton, { ButtonVariant } from "../../../BaseButton";

interface HtmlEditorProps {
    isLoading: boolean;
    generatedHtml: string | null;
    parsedHtml: React.ReactNode;
    improvementComment: string;
    onImprovementCommentChange: (text: string) => void;
    onImprove: () => Promise<void>;
    onAddAudio: () => void;
}

/**
 * 2) Компонент вывода сгенерированного HTML и ввода "improvement instructions"
 * Не знает о ViewModel, получает данные и коллбеки через props
 */
const HtmlEditor: React.FC<HtmlEditorProps> = ({
                                                   isLoading,
                                                   generatedHtml,
                                                   parsedHtml,
                                                   improvementComment,
                                                   onImprovementCommentChange,
                                                   onImprove,
                                                   onAddAudio,
                                               }) => {
    if (!generatedHtml && !isLoading) {
        // Если ничего нет, можно вернуть null или пустой блок
        return null;
    }

    return (
        <>
            {/* Spinner */}
            {isLoading && (
                <div style={{ display: "flex", justifyContent: "center", marginTop: 40 }}>
                    <style>
                        {`
                          @keyframes spin {
                            0% { transform: rotate(0deg); }
                            100% { transform: rotate(360deg); }
                          }
                        `}
                    </style>
                    <div
                        style={{
                            display: "inline-block",
                            width: 80,
                            height: 80,
                            border: "8px solid #f3f3f3",
                            borderTop: "8px solid #3498db",
                            borderRadius: "50%",
                            animation: "spin 1.5s linear infinite",
                        }}
                    />
                </div>
            )}

            {/* When there's generatedHtml (and we're not loading) - show improvements */}
            {!isLoading && generatedHtml && (
                <>
                    <div style={{ marginTop: 20 }}>
                        <label style={{ display: "block", marginBottom: 6 }}>
                            Improvement instructions:
                        </label>
                        <textarea
                            placeholder="Write your improvement instructions..."
                            value={improvementComment}
                            onChange={(e) => onImprovementCommentChange(e.target.value)}
                            style={{ width: "100%", height: 60 }}
                        />
                        <div style={{ marginTop: 10 }}>
                            <BaseButton
                                onClick={onImprove}
                                disabled={isLoading}
                                variant={ButtonVariant.Classic}
                            >
                                Improve
                            </BaseButton>

                            <BaseButton
                                onClick={onAddAudio}
                                disabled={isLoading}
                                variant={ButtonVariant.Classic}
                            >
                                Add audio
                            </BaseButton>
                        </div>
                    </div>

                    {parsedHtml && (
                        <div
                            style={{
                                marginTop: 20,
                                border: "1px solid #ccc",
                                padding: 10,
                                textAlign: 'left'
                            }}
                        >
                            {parsedHtml}
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default HtmlEditor;