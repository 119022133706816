// src/components/MainContent.tsx
import React from 'react';
import StartScreen from '../start/StartScreen';
import AddingTypes from '../words/adding/AddingTypes';
import WordsToAddPreview from '../words/adding/WordsToAddPreview';
import SubscriptionScreen from '../start/SubscriptionScreen';
import ScreenForType from './ScreenForType';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SettingsScreen from "../settings/SettingsScreen";
import AuthScreen from "../settings/auth/AuthScreen";
import LessonsList from "../../ui/components/PracticeManagement/UI/LessonsList/LessonsList";
import {AllExercisesView} from "../../ui/components/PracticeManagement/UI/AllExercisesView/AllExercisesView";
import {LessonCreationView} from "../../ui/components/PracticeManagement/UI/LessonCreation/LessonCreationView";
import {SingleExerciseView} from "../../ui/components/Practice/SingleExercise/SingleExerciseView";
import StudentsScreen from "../../ui/components/Students/StudentsList/StudentsScreen";
import ConfirmTutorScreen from "../../ui/components/Students/ConfirmTutor/ConfirmTutorScreen";
import StudentScreen from "../../ui/components/Students/StudentScreen/StudentScreen";
import LearningPlanEditScreen from "../../ui/components/Students/LearningPlanEditScreen/LearningPlanEditScreen";
import {RequireUserData} from "../../ui/components/Onboarding/RequireUserData";
import {OnboardingFlowRoot} from "../../ui/components/Onboarding/OnboardingFlowRoot";
import Classroom from "../../ui/components/Practice/Classroom/Classroom";
import LessonPracticeContainer from "../../ui/components/Practice/Lesson/LessonPracticeContainer";

const MainContent: React.FC = () => {
    return (
        <Router>
            <Routes>
                {/*
          1) Пусть экраны выбора роли и языков будут открыты:
             Пользователь может перейти сюда по navigate (например, из RequireUserData).
        */}
                {/*<Route path="/chooseRole" element={<UserTypeSelectionScreen />} />*/}
                {/*<Route path="/chooseLanguages" element={<UserLanguageSelectionScreen />} />*/}
                <Route path="/onboardingFlow" element={<OnboardingFlowRoot />} />

                {/*
          2) Все остальные маршруты обернём в RequireUserData.
             Если пользователь не заполнил роль/языки,
             внутри RequireUserData произойдёт редирект на нужный экран.
        */}
                <Route
                    path="/"
                    element={
                        <RequireUserData>
                            <StartScreen />
                        </RequireUserData>
                    }
                />
                <Route
                    path="/addFromTextEnteringText"
                    element={
                        <RequireUserData>
                            <ScreenForType screen="addFromTextEnteringText" />
                        </RequireUserData>
                    }
                />
                <Route
                    path="/addByTheme"
                    element={
                        <RequireUserData>
                            <ScreenForType screen="addByTheme" />
                        </RequireUserData>
                    }
                />
                <Route
                    path="/addMultiple"
                    element={
                        <RequireUserData>
                            <ScreenForType screen="addMultiple" />
                        </RequireUserData>
                    }
                />
                <Route
                    path="/mainAdd"
                    element={
                        <RequireUserData>
                            <AddingTypes />
                        </RequireUserData>
                    }
                />
                <Route
                    path="/subscription"
                    element={
                        <RequireUserData>
                            <SubscriptionScreen />
                        </RequireUserData>
                    }
                />
                <Route
                    path="/addManually"
                    element={
                        <RequireUserData>
                            <ScreenForType screen="addManually" />
                        </RequireUserData>
                    }
                />
                <Route
                    path="/mainStat"
                    element={
                        <RequireUserData>
                            <ScreenForType screen="mainStat" />
                        </RequireUserData>
                    }
                />
                <Route
                    path="/settings"
                    element={
                        <RequireUserData>
                            <SettingsScreen />
                        </RequireUserData>
                    }
                />
                <Route
                    path="/auth"
                    element={
                        <RequireUserData>
                            <AuthScreen />
                        </RequireUserData>
                    }
                />
                <Route
                    path="/mainStudy"
                    element={
                        <RequireUserData>
                            <ScreenForType screen="mainStudy" />
                        </RequireUserData>
                    }
                />
                <Route
                    path="/lessonsList"
                    element={
                        <RequireUserData>
                            <LessonsList />
                        </RequireUserData>
                    }
                />
                <Route
                    path="/lessonPractice/:id"
                    element={
                        <LessonPracticeContainer />
                    }
                />
                <Route
                    path="/allExercises"
                    element={
                        <RequireUserData>
                            <AllExercisesView />
                        </RequireUserData>
                    }
                />
                <Route
                    path="/createLesson"
                    element={
                        <RequireUserData>
                            <LessonCreationView />
                        </RequireUserData>
                    }
                />
                <Route
                    path="/exercise/:id"
                    element={
                        <RequireUserData>
                            <SingleExerciseView />
                        </RequireUserData>
                    }
                />
                <Route
                    path="/students"
                    element={
                        <RequireUserData>
                            <StudentsScreen />
                        </RequireUserData>
                    }
                />
                <Route
                    path="/confirmTutor/:tutorId"
                    element={
                        <RequireUserData>
                            <ConfirmTutorScreen />
                        </RequireUserData>
                    }
                />
                <Route
                    path="/student/:id"
                    element={
                        <RequireUserData>
                            <StudentScreen />
                        </RequireUserData>
                    }
                />
                <Route
                    path="/learningPlan/:studentId"
                    element={
                            <LearningPlanEditScreen />
                    }
                />

                <Route
                    path="/class/:id"
                    element={
                        <RequireUserData>
                            <Classroom />
                        </RequireUserData>
                    }
                />
            </Routes>
        </Router>
    );
};

export default MainContent;
