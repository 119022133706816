import { makeAutoObservable, reaction, runInAction } from "mobx";
import { JustContentPracticeUIHandler } from "./JustContentPracticeUIHandler";
import {PracticeSessionDelegate} from "../../../../domain/Exercises/PracticeSessionDelegate";
import {Exercise} from "../../../../domain/models";
import {CanvasInteractor} from "../Base/CanvasInteractor";
import {CanvasItem} from "../Base/CanvasItem";

export class JustContentPracticeInteractor  {
    private delegate?: PracticeSessionDelegate;
    public uiHandler: JustContentPracticeUIHandler;

    public canvas: CanvasInteractor;

    constructor(
        exercise: Exercise,
        delegate?: PracticeSessionDelegate,
        uiHandler?: JustContentPracticeUIHandler,
    ) {
        if (exercise.type.kind !== "justContent") {
            throw new Error("Not a justContent exercise");
        }
        this.delegate = delegate;
        this.uiHandler = uiHandler ?? new JustContentPracticeUIHandler(exercise.id);

        this.canvas = new CanvasInteractor()
        this.canvas.items = [...exercise.type.data.contents]

        this.setupBindings();
        setTimeout(() => { this.syncFromUIHandler(); }, 0);
        makeAutoObservable(this);
    }

    private setupBindings(): void {
        reaction(
            () => JSON.stringify(this.uiHandler.contents),
            () => {
                this.syncFromUIHandler();
            }
        );

        reaction(
            () => this.canvas.items,
            (newItems) => {
                this.syncToUIHandler(newItems)
            }
        );
    }

    private syncFromUIHandler() {
        let currentContents = this.uiHandler.contents;

        if (JSON.stringify(currentContents) !== JSON.stringify(this.canvas.items)) {
            if (currentContents.length === 0) {
                currentContents = this.canvas.items
            }
            runInAction(() => {
                this.canvas.items = currentContents
            });
        }
    }

    private syncToUIHandler(newItems: CanvasItem[]) {
        this.uiHandler.contents = newItems;
    }
}