// FreePracticeUseCase.ts
import { FreePracticeState } from "./FreePracticeState";

export class FreePracticeUseCase {
    /**
     * Update a single field (by id) with a new value.
     */
    public updateFieldValue(
        currentState: FreePracticeState,
        fieldId: string,
        newValue: string
    ): FreePracticeState {
        const newFields = {
            ...currentState.fields,
            [fieldId]: newValue,
        };

        return {
            ...currentState,
            fields: newFields,
        };
    }

    /**
     * (Optional) If you need to update the HTML template itself.
     */
    public updateHtmlTemplate(
        currentState: FreePracticeState,
        newHtml: string
    ): FreePracticeState {
        return {
            ...currentState,
            htmlString: newHtml,
        };
    }
}