// src/onboarding/RoleSelectionViewModel.ts
import { makeAutoObservable } from 'mobx';
import { RoleSelectionInteractor } from './RoleSelectionInteractor';

export class RoleSelectionViewModel {
    isLoading = false;
    error: string | null = null;

    constructor(private interactor: RoleSelectionInteractor) {
        makeAutoObservable(this);
    }

    async selectRole(role: 'student' | 'teacher') {
        this.isLoading = true;
        this.error = null;
        try {
            await this.interactor.updateRole(role);
        } catch (e: any) {
            this.error = e.message;
        } finally {
            this.isLoading = false;
        }
    }
}