import React, { useRef, useCallback } from "react";
import BaseButton, { ButtonVariant } from "../../../BaseButton";

interface ImageSelectorProps {
    imageUrl: string | null;
    isLoading: boolean;
    onImageSelect: (file: File) => Promise<void>;
}

/**
 * Компонент выбора и отображения картинки.
 * Не знает о ViewModel, получает все необходимые данные и коллбеки через props.
 */
const ImageSelector: React.FC<ImageSelectorProps> = ({
                                                         imageUrl,
                                                         isLoading,
                                                         onImageSelect,
                                                     }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileSelect = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!isLoading && event.target.files && event.target.files.length > 0) {
                await onImageSelect(event.target.files[0]);
            }
        },
        [isLoading, onImageSelect]
    );

    const handleDrop = useCallback(
        async (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            if (!isLoading && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
                await onImageSelect(event.dataTransfer.files[0]);
            }
        },
        [isLoading, onImageSelect]
    );

    const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    }, []);

    const handlePaste = useCallback(
        async (event: React.ClipboardEvent<HTMLDivElement>) => {
            const { items } = event.clipboardData;
            for (let i = 0; i < items.length; i++) {
                if (items[i].kind === "file") {
                    const file = items[i].getAsFile();
                    if (file) {
                        event.preventDefault();
                        if (!isLoading) {
                            await onImageSelect(file);
                        }
                        break;
                    }
                }
            }
        },
        [isLoading, onImageSelect]
    );

    const handleClickSelectButton = useCallback(() => {
        if (!isLoading) {
            fileInputRef.current?.click();
        }
    }, [isLoading]);

    const handleClickImage = useCallback(() => {
        if (!isLoading) {
            fileInputRef.current?.click();
        }
    }, [isLoading]);

    return (
        <div
            style={{
                width: "100%",
                minHeight: 300,
                maxHeight: 400,
                boxSizing: "border-box",
                border: imageUrl ? "2px dashed transparent" : "2px dashed #ccc",
                padding: 20,
                marginBottom: 20,
                textAlign: "center",
                cursor: imageUrl ? "pointer" : "default",
                position: "relative",
                alignItems: "center",
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onPaste={handlePaste}
            onClick={imageUrl ? handleClickImage : undefined}
        >
            <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileSelect}
            />

            {!imageUrl && (
                <BaseButton onClick={handleClickSelectButton} variant={ButtonVariant.Classic}>
                    Select an image
                </BaseButton>
            )}

            {imageUrl && (
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <img
                        src={imageUrl}
                        alt="Selected"
                        style={{ maxWidth: "100%", maxHeight: 400 }}
                    />
                </div>
            )}
        </div>
    );
};

export default ImageSelector;