// src/components/RequireUserData.tsx
import React, { PropsWithChildren, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import {observer} from "mobx-react-lite";
import CompositionRoot from "../../../compositionRoot";

export const RequireUserData = observer(({ children }: PropsWithChildren<{}>) => {
    // const { currentUser, loading } = useAuth();
    // const navigate = useNavigate();
    // const location = useLocation();
    //
    // // Берём singleton flowManager:
    // const flowManager = CompositionRoot.onboardingFlowManager
    //
    // useEffect(() => {
    //     if (loading) return;
    //     if (!currentUser) return;
    //
    //     if (flowManager.currentStep !== 'done' && location.pathname !== '/onboardingFlow') {
    //         navigate('/onboardingFlow');
    //     }
    // }, [loading, currentUser, location, navigate, flowManager.currentStep]);
    //
    // if (loading || !currentUser) {
    //     return <div>Загрузка пользователя...</div>;
    // }

    return <>{children}</>;
});