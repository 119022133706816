// CrosswordCreateView.tsx

import React, { useState } from "react";
import {Exercise, WordItem} from "../../../../../domain/models";
import {DefaultCrosswordBuilder} from "../Domain/CrosswordBuilderProtocol";
import {ExerciseCrossword} from "../Domain/ExerciseCrossword";
import BaseButton, {ButtonVariant} from "../../../BaseButton";

interface CrosswordCreateViewProps {
    onExercisesCreated: (exs: Exercise[]) => void;
}

interface RowData {
    word: string;
    hint: string;
}

export function CrosswordCreateView({ onExercisesCreated }: CrosswordCreateViewProps) {
    const [rows, setRows] = useState<RowData[]>([{ word: "", hint: "" }]);

    const addRow = () => {
        setRows(prev => [...prev, { word: "", hint: "" }]);
    };

    const handleWordChange = (index: number, newVal: string) => {
        setRows(prev => {
            const copy = [...prev];
            copy[index] = { ...copy[index], word: newVal };
            return copy;
        });
    };

    const handleHintChange = (index: number, newVal: string) => {
        setRows(prev => {
            const copy = [...prev];
            copy[index] = { ...copy[index], hint: newVal };
            return copy;
        });
    };

    const handleCreate = () => {
        // Преобразуем каждую строку в WordItem
        const wordItems: WordItem[] = rows.map((r) => ({
            id: crypto.randomUUID().toUpperCase(),
            word: r.word.trim(),
            translation: r.hint.trim(),
            example: "",
            repetitions: [],
            imageURL: "",
        }));

        // Используем DefaultCrosswordBuilder для построения кроссворда
        const builder = new DefaultCrosswordBuilder();
        const result = builder.buildCrossword(wordItems);

        // Создаём ExerciseCrossword на основе результата билдера
        const cw = new ExerciseCrossword(
            wordItems,
            result.grid,
            result.placedWords,
            result.occupiedPositions,
            result.startPositions
        );

        // Формируем итоговый объект Exercise
        const exercise: Exercise = {
            id: crypto.randomUUID().toUpperCase(),
            type: { kind: "crossword", data: cw },
        };

        // Вызываем коллбек, передавая массив созданных упражнений
        onExercisesCreated([exercise]);
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
                width: "100%",
            }}
        >
            <h3>Create Crossword Exercise</h3>
            <div
                style={{
                    position: "relative",
                    overflow: "auto",
                    width: "fit-content",
                    margin: "0 auto",
                }}
            >
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                    <thead>
                    <tr>
                        <th style={{ border: "1px solid gray", padding: "4px" }}>Word</th>
                        <th style={{ border: "1px solid gray", padding: "4px" }}>Hint</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows.map((row, index) => (
                        <tr key={index}>
                            <td style={{ border: "1px solid gray", padding: "4px" }}>
                                <input
                                    type="text"
                                    value={row.word}
                                    onChange={(e) => handleWordChange(index, e.target.value)}
                                    placeholder="word"
                                    style={{ width: "200px" }}
                                />
                            </td>
                            <td style={{ border: "1px solid gray", padding: "4px" }}>
                                <input
                                    type="text"
                                    value={row.hint}
                                    onChange={(e) => handleHintChange(index, e.target.value)}
                                    placeholder="hint / translation"
                                    style={{ width: "200px" }}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                {/* Кнопка добавления строки располагается справа */}
                <div style={{ position: "absolute", top: "0", right: "0", margin: "8px" }}>
                    <BaseButton variant={ButtonVariant.Small} onClick={addRow}>
                        +
                    </BaseButton>
                </div>
            </div>
            <div>
                <BaseButton
                    variant={ButtonVariant.Big}
                    onClick={handleCreate}
                >
                    Create exercise
                </BaseButton>
            </div>
        </div>
    );
}