import React, { CSSProperties } from 'react';
import './LoadingButton.css';

export enum ButtonVariant {
    Classic = 'classic',
    Small = 'small',
}

interface LoadingButtonProps {
    color?: string;
    onClick: () => void;
    children: React.ReactNode;
    isLoading?: boolean;
    variant?: ButtonVariant;
    style?: CSSProperties; // Дополнительные стили, передаваемые в компонент
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
                                                         color = '#007bff',
                                                         onClick,
                                                         children,
                                                         isLoading = false,
                                                         variant = ButtonVariant.Classic,
                                                         style,
                                                     }) => {
    // Добавляем классы в зависимости от состояния загрузки и варианта кнопки
    const buttonClass = `loading-button ${variant} ${
        isLoading ? 'loading-button-loading' : ''
    }`;

    return (
        <button
            onClick={!isLoading ? onClick : undefined}
            className={buttonClass}
            style={{
                backgroundColor: color,
                color: '#fff',
                ...style, // Подключаем дополнительные стили
            }}
            disabled={isLoading}
        >
            {/* Текст кнопки */}
            <span className="loading-button-text">{children}</span>
            {/* Спиннер */}
            {isLoading && <div className="loading-button-spinner" />}
        </button>
    );
};

export default LoadingButton;