import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {useNavigate, useParams} from 'react-router-dom';

import StudentScreenViewModel from './StudentScreenViewModel';
import StudentCard from "../StudentCard";
import LoadingButton, {ButtonVariant} from "../../exercises_types/BaseComponents/LoadingButton";
import StudentWordsList from "./StudentWordsList";


const StudentScreen: React.FC = observer(() => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    // Создаём interactor и viewModel
    const [viewModel] = useState(() => {
        return new StudentScreenViewModel();
    });
    const [isStarting, setIsStarting] = useState(false);

    useEffect(() => {
        if (id) {
            viewModel.init(id);
        }
        return () => {
            viewModel.dispose();
        };
    }, [id, viewModel]);

    // 1. Пока ждём teacherId
    if (viewModel.loadingUser) {
        return (
            <div style={styles.containerCenter}>
                <h2>Loading current user...</h2>
            </div>
        );
    }

    // 2. Если ошибка
    if (viewModel.error) {
        return (
            <div style={styles.containerCenter}>
                <h2 style={{ color: 'red' }}>{viewModel.error}</h2>
            </div>
        );
    }

    // 3. Если ещё идёт загрузка данных о студенте
    if (viewModel.loadingStudent) {
        return (
            <div style={styles.containerCenter}>
                <h2>Loading student info...</h2>
            </div>
        );
    }

    // 4. Если данных о студенте нет
    if (!viewModel.studentData) {
        return (
            <div style={styles.containerCenter}>
                <h2>Student not found</h2>
            </div>
        );
    }

    const currentClassId = viewModel.currentUser?.currentClass;
    const hasCurrentClass = !!currentClassId;

    const { completedClasses } = viewModel;

    // 5. Если всё ок, показываем карточку + остальные вьюшки
    return (
        <div style={styles.pageContainer}>
            <StudentCard
                data={viewModel.studentData}
            />

            <StudentWordsList studentId={viewModel.studentData.student.id} />

            <LoadingButton
                variant={ButtonVariant.Classic}
                isLoading={isStarting}
                onClick={async () => {
                    if (hasCurrentClass) {
                        navigate(`/class/${currentClassId}`);
                        return;
                    }
                    try {
                        setIsStarting(true);
                        const classId = await viewModel.startClass();
                        if (classId) {
                            navigate(`/class/${classId}`);
                        }
                    } finally {
                        setIsStarting(false);
                    }
                }}
            >
                {hasCurrentClass ? 'Enter class' : 'Start'}
            </LoadingButton>

        </div>
    );
});

export default StudentScreen;

const styles: Record<string, React.CSSProperties> = {
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        maxWidth: '600px',
        margin: '40px auto',
    },
    containerCenter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
    },
    block: {
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '4px',
    },
};