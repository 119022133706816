// ---- src/ui/screens/confirmTutor/ConfirmTutorScreen.tsx ----
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import ConfirmTutorViewModel from './ConfirmTutorViewModel';
import ConfirmTutorInteractor from './ConfirmTutorInteractor';
import BaseButton, {ButtonVariant} from "../../BaseButton";

/**
 * Экран подтверждения преподавателя.
 * URL-роут предполагается вида: /confirm_tutor/:tutorId
 */
const ConfirmTutorScreen: React.FC = observer(() => {
    const { tutorId } = useParams<{ tutorId: string }>();
    const [viewModel] = useState(() => new ConfirmTutorViewModel(new ConfirmTutorInteractor()));

    useEffect(() => {
        if (tutorId) {
            viewModel.init(tutorId);
        }
        return () => {
            viewModel.dispose();
        };
    }, [tutorId, viewModel]);

    // Если tutorId вообще нет
    if (!tutorId) {
        return (
            <div style={styles.container}>
                <h2>No tutorId in URL</h2>
            </div>
        );
    }

    // Пока нет данных о текущем пользователе — показываем "Loading current user..."
    if (viewModel.loadingUser) {
        return (
            <div style={styles.container}>
                <h2>Loading current user...</h2>
            </div>
        );
    }

    // Если есть какая-то ошибка
    if (viewModel.error) {
        return (
            <div style={styles.container}>
                <h2 style={{ color: 'red' }}>{viewModel.error}</h2>
            </div>
        );
    }

    // Проверка: если айди текущего пользователя совпадает с айди препода,
    // значит, это сам препод, зашедший по собственной ссылке.
    const isSameId = viewModel.student?.uid === tutorId;

    return (
        <div style={styles.container}>
            <h1>Confirm Tutor</h1>

            {/* ID текущего пользователя (студента) */}
            <p style={{ fontWeight: 'bold' }}>
                Your ID: {viewModel.student?.uid ?? 'Unknown'}
            </p>

            {/* Если student.uid == tutorId => это страница самого преподавателя */}
            {isSameId && (
                <div style={{ marginTop: '10px', color: 'blue' }}>
                    You are on your own tutor page.
                    Students can use this link to confirm you as a tutor.
                </div>
            )}

            {/* Карточка с данными о преподе (AppUser) */}
            {viewModel.tutorInfo && (
                <div style={styles.card}>
                    <h3 style={{ marginBottom: '10px' }}>Tutor info</h3>
                    <p><b>Email:</b> {viewModel.tutorInfo.email}</p>
                    <p><b>UID:</b> {viewModel.tutorInfo.uid}</p>
                    <p><b>Status:</b> {viewModel.tutorInfo.status ?? 'No status'}</p>
                    <p><b>Trial Start:</b>{' '}
                        {viewModel.tutorInfo.trialStartDate
                            ? viewModel.tutorInfo.trialStartDate.toLocaleString()
                            : 'No trial date'}
                    </p>
                    <p><b>Delayed Sets:</b>{' '}
                        {viewModel.tutorInfo.delayedSets.length
                            ? viewModel.tutorInfo.delayedSets.join(', ')
                            : 'None'}
                    </p>
                    <p><b>Exercise Sets:</b>{' '}
                        {viewModel.tutorInfo.exerciseSets.length
                            ? viewModel.tutorInfo.exerciseSets.join(', ')
                            : 'None'}
                    </p>
                </div>
            )}

            {/* Если связь уже подтверждена — выводим сообщение об успехе */}
            {viewModel.confirmed && (
                <h2 style={{ color: 'green', marginTop: '20px' }}>
                    Tutor has been successfully confirmed!
                </h2>
            )}

            {/*
              Если не подтверждено и айди не совпадают
              => показываем кнопку Confirm
            */}
            {!viewModel.confirmed && !isSameId && (
                <BaseButton
                    variant={ButtonVariant.Big}
                    onClick={() => viewModel.confirmTutor()}
                    // disabled={viewModel.loadingConfirm}
                    // style={{ marginTop: '20px' }}
                >
                    {viewModel.loadingConfirm ? 'Confirming...' : 'Confirm'}
                </BaseButton>
            )}
        </div>
    );
});

export default ConfirmTutorScreen;

/**
 * Простейшие стили для центрирования и карточки
 */
const styles: Record<string, React.CSSProperties> = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',   // элементы будут прижаты к левой стороне
        justifyContent: 'flex-start',
        margin: '40px auto',        // выравнивание контейнера по центру страницы
        width: '400px',
    },
    card: {
        border: '1px solid #ccc',
        padding: '20px',
        borderRadius: '6px',
        width: '100%',
        textAlign: 'left',
        marginTop: '20px',
    },
};