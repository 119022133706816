import { CanvasItem } from "./CanvasItem";
import { DrawingManager } from "./DrawingManager";
import { findFreePosition } from "./FindFreePosition";
import {calculateProportionalDimensions} from "./ImageUtils";

export class CanvasUseCase {
    public addTextItem(
        items: CanvasItem[],
        position?: { x: number; y: number }
    ): { newItems: CanvasItem[]; newItem: CanvasItem } {
        const { x, y } = position ?? findFreePosition(items, 10, 10);

        const newItem: CanvasItem = {
            id: crypto.randomUUID().toUpperCase(),
            type: "text",
            content: "New Text",
            x,
            y,
            width: 300,
            height: 20,
        };

        const newItems = [...items, newItem];
        return { newItems, newItem };
    }

    public addDrawing(
        items: CanvasItem[]
    ): { newItems: CanvasItem[]; newItem: CanvasItem } {
        const newItem = DrawingManager.createDrawingItem();
        const newItems = [...items, newItem];
        return { newItems, newItem };
    }

    public addImageItemWithDimensions(
        items: CanvasItem[],
        imageUrl: string,
        dimensions: { width: number, height: number }
    ): CanvasItem[] {
        // Определяем максимальные и минимальные размеры
        const MAX_WIDTH = 400;
        const MAX_HEIGHT = 400;
        const MIN_SIZE = 100;

        // Вычисляем оптимальные размеры с сохранением пропорций
        let { width: newWidth, height: newHeight } = calculateProportionalDimensions(
            dimensions.width,
            dimensions.height,
            MAX_WIDTH,
            MAX_HEIGHT
        );

        // Увеличиваем маленькие изображения до минимального размера
        if (newWidth < MIN_SIZE && newHeight < MIN_SIZE) {
            const ratio = Math.max(MIN_SIZE / newWidth, MIN_SIZE / newHeight);
            newWidth = Math.round(newWidth * ratio);
            newHeight = Math.round(newHeight * ratio);
        }

        // Находим свободное место для изображения
        const { x, y } = findFreePosition(items, 50, 50);

        // Создаем новый элемент с вычисленными размерами
        const newItem: CanvasItem = {
            id: crypto.randomUUID().toUpperCase(),
            type: "image",
            content: imageUrl,
            x,
            y,
            width: newWidth,
            height: newHeight,
        };

        return [...items, newItem];
    }

    public updateItem(
        items: CanvasItem[],
        itemId: string,
        patch: Partial<Omit<CanvasItem, 'type'>>
    ): CanvasItem[] {
        const newItems = items.map(item =>
            item.id === itemId ? { ...item, ...patch } : item
        );
        return newItems;
    }

    public removeItem(
        items: CanvasItem[],
        itemId: string
    ): CanvasItem[] {
        return items.filter(item => item.id !== itemId);
    }

    public setAllItems(
        items: CanvasItem[],
        newItems: CanvasItem[]
    ): CanvasItem[] {
        return [...newItems];
    }

    public finalizeDrawing(
        items: CanvasItem[],
        itemId: string
    ): { newItems: CanvasItem[]; newItem: CanvasItem | null } {
        const oldItem = items.find(item => item.id === itemId);
        if (!oldItem || oldItem.type !== "drawing" || !oldItem.canDraw) {
            return { newItems: items, newItem: null };
        }
        const newItem = DrawingManager.finalizeDrawing(oldItem);
        const newItems = items.filter(item => item.id !== itemId).concat(newItem);
        return { newItems, newItem };
    }

    public duplicateItem(
        items: CanvasItem[],
        itemId: string
    ): { newItems: CanvasItem[]; newItem: CanvasItem | null } {
        const oldItem = items.find(item => item.id === itemId);
        if (!oldItem) {
            return { newItems: items, newItem: null };
        }
        const { x: freeX, y: freeY } = findFreePosition(items, oldItem.x + 15, oldItem.y + 15);
        const newItem: CanvasItem = {
            ...oldItem,
            id: crypto.randomUUID().toUpperCase(),
            x: freeX,
            y: freeY,
        };
        const newItems = [...items, newItem];
        return { newItems, newItem };
    }
}