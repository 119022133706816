// AddCard.tsx

import React from "react";
import BaseButton, { ButtonVariant } from "../../../BaseButton";

type AddCardProps = {
    onCreateClick: () => void;
    onAddExistingClick: () => void;
};

export function AddCard({ onCreateClick, onAddExistingClick }: AddCardProps) {
    return (
        <div
            style={{
                border: "1px dashed #ccc",
                padding: 16,
                borderRadius: 8,
                minWidth: 180,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 8,
            }}
        >
            <BaseButton
                variant={ButtonVariant.Classic}
                color="#2196f3"
                onClick={onCreateClick}
            >
                Create exercise
            </BaseButton>

            <BaseButton
                variant={ButtonVariant.Classic}
                color="#4caf50"
                onClick={onAddExistingClick}
            >
                Add from existing
            </BaseButton>
        </div>
    );
}