// AnagramTypePreview.tsx
import React from "react";
import { AnagramViewBase } from "../Base/AnagramViewBase";
import {Resizer} from "../../../Resizer";

export function AnagramTypePreview() {
    return (
        <Resizer targetSize={{ width: 800, height: 150 }}>
            <div style={{ width: "800px", height: "500px", alignContent: "center" }}>
                <AnagramViewBase
                    displayedWord="App name"
                    currentAnswer={["_", "_", "_", "_", "_", "_"]}
                    scrambledLetters={["d", "i", "c", "t", "u", "m"].reverse()}
                />
            </div>
        </Resizer>
    );
}