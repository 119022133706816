import { makeAutoObservable, reaction, runInAction } from "mobx";
import { NotesState } from "./NotesState";
import {NoteRepository} from "../Base/network/NoteRepository";
import {CanvasInteractor} from "../Base/CanvasInteractor";
import {CanvasItem} from "../Base/CanvasItem";

export class NotesInteractor {
    private repository: NoteRepository;
    private noteId: string;
    private unsubscribeFn: (() => void) | null = null;

    public version = 0;
    public canvas: CanvasInteractor;

    private isUpdatingFromServer = false;

    constructor(
        noteId: string,
        initialCanvas: NotesState,
        repository: NoteRepository
    ) {
        this.noteId = noteId;
        this.repository = repository;
        this.canvas = new CanvasInteractor()
        this.version = initialCanvas.version;

        this.canvas.items = [...initialCanvas.items];

        makeAutoObservable(this);

        this.subscribeToServerUpdates();

        reaction(
            () => this.canvas.items,
            (newItems, oldItems) => {
                const isItemsChanged = JSON.stringify(newItems) !== JSON.stringify(oldItems);
                if (isItemsChanged) {
                    this.updateRepo(newItems)
                }
            }
        );
    }

    private subscribeToServerUpdates() {
        this.unsubscribeFn = this.repository.subscribeToUpdates(this.noteId, (newState) => {
            if (!newState) return;
            if (newState.version > this.version) {
                this.updateCanvas(newState)
            }
        });
    }

    public unsubscribe() {
        if (this.unsubscribeFn) {
            this.unsubscribeFn();
            this.unsubscribeFn = null;
        }
    }

    updateRepo(newItems: CanvasItem[]) {
        if (this.isUpdatingFromServer) return;

        this.version += 1
        const newState: NotesState = {
            version: this.version,
            items: newItems
        }
        this.repository.saveState(this.noteId, newState);
    }

    updateCanvas(newCanvas: NotesState) {
        if (newCanvas.version <= this.version) return;
        const isItemsChanged = JSON.stringify(this.canvas.items) !== JSON.stringify(newCanvas.items);

        runInAction(() => {
            if (isItemsChanged) {
                this.isUpdatingFromServer = true;
                this.canvas.setItems(newCanvas.items)
                this.version = newCanvas.version;
            }
        });

        // Сбрасываем флаг не сразу, а на следующем цикле event loop
        setTimeout(() => {
            runInAction(() => {
                this.isUpdatingFromServer = false;
            });
        }, 0);
    }
}