import {createViewable} from "../../../exercises_types/exerciseViewableFactory";
import React, { MouseEvent } from "react";
import {Exercise} from "../../../../../domain/models";

interface ExcercisePreviewCardProps {
    exercise: Exercise;
    onDelete?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const ExcercisePreviewCard = ({exercise, onDelete}: ExcercisePreviewCardProps) => {
    const viewable = createViewable(exercise);

    return (
        <div
            style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #555",
                borderRadius: 4,
                marginRight: 8,
                width: 200,
                height: 200,
                overflow: "hidden",
            }}
        >
            {viewable.renderView(exercise, 200, 200)}
            {onDelete && (
                <button
                    style={{
                        position: "absolute",
                        bottom: 8,
                    }}
                    onClick={onDelete}>
                    Delete
                </button>
            )}
        </div>
    );
};