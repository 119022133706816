import React, { useEffect, useState } from "react";
import { useSizeContext } from "./SizeContext";
import { useSelectionState } from "./SelectionStateContext";
import {observer} from "mobx-react-lite";

// Mimics SwiftUI's SelectedWordView, showing/hiding the selected word
// and triggering ripple or shake animations.

export const SelectedWordView: React.FC = observer(() => {
    const { selectedWordViewHeight, setSelectedWordViewHeight, selectedWordViewOffset } =
        useSizeContext();
    const {
        selectedWord,
        lineColor,
        changeLineColor,
        wrongWordAnimateRef,
        correctWordAnimateRef,
    } = useSelectionState();

    const [isVisible, setIsVisible] = useState(false);
    const [lastVisibleWord, setLastVisibleWord] = useState("");
    const [shakeOffset, setShakeOffset] = useState(0);
    const [rippleEffect, setRippleEffect] = useState(false);

    // Assign the animate callbacks to replicate Swift closures.
    useEffect(() => {
        wrongWordAnimateRef.current = shake;
        correctWordAnimateRef.current = ripple;
    }, [wrongWordAnimateRef, correctWordAnimateRef]);

    // Observe changes to selectedWord
    useEffect(() => {
        if (selectedWord) {
            setLastVisibleWord(selectedWord);
            setIsVisible(true);
        } else {
            // Hide with a small delay, then change color
            setTimeout(() => {
                setIsVisible(false);
                setTimeout(() => changeLineColor(), 500);
            }, 100);
        }
    }, [selectedWord, changeLineColor]);

    function ripple() {
        setRippleEffect(true);
        // Reset after 0.5s
        setTimeout(() => {
            setRippleEffect(false);
        }, 500);
    }

    function shake() {
        // A quick sequence of offsets
        setShakeOffset(-10);
        setTimeout(() => setShakeOffset(10), 50);
        setTimeout(() => setShakeOffset(-10), 150);
        setTimeout(() => setShakeOffset(10), 250);
        setTimeout(() => setShakeOffset(0), 350);
    }

    // For dynamic height measurement
    const containerRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (containerRef.current) {
            setSelectedWordViewHeight(containerRef.current.getBoundingClientRect().height);
        }
    }, [isVisible, lastVisibleWord, setSelectedWordViewHeight]);

    return (
        <div
            ref={containerRef}
            style={{
                transform: `translateY(${selectedWordViewOffset}px) translateX(${shakeOffset}px)`,
                opacity: isVisible ? 1 : 0,
                transition: "opacity 0.5s ease-in-out, transform 0.05s linear",
            }}
        >
            <div
                style={{
                    display: "inline-block",
                    backgroundColor: lineColor,
                    color: "#fff",
                    padding: "4px 12px",
                    borderRadius: "9999px",
                    position: "relative",
                    overflow: "hidden",
                }}
            >
                {lastVisibleWord}

                {rippleEffect && (
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            border: `2px solid ${lineColor}`,
                            borderRadius: "9999px",
                            transform: "scale(1.3)",
                            opacity: 0,
                            animation: "rippleAnim 0.5s ease-out forwards",
                        }}
                    />
                )}
            </div>

            {/* Keyframe for ripple */}
            <style>{`
        @keyframes rippleAnim {
          0% { transform: scale(1); opacity: 1; }
          100% { transform: scale(1.5); opacity: 0; }
        }
      `}</style>
        </div>
    );
});