import {Exercise} from "../models";
import {ExercisesStorage} from "../interfaces/ExercisesStorage";
import AppUserManager from "../services/AppUserManager";
import CompositionRoot from "../../compositionRoot";

export interface SaveSingleExerciseInteractor {
    saveExercise(exercise: Exercise): void;
}

export class SaveSingleExerciseInteractorStub implements SaveSingleExerciseInteractor {
    saveExercise(exercise: Exercise) {
        console.log("Stub: Saving exercise", exercise);
    }
}

export class SaveSingleExerciseInteractorImpl implements SaveSingleExerciseInteractor {
    private storage: ExercisesStorage;
    private userManager: AppUserManager

    constructor(storage: ExercisesStorage) {
        this.userManager = CompositionRoot.getAppUserManager()
        this.storage = storage;
    }

    saveExercise(exercise: Exercise) {
        const userId = this.userManager.currentUser?.uid;
        exercise.authorId = userId;
        (async () => {
            try {
                await this.storage.saveExercise(exercise);
            } catch (error) {
                console.error("Failed to save single exercise:", error);
            }
        })();
    }
}