// ----------------- src/ui/screens/students/StudentsScreen.tsx -----------------
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import StudentsScreenViewModel from './StudentsScreenViewModel';
import StudentCard from '../StudentCard';
import ShareButton from "../../exercises_types/BaseComponents/ShareButton";
import {ButtonVariant} from "../../BaseButton";

/**
 * Экран со списком студентов.
 */
const StudentsScreen: React.FC = observer(() => {
    const [viewModel] = useState(() => new StudentsScreenViewModel());
    const navigate = useNavigate();

    useEffect(() => {
        viewModel.init();
        return () => {
            viewModel.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 1. Пока не знаем userId (ещё загружается) => показываем «Loading...»
    if (viewModel.loadingUser) {
        return (
            <div style={styles.containerCenter}>
                <h2>Loading...</h2>
            </div>
        );
    }

    // 2. Если есть ошибка => показываем её
    if (viewModel.error) {
        return (
            <div style={styles.containerCenter}>
                <h2 style={{ color: 'red' }}>{viewModel.error}</h2>
            </div>
        );
    }

    // 3. Если userId не определился => показываем крутилку (запрошено вместо текста)
    if (!viewModel.userId) {
        return (
            <div style={styles.containerCenter}>
                <h2>Loading...</h2>
            </div>
        );
    } else {
        console.log("user id: ", viewModel.userId)
    }

    // 4. Теперь у нас есть userId, и может быть ещё идёт загрузка студентов (viewModel.loading).
    //    Но мы хотим, чтобы ShareButton и описание механизма подтвердки
    //    появлялись только после того, как мы точно знаем, сколько у нас студентов.
    //    => Проверим, если ещё идёт загрузка (viewModel.loading), пока выводим Spinner
    if (viewModel.loading) {
        return (
            <div style={styles.containerCenter}>
                <h2>Loading students...</h2>
            </div>
        );
    }

    // 5. Когда всё загружено:
    const hasStudents = viewModel.students.length > 0;

    return (
        <div style={{ position: 'relative', minHeight: '100vh', padding: '20px' }}>
            {hasStudents ? (
                // Если есть студенты — описание и кнопка Sharе в правом верхнем углу
                <div style={styles.shareTopRight}>
                    <p style={styles.shareText}>
                        To add a new student, share this link:
                    </p>
                    <ShareButton link={`${window.location.origin}/confirmTutor/${viewModel.userId}`} />
                </div>
            ) : (
                // Если нет студентов — описание и кнопка Share по центру
                <div style={styles.containerCenter}>
                    <p style={styles.shareTextBig}>
                        To add a new student, share this link. The student will open the link, confirm, and appear in your students list.
                    </p>
                    <ShareButton
                        link={`${window.location.origin}/confirmTutor/${viewModel.userId}`}
                        variant={ButtonVariant.Big}
                    />
                </div>
            )}

            <h1>Students</h1>
            {viewModel.students.map((data) => (
                <StudentCard
                    key={data.student.id}
                    data={data}
                    onClick={() => navigate(`/student/${data.student.id}`)}
                />
            ))}
        </div>
    );
});

export default StudentsScreen;

/**
 * Простейшие стили
 */
const styles: Record<string, React.CSSProperties> = {
    containerCenter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        height: '100vh',
        textAlign: 'center',
    },
    shareTopRight: {
        position: 'absolute',
        top: 20,
        right: 20,
        width: 220,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        textAlign: 'left',
    },
    shareText: {
        fontSize: '14px',
        margin: 0,
    },
};