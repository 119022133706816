import React, { createContext, useContext, useState } from "react";

interface SizeContextProps {
    gridSizeWidth: number;
    setGridSizeWidth: React.Dispatch<React.SetStateAction<number>>;

    wordsListViewHeight: number;
    setWordsListViewHeight: React.Dispatch<React.SetStateAction<number>>;

    selectedWordViewHeight: number;
    setSelectedWordViewHeight: React.Dispatch<React.SetStateAction<number>>;

    gameViewsSpacing: number;

    // Computed property from Swift: selectedWordViewOffset
    selectedWordViewOffset: number;
}

const defaultSizeContext: SizeContextProps = {
    gridSizeWidth: 0,
    setGridSizeWidth: () => {},
    wordsListViewHeight: 0,
    setWordsListViewHeight: () => {},
    selectedWordViewHeight: 0,
    setSelectedWordViewHeight: () => {},
    gameViewsSpacing: 16,
    selectedWordViewOffset: 0,
};

const SizeContext = createContext<SizeContextProps>(defaultSizeContext);

export const SizeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [gridSizeWidth, setGridSizeWidth] = useState(0);
    const [wordsListViewHeight, setWordsListViewHeight] = useState(0);
    const [selectedWordViewHeight, setSelectedWordViewHeight] = useState(0);
    const gameViewsSpacing = 16;

    const selectedWordViewOffset =
        wordsListViewHeight + gameViewsSpacing / 2 - selectedWordViewHeight / 2;

    return (
        <SizeContext.Provider
            value={{
                gridSizeWidth,
                setGridSizeWidth,
                wordsListViewHeight,
                setWordsListViewHeight,
                selectedWordViewHeight,
                setSelectedWordViewHeight,
                gameViewsSpacing,
                selectedWordViewOffset,
            }}
        >
            {children}
        </SizeContext.Provider>
    );
};

export function useSizeContext() {
    return useContext(SizeContext);
}