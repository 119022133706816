// ExerciseCrosswordMapping.ts
import {
    Exercise,
    PlacedWord,
    WordItem,
    Position
} from "../../../domain/models";
import { serializeWordItem, deserializeWordItem } from "./WordItemSerializer";
import {ExerciseCrossword} from "../../../ui/components/exercises_types/Crossword/Domain/ExerciseCrossword";

export function serializeCrossword(exercise: Exercise): any {
    if (exercise.type.kind !== "crossword") {
        throw new Error("serializeCrossword called with non-crossword exercise");
    }

    const cw = exercise.type.data as ExerciseCrossword;
    const dict = crosswordToDict(cw);
    dict["type"] = "crossword";
    dict["authorId"] = exercise.authorId;
    dict["description"] = exercise.description;
    return dict;
}

export function deserializeCrossword(data: any, id: string): Exercise | null {
    const cw = crosswordFromDict(data);
    if (!cw) return null;

    return {
        id,
        authorId: data.authorId,
        description: data.description,
        type: {
            kind: "crossword",
            data: cw
        }
    };
}

function crosswordToDict(cw: ExerciseCrossword): any {
    const placedWordsArray = cw.placedWords.map((pw: PlacedWord) => ({
        word: serializeWordItem(pw.word),
        horizontal: pw.horizontal,
        startRow: pw.startRow,
        startCol: pw.startCol,
        wordIndex: pw.wordIndex
    }));

    return {
        words: cw.words.map((w: WordItem) => serializeWordItem(w)),
        placedWords: placedWordsArray
    };
}

function uniquePositions(positions: Position[]): Position[] {
    const map = new Map<string, Position>();
    positions.forEach((pos) => {
        const key = `${pos.row},${pos.col}`;
        if (!map.has(key)) {
            map.set(key, pos);
        }
    });
    return Array.from(map.values());
}

function crosswordFromDict(data: any): ExerciseCrossword | null {
    const wordsData = data["words"];
    const placedWordsData = data["placedWords"];

    if (!Array.isArray(wordsData) || !Array.isArray(placedWordsData)) {
        return null;
    }

    const words: WordItem[] = wordsData
        .map((wd: any) => deserializeWordItem(wd))
        .filter((w: WordItem | null): w is WordItem => w !== null);

    const placedWords: PlacedWord[] = placedWordsData
        .map((pwObj: any) => {
            const w = deserializeWordItem(pwObj.word);
            if (!w) return null;
            return {
                word: w,
                horizontal: Boolean(pwObj.horizontal),
                startRow: pwObj.startRow,
                startCol: pwObj.startCol,
                wordIndex: pwObj.wordIndex
            } as PlacedWord;
        })
        .filter((p: PlacedWord | null): p is PlacedWord => p !== null);

    const allPositions: Position[] = [];
    placedWords.forEach((pw) => {
        const len = pw.word.word.length;
        for (let i = 0; i < len; i++) {
            const pos: Position = {
                row: pw.startRow + (pw.horizontal ? 0 : i),
                col: pw.startCol + (pw.horizontal ? i : 0)
            };
            allPositions.push(pos);
        }
    });

    if (allPositions.length === 0) {
        return null;
    }

    const minRow = Math.min(...allPositions.map((p) => p.row));
    const maxRow = Math.max(...allPositions.map((p) => p.row));
    const minCol = Math.min(...allPositions.map((p) => p.col));
    const maxCol = Math.max(...allPositions.map((p) => p.col));

    const rowsCount = maxRow - minRow + 1;
    const columnsCount = maxCol - minCol + 1;

    const grid: string[][] = Array.from({ length: rowsCount }, () =>
        Array.from({ length: columnsCount }, () => "")
    );

    const occupiedPositionsArray: Position[] = [];
    const startPositionsMap: { [key: string]: number } = {};

    placedWords.forEach((pw) => {
        const chars = Array.from(pw.word.word.toUpperCase());
        for (let i = 0; i < chars.length; i++) {
            const rr = pw.startRow + (pw.horizontal ? 0 : i);
            const cc = pw.startCol + (pw.horizontal ? i : 0);
            const localRow = rr - minRow;
            const localCol = cc - minCol;
            grid[localRow][localCol] = ""; // оставляем клетку пустой
            occupiedPositionsArray.push({ row: rr, col: cc });
        }
        const key = `${pw.startRow},${pw.startCol}`;
        startPositionsMap[key] = pw.wordIndex;
    });

    // Дедупликация позиций
    const uniqueOccPositions = uniquePositions(occupiedPositionsArray);

    return new ExerciseCrossword(
        words,
        grid,
        placedWords,
        new Set(uniqueOccPositions),
        startPositionsMap
    );
}