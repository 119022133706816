import React from "react";
import { SizeProvider } from "./SizeContext";
import { SelectionStateProvider } from "./SelectionStateContext";
import { WordSearchView } from "./WordSearchView";
import { Game } from "./Game";
import {observer} from "mobx-react-lite";

// Top-level component that sets up context providers
// (similar to SwiftUI's environment objects).
interface GameViewProps {
    game: Game;
}

export const GameView: React.FC<GameViewProps> = observer(({ game }) => {
    return (
        <SizeProvider>
            <div style={{ backgroundColor: "lightgreen", padding: "1rem" }}>
                <WordSearchView />
            </div>
        </SizeProvider>
    );
});