// ========= ./CrosswordState.ts ==========


import {Position, WordItem} from "../../../../../domain/models";
import {PlacedWord} from "./PlacedWord";
import {CGFloat} from "./ExerciseCrossword";

export class CrosswordState {
    public words: WordItem[];
    public placedWords: PlacedWord[];
    public occupiedPositions: Set<Position>;
    public startPositions: { [key: string]: number };
    public cellSize: CGFloat;
    public hintedWords: Set<WordItem>;
    public foundWords: Set<WordItem>;
    public grid: string[][];
    public isCrosswordSolved: boolean;
    public activeWord?: PlacedWord;

    constructor(
        words: WordItem[],
        placedWords: PlacedWord[],
        occupiedPositions: Set<Position>,
        startPositions: { [key: string]: number },
        cellSize: CGFloat,
        hintedWords: Set<WordItem>,
        foundWords: Set<WordItem>,
        grid: string[][],
        isCrosswordSolved: boolean,
        activeWord?: PlacedWord
    ) {
        this.words = words;
        this.placedWords = placedWords;
        this.occupiedPositions = occupiedPositions;
        this.startPositions = startPositions;
        this.cellSize = cellSize;
        this.hintedWords = hintedWords;
        this.foundWords = foundWords;
        this.grid = grid;
        this.isCrosswordSolved = isCrosswordSolved;
        this.activeWord = activeWord;
    }

    // Equality check
    public isEqual(other: CrosswordState): boolean {
        if (this.words.length !== other.words.length) return false;
        // For brevity, we'll just do reference checks or shallow checks for arrays/sets:
        // A full deep compare could be done similarly to Swift code.
        if (this.placedWords.length !== other.placedWords.length) return false;
        if (this.occupiedPositions.size !== other.occupiedPositions.size) return false;
        if (Object.keys(this.startPositions).length !== Object.keys(other.startPositions).length)
            return false;
        if (this.cellSize !== other.cellSize) return false;
        if (this.hintedWords.size !== other.hintedWords.size) return false;
        if (this.foundWords.size !== other.foundWords.size) return false;
        if (this.grid.length !== other.grid.length) return false;
        if (this.isCrosswordSolved !== other.isCrosswordSolved) return false;
        for (let i = 0; i < this.grid.length; i++) {
            if (this.grid[i].length !== other.grid[i].length) return false;
            for (let j = 0; j < this.grid[i].length; j++) {
                if (this.grid[i][j] !== other.grid[i][j]) return false;
            }
        }

        if ((this.activeWord && !other.activeWord) || (!this.activeWord && other.activeWord))
            return false;
        // ... More thorough checks needed for a perfect equality.

        return true;
    }

    public toString(): string {
        // Mimic Swift's CustomStringConvertible
        const minRow = Math.min(...Array.from(this.occupiedPositions).map((p) => p.row));
        const minCol = Math.min(...Array.from(this.occupiedPositions).map((p) => p.col));

        const result: string[] = [];
        for (let rIndex = 0; rIndex < this.grid.length; rIndex++) {
            for (let cIndex = 0; cIndex < this.grid[rIndex].length; cIndex++) {
                const letter = this.grid[rIndex][cIndex];
                if (letter.length === 0) continue;
                const globalRow = minRow + rIndex;
                const globalCol = minCol + cIndex;
                result.push(`(${globalRow},${globalCol},${letter})`);
            }
        }

        return result.join(', ');
    }
}