// EditSentenceView.tsx
import React from 'react';
import {ExerciseSentence} from "../../../../../domain/models/ExerciseCreate";

interface EditSentenceViewProps {
    sentence: ExerciseSentence;
    setSentence: (sentence: ExerciseSentence) => void;
    onDelete: () => void;
}

export const EditSentenceView: React.FC<EditSentenceViewProps> = ({
                                                                      sentence,
                                                                      setSentence,
                                                                      onDelete,
                                                                  }) => {
    const handleChange = (field: keyof ExerciseSentence, value: string) => {
        setSentence({ ...sentence, [field]: value });
    };

    return (
        <div style={{ maxWidth: '800px', border: '1px solid #ccc', padding: '8px', marginBottom: '8px' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '8px' }}>
                <div style={{ flexGrow: 1 }}>
                    <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <label style={{ whiteSpace: 'nowrap' }}>Sentence</label>
                        <input
                            type="text"
                            value={sentence.sentence}
                            onChange={(e) => handleChange('sentence', e.target.value)}
                            style={{ flexGrow: 1, padding: '4px' }}
                        />
                    </div>
                    <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <label style={{ whiteSpace: 'nowrap' }}>Target Word</label>
                        <input
                            type="text"
                            value={sentence.targetWord}
                            onChange={(e) => handleChange('targetWord', e.target.value)}
                            style={{
                                width: '150px', // Фиксированная ширина для Target Word
                                padding: '4px',
                            }}
                        />
                    </div>
                </div>
                <button onClick={onDelete} style={{ marginLeft: '8px', color: 'red' }}>
                    Delete
                </button>
            </div>
        </div>
    );

};
