// WordSearchTypePreview.tsx

import React from "react";
import { Resizer } from "../../../Resizer";
import { Exercise, WordItem } from "../../../../../domain/models";
import {WordSearchViewModel} from "../Practice/WordSearchViewModel";
import {PracticeWordSearchView} from "../Practice/PracticeWordSearchView";
import {Placer3} from "../Create/Placer3";

/**
 * Превью-компонент для WordSearch-упражнения.
 * Аналогично CrosswordTypePreview, создаёт моковые данные
 * и генерирует сетку (если не передана явно), а затем
 * отображает это в резайзере.
 */
export function WordSearchTypePreview() {
    // 1) Создаем несколько моковых слов (WordItem[])
    const mockWords: WordItem[] = createMockWords();

    // 2) Формируем объект Exercise с типом "wordSearch"
    //    Здесь можно передать grid: undefined, чтобы WordSearchViewModel
    //    выполнил автоматическую генерацию сетки при помощи Placer3.

    const gridSize = { rowsCount: 10, columnsCount: 10 };
    // Создаем генератор сетки Placer3
    const placer = new Placer3(gridSize.rowsCount, gridSize.columnsCount);
    // Для генерации сетки приводим все слова к верхнему регистру
    const upperWords = mockWords.map((item) => item.word.toUpperCase());
    const [grid, placedWords] = placer.generateWordGrid(upperWords);

    console.log("WordSearchTypePreview grid: ", grid)
    const exercise: Exercise = {
        id: crypto.randomUUID().toUpperCase(),
        type: {
            kind: "wordSearch",
            data: {
                words: mockWords,
                grid: grid,
            },
        },
    };

    // 3) Создаем вью-модель, используя Exercise
    const vm = new WordSearchViewModel(exercise);

    // 4) Рендерим в Resizer, указывая желаемый размер
    return (
        <Resizer targetSize={{ width: 300, height: 150 }}>
            <div style={{ width: '500px', height: '500px'}}>
                <PracticeWordSearchView vm={vm} />
            </div>
        </Resizer>
    );
}

// Простая функция, чтобы создать несколько тестовых слов
function createMockWords(): WordItem[] {
    return [
        {
            id: "1",
            word: "CAT",
            translation: "A small feline pet",
            example: "",
            repetitions: [],
            imageURL: ""
        },
        {
            id: "2",
            word: "DOG",
            translation: "A common domestic animal",
            example: "",
            repetitions: [],
            imageURL: ""
        },
        {
            id: "3",
            word: "APPLE",
            translation: "Popular fruit",
            example: "",
            repetitions: [],
            imageURL: ""
        },
        {
            id: "4",
            word: "BANANA",
            translation: "Tropical fruit",
            example: "",
            repetitions: [],
            imageURL: ""
        },
    ];
}