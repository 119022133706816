// ./ui/components/statistics/WordsByStatusView.tsx

import React, { useMemo } from 'react';
import WordsViewModel from '../../../ui/viewmodels/WordsViewModel';
import { WordItem } from '../../../domain/models/WordItem';
import { WordItemModel } from '../../../domain/models/WordItemModel';
import { useNavigate } from 'react-router-dom';
import { WordState } from "../../../domain/models";

interface WordsByStatusViewProps {
    wordsViewModel: WordsViewModel;
}

const WordsByStatusView: React.FC<WordsByStatusViewProps> = ({ wordsViewModel }) => {
    const navigate = useNavigate();

    const wordsByState = useMemo(() => {
        const grouped: { [key: string]: WordItem[] } = {};

        wordsViewModel.words.forEach(word => {
            const state = WordItemModel.learningMode(word);
            if (!grouped[state]) {
                grouped[state] = [];
            }
            grouped[state].push(word);
        });

        return grouped;
    }, [wordsViewModel.words]);

    const examWordsGroupedByAttempts = useMemo(() => {
        const examWords = wordsByState[WordState.Exam] || [];
        const grouped: { [key: number]: WordItem[] } = {};

        examWords.forEach(word => {
            const remaining = WordItemModel.remainingAttempts(word);
            if (!grouped[remaining]) {
                grouped[remaining] = [];
            }
            grouped[remaining].push(word);
        });

        return grouped;
    }, [wordsByState]);

    const wordItemView = (word: WordItem) => {
        const handleClick = () => {
            navigate(`/wordDetails/${word.id}`);
        };

        return (
            <div key={word.id} style={wordItemStyle} onClick={handleClick}>
                <div style={{ fontWeight: 'bold', color: '#FFFFFF' }}>{word.word}</div>
                <div style={{ color: '#DDDDDD', fontSize: '0.9em' }}>{word.translation}</div>
            </div>
        );
    };

    return (
        <div style={containerStyle}>
            <div style={{ display: 'flex', gap: '20px' }}>
                {/* Learn State */}
                <div style={stateContainerStyle}>
                    <h3 style={{ color: '#FFFFFF' }}>Learn</h3>
                    <div style={scrollContainerStyle}>
                        {wordsByState[WordState.Learn]?.map(word => wordItemView(word))}
                    </div>
                    <div style={{ fontSize: '0.8em', color: '#AAAAAA', marginTop: '5px' }}>
                        Total: {wordsByState[WordState.Learn]?.length || 0}
                    </div>
                </div>

                {/* Exam State */}
                {Object.keys(examWordsGroupedByAttempts)
                    .sort((a, b) => Number(b) - Number(a))
                    .map(attemptsLeft => (
                        <div key={attemptsLeft} style={stateContainerStyle}>
                            <h3 style={{ color: '#FFFFFF' }}>Exam ({attemptsLeft} Attempts Left)</h3>
                            <div style={scrollContainerStyle}>
                                {examWordsGroupedByAttempts[Number(attemptsLeft)].map(word => wordItemView(word))}
                            </div>
                            <div style={{ fontSize: '0.8em', color: '#AAAAAA', marginTop: '5px' }}>
                                Total: {examWordsGroupedByAttempts[Number(attemptsLeft)].length}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

// Обновленный стиль контейнера
const containerStyle: React.CSSProperties = {
    padding: '20px',
    backgroundColor: '#333333', // Темный фон для всего компонента
    borderRadius: '10px',
    marginBottom: '20px',
};

// Обновленный стиль контейнера состояния
const stateContainerStyle: React.CSSProperties = {
    flex: 1,
    padding: '10px',
    backgroundColor: '#444444', // Темный фон для состояния
    borderRadius: '10px',
    overflowY: 'auto',
    maxHeight: '400px',
};

// Стиль для прокручиваемой области
const scrollContainerStyle: React.CSSProperties = {
    overflowY: 'auto',
};

// Обновленный стиль элемента слова
const wordItemStyle: React.CSSProperties = {
    padding: '5px',
    backgroundColor: '#555555', // Еще более темный фон для элемента слова
    borderRadius: '8px',
    cursor: 'pointer',
    marginBottom: '5px',
};

export default WordsByStatusView;
