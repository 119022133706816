// ExerciseCreationFlowViewModel.ts
import { makeAutoObservable } from "mobx";
import {CreateExerciseFlowState, CreateExerciseFlowStep} from "./CreateExerciseFlowStep";
import {ExerciseCreationType} from "../../Models/ExerciseCreationType";

export class ExerciseCreationFlowViewModel {
    state: CreateExerciseFlowState = { step: CreateExerciseFlowStep.selectType };

    constructor() {
        makeAutoObservable(this);
    }

    selectType(type: ExerciseCreationType) {
        this.state = {
            step: CreateExerciseFlowStep.settings,
            selectedExerciseType: type,
        };
    }

    backToSelectType() {
        this.state = { step: CreateExerciseFlowStep.selectType };
    }

}