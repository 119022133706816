// src/components/StartScreenMacOS.tsx
import React from 'react';
import MainContent from '../navigation/MainContent';
import { AuthProvider } from '../../ui/context/AuthContext';
import {useTheme} from "../../ui/context/ThemeContext"; // Mock context

const StartScreenMacOS: React.FC = () => {
    return (
        <AuthProvider>
            <div style={{ display: 'flex', height: '100vh', minWidth: '800px', minHeight: '700px' }}>
                <main style={{ width: '100%'}}>
                    <MainContent />
                </main>
            </div>
        </AuthProvider>
    );
};

export default StartScreenMacOS;
