// ========= ./Base/CrosswordBuilderProtocol.ts ==========

import { PlacedWord } from './PlacedWord';
import {Position, WordItem} from "../../../../../domain/models";

export interface CrosswordBuilderProtocol {
    rows: number;
    cols: number;
    cellSize: number;

    buildCrossword(words: WordItem[]): CrosswordResult;
}

export type MapKeyPositionNumber = { [key: string]: number };

export interface CrosswordResult {
    grid: string[][];
    placedWords: PlacedWord[];
    occupiedPositions: Set<Position>;
    startPositions: MapKeyPositionNumber; // TS-friendly representation of `[Position: Int]`
}


export class DefaultCrosswordBuilder implements CrosswordBuilderProtocol {
    public readonly rows = 50;
    public readonly cols = 50;
    public readonly cellSize = 30;

    private grid: string[][] = [];
    private placedWords: PlacedWord[] = [];
    private occupiedPositions: Set<Position> = new Set();
    private startPositions: { [key: string]: number } = {};
    private words: WordItem[] = [];

    constructor() {
        // Empty constructor if needed
    }

    buildCrossword(words: WordItem[]): CrosswordResult {
        this.words = words;
        // Create a grid filled with spaces
        this.grid = Array.from({ length: this.rows }, () =>
            Array.from({ length: this.cols }, () => ' ')
        );
        this.placedWords = [];
        this.occupiedPositions = new Set();
        this.startPositions = {};

        this.placeAllWords();

        // Replace all used positions in the grid with empty string (like Swift code)
        this.occupiedPositions.forEach((pos) => {
            this.grid[pos.row][pos.col] = '';
        });

        return {
            grid: this.grid,
            placedWords: this.placedWords,
            occupiedPositions: this.occupiedPositions,
            startPositions: this.startPositions,
        };
    }

    private placeAllWords(): void {
        if (this.words.length === 0) return;

        const first = this.words[0];
        const centerRow = Math.floor(this.rows / 2);
        const centerCol = Math.floor(this.cols / 2);
        const startCol = centerCol - Math.floor(first.word.length / 2);

        // Place first word horizontally, wordIndex = 1
        this.placeWord(first, [centerRow, startCol], true, 1);

        // Place subsequent words
        this.words.slice(1).forEach((w, i) => {
            const wordIndex = i + 2;
            if (!this.tryPlaceWithIntersection(w, wordIndex)) {
                const fallbackPos: [number, number] = [centerRow + 10, centerCol];
                this.placeIfPossibleWithoutIntersection(w, fallbackPos, true, wordIndex);
            }
        });
    }

    private placeIfPossibleWithoutIntersection(
        w: WordItem,
        pos: [number, number],
        horizontal: boolean,
        wordIndex: number
    ): boolean {
        const chars = Array.from(w.word);
        const [r, c] = pos;
        const length = chars.length;

        if (horizontal) {
            if (c < 0 || c + length > this.cols) return false;
            for (let x = 0; x < length; x++) {
                const rr = r;
                const cc = c + x;
                if (!this.canPlaceLetter(chars[x], [rr, cc])) {
                    return false;
                }
            }
            if (!this.checkNoParallelConflicts(true, r, c, length)) {
                return false;
            }

            // Place letters
            for (let x = 0; x < length; x++) {
                const rr = r;
                const cc = c + x;
                this.grid[rr][cc] = chars[x].toUpperCase();
                this.occupiedPositions.add({ row: rr, col: cc });
            }
            this.placedWords.push({
                word: w,
                horizontal: true,
                startRow: r,
                startCol: c,
                wordIndex,
            });
            this.startPositions[`${r},${c}`] = wordIndex;
            return true;
        } else {
            const startR = r;
            if (startR < 0 || startR + length > this.rows) return false;
            for (let x = 0; x < length; x++) {
                const rr = startR + x;
                const cc = c;
                if (!this.canPlaceLetter(chars[x], [rr, cc])) {
                    return false;
                }
            }
            if (!this.checkNoParallelConflicts(false, startR, c, length)) {
                return false;
            }

            for (let x = 0; x < length; x++) {
                const rr = startR + x;
                const cc = c;
                this.grid[rr][cc] = chars[x].toUpperCase();
                this.occupiedPositions.add({ row: rr, col: cc });
            }
            this.placedWords.push({
                word: w,
                horizontal: false,
                startRow: startR,
                startCol: c,
                wordIndex,
            });
            this.startPositions[`${startR},${c}`] = wordIndex;
            return true;
        }
    }

    private tryPlaceWithIntersection(w: WordItem, wordIndex: number): boolean {
        const newChars = Array.from(w.word);
        for (const pw of this.placedWords) {
            const placedChars = Array.from(pw.word.word);
            for (let i = 0; i < newChars.length; i++) {
                const newChar = newChars[i];
                const j = placedChars.indexOf(newChar);
                if (j !== -1) {
                    const [r, c] = this.coordForLetter(pw, j);
                    const horizontal = !pw.horizontal;
                    if (this.placeIfPossible(w, [r, c], i, horizontal, wordIndex)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private coordForLetter(pw: PlacedWord, letterIndex: number): [number, number] {
        if (pw.horizontal) {
            return [pw.startRow, pw.startCol + letterIndex];
        } else {
            return [pw.startRow + letterIndex, pw.startCol];
        }
    }

    private placeIfPossible(
        w: WordItem,
        intersectPos: [number, number],
        intersectIndex: number,
        horizontal: boolean,
        wordIndex: number
    ): boolean {
        const chars = Array.from(w.word);
        const length = chars.length;
        const [r, c] = intersectPos;

        if (horizontal) {
            const startC = c - intersectIndex;
            if (startC < 0 || startC + length > this.cols) return false;
            const row = r;
            for (let x = 0; x < length; x++) {
                const rr = row;
                const cc = startC + x;
                if (!this.canPlaceLetter(chars[x], [rr, cc])) {
                    return false;
                }
            }
            if (!this.checkNoParallelConflicts(true, row, startC, length)) {
                return false;
            }

            // Place
            for (let x = 0; x < length; x++) {
                const rr = row;
                const cc = startC + x;
                this.grid[rr][cc] = chars[x].toUpperCase();
                this.occupiedPositions.add({ row: rr, col: cc });
            }
            this.placedWords.push({
                word: w,
                horizontal: true,
                startRow: row,
                startCol: startC,
                wordIndex,
            });
            this.startPositions[`${row},${startC}`] = wordIndex;
            return true;
        } else {
            const startR = r - intersectIndex;
            if (startR < 0 || startR + length > this.rows) return false;
            const col = c;
            for (let x = 0; x < length; x++) {
                const rr = startR + x;
                const cc = col;
                if (!this.canPlaceLetter(chars[x], [rr, cc])) {
                    return false;
                }
            }
            if (!this.checkNoParallelConflicts(false, startR, col, length)) {
                return false;
            }

            for (let x = 0; x < length; x++) {
                const rr = startR + x;
                const cc = col;
                this.grid[rr][cc] = chars[x].toUpperCase();
                this.occupiedPositions.add({ row: rr, col: cc });
            }
            this.placedWords.push({
                word: w,
                horizontal: false,
                startRow: startR,
                startCol: col,
                wordIndex,
            });
            this.startPositions[`${startR},${col}`] = wordIndex;
            return true;
        }
    }

    private placeWord(
        w: WordItem,
        pos: [number, number],
        horizontal: boolean,
        wordIndex: number
    ): void {
        const chars = Array.from(w.word);
        const [r, c] = pos;

        for (let x = 0; x < chars.length; x++) {
            const rr = horizontal ? r : r + x;
            const cc = horizontal ? c + x : c;
            this.grid[rr][cc] = chars[x].toUpperCase();
            this.occupiedPositions.add({ row: rr, col: cc });
        }
        this.placedWords.push({
            word: w,
            horizontal,
            startRow: r,
            startCol: c,
            wordIndex,
        });
        this.startPositions[`${r},${c}`] = wordIndex;
    }

    private canPlaceLetter(ch: string, pos: [number, number]): boolean {
        const [rr, cc] = pos;
        if (rr < 0 || rr >= this.rows || cc < 0 || cc >= this.cols) return false;
        const cellVal = this.grid[rr][cc];
        if (cellVal === ' ' || cellVal === '' || cellVal.toUpperCase() === ch.toUpperCase()) {
            return true;
        }
        return false;
    }

    private checkNoParallelConflicts(
        horizontal: boolean,
        startRow: number,
        startCol: number,
        length: number
    ): boolean {
        if (horizontal) {
            const topRow = startRow - 1;
            const bottomRow = startRow + 1;
            const colRange = { start: startCol, end: startCol + length };
            if (topRow >= 0) {
                if (this.hasHorizontalWord(topRow, colRange.start, colRange.end)) {
                    return false;
                }
            }
            if (bottomRow < this.rows) {
                if (this.hasHorizontalWord(bottomRow, colRange.start, colRange.end)) {
                    return false;
                }
            }
        } else {
            const leftCol = startCol - 1;
            const rightCol = startCol + 1;
            const rowRange = { start: startRow, end: startRow + length };
            if (leftCol >= 0) {
                if (this.hasVerticalWord(leftCol, rowRange.start, rowRange.end)) {
                    return false;
                }
            }
            if (rightCol < this.cols) {
                if (this.hasVerticalWord(rightCol, rowRange.start, rowRange.end)) {
                    return false;
                }
            }
        }
        return true;
    }

    private hasHorizontalWord(row: number, startCol: number, endCol: number): boolean {
        for (const pw of this.placedWords) {
            if (pw.horizontal && pw.startRow === row) {
                const pwStart = pw.startCol;
                const pwEnd = pw.startCol + pw.word.word.length;
                // Check overlap
                if (!(pwEnd <= startCol || pwStart >= endCol)) {
                    return true;
                }
            }
        }
        return false;
    }

    private hasVerticalWord(col: number, startRow: number, endRow: number): boolean {
        for (const pw of this.placedWords) {
            if (!pw.horizontal && pw.startCol === col) {
                const pwStart = pw.startRow;
                const pwEnd = pw.startRow + pw.word.word.length;
                if (!(pwEnd <= startRow || pwStart >= endRow)) {
                    return true;
                }
            }
        }
        return false;
    }
}