// ========== MissingWord/Previews/MissingWordViewable.tsx ==========

import { ExerciseViewable } from '../../ExerciseViewable';
import React from 'react';
import { MissingWordViewBase } from '../Base/MissingWordViewBase';
import {Exercise, ExerciseMissingWord} from "../../../../../domain/models";
import {Resizer} from "../../../Resizer";

export class MissingWordViewable implements ExerciseViewable {
    private exercise: ExerciseMissingWord;

    constructor(exercise: ExerciseMissingWord) {
        this.exercise = exercise;
    }

    renderView(exercise: Exercise, width?: number, height?: number): JSX.Element {
        if (exercise.type.kind !== "missingWord") {
            throw new Error("Unsupported exercise type for ExerciseSelectOptionViewable");
        }

        const targetWidth = width ?? 300;
        const targetHeight = height ?? 150;

        return (
            <Resizer targetSize={{ width: targetWidth, height: targetHeight }}>
                <div style={{ width: '800px', height: '500px', alignContent:'center'}}>
                    <MissingWordViewBase
                        sentence={exercise.type.data.sentence}
                        inputText=""
                        isCorrect={null}
                        correctAnswer={exercise.type.data.correctForm}
                        onInputChange={() => {}}
                        onEnterPressed={() => {}}
                        onCheckPressed={() => {}}
                    />
                </div>
            </Resizer>
        );
    }
}

