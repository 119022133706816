
export enum Direction {
    horizontal = "horizontal",
    vertical = "vertical",
    diagonal = "diagonal",
    reverseHorizontal = "reverseHorizontal",
    reverseVertical = "reverseVertical",
    reverseDiagonal = "reverseDiagonal",
}

// Массив всех направлений (аналог allCases)
export const allDirections: Direction[] = [
    Direction.horizontal,
    Direction.vertical,
    Direction.diagonal,
    Direction.reverseHorizontal,
    Direction.reverseVertical,
    Direction.reverseDiagonal,
];

export interface Vector {
    rowDelta: number;
    colDelta: number;
}

// Функция для получения вектора по направлению
export function getVector(direction: Direction): Vector {
    switch (direction) {
        case Direction.horizontal:
            return { rowDelta: 0, colDelta: 1 };
        case Direction.vertical:
            return { rowDelta: 1, colDelta: 0 };
        case Direction.diagonal:
            return { rowDelta: 1, colDelta: 1 };
        case Direction.reverseHorizontal:
            return { rowDelta: 0, colDelta: -1 };
        case Direction.reverseVertical:
            return { rowDelta: -1, colDelta: 0 };
        case Direction.reverseDiagonal:
            return { rowDelta: -1, colDelta: -1 };
    }
}


export type Grid = string[][];

export interface GridGenerating {
    generateWordGrid(words: string[]): [Grid, string[]];
}