// src/onboarding/OnboardingFlowRoot.tsx
import React, {useEffect, useMemo} from 'react';
import { observer } from 'mobx-react-lite';
import { OnboardingFlowManager } from './OnboardingFlowManager';
import {RoleSelectionInteractor} from "./UserTypeSelection/RoleSelectionInteractor";
import {RoleSelectionViewModel} from "./UserTypeSelection/RoleSelectionViewModel";
import {LanguageSelectionInteractor} from "./UserLanguageSelection/LanguageSelectionInteractor";
import {LanguageSelectionViewModel} from "./UserLanguageSelection/LanguageSelectionViewModel";
import {RoleSelectionScreen} from "./UserTypeSelection/RoleSelectionScreen";
import {LanguageSelectionScreen} from "./UserLanguageSelection/LanguageSelectionScreen";
import {AuthInteractor} from "./Auth/AuthInteractor";
import {AuthViewModel} from "./Auth/AuthViewModel";
import {AuthScreen} from "./Auth/AuthScreen";
import {ProfileInteractor} from "./Profile/ProfileInteractor";
import {ProfileViewModel} from "./Profile/ProfileViewModel";
import {ProfileScreen} from "./Profile/ProfileScreen";
import CompositionRoot from "../../../compositionRoot";
import {useNavigate} from "react-router-dom";

export const OnboardingFlowRoot: React.FC = observer(() => {
    // 1) Берём userManager из CompositionRoot
    const navigate = useNavigate();

    // 2) Создаём FlowManager
    // const flowManager = useMemo(() => new OnboardingFlowManager(userManager), [userManager]);
    const flowManager: OnboardingFlowManager = CompositionRoot.onboardingFlowManager;

    // При размонтировании отписываемся
    // useEffect(() => {
    //     return () => {
    //         console.log("OnboardingFlowRoot flowManager.dispose")
    //         flowManager.dispose();
    //     };
    // }, [flowManager]);

    // Смотрим, какой шаг
    const step = flowManager.currentStep;
    console.log("OnboardingFlowRoot, step: ", step)

    useEffect(() => {
        if (step === 'done') {
            // Редирект на основной контент приложения
            navigate('/');
        }
    }, [step, navigate]);


    switch (step) {
        case 'chooseRole': {
            return <RoleSelectionScreen />;
        }
        case 'chooseLanguages': {
            return <LanguageSelectionScreen />;
        }
        case 'auth': {
            return <AuthScreen />;
        }
        case 'profile': {
            return <ProfileScreen />;
        }
        case 'done': {
            return <div style={styles.centered}>Онбординг завершён!</div>;
        }
        default: {
            return <div style={styles.centered}>Неизвестный шаг онбординга...</div>;
        }
    }
});

const styles = {
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    }
};