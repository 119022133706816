// ui/EditableDrawing.tsx
import React, { useRef, useEffect, useState } from 'react';
import {CanvasItem, DrawingCanvasItem} from '../CanvasItem';
import { toJS } from "mobx";

interface EditableDrawingProps {
    item: DrawingCanvasItem;
    isActive: boolean;
    onUpdate: (data: Partial<CanvasItem>) => void;
}

const EditableDrawing: React.FC<EditableDrawingProps> = ({ item, isActive, onUpdate }) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [paths, setPaths] = useState(item.paths || []);

    useEffect(() => {
        setPaths(toJS(item.paths) || []);
    }, [item.paths]);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        // Clear
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Re-draw every stroke in paths
        paths.forEach((stroke) => {
            if (stroke.length > 0) {
                ctx.beginPath();
                ctx.moveTo(stroke[0].x, stroke[0].y);
                for (let i = 1; i < stroke.length; i++) {
                    ctx.lineTo(stroke[i].x, stroke[i].y);
                }
                ctx.strokeStyle = 'black';
                ctx.lineWidth = 2;
                ctx.stroke();
            }
        });
    }, [paths, item.width, item.height]);

    // On pointer down, start a new stroke
    const handlePointerDown = (e: React.PointerEvent) => {
        if (!isActive || !item.canDraw) return;

        setIsDrawing(true);
        const rect = (e.target as HTMLCanvasElement).getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        // Start a new stroke
        setPaths((prev) => [...prev, [{ x, y }]]);
    };

    // On pointer move, add points to the current stroke
    const handlePointerMove = (e: React.PointerEvent) => {
        if (!isActive || !isDrawing) return;
        const rect = (e.target as HTMLCanvasElement).getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        setPaths((prev) => {
            const newPaths = [...prev];
            // Add point to the last stroke
            const lastStroke = newPaths[newPaths.length - 1];
            lastStroke.push({ x, y });
            return newPaths;
        });
    };

    // On pointer up, we finalize the stroke and call onUpdate
    const handlePointerUp = () => {
        if (!isActive) return;
        setIsDrawing(false);
        // Persist the new paths in the item
        onUpdate({ paths });
    };

    // We must update canvas width/height so drawing scales correctly
    // (These come from item.width/height, from the Rnd wrapper).
    const canvasWidth = Math.max(1, item.width);
    const canvasHeight = Math.max(1, item.height);

    const canvasCursor = (!isActive || !item.canDraw) ? 'move' : 'crosshair';

    return (
        <canvas
            ref={canvasRef}
            width={canvasWidth}
            height={canvasHeight}
            style={{
                width: '100%',
                height: '100%',
                display: 'block',
                cursor: canvasCursor
            }}
            onPointerDown={handlePointerDown}
            onPointerMove={handlePointerMove}
            onPointerUp={handlePointerUp}
            onPointerLeave={() => isDrawing && handlePointerUp()} // in case pointer leaves the canvas
        />
    );
};

export default EditableDrawing;