import React, { CSSProperties, useState } from "react";
import { observer } from "mobx-react-lite";
import { Exercise } from "../../../../../domain/models";
import { AllExercisesViewModel } from "./AllExercisesViewModel";
import { LoadExercisesInteractorImpl } from "../../../../../domain/AllExercises/LoadExercisesInteractor";
import { SaveSingleExerciseInteractorImpl } from "../../../../../domain/AllExercises/SaveSingleExerciseInteractor";
import { CreateSimilarInteractorMock } from "../../../../../domain/AllExercises/CreateSimilarInteractor";
import CompositionRoot from "../../../../../compositionRoot";
import { SimilarTableUseCase } from "../../../../../domain/Exercises/Table/SimilarTableUseCase";
import { ExercisesFilterInteractorImpl } from "../../Logic/ExercisesFilterInteractor";
import { CreateExerciseModal } from "./CreateExerciseModal";
import { ExercisePreviewModal } from "./ExercisePreviewModal";
import { EditExerciseModal } from "./EditExerciseModal";
import { FloatingPlusButton } from "../../Components/FloatingPlusButton";
import {FilteredExercisesGridView} from "../../Components/FilteredExercisesGridView/FilteredExercisesGridView";

export const AllExercisesView = observer(() => {
    const storage = CompositionRoot.exercisesStorage;
    const tableRequester = CompositionRoot.tableRequester;

    const [viewModel] = useState(() => new AllExercisesViewModel(
        new LoadExercisesInteractorImpl(storage),
        new SaveSingleExerciseInteractorImpl(storage),
        new CreateSimilarInteractorMock(new SimilarTableUseCase(tableRequester)),
        new ExercisesFilterInteractorImpl()
    ));

    const [creatingExercise, setCreatingExercise] = useState(false);

    return (
        <div style={containerStyle}>
            {/* Общий компонент со списком упражнений и фильтром */}
            <FilteredExercisesGridView
                viewModel={viewModel.gridViewModel}
                onSelect={(exercise) => (viewModel.previewingExercise = exercise)}
                onContextMenu={(exercise, e) => {
                    e.preventDefault();
                    viewModel.generateSimilarExercise(exercise);
                }}
            />

            {/* "Plus" button */}
            <FloatingPlusButton onClick={() => setCreatingExercise(true)} />

            {/* Sheet (modal) for creating exercise */}
            {creatingExercise && (
                <CreateExerciseModal
                    onClose={() => setCreatingExercise(false)}
                    onSelect={(newEx: Exercise) => {
                        viewModel.addExercise(newEx);
                        setCreatingExercise(false);
                    }}
                />
            )}

            {/* Sheet for previewing */}
            {viewModel.previewingExercise && (
                <ExercisePreviewModal
                    exercise={viewModel.previewingExercise}
                    onEdit={(ex) => {
                        viewModel.editExercise(ex);
                    }}
                    onRemove={(ex) => {
                        viewModel.removeExercise(ex);
                        viewModel.previewingExercise = null;
                    }}
                    onCreateSimilar={(ex) => {
                        viewModel.generateSimilarExercise(ex);
                    }}
                    onClose={() => {
                        viewModel.previewingExercise = null;
                    }}
                />
            )}

            {/* Sheet for editing */}
            {viewModel.editingExercise && (
                <EditExerciseModal
                    exercise={viewModel.editingExercise}
                    onClose={() => {
                        viewModel.editingExercise = null;
                    }}
                    onSave={(updatedExercise) => {
                        viewModel.saveEditedExercise(updatedExercise);
                        viewModel.editingExercise = null;
                    }}
                />
            )}
        </div>
    );
});

const containerStyle: CSSProperties = {
    position: "relative",
    minHeight: "100vh",
};