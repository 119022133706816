// src/onboarding/ProfileViewModel.ts
import { makeAutoObservable } from 'mobx';
import { ProfileInteractor, ProfileData } from './ProfileInteractor';

export class ProfileViewModel {
    name = '';
    avatar = '';
    about = '';

    isLoading = false;
    error: string | null = null;

    constructor(private interactor: ProfileInteractor) {
        makeAutoObservable(this);
    }

    async saveProfile() {
        this.isLoading = true;
        this.error = null;
        try {
            const data: ProfileData = {
                name: this.name,
                avatar: this.avatar,
                about: this.about
            };
            await this.interactor.saveProfile(data);
        } catch (e: any) {
            this.error = e.message;
        } finally {
            this.isLoading = false;
        }
    }

    async skip() {
        this.isLoading = true;
        this.error = null;
        try {
            await this.interactor.skipProfile();
        } catch (e: any) {
            this.error = e.message;
        } finally {
            this.isLoading = false;
        }
    }
}