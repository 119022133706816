// StartScreen.tsx
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {ClassButton} from "./StartScreenComponents/ClassButton";
import CompositionRoot from "../../compositionRoot";
import {RoleSelection} from "./StartScreenComponents/RoleSelection";
import {TopPanel} from "./StartScreenComponents/TopPanel";
import {StudentActions} from "./StartScreenComponents/StudentActions";
import {TeacherActions} from "./StartScreenComponents/TeacherActions";


const StartScreen: React.FC = observer(() => {
    const navigate = useNavigate();
    const viewModel = CompositionRoot.getStartScreenViewModel();

    if (!viewModel.user) {
        return <div>Loading...</div>;
    }

    const handleSetRole = async (role: 'teacher' | 'student') => {
        await viewModel.setUserRole(role);
    };

    if (!viewModel.user.role) {
        return <RoleSelection onSelectRole={handleSetRole} />;
    }

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <TopPanel navigate={navigate} viewModel={viewModel} onRoleChange={handleSetRole} />

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    gap: '100px',
                }}
            >
                {viewModel.user.role === 'student' && <StudentActions navigate={navigate} />}
                {viewModel.user.role === 'teacher' && <TeacherActions navigate={navigate} />}
                {viewModel.user.currentClass && (
                    <ClassButton navigate={navigate} currentClass={viewModel.user.currentClass} />
                )}
            </div>
        </div>
    );
});


export default StartScreen;