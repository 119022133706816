import {collection, doc, getDocs, getFirestore, query, serverTimestamp, setDoc, where,} from "firebase/firestore";
import {SessionInfo} from "../Models/SessionInfo";

export interface SessionInfoRepository {
    createOrGetSession(lessonId: string, studentId: string): Promise<string>;
    getSessionsByLesson(lessonId: string): Promise<SessionInfo[]>
}

export class FirestoreSessionInfoRepository implements SessionInfoRepository {
    private db = getFirestore();

    async createOrGetSession(lessonId: string, studentId: string): Promise<string> {
        const docId = `${lessonId}_${studentId}`.toUpperCase(); // или без toUpperCase
        const ref = doc(this.db, 'sessionsInfo', docId);

        await setDoc(ref, {
            sessionId: docId,
            lessonId,
            studentId,
            createdAt: serverTimestamp()
        }, { merge: true });

        return docId;
    }

    public async getSessionsByLesson(lessonId: string): Promise<SessionInfo[]> {
        const ref = collection(this.db, "sessionsInfo");
        const q = query(ref, where("lessonId", "==", lessonId));
        const snapshot = await getDocs(q);

        const result: SessionInfo[] = [];
        snapshot.forEach(docSnap => {
            const data = docSnap.data();
            result.push({
                sessionId: docSnap.id,
                lessonId: data.lessonId,
                studentId: data.studentId,
                createdAt: data.createdAt
            });
        });
        return result;
    }
}