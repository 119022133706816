import React from "react";
import { Exercise } from "../../../../domain/models";
import {createViewable} from "../../exercises_types/exerciseViewableFactory";
import {ExerciseTextPreview} from "../../exercises_types/ExerciseTextPreview";

interface Props {
    exercise: Exercise;
    width?: number;
    height?: number;
}

export const ExerciseRowView = ({ exercise, width, height }: Props) => {
    const viewable = createViewable(exercise);

    return (
        <div style={{ display: 'flex', gap: '16px', alignItems: 'flex-start' }}>
            <div>{viewable.renderView(exercise, width, height)}</div>

            <div>
                <ExerciseTextPreview exercise={exercise} />
            </div>
        </div>
    );
};