// ui/components/exercises_types/FillBlanks/Create/CreateFillBlanksView.tsx

import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Exercise } from "../../../../../domain/models";
import CompositionRoot from "../../../../../compositionRoot";
import { FillBlanksCreationViewModel } from "./FillBlanksCreationViewModel";
import BaseButton from "../../../BaseButton";
import "./CreateFillBlanksScreen.css";

interface CreateFillBlanksViewProps {
    onExercisesCreated: (exercises: Exercise[]) => void;
}

const CreateFillBlanksView: React.FC<CreateFillBlanksViewProps> = observer(
    ({ onExercisesCreated }) => {
        // Create the VM only once
        const [vm] = useState(() => {
            return new FillBlanksCreationViewModel(
                CompositionRoot.fillBlanksCreationInteractor
            );
        });

        const textareaRef = useRef<HTMLTextAreaElement>(null);

        useEffect(() => {
            adjustHeight();
        }, [vm.text]);

        const adjustHeight = () => {
            if (textareaRef.current) {
                textareaRef.current.style.height = "auto";
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
            }
        };

        /**
         * Generate text from raw words
         */
        const handleGenerate = async () => {
            await vm.generateText();
        };

        /**
         * Final confirm => produce new Exercise => pass up
         */
        const handleConfirm = async () => {
            const newExercise = await vm.confirmCreation();
            if (newExercise) {
                onExercisesCreated([newExercise]);
                // Possibly close or navigate away
            }
        };

        return (
            <div className="container">
                {/* 1) Input words block */}
                <div className="block">
                    <label className="label">Paste words:</label>
                    <textarea
                        className="textarea"
                        value={vm.words}
                        onChange={(e) => (vm.words = e.target.value)}
                    />

                    <div className="requester-selection">
                        <label className="label">Select model:</label>
                        <select
                            value={vm.selectedModel}
                            onChange={(e) =>
                                (vm.selectedModel = e.target.value as "openai" | "gemini")
                            }
                        >
                            <option value="openai">OpenAI</option>
                            <option value="gemini">Gemini</option>
                        </select>
                    </div>

                    {vm.isLoading ? (
                        <p className="loading">Loading...</p>
                    ) : (
                        <BaseButton color="#007bff" onClick={handleGenerate}>
                            Generate dialogue
                        </BaseButton>
                    )}
                </div>

                {/* 2) Generated text block */}
                {vm.text && (
                    <div className="block">
                        <h3 className="label">Generated text:</h3>
                        <textarea
                            ref={textareaRef}
                            className="textarea2"
                            value={vm.text}
                            onChange={(e) => (vm.text = e.target.value)}
                        />
                    </div>
                )}

                {/* 3) Confirm block - no separate "Exercise name" */}
                {vm.text && (
                    <div className="block" style={{ marginTop: "20px" }}>
                        {vm.isLoading ? (
                            <p className="loading">Loading...</p>
                        ) : (
                            <BaseButton
                                color="#007bff"
                                onClick={handleConfirm}
                            >
                                Confirm
                            </BaseButton>
                        )}
                    </div>
                )}
            </div>
        );
    }
);

export default CreateFillBlanksView;