import React, { useState } from "react";
import { Exercise, WordItem } from "../../../../../domain/models";
import BaseButton, { ButtonVariant } from "../../../BaseButton";

interface RowData {
    word: string;
    hint: string;
}

interface AnagramCreateViewProps {
    onExercisesCreated: (exs: Exercise[]) => void;
}

export function AnagramCreateView({ onExercisesCreated }: AnagramCreateViewProps) {
    const [rows, setRows] = useState<RowData[]>([{ word: "", hint: "" }]);

    // Добавляем новую «строчку»
    const addRow = () => {
        setRows((prev) => [...prev, { word: "", hint: "" }]);
    };

    // Обработка изменений поля word
    const handleWordChange = (index: number, newVal: string) => {
        setRows((prev) => {
            const copy = [...prev];
            copy[index] = { ...copy[index], word: newVal };
            return copy;
        });
    };

    // Обработка изменений поля hint
    const handleHintChange = (index: number, newVal: string) => {
        setRows((prev) => {
            const copy = [...prev];
            copy[index] = { ...copy[index], hint: newVal };
            return copy;
        });
    };

    // По нажатию "Create exercise" формируем массив анаграмм-упражнений
    const handleCreate = () => {
        // Преобразуем каждую строку в WordItem
        // (word: строка, translation: hint)
        const exercises: Exercise[] = rows
            .map((r) => {
                const trimmedWord = r.word.trim();
                if (!trimmedWord) {
                    // Если слово пустое — пропускаем
                    return null;
                }
                const wordItem: WordItem = {
                    id: crypto.randomUUID().toUpperCase(),
                    word: trimmedWord,
                    translation: r.hint.trim(),
                    example: "",
                    repetitions: [],
                    imageURL: "",
                };

                // Формируем Exercise с kind: "anagram"
                const exercise: Exercise = {
                    id: crypto.randomUUID().toUpperCase(),
                    type: {
                        kind: "anagram",
                        data: {
                            word: wordItem,
                        },
                    },
                };
                return exercise;
            })
            .filter((ex): ex is Exercise => ex !== null);

        if (exercises.length === 0) {
            alert("No valid words. Please fill at least one row with a word.");
            return;
        }

        // Вызываем колбэк
        onExercisesCreated(exercises);
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
                width: "100%",
            }}
        >
            <h3>Create Anagram Exercise</h3>
            <div
                style={{
                    position: "relative",
                    overflow: "auto",
                    width: "fit-content",
                    margin: "0 auto",
                }}
            >
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                    <thead>
                    <tr>
                        <th style={{ border: "1px solid gray", padding: "4px" }}>Word</th>
                        <th style={{ border: "1px solid gray", padding: "4px" }}>Hint/Translation</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows.map((row, index) => (
                        <tr key={index}>
                            <td style={{ border: "1px solid gray", padding: "4px" }}>
                                <input
                                    type="text"
                                    value={row.word}
                                    onChange={(e) => handleWordChange(index, e.target.value)}
                                    placeholder="Word"
                                    style={{ width: "200px" }}
                                />
                            </td>
                            <td style={{ border: "1px solid gray", padding: "4px" }}>
                                <input
                                    type="text"
                                    value={row.hint}
                                    onChange={(e) => handleHintChange(index, e.target.value)}
                                    placeholder="Hint / translation"
                                    style={{ width: "200px" }}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div style={{ position: "absolute", top: "0", right: "0", margin: "8px" }}>
                    <BaseButton variant={ButtonVariant.Small} onClick={addRow}>
                        +
                    </BaseButton>
                </div>
            </div>
            <div>
                <BaseButton variant={ButtonVariant.Big} onClick={handleCreate}>
                    Create exercise
                </BaseButton>
            </div>
        </div>
    );
}