import {Exercise} from "../../../../domain/models";
import {ExerciseTypeFilter} from "../Models/ExerciseTypeFilter";

export interface ExercisesFilterInteractor {
    filterExercises(exercises: Exercise[], filter: ExerciseTypeFilter): Exercise[];
}

export class ExercisesFilterInteractorImpl implements ExercisesFilterInteractor {
    filterExercises(exercises: Exercise[], filter: ExerciseTypeFilter): Exercise[] {
        if (filter === ExerciseTypeFilter.all) {
            return exercises;
        }
        return exercises.filter((ex) => {
            switch (ex.type.kind) {
                case "missingWord":
                    return filter === ExerciseTypeFilter.missingWord;
                case "selectOption":
                    return filter === ExerciseTypeFilter.selectOption;
                case "wordSearch":
                    return filter === ExerciseTypeFilter.wordSearch;
                case "anagram":
                    return filter === ExerciseTypeFilter.anagram;
                case "crossword":
                    return filter === ExerciseTypeFilter.crossword;
                case "fillBlanks":
                    return filter === ExerciseTypeFilter.fillBlanks;
                case "matchingPairs":
                    return filter === ExerciseTypeFilter.matchingPairs;
                case "justContent":
                    return filter === ExerciseTypeFilter.justContent;
                case "table":
                    return filter === ExerciseTypeFilter.table;
                case "hangman":
                    return filter === ExerciseTypeFilter.hangman;
                case "free":
                    return filter === ExerciseTypeFilter.free;
                default:
                    return false;
            }
        });
    }
}