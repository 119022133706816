import { makeAutoObservable } from "mobx";
import { MissingWordState } from "./MissingWordState";
import { MissingWordInteractor } from "./MissingWordInteractor";
import { Exercise } from "../../../../../domain/models";
import { PracticeSessionDelegate } from "../../../../../domain/Exercises/PracticeSessionDelegate";
import { MissingWordUIHandler } from "./MissingWordUIHandler";
import { MissingWordUseCase } from "./MissingWordUseCase";

/**
 * ViewModel — адаптирует Interactor к React-компонентам.
 * Служит источником truth-данных для React (через MobX observer).
 */
export class MissingWordViewModel {
    private interactor: MissingWordInteractor;
    public state: MissingWordState;

    constructor(
        exercise: Exercise,
        delegate?: PracticeSessionDelegate,
        uiHandler?: MissingWordUIHandler,
        useCase?: MissingWordUseCase
    ) {
        this.interactor = new MissingWordInteractor(
            exercise,
            delegate,
            uiHandler,
            useCase
        );
        this.state = this.interactor.state;

        // Когда в Interactor-е меняется стейт, ViewModel обновляет свой.
        this.interactor.onStateChange = (newState) => {
            this.state = newState;
        };

        makeAutoObservable(this);
    }

    public get sentence(): string {
        return this.state.sentence;
    }

    public get currentInput(): string {
        return this.state.currentInput;
    }

    public get isCorrect(): boolean | null {
        return this.state.isCorrect;
    }

    public get correctForm(): string {
        return this.state.correctForm;
    }

    // Методы, которые вызываются из React UI:
    public updateInput(newValue: string) {
        this.interactor.updateInputValue(newValue);
    }

    public checkAnswer() {
        this.interactor.checkAnswer();
    }

    public goNext() {
        this.interactor.goNext();
    }
}