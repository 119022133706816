/**
 * Структура данных, которая будет «летать» по сети.
 * Хранит всю необходимую информацию для восстановления состояния на клиенте.
 */
export class MissingWordUIState {
    public readonly type = "missingWord" as const;

    constructor(
        public currentInput: string,
        public isCorrect: boolean | null,
    ) {}

    /**
     * Метод для сравнения двух состояний — нужен, чтобы не слать лишние апдейты по сети.
     */
    public equals(other: MissingWordUIState): boolean {
        // if (other.type !== "MissingWordUIState") {
        //     return false;
        // }
        return (
            this.currentInput === other.currentInput &&
            this.isCorrect === other.isCorrect
        );
    }
}