import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Exercise } from "../../../../domain/models";
import { FilteredExercisesGridView } from "./FilteredExercisesGridView/FilteredExercisesGridView";
import { FilteredExercisesGridViewModel } from "./FilteredExercisesGridView/FilteredExercisesGridViewModel";
import { LoadExercisesInteractorImpl } from "../../../../domain/AllExercises/LoadExercisesInteractor";
import { ExercisesFilterInteractorImpl } from "../Logic/ExercisesFilterInteractor";
import CompositionRoot from "../../../../compositionRoot";

interface AllExercisesPickerViewProps {
    onSelect: (exercise: Exercise) => void;
    onClose: () => void;
}

export const AllExercisesPickerView = observer((props: AllExercisesPickerViewProps) => {
    const [gridViewModel] = useState(
        () =>
            new FilteredExercisesGridViewModel(
                new LoadExercisesInteractorImpl(CompositionRoot.exercisesStorage),
                new ExercisesFilterInteractorImpl()
            )
    );

    return (
        <div>
            <FilteredExercisesGridView
                viewModel={gridViewModel}
                onSelect={(exercise) => props.onSelect(exercise)}
            />

            <div style={{ marginTop: 16, textAlign: "right" }}>
                <button onClick={props.onClose}>Close</button>
            </div>
        </div>
    );
});