// ----------------- src/ui/screens/students/StudentsScreenViewModel.ts -----------------
import { makeAutoObservable, runInAction } from 'mobx';
import StudentsScreenInteractor from './StudentsScreenInteractor';
import {CombinedStudent} from "../CombinedStudent";

export default class StudentsScreenViewModel {
    // Список студентов
    students: CombinedStudent[] = [];

    // Флаги загрузки
    loadingUser = true;  // пока не знаем userId
    loading = false;     // загрузка самих студентов
    error: string | null = null;

    // ID текущего пользователя
    userId: string | null = null;

    private interactor: StudentsScreenInteractor;
    private unsubscribeFn: (() => void) | null = null;
    private unsubscribeStudents: (() => void) | null = null;

    constructor() {
        makeAutoObservable(this);
        this.interactor = new StudentsScreenInteractor();
    }

    /**
     * Инициализация: подписываемся на изменения currentUser.
     * Как только получили userId — грузим список студентов.
     */
    init() {
        this.unsubscribeFn = this.interactor.subscribeToCurrentUser((user) => {
            runInAction(() => {
                this.userId = user?.uid || null;
                this.loadingUser = false;
                if (this.userId) {
                    // Пользователь определился — загружаем студентов
                    this.startStudentsSubscription(this.userId);
                }
            });
        });
    }

    private startStudentsSubscription(userId: string) {
        runInAction(() => {
            this.loading = true;
            this.error = null;
        });

        this.unsubscribeStudents = this.interactor.subscribeToStudents(
            userId,
            (students) => {
                runInAction(() => {
                    this.students = students;
                    // Once we get the first snapshot, set loading to false
                    this.loading = false;
                });
            },
            (error) => {
                runInAction(() => {
                    this.error = error.message || "Failed to subscribe to students";
                    this.loading = false;
                });
            }
        );
    }

    /**
     * Отписываемся при размонтировании
     */
    dispose() {
        this.unsubscribeFn?.();
        this.unsubscribeStudents?.();
    }
}