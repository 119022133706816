import {SelectOptionTypePreview} from "./SelectOption/Previews/SelectOptionTypePreview";
import {WordSearchTypePreview} from "./WordSearch/Previews/WordSearchTypePreview";
import {CrosswordTypePreview} from "./Crossword/Previews/CrosswordTypePreview";
import {MatchingPairsTypePreview} from "./MatchingPairs/SmallPreview/MatchingPairsTypePreview";
import {JustContentTypePreview} from "./JustContent/SmallPreview/JustContentTypePreview";
import {TableTypePreview} from "./Table/SmallPreview/TableTypePreview";
import {HangmanTypePreview} from "./Hangman/SmallPreview/HangmanTypePreview";
import {FreeTypePreview} from "./Free/Previews/FreeTypePreview";
import {ExerciseCreationType} from "../PracticeManagement/Models/ExerciseCreationType";
import {Exercise} from "../../../domain/models";
import {SelectOptionCreateView} from "./SelectOption/Create/SelectOptionCreateView";
import {WordSearchCreateView} from "./WordSearch/Create/WordSearchCreateView";
import {CrosswordCreateView} from "./Crossword/Create/CrosswordCreateView";
import {MatchingPairsCreateView} from "./MatchingPairs/Create/MatchingPairsCreateView";
import {JustContentCreateView} from "../CanvasRefactor/JustContentCreate/JustContentCreateView";
import {TableCreateView} from "./Table/Create/TableCreateView";
import {HangmanCreateView} from "./Hangman/Create/HangmanCreateView";
import {FreeExerciseCreateView} from "./Free/Create/FreeExerciseCreateView";
import React from "react";
import {MissingWordTypePreview} from "./MissingWord/Previews/MissingWordTypePreview";
import {MissingWordCreateView} from "./MissingWord/Create/MissingWordCreateView";
import CreateFillBlanksView from "./FillBlanks/Create/CreateFillBlanksView";
import {FillBlanksTypePreview} from "./FillBlanks/Previews/FillBlanksTypePreview";
import {AnagramCreateView} from "./Anagram/Create/AnagramCreateView";
import {AnagramTypePreview} from "./Anagram/Preview/AnagramTypePreview";

export function renderPreview(type: ExerciseCreationType): JSX.Element {
    switch (type) {
        case ExerciseCreationType.SelectOption:
            return <SelectOptionTypePreview key={Date.now()} />
        case ExerciseCreationType.WordSearch:
            return <WordSearchTypePreview key={Date.now()} />;
        case ExerciseCreationType.Crossword:
            return <CrosswordTypePreview key={Date.now()} />
        case ExerciseCreationType.MatchingPairs:
            return <MatchingPairsTypePreview key={Date.now()} />;
        case ExerciseCreationType.JustContent:
            return <JustContentTypePreview key={Date.now()} />;
        case ExerciseCreationType.Table:
            return <TableTypePreview key={Date.now()} />;
        case ExerciseCreationType.Hangman:
            return <HangmanTypePreview key={Date.now()} />
        case ExerciseCreationType.Free:
            return <FreeTypePreview key={Date.now()} />

        case ExerciseCreationType.FillBlanks:
            return <FillBlanksTypePreview />;
        case ExerciseCreationType.Anagram:
            return <AnagramTypePreview />;
        case ExerciseCreationType.MissingWord:
            return <MissingWordTypePreview />

        default:
            return <GenericTypePreview label={`${type}\nPreview`} />;
    }
}

export function renderSettings(type: ExerciseCreationType, onExercisesCreated: (exercises: Exercise[]) => void): JSX.Element {
    switch (type) {
        case "selectOption": return <SelectOptionCreateView onExercisesCreated={onExercisesCreated} />;
        case "missingWord": return <MissingWordCreateView onExercisesCreated={onExercisesCreated} />;
        case "wordSearch": return <WordSearchCreateView onExercisesCreated={onExercisesCreated} />;
        case "crossword": return <CrosswordCreateView onExercisesCreated={onExercisesCreated} />;
        case "matchingPairs": return <MatchingPairsCreateView onExercisesCreated={onExercisesCreated} />;
        case "justContent": return <JustContentCreateView onExercisesCreated={onExercisesCreated} />;
        case "table": return <TableCreateView onExercisesCreated={onExercisesCreated} />;
        case "hangman": return <HangmanCreateView onExercisesCreated={onExercisesCreated} />;
        case "free": return <FreeExerciseCreateView onExercisesCreated={onExercisesCreated} />;
        case "anagram": return <AnagramCreateView onExercisesCreated={onExercisesCreated} />;
        case "fillBlanks": return <CreateFillBlanksView onExercisesCreated={onExercisesCreated} />;
        default: return <div>Not implemented</div>;
    }
}

interface GenericTypePreviewProps {
    label: string;
}

function GenericTypePreview({ label }: GenericTypePreviewProps) {
    return (
        <div style={containerStyle}>
            <span style={textStyle}>{label}</span>
        </div>
    );
}

const containerStyle: React.CSSProperties = {
    backgroundColor: 'rgba(128,128,128,0.3)',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};

const textStyle: React.CSSProperties = {
    fontSize: 12,
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    color: '#fff'
};