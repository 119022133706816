import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useSelectionState } from "./SelectionStateContext";
import { WordLine } from "./types";

interface FoundLinesViewProps {
    width: number;
    height: number;
}

export const FoundLinesView: React.FC<FoundLinesViewProps> = observer(
    ({ width, height }) => {
        const { game } = useSelectionState();
        const [rippleEffect, setRippleEffect] = useState(false);
        const [rippleHidden, setRippleHidden] = useState(false);

        // Подсвечиваем ripple-эффектом последнюю найденную линию
        const lines = game.wordLines;
        useEffect(() => {
            if (lines.length > 0) {
                setRippleEffect(true);
                setTimeout(() => {
                    setRippleEffect(false);
                    setRippleHidden(true);
                    setTimeout(() => {
                        setRippleHidden(false);
                    }, 500);
                }, 500);
            }
        }, [lines]);

        const rows = game.fieldSize.rowsCount;
        const cols = game.fieldSize.columnsCount;
        const stepX = width / cols;
        const stepY = height / rows;
        const squareSize = Math.min(stepX, stepY);
        // Толщина линии
        const lineThickness = squareSize * 0.75;

        return (
            <>
                {lines.map((line, index) => (
                    <DrawLine
                        key={index}
                        line={line}
                        stepX={stepX}
                        stepY={stepY}
                        lineThickness={lineThickness}
                        isLast={index === lines.length - 1}
                        rippleEffect={rippleEffect}
                        rippleHidden={rippleHidden}
                    />
                ))}
            </>
        );
    }
);

interface DrawLineProps {
    line: WordLine;
    stepX: number;
    stepY: number;
    lineThickness: number;
    isLast: boolean;
    rippleEffect: boolean;
    rippleHidden: boolean;
}

const DrawLine: React.FC<DrawLineProps> = ({
                                               line,
                                               stepX,
                                               stepY,
                                               lineThickness,
                                               isLast,
                                               rippleEffect,
                                               rippleHidden,
                                           }) => {
    const { startIndex, endIndex, color } = line;

    // Центры начальной и конечной ячейки
    const startX = (startIndex.col + 0.5) * stepX;
    const startY = (startIndex.row + 0.5) * stepY;
    const endX = (endIndex.col + 0.5) * stepX;
    const endY = (endIndex.row + 0.5) * stepY;

    // Вектор и угол
    const dx = endX - startX;
    const dy = endY - startY;
    const distance = Math.sqrt(dx * dx + dy * dy) || 1; // чтобы избежать деления на 0
    const angle = Math.atan2(dy, dx);

    // Мы хотим «выступать» за границы на половину толщины в начале и в конце,
    // значит итоговая длина будет больше обычной.
    const totalWidth = distance + lineThickness;

    // — Сдвигаем сам блок так, чтобы "anchor" (transformOrigin) совпадал с центром start.
    //   Т.к. transformOrigin = (lineThickness/2) px от левого края, то сам left сдвигаем на эту же величину назад.
    const halfThickness = lineThickness / 2;
    const lineStyle: React.CSSProperties = {
        position: "absolute",
        left: startX - halfThickness,           // смещаем влево на половину толщины
        top: startY - halfThickness,            // чтобы линия была по вертикали через её середину
        width: totalWidth,
        height: lineThickness,
        backgroundColor: color,
        borderRadius: lineThickness / 2,
        transform: `rotate(${angle}rad)`,
        // В качестве точки поворота используем "lineThickness/2 по X" ("left center" при 0% не подходит)
        // Так pivot окажется ровно в startX,startY.
        transformOrigin: `${halfThickness}px center`,
        pointerEvents: "none",
    };

    // Ripple-обводка вокруг линии
    const rippleStyle: React.CSSProperties = {
        position: "absolute",
        left: startX - halfThickness,
        top: startY - halfThickness,
        width: totalWidth,
        height: lineThickness,
        borderRadius: lineThickness / 2,
        border: `2px solid ${color}`,
        transform: `rotate(${angle}rad) scale(${rippleEffect ? 1.3 : 1})`,
        transformOrigin: `${halfThickness}px center`,
        transition: "transform 0.5s ease-out, opacity 0.5s ease-out",
        pointerEvents: "none",
    };

    return (
        <>
            {/* Основная закрашенная линия */}
            <div style={lineStyle} />

            {/* Визуальный ripple на последней линии */}
            {isLast && !rippleHidden && (
                <div style={rippleStyle} />
            )}
        </>
    );
};