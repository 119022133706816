import React, { useState } from "react";
import { Exercise, WordItem } from "../../../../../domain/models";
import BaseButton, { ButtonVariant } from "../../../BaseButton";
import {Placer3} from "./Placer3";
// Предполагаем, что WordSearchGridBuilder реализует метод buildWordSearch
// который принимает массив WordItem и возвращает объект с сеткой и списком размещённых слов.

interface WordSearchCreateViewProps {
    onExercisesCreated: (exercises: Exercise[]) => void;
}

interface RowData {
    word: string;
    hint: string;
}

export function WordSearchCreateView({
                                         onExercisesCreated,
                                     }: WordSearchCreateViewProps) {
    const [rows, setRows] = useState<RowData[]>([{ word: "", hint: "" }]);

    const addRow = () => {
        setRows((prev) => [...prev, { word: "", hint: "" }]);
    };

    const handleWordChange = (index: number, newVal: string) => {
        setRows((prev) => {
            const copy = [...prev];
            copy[index] = { ...copy[index], word: newVal };
            return copy;
        });
    };

    const handleHintChange = (index: number, newVal: string) => {
        setRows((prev) => {
            const copy = [...prev];
            copy[index] = { ...copy[index], hint: newVal };
            return copy;
        });
    };

    const handleCreate = () => {
        // Преобразуем каждую строку в WordItem
        const wordItems: WordItem[] = rows
            .map((r) => ({
                id: crypto.randomUUID().toUpperCase(),
                word: r.word.trim(),
                translation: r.hint.trim(),
                example: "",
                repetitions: [],
                imageURL: "",
            }))
            .filter((item) => item.word.length > 0);

        // Задаем размеры сетки (например, 10x10)
        const gridSize = { rowsCount: 10, columnsCount: 10 };
        // Создаем генератор сетки Placer3
        const placer = new Placer3(gridSize.rowsCount, gridSize.columnsCount);
        // Для генерации сетки приводим все слова к верхнему регистру
        const upperWords = wordItems.map((item) => item.word.toUpperCase());
        const [grid, placedWords] = placer.generateWordGrid(upperWords);

        // Формируем итоговый объект Exercise типа wordSearch
        const exercise: Exercise = {
            id: crypto.randomUUID().toUpperCase(),
            type: {
                kind: "wordSearch",
                data: { words: wordItems, grid: grid },
            },
            // Дополнительные поля, такие как authorId или description, можно добавить при необходимости
        };

        onExercisesCreated([exercise]);
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
                width: "100%",
            }}
        >
            <h3>Create WordSearch Exercise</h3>
            <div
                style={{
                    position: "relative",
                    overflow: "auto",
                    width: "fit-content",
                    margin: "0 auto",
                }}
            >
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                    <thead>
                    <tr>
                        <th style={{ border: "1px solid gray", padding: "4px" }}>
                            Word
                        </th>
                        <th style={{ border: "1px solid gray", padding: "4px" }}>
                            Hint
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows.map((row, index) => (
                        <tr key={index}>
                            <td style={{ border: "1px solid gray", padding: "4px" }}>
                                <input
                                    type="text"
                                    value={row.word}
                                    onChange={(e) => handleWordChange(index, e.target.value)}
                                    placeholder="word"
                                    style={{ width: "200px" }}
                                />
                            </td>
                            <td style={{ border: "1px solid gray", padding: "4px" }}>
                                <input
                                    type="text"
                                    value={row.hint}
                                    onChange={(e) => handleHintChange(index, e.target.value)}
                                    placeholder="hint / translation"
                                    style={{ width: "200px" }}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                {/* Кнопка добавления строки располагается справа */}
                <div style={{ position: "absolute", top: "0", right: "0", margin: "8px" }}>
                    <BaseButton variant={ButtonVariant.Small} onClick={addRow}>
                        +
                    </BaseButton>
                </div>
            </div>
            <div>
                <BaseButton variant={ButtonVariant.Big} onClick={handleCreate}>
                    Create exercise
                </BaseButton>
            </div>
        </div>
    );
}