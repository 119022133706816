// src/compositionRoot.ts

import { WordParser } from './domain/interfaces/WordParser';
import {OpenAITextParser} from "./data/implementations/OpenAITextParser";
import {AuthService} from "./domain/interfaces/AuthService";
import {UserStorage} from "./domain/interfaces/UserStorage";
import AppUserManager from "./domain/services/AppUserManager";
import FirebaseAuthService from "./data/implementations/FirebaseAuthService";
import FirestoreUserService from "./data/implementations/FirestoreUserService";
import MockWordsService from "./data/mocks/MockWordsService";
import FirestoreWordsService from "./data/implementations/FirestoreWordsService";
import {WordsServiceProtocol} from "./domain/interfaces/WordsServiceProtocol";
import {
    ExerciseCreateInteractor,
    MockExerciseCreateInteractor,
    RealExerciseCreateInteractor
} from "./domain/interfaces/ExerciseCreateInteractor";
import {SaveExerciseInteractor, SaveExerciseInteractorImpl} from "./domain/interfaces/SaveExerciseInteractor";
import {ExercisesStorage} from "./domain/interfaces/ExercisesStorage";
import {FirestoreExercisesService} from "./data/implementations/FirestoreExercisesService";
import {PracticeSessionStorage, PracticeSessionStorageStub} from "./domain/interfaces/PracticeSessionStorage";
import {FirestorePracticeSessionService} from "./data/implementations/FirestorePracticeSessionService";
import {CreateFillBlanksServiceImpl} from "./data/implementations/CreateFillBlanksServiceImpl";
import {OpenAISpeechGenerator} from "./data/implementations/OpenAISpeechGenerator";
import {TableRequester, TableRequesting} from "./domain/Exercises/Table/TableRequester";
import {apiKey} from "./data/config/environment";
import {OpenAIRequester} from "./data/implementations/OpenAIRequester";
import {SessionRepository} from "./domain/Multiplayer/SessionRepository";
import {FirestoreSessionRepository} from "./data/implementations/FirestoreSessionRepository";
import {MediaLoader} from "./data/implementations/Files/MediaLoader";
import {ImageUploader, ImageUploading} from "./data/implementations/Files/ImageUploader";
import {AudioUploading, MediaUploading} from "./domain/interfaces/MediaTypes";
import {NoteRepository} from "./ui/components/CanvasRefactor/Base/network/NoteRepository";
import {FirestoreNoteRepository} from "./ui/components/CanvasRefactor/Base/network/FirestoreNoteRepository";
import {PlanStorage} from "./ui/components/PracticeManagement/Logic/PlanStorage";
import {FirestoreLearningPlanService} from "./data/implementations/FirestoreLearningPlanService";
import {OnboardingFlowManager} from "./ui/components/Onboarding/OnboardingFlowManager";
import FirestoreClassService, {ClassStorage} from "./ui/components/Practice/ARefactor/Repos/FirestoreClassService";
import {
    FirebaseAudioUploadService,
    IAudioUploadService
} from "./ui/components/Components/AudioPlayer/Services/IAudioUploadService";
import {
    ITextRecognitionService,
    OpenAIWhisperRecognitionService
} from "./ui/components/Components/AudioPlayer/Services/ITextRecognitionService";
import {
    FirestoreAudioStorageService,
    IAudioStorageService
} from "./ui/components/Components/AudioPlayer/Services/IAudioStorageService";
import StartScreenViewModel from "./features/start/StartScreenViewModel";
import {LessonsListViewModel} from "./ui/components/PracticeManagement/UI/LessonsList/LessonListViewModel";
import {NavigateFunction} from "react-router-dom";
import {
    FirestoreSessionInfoRepository,
    SessionInfoRepository
} from "./ui/components/Practice/ARefactor/Repos/FirestoreSessionInfoRepository";
import {
    FillBlanksCreationInteractor,
    FillBlanksCreationInteractorImpl
} from "./ui/components/exercises_types/FillBlanks/Create/FillBlanksCreationInteractor";

// Composition Root для конфигурации всех зависимостей
class CompositionRoot {
    private static wordParserInstance: WordParser;
    private static authServiceInstance: AuthService;
    private static userStorageInstance: UserStorage;
    static exercisesStorage: ExercisesStorage
    private static appUserManagerInstance: AppUserManager;
    private static wordsServiceInstance: WordsServiceProtocol;
    static exerciseCreateInteractor: ExerciseCreateInteractor;
    static saveExerciseInteractor: SaveExerciseInteractor
    static practiceSessionStorage: PracticeSessionStorage
    static onboardingFlowManager: OnboardingFlowManager;

    static fillBlanksCreationInteractor: FillBlanksCreationInteractor;

    static tableRequester: TableRequesting
    static sessionRepository: SessionRepository

    static audioUploading: AudioUploading
    static imageUploading: ImageUploading
    static mediaUploading: MediaUploading
    static noteRepository: NoteRepository
    static planStorage: PlanStorage
    private static classStorage: ClassStorage;

    public static audioUploadService: IAudioUploadService
    public static textRecognitionService: ITextRecognitionService
    public static audioStorageService: IAudioStorageService
    static sessionInfoRepository: SessionInfoRepository = new FirestoreSessionInfoRepository();

    static initialize() {
        // Экземпляр OpenAIWordParser, реализующий WordParser
        this.wordParserInstance = new OpenAITextParser();

        // Создаём экземпляры authService и userStorage
        this.authServiceInstance = new FirebaseAuthService();
        this.userStorageInstance = new FirestoreUserService();

        // Создаём экземпляр AppUserManager, внедряя authService и userStorage
        this.appUserManagerInstance = new AppUserManager(this.authServiceInstance, this.userStorageInstance);
        this.audioUploading = new MediaLoader()
        this.mediaUploading = new MediaLoader()
        this.imageUploading = new ImageUploader()
        this.noteRepository = new FirestoreNoteRepository()

        this.wordsServiceInstance = FirestoreWordsService.getInstance();

        // this.exerciseCreateInteractor = new MockExerciseCreateInteractor();
        this.exerciseCreateInteractor = new RealExerciseCreateInteractor();
        this.exercisesStorage = new FirestoreExercisesService();
        console.log("initialize CompositionRoot, exercisesStorage: ", this.exercisesStorage)
        this.saveExerciseInteractor = new SaveExerciseInteractorImpl(this.exercisesStorage, this.appUserManagerInstance)
        this.practiceSessionStorage = new FirestorePracticeSessionService()
        this.tableRequester = new TableRequester(new OpenAIRequester(apiKey))
        this.sessionRepository = new FirestoreSessionRepository()
        this.planStorage = new FirestoreLearningPlanService()
        this.onboardingFlowManager = new OnboardingFlowManager(this.appUserManagerInstance);

        this.classStorage = new FirestoreClassService();

        this.audioUploadService = new FirebaseAudioUploadService();
        this.textRecognitionService = new OpenAIWhisperRecognitionService(apiKey);
        this.audioStorageService = new FirestoreAudioStorageService();

        this.fillBlanksCreationInteractor = new FillBlanksCreationInteractorImpl(
            new CreateFillBlanksServiceImpl(),
            new OpenAISpeechGenerator(),
            this.audioUploading
        );

    }

    static getWordParser(): WordParser {
        return this.wordParserInstance;
    }

    static getExercisesStorage(): ExercisesStorage {
        return this.exercisesStorage;
    }

    static getAppUserManager(): AppUserManager {
        return this.appUserManagerInstance;
    }

    static getWordsService(): WordsServiceProtocol {
        return this.wordsServiceInstance;
    }

    static getUserStorage(): UserStorage {
        return this.userStorageInstance;
    }

    static getClassStorage(): ClassStorage {
        return CompositionRoot.classStorage;
    }

    private static _startScreenViewModel: StartScreenViewModel | null = null;

    public static getStartScreenViewModel(): StartScreenViewModel {
        if (!this._startScreenViewModel) {
            this._startScreenViewModel = new StartScreenViewModel();
        }
        return this._startScreenViewModel;
    }

    private static _lessonsListViewModel: LessonsListViewModel | null = null;

    static getLessonsListViewModel(navigate: NavigateFunction) {
        if (!this._lessonsListViewModel) {
            this._lessonsListViewModel = new LessonsListViewModel(navigate);
        } else {
            // На случай если navigate меняется:
            this._lessonsListViewModel.setNavigate(navigate);
        }
        return this._lessonsListViewModel;
    }

    static disposeLessonsListViewModel() {
        this._lessonsListViewModel = null;
    }

}

export default CompositionRoot;
