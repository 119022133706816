import { doc, getDoc, setDoc } from "firebase/firestore";
import {db} from "../../../../../data/config/firebase";

/** Сервис "стораджа", где мы храним (audioId, fileLink, recognizedText) */
export interface IAudioStorageService {
    /** Сохранить структуру с данными аудио */
    storeAudioData(data: { audioId: string; fileLink: string; recognizedText: string }): Promise<void>;

    /** Получить структуру с данными аудио по ID */
    fetchAudioData(audioId: string): Promise<{ fileLink: string; recognizedText: string }>;
}

/**
 * Мок стораджа. Храним всё локально в Map<string, { fileLink, recognizedText }>
 * В реальном проекте – обращения к базе/серверу.
 */
export class MockAudioStorageService implements IAudioStorageService {
    private storage = new Map<string, { fileLink: string; recognizedText: string }>();

    async storeAudioData(data: { audioId: string; fileLink: string; recognizedText: string }): Promise<void> {
        await this.simulateDelay(800);
        this.storage.set(data.audioId, { fileLink: data.fileLink, recognizedText: data.recognizedText });
    }

    async fetchAudioData(audioId: string): Promise<{ fileLink: string; recognizedText: string }> {
        await this.simulateDelay(500);
        const found = this.storage.get(audioId);
        if (!found) {
            // Имитируем ошибку: "нет записи"
            throw new Error("No data found in storage for audioId=" + audioId);
        }
        return found;
    }

    private async simulateDelay(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
}

export class FirestoreAudioStorageService implements IAudioStorageService {
    /**
     * Сохраняем объект { audioId, fileLink, recognizedText }
     * в документе "audio/{audioId}". Если fileLink или recognizedText
     * пустые, кидаем ошибку.
     */
    async storeAudioData(data: {
        audioId: string;
        fileLink: string;
        recognizedText: string;
    }): Promise<void> {
        const { audioId, fileLink, recognizedText } = data;

        if (!fileLink) {
            throw new Error("Cannot store audio data: fileLink is empty.");
        }
        if (!recognizedText) {
            throw new Error("Cannot store audio data: recognizedText is empty.");
        }

        const docRef = doc(db, "audio", audioId);

        // Сохраняем объект
        await setDoc(docRef, {
            fileLink,
            recognizedText
        });
    }

    /**
     * Получаем данные по audioId из "audio/{audioId}".
     * Если документ не найден, бросаем ошибку.
     */
    async fetchAudioData(audioId: string): Promise<{ fileLink: string; recognizedText: string }> {
        const docRef = doc(db, "audio", audioId);
        const snap = await getDoc(docRef);

        if (!snap.exists()) {
            throw new Error(`No data found in storage for audioId="${audioId}"`);
        }

        const data = snap.data();
        // Предполагаем, что data.fileLink и data.recognizedText — строки
        const fileLink = data.fileLink as string;
        const recognizedText = data.recognizedText as string;

        // Если вдруг поля не заданы — тоже ошибка
        if (!fileLink) {
            throw new Error(`Stored audio data is missing fileLink for audioId="${audioId}"`);
        }
        if (!recognizedText) {
            throw new Error(`Stored audio data is missing recognizedText for audioId="${audioId}"`);
        }

        return { fileLink, recognizedText };
    }
}