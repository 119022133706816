// FirestoreNoteRepository.ts

import {
    getFirestore,
    doc,
    onSnapshot,
    setDoc,
    updateDoc,
    getDoc
} from "firebase/firestore";
import {NoteRepository} from "./NoteRepository";
import {NotesState} from "../../Notes/NotesState";
import {CanvasItem} from "../CanvasItem";

export class FirestoreNoteRepository implements NoteRepository {
    private db = getFirestore();

    // Keep unsub functions in a map, if multiple noteId usage
    private unsubMap: Map<string, () => void> = new Map();

    subscribeToUpdates(
        noteId: string,
        onUpdate: (state: NotesState | undefined) => void
    ): () => void {
        const ref = doc(this.db, "notes", noteId);

        const unsubscribe = onSnapshot(ref, (snapshot) => {
            if (!snapshot.exists()) {
                onUpdate(undefined);
                return;
            }
            const data = snapshot.data();
            try {
                const state = decodeCanvasState(data);
                onUpdate(state);
            } catch (err) {
                console.error("Error decoding canvas state:", err);
                onUpdate(undefined);
            }
        });

        this.unsubMap.set(noteId, unsubscribe);

        return () => {
            unsubscribe();
            this.unsubMap.delete(noteId);
        };
    }

    async saveState(noteId: string, state: NotesState): Promise<void> {
        const ref = doc(this.db, "notes", noteId);
        const snapshot = await getDoc(ref);

        if (!snapshot.exists()) {
            // create new doc
            await setDoc(ref, encodeCanvasState(state));
        } else {
            // update existing doc
            await updateDoc(ref, encodeCanvasState(state));
        }
    }
}
/**
 * Encodes/decodes CanvasState <-> Firestore JSON
 */
function decodeCanvasState(data: any): NotesState {
    return {
        version: typeof data.version === "number" ? data.version : 0,
        items: Array.isArray(data.items)
            ? data.items.map(decodeCanvasItem)
            : [],
    };
}

function encodeCanvasState(state: NotesState): any {
    return {
        version: state.version,
        items: state.items.map(encodeCanvasItem),
    };
}

export function encodeCanvasItem(item: CanvasItem): any {
    // Общие поля для всех типов
    const docData: any = {
        id: item.id,
        type: item.type,
        x: item.x,
        y: item.y,
        width: item.width,
        height: item.height,
    };

    // Дополнительно — в зависимости от типа
    switch (item.type) {
        case 'text':
            docData.content = item.content;
            break;

        case 'image':
            docData.content = item.content;
            break;

        case 'drawing':
            // Если в объекте уже есть paths (новая модель) – сериализуем их,
            // иначе если есть уже готовая строка pathsJson – используем её.
            docData.content = item.content;
            if (item.paths !== undefined) {
                docData.pathsJson = JSON.stringify(item.paths);
            } else if ((item as any).pathsJson !== undefined) {
                // Если поле pathsJson уже присутствует в объекте, используем его
                docData.pathsJson = (item as any).pathsJson;
            } else {
                docData.pathsJson = JSON.stringify([]);
            }
            docData.canDraw = item.canDraw;
            break;
    }

    // Заменяем undefined на null, чтобы Firestore сохранял корректно
    Object.keys(docData).forEach((k) => {
        if (docData[k] === undefined) {
            docData[k] = null;
        }
    });

    return docData;
}

export function decodeCanvasItem(raw: any): CanvasItem {
    // Считаем, что уже проверили: raw.type вообще есть

    // Общие поля
    const baseFields = {
        id: typeof raw.id === 'string' ? raw.id : '',
        x: Number(raw.x) || 0,
        y: Number(raw.y) || 0,
        width: Number(raw.width) || 100,
        height: Number(raw.height) || 50,
    };

    // Переходим к разветвлению по type
    switch (raw.type) {
        case 'text':
            return {
                ...baseFields,
                type: 'text',
                content: typeof raw.content === 'string' ? raw.content : '',
            };

        case 'image':
            return {
                ...baseFields,
                type: 'image',
                content: typeof raw.content === 'string' ? raw.content : '',
            };

        case 'drawing':
            let paths: Array<Array<{ x: number; y: number }>> = [];
            if (typeof raw.pathsJson === 'string') {
                try {
                    paths = JSON.parse(raw.pathsJson);
                } catch {
                    paths = [];
                }
            }
            return {
                ...baseFields,
                type: 'drawing',
                content: typeof raw.content === 'string' ? raw.content : '',
                canDraw: !!raw.canDraw,
                paths,
            };

        default:
            // На случай, если придёт неправильный type
            throw new Error(`Unsupported item type: ${raw.type}`);
    }
}