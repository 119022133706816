import { makeAutoObservable, runInAction } from "mobx";
import { PracticeSessionDelegate } from "../../../../domain/Exercises/PracticeSessionDelegate";
import { Exercise, Lesson } from "../../../../domain/models";
import { LessonPracticeSession } from "../../../../domain/services/LessonPracticeSession";
import { SessionStateInteractor } from "../../../../domain/Multiplayer/SessionStateInteractor";
import CompositionRoot from "../../../../compositionRoot";
import { StopClassUseCase } from "../ARefactor/Logic/StopClassUseCase";
import { PracticeStateFactory } from "../PracticeStateFactory";
import {PracticeStateUI} from "../PracticeStateUI";
import {InsertCanvasUseCase} from "../ARefactor/Logic/InsertCanvasUseCase";

export class ClassroomInteractor implements PracticeSessionDelegate {
    lesson: Lesson | null = null;
    session: LessonPracticeSession | null = null;
    practiceState: PracticeStateUI = { type: "done" };

    teacherId: string | null = null;
    studentId: string | null = null;

    sessionStateInteractor: SessionStateInteractor;

    private storage = CompositionRoot.getExercisesStorage();
    private planStorage = CompositionRoot.planStorage;
    private practiceStorage = CompositionRoot.practiceSessionStorage;
    private userManager = CompositionRoot.getAppUserManager();
    private stopClassUseCase = new StopClassUseCase();
    private practiceStateFactory: PracticeStateFactory;

    private insertCanvasUseCase: InsertCanvasUseCase;

    /**
     * Отписка от подписки на изменения плана.
     */
    private unsubscribePlan: (() => void) | null = null;

    constructor(public lessonId: string) {
        makeAutoObservable(this);

        const sessionRepo = CompositionRoot.sessionRepository;
        this.sessionStateInteractor = new SessionStateInteractor(lessonId, sessionRepo);

        this.insertCanvasUseCase = new InsertCanvasUseCase(CompositionRoot.planStorage);

        this.practiceStateFactory = new PracticeStateFactory(this.sessionStateInteractor, this);

        this.sessionStateInteractor.onIndexChange = (newIndex: number) => {
            if (this.session && this.session.currentIndex !== newIndex) {
                runInAction(() => {
                    this.session!.currentIndex = newIndex;
                });
                this.updatePracticeState();
            }
        };

        this.loadClassAndSubscribe();
    }

    get totalExercises(): number {
        return this.lesson?.exercises.length ?? 0;
    }

    get currentExerciseIndex(): number {
        return this.session?.currentIndex ?? 0;
    }

    /**
     * Загружаем данные по текущему "классу" (teacherId, studentId)
     * и затем подписываемся на изменения плана (LearningPlan).
     */
    private async loadClassAndSubscribe() {
        try {
            const classStorage = CompositionRoot.getClassStorage();
            const classData = await classStorage.getClassSession(this.lessonId);

            if (!classData) {
                console.error("No class found with id:", this.lessonId);
                runInAction(() => {
                    this.practiceState = { type: "done" };
                });
                return;
            }

            runInAction(() => {
                this.teacherId = classData.teacherId;
                this.studentId = classData.studentId;
            });

            // Подписываемся на изменения самого плана
            if (this.unsubscribePlan) {
                this.unsubscribePlan(); // если вдруг уже подписаны, убираем старую
            }
            this.unsubscribePlan = this.planStorage.subscribeToPlan(
                classData.teacherId,
                classData.studentId,
                (plan) => {
                    // Если план = null, значит документа нет
                    if (!plan) {
                        runInAction(() => {
                            this.lesson = null;
                            this.practiceState = { type: "done" };
                        });
                        return;
                    }
                    // Обрабатываем актуальный план
                    this.onPlanUpdate(plan);
                }
            );
        } catch (err) {
            console.error("Failed to load class session:", err);
            runInAction(() => {
                this.practiceState = { type: "done" };
            });
        }
    }

    /**
     * Реагируем на приход новых данных плана ( LearningPlan ).
     */
    private onPlanUpdate(plan: { teacherId: string; studentId: string; name: string; exercises: Exercise[] }) {
        // Создаём (или обновляем) lesson
        const updatedLesson: Lesson = {
            id: this.lessonId,
            exercises: plan.exercises,
            authorId: plan.teacherId,
            name: plan.name
        };

        runInAction(() => {
            this.lesson = updatedLesson;
            this.sessionStateInteractor.setExercises(plan.exercises)

            // Инициализируем (или обновляем) LessonPracticeSession
            if (!this.session) {
                const practiceSession = new LessonPracticeSession(
                    updatedLesson,
                    updatedLesson.id,
                    this.userManager,
                    this.practiceStorage
                );
                this.session = practiceSession;

                // Восстанавливаем сохранённый индекс
                const existingIndex = this.sessionStateInteractor.currentExerciseIndex;
                if (existingIndex !== undefined) {
                    this.session.currentIndex = existingIndex;
                }
            } else {
                // Если сессия уже есть, просто обновим lesson внутри сессии
                // (В реальном коде вы могли бы пересоздать массив упражнений,
                //  подкорректировать currentIndex, если упражнений стало меньше и т.д.)
                this.session.set = updatedLesson;
                if (this.session.currentIndex >= updatedLesson.exercises.length) {
                    this.session.currentIndex = updatedLesson.exercises.length - 1;
                }
            }

            // Обновляем состояние UI
            this.updatePracticeState();
        });
    }

    async insertCanvas() {
        if (!this.lesson || !this.teacherId || !this.studentId || !this.session) return;
        // Just call the use case
        await this.insertCanvasUseCase.execute({
            lesson: this.lesson,
            teacherId: this.teacherId,
            studentId: this.studentId,
            session: this.session,
            currentIndex: this.currentExerciseIndex
        });
    }

    async stopClass() {
        if (!this.teacherId || !this.studentId || !this.lesson || !this.session) return;

        await this.stopClassUseCase.execute(
            this.lessonId,
            this.teacherId,
            this.studentId,
            this.lesson,
            this.currentExerciseIndex
        );
    }

    goNext() {
        if (!this.session) return;
        this.session.goNext();
        this.sessionStateInteractor.updateCurrentExerciseIndex(this.session.currentIndex);
        this.updatePracticeState();
    }

    goPrev() {
        if (!this.session) return;
        this.session.goPrev();
        this.sessionStateInteractor.updateCurrentExerciseIndex(this.session.currentIndex);
        this.updatePracticeState();
    }

    goToExercise(index: number) {
        if (!this.session) return;
        this.session.currentIndex = index;
        this.sessionStateInteractor.updateCurrentExerciseIndex(index);
        this.updatePracticeState();
    }

    private updatePracticeState() {
        if (!this.session) {
            this.practiceState = { type: "done" };
            return;
        }
        const exercise = this.session.getExercise();
        this.practiceState = this.practiceStateFactory.create(exercise);
    }

    // ----- PracticeSessionDelegate -----
    saveAttempt(exercise: Exercise, answer: any) {
        this.session?.saveAttempt(exercise, answer);
    }

    currentStepDone() {
        this.goNext();
    }
}