// src/context/ThemeContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';

interface ThemeContextProps {
    theme: 'light' | 'dark';
    toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextProps>({
    theme: 'light',
    toggleTheme: () => {},
});

export const ThemeProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    // Читаем тему из localStorage
    const [theme, setTheme] = useState<'light' | 'dark'>(() => {
        const savedTheme = localStorage.getItem('appTheme');
        return savedTheme === 'dark' ? 'dark' : 'light';
    });

    // Сохраняем тему в localStorage при каждом изменении
    useEffect(() => {
        localStorage.setItem('appTheme', theme);
    }, [theme]);

    // Здесь же следим, чтобы body получал нужный класс
    useEffect(() => {
        document.body.classList.remove('light-theme', 'dark-theme');
        document.body.classList.add(theme === 'dark' ? 'dark-theme' : 'light-theme');
    }, [theme]);

    const toggleTheme = () => {
        setTheme((prev) => (prev === 'light' ? 'dark' : 'light'));
    };

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

// Хук для удобства
export const useTheme = () => useContext(ThemeContext);