// LessonPractice.tsx
import React from 'react';
import { observer } from 'mobx-react-lite';
import { LessonPracticeViewModel} from './LessonPracticeViewModel';
import {BaseText} from "../../exercises_types/BaseComponents/BaseText";
import CompositionRoot from "../../../../compositionRoot";
import {NotesInteractor} from "../../CanvasRefactor/Notes/NotesInteractor";
import {NotesWindowView} from "../../CanvasRefactor/Notes/NotesWindowView";
import {NotesWindowViewModel} from "../../CanvasRefactor/Notes/NotesWindowViewModel";
import {PracticeExerciseContent} from "../PracticeExerciseContent";

interface LessonPracticeProps {
    lessonId: string;
    sessionId: string;
}

const LessonPracticeView: React.FC<LessonPracticeProps> = observer(({ lessonId, sessionId }) => {
    const [vm] = React.useState(() => new LessonPracticeViewModel(lessonId, sessionId));
    const practiceState = vm.practiceState;
    const total = vm.totalExercises;
    const currentIndex = vm.currentExerciseIndex;

    return (
        <div style={{ display: 'flex', flexDirection: 'row'}}>
            <div
                style={{
                    width: '65%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                }}
            >
                <BaseText font="subheadline">
                    Lesson {vm.lesson?.name} — Exercise {currentIndex + 1} / {total}
                </BaseText>

                <PracticeExerciseContent practiceState={practiceState} />

                <div style={{ marginTop: 0 }}>
                    <button onClick={() => vm.goPrev()} disabled={currentIndex <= 0}>
                        Back
                    </button>
                    <button onClick={() => vm.goNext()} disabled={currentIndex >= total - 1}>
                        Next
                    </button>
                </div>
            </div>

            <div style={{ width: '40%', borderLeft: '1px solid #ccc', overflowY: 'auto' }}>
                <NotesWindowView noteId={`${sessionId}`} />
            </div>
        </div>
    );
});

export default LessonPracticeView;