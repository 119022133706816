import {
    getFirestore,
    doc,
    onSnapshot,
    setDoc,
    updateDoc,
    getDoc
} from "firebase/firestore";
import { LessonWordsRepository } from "./LessonWordsRepository";
import { LessonWordsState } from "./LessonWordsState";
import { decodeWordsState, encodeWordsState } from "./LessonWordsEncoding";

export class FirestoreLessonWordsRepository implements LessonWordsRepository {
    private db = getFirestore();
    private unsubMap: Map<string, () => void> = new Map();

    subscribeToUpdates(
        sessionId: string,
        onUpdate: (state: LessonWordsState | undefined) => void
    ): () => void {
        const ref = doc(this.db, "sessionWords", sessionId);

        const unsubscribe = onSnapshot(ref, (snapshot) => {
            if (!snapshot.exists()) {
                onUpdate(undefined);
                return;
            }
            const data = snapshot.data();
            try {
                const state = decodeWordsState(data);
                onUpdate(state);
            } catch (err) {
                console.error("Error decoding words state:", err);
                onUpdate(undefined);
            }
        });

        this.unsubMap.set(sessionId, unsubscribe);

        return () => {
            unsubscribe();
            this.unsubMap.delete(sessionId);
        };
    }

    async saveState(sessionId: string, state: LessonWordsState): Promise<void> {
        const ref = doc(this.db, "sessionWords", sessionId);
        const snapshot = await getDoc(ref);

        const encoded = encodeWordsState(state);

        if (!snapshot.exists()) {
            // create new doc
            await setDoc(ref, encoded);
        } else {
            // update existing doc
            await updateDoc(ref, encoded);
        }
    }
}