// ./ui/components/statistics/LearningStatisticsView.tsx

import React from 'react';
import DifficultWordsView from './DifficultWordsView';
import WordsByStatusView from './WordsByStatusView';
import LearnByDateView from './LearnByDateView';
import WordsViewModel from '../../../ui/viewmodels/WordsViewModel';

interface LearningStatisticsViewProps {
    wordsViewModel: WordsViewModel;
}

const LearningStatisticsView: React.FC<LearningStatisticsViewProps> = ({ wordsViewModel }) => {
    return (
        <div style={{ overflowY: 'auto', height: '100%', padding: '20px' }}>
            <DifficultWordsView wordsViewModel={wordsViewModel} />
            <WordsByStatusView wordsViewModel={wordsViewModel} />
            <LearnByDateView wordsViewModel={wordsViewModel} />
        </div>
    );
};

export default LearningStatisticsView;
