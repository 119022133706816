import React from "react";
import { useSizeContext } from "./SizeContext";
import { useSelectionState } from "./SelectionStateContext";
import { WordsWrapView } from "./WordsWrapView";
import { GridSizeView } from "./GridSizeView";
import { SelectedWordView } from "./SelectedWordView";
import {observer} from "mobx-react-lite";

export const WordSearchView: React.FC = observer(() => {
    const { gameViewsSpacing, gridSizeWidth } = useSizeContext();

    return (
        <div style={{ position: "relative" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: gameViewsSpacing }}>
                <div style={{ margin: "0 auto" }}>
                    <WordsWrapView width={gridSizeWidth * 0.8} />
                </div>
                <GridSizeView />
            </div>

            {/* Overlay alignment at top */}
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <SelectedWordView />
            </div>
        </div>
    );
});