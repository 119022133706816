// EditExerciseModal.tsx
import React from 'react';
import { Exercise } from '../../../../../domain/models';

interface Props {
    exercise: Exercise;
    onClose: () => void;
    onSave: (exercise: Exercise) => void;
}

export function EditExerciseModal({ exercise, onClose, onSave }: Props) {
    return (
        <div style={modalStyle}>
            <div style={modalContentStyle}>
                <h2>Edit Exercise (type: {exercise.type.kind})</h2>
                {exercise.type.kind === "table" ? (
                    <div>Here you can embed a TableCreateView or similar.</div>
                ) : (
                    <div>Editing is not implemented yet for this type</div>
                )}

                <div style={{ marginTop: 20 }}>
                    <button onClick={onClose}>Close</button>
                    <button
                        onClick={() => {
                            // just save back
                            onSave(exercise);
                        }}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}
const modalStyle: React.CSSProperties = {
    position: "fixed",
    top: 0, left: 0, right: 0, bottom: 0,
    backgroundColor: "rgba(0,0,0,0.4)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
};
const modalContentStyle: React.CSSProperties = {
    backgroundColor: "#fff",
    padding: 20,
    borderRadius: 8,
    minWidth: 400
};